import { Refine } from '@pankod/refine-core'
import {
  notificationProvider,
  Layout,
  ErrorComponent,
  ConfigProvider
} from '@pankod/refine-antd'
import { dataProvider, liveProvider } from '@pankod/refine-supabase'
import routerProvider from '@pankod/refine-react-router-v6'
import es_ES from 'antd/lib/locale/es_ES' // eslint-disable-line camelcase

import '@pankod/refine-antd/dist/styles.min.css'

import { Login } from 'pages/login'
import { supabaseClient } from 'utility'
import { Logo } from 'components/logo'
import { useTranslation } from 'react-i18next'

import 'custom.css'
import { Header } from 'components/header'
import { CustomSider } from 'components/sider'
import { authProvider } from 'utility/appUtils/authProvider'
import { grantedResources } from 'utility/appUtils/resourses'

const App: React.FC = () => {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  }

  const locale = i18nProvider.getLocale()

  return (
    <ConfigProvider locale={locale === 'es' ? es_ES : undefined}> { /* eslint-disable-line camelcase */ }
      <Refine
        dataProvider={dataProvider(supabaseClient)}
        liveProvider={liveProvider(supabaseClient)}
        routerProvider={routerProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        LoginPage={Login}
        Title={Logo}
        Header={Header}
        Sider={CustomSider}
        resources={grantedResources()}
        options={{ liveMode: 'auto' }}
        notificationProvider={notificationProvider}
        Layout={Layout}
        catchAll={<ErrorComponent />}
      />
    </ConfigProvider>
  )
}

export default App
