import { useTranslate } from '@pankod/refine-core'
import { Alert, Form, Modal, ModalProps, Select } from '@pankod/refine-antd'
import { useState } from 'react'
import { ENDPOINT_TIPSA, ENDPOINT_CORREOS, API_URL, SHIPMENT_TYPE_DIRECT, SHIPMENT_TYPE_CONSOLIDATED, CORREOS_SERVICE_TYPE_STANDARD_STR, CORREOS_SERVICE_TYPE_PREMIUM_STR } from 'utility/constants'

type ProcessModalProps = {
  modalProps: ModalProps,
  close: any,
  orderId: number | undefined,
  setOrderId?: Function | undefined,
  tableQuery?: any
}

export const ProcessModal: React.FC<ProcessModalProps> = ({ modalProps, close, orderId, setOrderId, tableQuery }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [shipperEndpoint, setShipperEndpoint] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const t = useTranslate()

  const getErrorDescription = (msg: string): string => {
    if (msg === 'Uncomplete data') return t('shipments.errors.uncompletedData')
    return msg
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      const response = await fetch(`${API_URL}/${shipperEndpoint}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        },
        // TODO: Set same cookies as web when login into the dashboard
        credentials: 'include'
      })

      if (!response.ok) {
        const message = await response.text()
        setErrorMsg(getErrorDescription(message))
      } else {
        const data = await response.json()

        if (data.error) {
          setErrorMsg(getErrorDescription(data.message))
        } else {
          setConfirmLoading(false)
          close()
          setErrorMsg('')
          if (setOrderId) setOrderId(0)
          setShipperEndpoint('')
          if (tableQuery) tableQuery.refetch()
        }
      }
    } catch (e) {
      console.log(e)
      setErrorMsg('something wrong')
      setShipperEndpoint('')
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <Modal
      {...modalProps}
      title={t('shipments.actions.processShipment')}
      centered
      onOk={handleOk}
      onCancel={() => {
        setErrorMsg('')
        if (setOrderId) setOrderId(0)
        setShipperEndpoint('')
        close()
        if (tableQuery) tableQuery.refetch()
      }}
      confirmLoading={confirmLoading}
    >
      <Form layout='vertical'>
        <Form.Item
          label={t('shipments.actions.chooseShipper')}
        >
          <Select
          value={shipperEndpoint}
            onChange={(value) => setShipperEndpoint(value)}
            options={[
              { value: '', label: '-----------------' },
              { value: `${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_CONSOLIDATED}`, label: t('shipments.methods.tipsaStandard') },
              { value: `${ENDPOINT_TIPSA}/${orderId}/${SHIPMENT_TYPE_DIRECT}`, label: t('shipments.methods.tipsaExpress') },
              { value: `${ENDPOINT_CORREOS}/${orderId}/${CORREOS_SERVICE_TYPE_STANDARD_STR}`, label: t('shipments.methods.correosStandard') },
              { value: `${ENDPOINT_CORREOS}/${orderId}/${CORREOS_SERVICE_TYPE_PREMIUM_STR}`, label: t('shipments.methods.correosExpress') }
            ]}
          />
        </Form.Item>
      </Form>
      {errorMsg && (
        <Alert
          message={'❌ Error:'}
          description={errorMsg}
          type="error"
        />
      )}
    </Modal>
  )
}
