import { useSelect, useTranslate } from '@pankod/refine-core'
import {
  Form,
  Input,
  FormProps,
  Radio,
  Select,
  InputNumber
} from '@pankod/refine-antd'

import 'react-mde/lib/styles/css/react-mde-all.css'
import { definitions } from 'interfaces'

type ChildCategoryFormProps = {
  formProps: FormProps<{}>
};

export const ChildCategoryForm: React.FC<ChildCategoryFormProps> = (data) => {
  const t = useTranslate()

  const { options } = useSelect<definitions['marketplace_categories']>({
    resource: 'marketplace_categories',
    fetchSize: 50000,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'parent_id',
        operator: 'null',
        value: ''
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  return (
    <Form
      {...data?.formProps}
      layout="vertical"
    >
      <Form.Item
        label={t('marketplace_categories.fields.name')}
        name='name'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.description')}
        name='description'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.weight')}
        name='weight'
        initialValue={1}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.parentCategory')}
        name="parent_id"
      >
          <Select
            options={options}
            getPopupContainer={trigger => trigger.parentElement}
          />
      </Form.Item>
      <Form.Item
        label={t('marketplace.fields.isInHome')}
        name="is_in_home"
      >
        <Radio.Group>
          <Radio value={true}>{t('status.yes')}</Radio>
          <Radio value={false}>{t('status.no')}</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}
