import { ResourceProps } from '@pankod/refine-core'
import { Icons } from '@pankod/refine-antd'
import { OrderList, OrderShow, MarketplaceList, MarketplaceCategoriesList, IslandList, IslandCreate, IslandEdit, IslandShow, PaymentMethodsList, PaymentMethodsCreate, ShipmentMethodsList, IgicTypeList, IgicTypeCreate, BannerList, CmsCategoryList, ShipmentsList, ShipmentEdit, SalesList, PurchaseList, UserList, UserCreate, UserEdit, UserShow, VariablesList, AddressesList, CodesList, SupplierList, configurator, itemCategories, LogsList, IssueList, IssueShow, InvoicesMatchingsList, PromoList } from 'pages'
import { OrderLiveList } from 'pages/ordersLive'
import { CompanyOrderList, CompanyOrderShow } from 'pages/company_order'
import { Reports } from 'pages/reports'
import { supabaseClient } from 'utility/supabaseClient'
import { ADMIN_ROLE_ID, DASHBOARD_REPORTING_USER_ROLE } from 'utility/constants'
import { SellerList } from 'pages/sellers'

export const resources: ResourceProps[] = [
  {
    name: 'ordersLive',
    list: OrderLiveList,
    show: OrderShow,
    icon: <Icons.ShoppingCartOutlined />
  },
  {
    name: 'orders',
    list: OrderList,
    show: OrderShow,
    icon: <Icons.DatabaseOutlined />
  },
  {
    options: {
      label: 'company_order',
      route: 'company_order'
    },
    name: 'company_order',
    list: CompanyOrderList,
    show: CompanyOrderShow,
    icon: <Icons.ReconciliationOutlined />
  },
  {
    name: 'issues',
    list: IssueList,
    show: IssueShow,
    icon: <Icons.IssuesCloseOutlined />
  },
  {
    name: 'shipments',
    list: ShipmentsList,
    edit: ShipmentEdit,
    icon: <Icons.ReconciliationOutlined />
  },
  {
    options: {
      label: 'sales',
      route: 'sales'
    },
    name: 'items',
    list: SalesList,
    icon: <Icons.EuroCircleFilled />
  },
  {
    options: {
      label: 'purchases',
      route: 'purchases'
    },
    name: 'market_orders',
    list: PurchaseList,
    icon: <Icons.ShoppingOutlined />
  },
  {
    name: 'invoices_matchings',
    list: InvoicesMatchingsList,
    icon: <Icons.InboxOutlined />
  },
  {
    name: 'reports',
    list: Reports,
    icon: <Icons.BarChartOutlined />
  },
  {
    name: 'Tiendas',
    icon: <Icons.ShopOutlined />
  },
  {
    name: 'marketplaces',
    parentName: 'Tiendas',
    options: { label: 'marketplaces', route: 'marketplaces' },
    list: MarketplaceList,
    icon: <Icons.ShopOutlined />
  },
  {
    name: 'marketplace_categories',
    parentName: 'Tiendas',
    options: { label: 'marketplace_categories', route: 'marketplace_categories' },
    list: MarketplaceCategoriesList,
    icon: <Icons.FolderOutlined />
  },
  {
    name: 'suppliers',
    parentName: 'Tiendas',
    options: { label: 'suppliers', route: 'suppliers' },
    list: SupplierList,
    icon: <Icons.BoxPlotOutlined />
  },
  {
    name: 'sellers',
    parentName: 'Tiendas',
    options: { label: 'sellers', route: 'sellers' },
    list: SellerList,
    icon: <Icons.ShoppingOutlined />
  },
  {
    name: 'Usuarios',
    icon: <Icons.UserOutlined />
  },
  {
    name: 'users',
    parentName: 'Usuarios',
    options: { label: 'users', route: 'users' },
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
    icon: <Icons.UserOutlined />
  },
  {
    name: 'addresses',
    parentName: 'Usuarios',
    options: { label: 'addresses', route: 'addresses' },
    list: AddressesList,
    icon: <Icons.HomeOutlined />
  },
  {
    name: 'Personalizaciones',
    icon: <Icons.BgColorsOutlined />
  },
  {
    name: 'configurator',
    parentName: 'Personalizaciones',
    options: { label: 'configurator', route: 'configurator' },
    list: configurator,
    icon: <Icons.MobileOutlined />
  },
  {
    name: 'banners',
    parentName: 'Personalizaciones',
    options: { label: 'banners', route: 'banners' },
    list: BannerList,
    icon: <Icons.FileImageOutlined />
  },
  {
    name: 'cms_categories',
    parentName: 'Personalizaciones',
    options: { label: 'cms_categories', route: 'cms_categories' },
    list: CmsCategoryList
  },
  {
    name: 'Configuraciones',
    icon: <Icons.ToolOutlined />
  },
  {
    name: 'islands',
    parentName: 'Configuraciones',
    options: { label: 'islands', route: 'islands' },
    list: IslandList,
    create: IslandCreate,
    edit: IslandEdit,
    show: IslandShow,
    icon: <Icons.EnvironmentOutlined />
  },
  {
    name: 'payment_methods',
    parentName: 'Configuraciones',
    options: { label: 'payment_methods', route: 'payment_methods' },
    list: PaymentMethodsList,
    create: PaymentMethodsCreate,
    icon: <Icons.CreditCardOutlined />
  },
  {
    name: 'shipment_methods',
    parentName: 'Configuraciones',
    options: { label: 'shipment_methods', route: 'shipment_methods' },
    list: ShipmentMethodsList,
    icon: <Icons.RocketOutlined />
  },
  {
    name: 'igic_types',
    parentName: 'Configuraciones',
    options: { label: 'igic_types', route: 'igic_types' },
    list: IgicTypeList,
    create: IgicTypeCreate,
    icon: <Icons.PayCircleOutlined />
  },
  {
    name: 'item_categories',
    parentName: 'Configuraciones',
    options: { label: 'item_categories', route: 'item_categories' },
    list: itemCategories,
    icon: <Icons.CalculatorOutlined/>
  },
  {
    name: 'codes',
    parentName: 'Configuraciones',
    options: { label: 'codes', route: 'codes' },
    list: CodesList,
    icon: <Icons.BarcodeOutlined />
  },
  {
    name: 'promo',
    parentName: 'Configuraciones',
    options: { label: 'promo', route: 'promo' },
    list: PromoList,
    icon: <Icons.QrcodeOutlined />
  },
  {
    name: 'variables',
    parentName: 'Configuraciones',
    options: { label: 'variables', route: 'variables' },
    list: VariablesList,
    icon: <Icons.GlobalOutlined />
  },
  {
    name: 'logs',
    list: LogsList,
    icon: <Icons.AlignLeftOutlined />
  }
]

export const grantedResources = ():ResourceProps[] => {
  const role = supabaseClient.auth.user()?.user_metadata?.role_id

  let granted = resources
  if (role === DASHBOARD_REPORTING_USER_ROLE) granted = granted.filter(r => r.name === 'reports')
  else if (role !== ADMIN_ROLE_ID) granted = granted.filter(r => r.name !== 'reports')

  return granted
}
