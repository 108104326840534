import { AutoComplete, Icons, Input } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useEffect, useState } from 'react'

import { supabaseClient } from 'utility'

const { SearchOutlined } = Icons

type MarketplacesFinderProps = {
  updateData: Function
}

export const MarketplacesFinder: React.FC<MarketplacesFinderProps> = ({ updateData }) => {
  const t = useTranslate()

  const [value, setValue] = useState<string>('')
  const [options, setOptions] = useState<any[]>([])

  const updateSelected = (item: any) => {
    updateData(item)
  }

  const renderItem = (label: string, item: any) => {
    return (
      <div onClick={(e) => {
        e.preventDefault()
        updateSelected(item)
      }}>
        <p style={{ height: '15px', textAlign: 'start' }}>{label}</p>
      </div>
    )
  }

  const refetchPosts = async () => {
    const { data: markets, error: errorMarkets } = await supabaseClient.from('marketplaces')
      .select('id, name')
      .ilike('name', `%${value}%`)
      .eq('deleted', false)

    if (errorMarkets) {
      return
    }

    if (markets) {
      const data = [...markets]
      data.forEach(item => {
        item.label = renderItem(`${item.name}`, item)
      })

      if (data.length > 0) {
        setOptions([
          {
            label: (<p>{'Marketplaces'}</p>),
            options: data
          }
        ])
      }
    }
  }

  useEffect(() => {
    setOptions([])
    refetchPosts()
  }, [value])

  return (
    <>
      <AutoComplete
        style={{ width: '100%', marginBottom: '1rem' }}
        filterOption={false}
        options={options}
        onSearch={(value: string) => setValue(value)}
      >
        <Input
          size="large"
          placeholder={t('codes.fields.searchMarketText')}
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
    </>
  )
}
