import { useState } from 'react'
import { useShow, IResourceComponentsProps } from '@pankod/refine-core'
import {
  Show,
  Typography,
  Alert,
  Button,
  ListButton,
  EditButton,
  RefreshButton,
  Tag
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography

export const IslandShow: React.FC<IResourceComponentsProps> = () => {
  const [isDeprecated, setIsDeprecated] = useState(false)
  const { t } = useTranslation()

  const { queryResult } = useShow<definitions['islands']>({
    liveMode: 'manual',
    onLiveEvent: () => {
      setIsDeprecated(true)
    }
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const handleRefresh = () => {
    queryResult.refetch()
    setIsDeprecated(false)
  }

  const rows = [
    {
      label: t('islands.fields.id'),
      value: record?.id
    },
    {
      label: t('islands.fields.name'),
      value: record?.name
    },
    {
      label: t('islands.fields.province'),
      value: record?.province
    },
    {
      label: t('islands.fields.cpPrefixes'),
      value: record?.cp_prefixes
    },
    {
      label: t('islands.fields.planeExtraCost'),
      value: `${record?.plane_extra_cost} €`
    },
    {
      label: t('islands.fields.boatExtraCost'),
      value: `${record?.boat_extra_cost} €`
    }
  ]

  return (
        <Show
            isLoading={isLoading}
            pageHeaderProps={{
              extra: (
                    <>
                        <ListButton />
                        <EditButton />
                        <RefreshButton onClick={handleRefresh} />
                    </>
              )
            }}
        >
            {isDeprecated && (
                <Alert
                    message="This post is changed. Reload to see it's latest version."
                    type="warning"
                    style={{
                      marginBottom: 20
                    }}
                    action={
                        <Button
                            onClick={handleRefresh}
                            size="small"
                            type="ghost"
                        >
                            Refresh
                        </Button>
                    }
                />
            )}

            {rows.map(row => {
              return <div key={row.label}>
                    <Title level={5}>{row.label}</Title>
                    <Text>
                        {row.value !== record?.cp_prefixes
                          ? row.value
                          : row.value?.map(prefix => {
                            return <Tag key={prefix}>{prefix}</Tag>
                          })}
                    </Text>
                </div>
            })}
        </Show>
  )
}
