import {
  List,
  Table,
  Space,
  EditButton,
  useDrawerForm,
  CreateButton,
  useModal,
  RefreshButton,
  Form,
  Select,
  useEditableTable,
  SaveButton,
  Button
} from '@pankod/refine-antd'
import { GetOneResponse, HttpError, useSelect, useTranslate } from '@pankod/refine-core'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { RefundActions } from 'components/refund/actions'
import { definitions, Order } from 'interfaces'
import { useEffect, useState } from 'react'
import { currency } from 'utility/currency'
import { EditOutlined } from '@ant-design/icons'
import { QueryObserverResult } from '@tanstack/react-query'

type RefundsProps = {
  order: definitions['Order']
  orderQuery: QueryObserverResult<GetOneResponse<Order>, unknown>
  setRefQuery?: any
};

export const Refunds: React.FC<RefundsProps> = ({ order, orderQuery, setRefQuery }) => {
  const { modalProps, show, close } = useModal()
  const t = useTranslate()

  const [id, setId] = useState(0)

  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    tableQueryResult
  } = useEditableTable<definitions['refunds'], HttpError>({
    resource: 'refunds',
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'order_id',
        operator: 'eq',
        value: order.id
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    initialPageSize: 4,
    syncWithLocation: false,
    metaData: {
      select: '*'
    }
  })

  const { options: statusOptions } = useSelect<definitions['status']>({
    resource: 'status',
    fetchSize: 30,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    filters: [
      {
        field: 'id',
        operator: 'in',
        value: [0, 6]
      }
    ]
  })

  useEffect(() => {
    if (tableQueryResult.status === 'success') {
      setRefQuery(tableQueryResult)
    }
  }, [tableQueryResult])

  const handleRefresh = () => {
    tableQueryResult.refetch()
  }

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['refunds']>({
    action: 'create',
    resource: 'refunds',
    redirect: false
  })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    close: editClose,
    saveButtonProps: editSaveButtonProps
  } = useDrawerForm<definitions['refunds']>({
    action: 'edit',
    resource: 'refunds',
    redirect: false
  })

  createFormProps.initialValues = {
    order_id: order?.id
  }

  editFormProps.initialValues = {
    ...editFormProps.initialValues,
    order_id: order?.id
  }

  let totalClient = 0
  let totalSupplier = 0
  let totalSuplements = 0
  if (order?.refunds && order?.refunds.length > 0) {
    totalClient = order.refunds.map((refund) => {
      if (refund.type === 'client') return refund.amount
      else return 0
    }).reduce((prev, next) => prev + next)
    totalSupplier = order.refunds.map((refund) => {
      if (refund.type === 'supplier') return refund.amount
      else return 0
    }).reduce((prev, next) => prev + next)
  }
  if (order?.supplements && order?.supplements.length > 0) {
    totalSuplements = order.supplements.map((supplement) => {
      if (supplement.description === 'penalization') return supplement.supplement || 0
      else return 0
    }).reduce((prev, next) => prev + next)
  }

  return (
    <>
      <List
        title={`${t('refund.refunds')} - ${t('refund.totalClient')}: ${currency(totalClient - totalSuplements)} / ${t('refund.totalSupplier')}: ${currency(totalSupplier)}`}
        breadcrumb={false}
        pageHeaderProps={{
          extra: (
            <>
              <CreateButton onClick={() => createShow()}>
                {t('order.actions.addRefund')}
              </CreateButton>
              <RefreshButton onClick={handleRefresh} />
            </>
          )
        }}
      >
        <Form {...formProps}>
          <Table
            {...tableProps}
            rowKey="id"
            onRow={(record) => ({
              onClick: (event: any) => {
                if (event.target.classList.contains('status_id') || event.target.closest('.status_id')) {
                  setEditId && setEditId(record.id)
                }
              }
            })}
          >
            <Table.Column
              key="description"
              dataIndex="description"
              title={t('refund.fields.description')}
              align='center'
              sorter
              render={(value: string, record: definitions['refunds']) =>
                (record.description === 'refundTransport' || record.description === 'refundByCancelation' || record.description === 'refundStore' || record.description === 'unified')
                  ? t(`supplement.descriptions.${record.description}`)
                  : record.description
              }
            />
            <Table.Column
              key="amount"
              dataIndex="amount"
              title={t('refund.fields.amount')}
              render={(value) => currency(value)}
              align='center'
              sorter
            />
            <Table.Column
              key="type"
              dataIndex="type"
              title={t('refund.fields.type')}
              render={(value) => value ? t(`refund.${value}`) : ''}
              align='center'
              sorter
            />
            <Table.Column
              dataIndex={'status_id'}
              align="center"
              className='status_id'
              title={t('refund.fields.status')}
              render={(value, data: definitions['refunds']) => {
                if (isEditing(data.id)) {
                  return (
                    <Form.Item
                      name='status_id'
                      style={{ margin: 0 }}
                    >
                      <Select
                        style={{ minWidth: 200 }}
                        showSearch
                        options={statusOptions}
                        filterOption={(input, option) => !!option?.label?.toLocaleLowerCase().includes(input.toLowerCase())}
                      />
                    </Form.Item>
                  )
                }

                return <>
                  <Form.Item style={{ minWidth: 200 }}>
                    {statusOptions.filter(s => s.value === value)[0]?.label}
                    <EditOutlined style={{ marginLeft: 10 }}/>
                  </Form.Item>

                </>
              }}
              sorter
            />
            <Table.Column<definitions['refunds']>
              title={t('table.actions')}
              dataIndex="actions"
              align='center'
              render={(_, record) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton
                        {...saveButtonProps}
                        hideText
                        size="small"
                        onClick={ () => {
                          saveButtonProps.onClick()
                          setTimeout(() => orderQuery.refetch(), 200)
                        }}
                      />
                      <Button
                        {...cancelButtonProps}
                        size="small"
                      >
                        {t('buttons.cancel')}
                      </Button>
                    </Space>
                  )
                }

                return (
                  <Space wrap>
                    <EditButton
                      onClick={() => editShow(record.id)}
                      hideText
                      size='small'
                    />
                    <ButtonLogicDelete click={() => {
                      show()
                      setId(record.id)
                    }} />
                    <DeleteModal
                      modalProps={modalProps}
                      close={close}
                      id={id}
                      entity='refunds'
                      tableQueryResult={tableQueryResult}
                    />
                  </Space>
                )
              }}
            />
          </Table>
        </Form>
        <RefundActions
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          closeDrawer={createClose}
          refundsQuery={tableQueryResult}
          orderQuery={orderQuery}
        />
        <RefundActions
          edit={true}
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          closeDrawer={editClose}
          refundsQuery={tableQueryResult}
          orderQuery={orderQuery}
        />
      </List>
    </>
  )
}
