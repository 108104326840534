import { Button, Col, DatePicker, Form, Icons, Input, Row } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

type FilterMarketplaceProps = {
  searchFormProps: any
}

export const MarketFilter: React.FC<FilterMarketplaceProps> = ({ searchFormProps }) => {
  const t = useTranslate()

  const { RangePicker } = DatePicker

  return (
    <Row gutter={[5, 5]}>
      <Form {...searchFormProps}>
        <Col lg={24} xs={24}>
          <Form.Item label="Marca" name="name">
            <Input
              placeholder={t('marketplace.fields.name')}
              prefix={<Icons.SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item label="Fecha de creación" name="createdAt">
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Row>
  )
}
