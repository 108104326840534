import { useShow, IResourceComponentsProps } from '@pankod/refine-core'

import {
  Show,
  Typography,
  MarkdownField,
  Alert,
  Button,
  ListButton,
  EditButton,
  RefreshButton,
  DateField
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_DATE_TIME_FORMAT } from 'utility'

const { Title, Text } = Typography

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation()
  const [isDeprecated, setIsDeprecated] = useState(false)

  const { queryResult } = useShow<definitions['users']>({
    liveMode: 'manual',
    onLiveEvent: () => {
      setIsDeprecated(true)
    }
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  const handleRefresh = () => {
    queryResult.refetch()
    setIsDeprecated(false)
  }

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton />
            <EditButton />
            <RefreshButton onClick={handleRefresh} />
          </>
        )
      }}
    >
      {isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={{ marginBottom: 20 }}
          action={
            <Button
              onClick={handleRefresh}
              size="small"
              type="ghost"
            >
              Refresh
            </Button>
          }
        />
      )}

      <Title level={5}>{t('users.id')}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{t('users.email')}</Title>
      <Text>{record?.email}</Text>

      <Title level={5}>{t('users.created_at')}</Title>
      <Text><DateField format={DEFAULT_DATE_TIME_FORMAT} value={record?.created_at}/></Text>

      <Title level={5}>{t('users.userSupabaseId')}</Title>
      <MarkdownField value={record?.supabase_user_id} />

    </Show>
  )
}
