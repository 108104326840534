import { Button, Icons, InputNumber, Modal, Typography, useModal, DatePicker } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { User } from 'interfaces'
import { useState } from 'react'
import { Dayjs } from 'dayjs'
import { REFERRAL_BENEFIT_STATUS_MODIFIED, SUPERUSER_WALLET_LIST, SYNIVA_DEFAULT_USER, supabaseClient } from 'utility'

type ModalProps = {
  user: User
}

export const AddAmountToWalletModal: React.FC<ModalProps> = ({ user }) => {
  const { modalProps, show, close } = useModal()
  const { open } = useNotification()
  const [moneyToAdd, setMoneyToAdd] = useState<number>(0)
  const [walletBalance, setWalletBalance] = useState<number>(user.wallet_balance)
  const [dateLimit, setDateLimit] = useState<Dayjs | null>(null)
  const t = useTranslate()

  const handleOk = async () => {
    let valid = false
    console.log(!!user.id, dateLimit, moneyToAdd, walletBalance)

    if (moneyToAdd > 0) valid = (!!user.id && moneyToAdd > 0 && dateLimit !== null)
    else valid = (!!user.id && moneyToAdd < 0)

    console.log(valid)

    if (!valid) {
      open?.({
        type: 'error',
        description: t('addToWallet.emptyInputMessage'),
        message: ''
      })
      return null
    }

    const { data, error } = await supabaseClient
      .from('users')
      .update({
        wallet_balance: walletBalance
      })
      .eq('id', user.id)
    if (data) {
      await supabaseClient
        .from('referral_benefits')
        .insert({
          referrer_user_id: SYNIVA_DEFAULT_USER,
          referred_user_id: user.id,
          benefit_amount: moneyToAdd,
          status: REFERRAL_BENEFIT_STATUS_MODIFIED,
          referred_expired_at: dateLimit?.toISOString() || new Date(Date.now()).toISOString(),
          referrer_expired_at: dateLimit?.toISOString() || new Date(Date.now()).toISOString(),
          increased_by_syniva: true
        })
      open?.({
        type: 'success',
        message: t('addToWallet.successMessage', { email: user.email }),
        description: t('addToWallet.successDescription')
      })
      close()
    } else if (error) {
      open?.({
        type: 'error',
        message: t('addToWallet.errorMessage', { email: user.email }),
        description: t('addToWallet.errorDescription')
      })
    }
  }

  const showModal = () => {
    setMoneyToAdd(0)
    setWalletBalance(user.wallet_balance)
    setDateLimit(null)
    show()
  }

  const checkPermission = () => {
    const currentUser = supabaseClient.auth.user()?.email || ''
    return SUPERUSER_WALLET_LIST.includes(currentUser)
  }

  return (
    <>
      <Button onClick={showModal} disabled={!checkPermission()} size="small" className="ant-btn ant-btn-primary">
        <Icons.WalletOutlined />
      </Button>

      <Modal
        {...modalProps}
        title={t('addToWallet.insertMoney', { email: user.email })}
        centered
        onOk={handleOk}
        onCancel={close}
        style={{ minWidth: '40svw' }}
      >
          <Typography>{t('addToWallet.nextMoney')}</Typography>
          <InputNumber value={moneyToAdd} style={{ width: '150px', marginTop: '15px' }} onChange={(e) => {
            setMoneyToAdd(e)
            setWalletBalance((user.wallet_balance + (e) < 0) ? 0 : user.wallet_balance + (e))
          }}></InputNumber>

          <Typography>{t('addToWallet.actualMoney')}</Typography>
          <InputNumber disabled value={walletBalance} style={{ width: '150px', marginTop: '15px' }} onChange={(e) => {
            setWalletBalance(e)
          }}></InputNumber>

          <Typography>{t('addToWallet.expiredDate')}</Typography>
          <DatePicker style={{ width: '150px', marginTop: '15px' }} onChange={(e) => {
            setDateLimit(e)
          }} />
      </Modal>
    </>
  )
}
