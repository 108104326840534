import { useTranslate } from '@pankod/refine-core'

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  ButtonProps,
  Grid,
  InputNumber,
  Input,
  Row,
  Col
} from '@pankod/refine-antd'
import { supabaseClient } from 'utility'

type EditCompanyOrderProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
}

export const CompanyOrderEditDrawer: React.FC<EditCompanyOrderProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        title={t('company_order.editBusinessOrder')}
        breadcrumb={false}
        goBack={false}
        headerButtons={true}
        saveButtonProps={{
          onClick: async (e: any) => {
            await supabaseClient.from('company_order')
              .update({
                updated_at: new Date(Date.now()).toISOString(),
                updated_by: supabaseClient.auth.user()?.email || null
              })
              .eq('id', formProps.initialValues?.id)
            if (saveButtonProps && saveButtonProps.onClick) saveButtonProps?.onClick(e)
          }
        }}
      >
        <Form {...formProps} layout="vertical">
          <Row style={{ display: 'flex' }}>
            <Col>
              <Form.Item
                label={t('company_order.fields.vol_weight')}
                name="vol_weight"
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '30px' }}>
              <Form.Item
                label={t('company_order.fields.vol_price')}
                name="vol_price"
              >
                <InputNumber/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('company_order.fields.invoice_number')}
            name="invoice_number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('company_order.fields.location')}
            name="location"
          >
            <Input/>
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  )
}
