import { useTranslate, IResourceComponentsProps, getDefaultFilter } from '@pankod/refine-core'
import {
  List,
  Table,
  Form,
  Space,
  Button,
  SaveButton,
  EditButton,
  Select,
  Input,
  useEditableTable,
  FilterDropdown,
  FilterDropdownProps,
  Radio,
  useModal,
  Grid,
  useDrawerForm,
  CreateButton,
  BooleanField
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { currency } from 'utility/currency'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { PriceRangeTable } from './priceRangeTable'
import { ShipmentMethodsCreate } from './create'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'

const expandedRowRender = (record: definitions['shipment_methods']) => {
  return <PriceRangeTable record={record} />
}

export const ShipmentMethodsList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    filters,
    setFilters,
    tableQueryResult
  } = useEditableTable<definitions['shipment_methods']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'id, name, price, shipment_type, volumetric_factor, price_additional_kg, is_in_home'
    }
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['shipment_methods']>({
    action: 'create',
    resource: 'shipment_methods',
    redirect: false
  })

  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const breakpoint = Grid.useBreakpoint()

  return (
    <List title={t('shipment_methods.titles.list')}>
      <CreateButton onClick={() => createShow()} style={{ marginTop: -50, float: 'right' }}>
        {t('shipment_methods.titles.addMethod')}
      </CreateButton>
      <Form {...formProps}>
        <Table
          {...tableProps}
          expandable={{
            expandedRowRender: !breakpoint.xs
              ? expandedRowRender
              : undefined
          }}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title={t('shipment_methods.fields.id')}
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="shipment_type"
            title={t('shipment_methods.fields.shipment_type')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="shipment_type"
                    style={{ margin: 0 }}
                  >
                    <Select options={[
                      { label: `${t('shipment_methods.shipment_types.plane')}`, value: 'plane' },
                      { label: `${t('shipment_methods.shipment_types.boat')}`, value: 'boat' }
                    ]} />
                  </Form.Item>
                )
              }
              return t(`shipment_methods.shipment_types.${value}`)
            }
          }
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                  <Radio.Group>
                      <Radio value='plane'>{t('shipment_methods.shipment_types.plane')}</Radio>
                      <Radio value='boat'>{t('shipment_methods.shipment_types.boat')}</Radio>
                  </Radio.Group>
              </FilterDropdown>
            )}
            defaultFilteredValue={getDefaultFilter('id', filters)}
          />

          <Table.Column
            dataIndex="name"
            title={t('shipment_methods.fields.name')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="name"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return value
            }}
            sorter
            key="id"
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='shipment_methods'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column
            key='price'
            dataIndex="price"
            title={t('shipment_methods.fields.price')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="price"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return currency(value)
            }}
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='price'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key='volumetric_factor'
            dataIndex="volumetric_factor"
            title={t('shipment_methods.fields.volumetric_factor')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="volumetric_factor"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return value
            }}
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='volumetric_factor'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key='price_additional_kg'
            dataIndex="price_additional_kg"
            title={t('shipment_methods.fields.price_additional_kg')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="price_additional_kg"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return currency(value)
            }}
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='price_additional_kg'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key='is_in_home'
            dataIndex="is_in_home"
            title={t('marketplace.fields.isInHome')}
            align='center'
            render={(value, data: definitions['shipment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="is_in_home"
                    style={{ margin: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={true}>{t('status.yes')}</Radio>
                      <Radio value={false}>{t('status.no')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                )
              }
              return (
                <BooleanField
                  value={value}
                  valueLabelTrue={t('status.yes')}
                  valueLabelFalse={t('status.no')}
                />
              )
            }}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Radio.Group>
                  <Radio value='true'>{t('status.yes')}</Radio>
                  <Radio value='false'>{t('status.no')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
          />
          <Table.Column<definitions['shipment_methods']>
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                  <Space>
                    <EditButton
                      {...editButtonProps(record.id)}
                      hideText
                      size="small"
                    />
                    <ButtonLogicDelete
                      click={() => {
                        show()
                        setId(record.id)
                      }}
                    />
                    <DeleteModal
                      modalProps={modalProps}
                      close={close}
                      id={id}
                      entity="shipment_methods"
                      tableQueryResult={tableQueryResult}
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
      <ShipmentMethodsCreate
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </List>
  )
}
