import { ACEPTED_STATUS_TEXT, ADVERTISEMENT_STATUS_TEXT, CANCELLED_STATUS_TEXT, SENT_BUDGE_STATUS_TEXT, BUSINESS_STATUS_CANCELLED_ID, BUSINESS_STATUS_DELIVERED_ID, BUSINESS_STATUS_LABELLED_ID, BUSINESS_STATUS_RECEIVED_ID, BUSINESS_STATUS_REQUESTED_ID, BUSINESS_STATUS_SENT_ID, PROCESSED_STATUS } from './constants'

export const getColorLabel = (status: string | undefined, variant?: number | null): string => {
  if (status === 'solicitado') return 'gold'
  if (status === 'procesado') return 'blue'
  if (status === 'cancelado') return 'red'
  if (status === 'penalizacion') return 'red'
  if (status === 'devolucion') return 'orange'
  if (status === 'suplemento') return variant && variant < PROCESSED_STATUS ? 'gold' : 'lime'
  if (status === 'admitido') return 'cyan'
  if (status === 'rotura') return 'magenta'
  if (status === 'preparado') return 'geekblue'
  if (status === 'expedido') return 'volcano'
  if (status === 'enviado') return 'purple'
  if (status === 'entregado') return 'green'

  return ''
}

export const getColorLabelEstimatedCost = (status: string | undefined): string => {
  let color = ''
  switch (status) {
    case ADVERTISEMENT_STATUS_TEXT:
      color = 'gray'
      break
    case SENT_BUDGE_STATUS_TEXT:
      color = 'gold'
      break
    case ACEPTED_STATUS_TEXT:
      color = 'green'
      break
    case CANCELLED_STATUS_TEXT:
      color = 'red'
      break
  }

  return color
}

export const getColorBusinessLabel = (id: number) => {
  let color = ''
  switch (id) {
    case BUSINESS_STATUS_REQUESTED_ID:
      color = 'gold'
      break
    case BUSINESS_STATUS_LABELLED_ID:
      color = 'blue'
      break
    case BUSINESS_STATUS_RECEIVED_ID:
      color = 'cyan'
      break
    case BUSINESS_STATUS_SENT_ID:
      color = 'purple'
      break
    case BUSINESS_STATUS_DELIVERED_ID:
      color = 'green'
      break
    case BUSINESS_STATUS_CANCELLED_ID:
      color = 'red'
      break
  }
  return color
}
