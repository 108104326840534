import { ExportButton } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { ComponentProps, useState } from 'react'
import { XOR } from 'types/utilities'
import { CURRENT_DATE_TIME } from 'utility'
import { utils, writeFile } from 'xlsx'

type JsonDataExport = {
  jsonData: any[]
}

export type Filters = {
  dates?: [string, string]
}

type AsyncFunctionExport = {
  asyncFunction: (filters?: Filters) => Promise<any[]>
}

type DataToExport = XOR<JsonDataExport, AsyncFunctionExport>

type ExportToExcelButtonProps = {
  filename: string
  exportButtonName?: string
  close?: () => void
  filters?: Filters
} & DataToExport

type ExportToExcelProps = Omit<ComponentProps<typeof ExportButton>, 'onClick' | 'loading'> & ExportToExcelButtonProps

export const ExportToExcelButton: React.FC<ExportToExcelProps> = ({
  filename,
  exportButtonName = '',
  close,
  jsonData,
  asyncFunction,
  filters,
  ...props
}) => {
  const t = useTranslate()
  const { open } = useNotification()

  const [loading, setLoading] = useState<boolean>(false)

  filename = `${filename} ${CURRENT_DATE_TIME}.xlsx`

  const autofitColumns = (json: any[]) => {
    const jsonKeys = Object.keys(json[0])
    const objectMaxLength: any = []

    for (let i = 0; i < json.length; i++) {
      const value = json[i]
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0
          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
        }
      }

      const key = jsonKeys
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length
      }
    }

    return objectMaxLength.map((w: number) => {
      return { width: w }
    })
  }

  const exportToExcel = (res?: any[]) => {
    const dataToExport = res || jsonData
    if (!dataToExport?.length) {
      open?.({
        type: 'error',
        message: t('notifications.noDataToExport'),
        description: t('notifications.errorMsg')
      })
      return
    }

    const worksheet = utils.json_to_sheet(dataToExport)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    worksheet['!cols'] = autofitColumns(dataToExport)
    writeFile(workbook, filename)
    close?.()
  }

  return <ExportButton
    {...props}
    onClick={async () => {
      setLoading(true)
      if (asyncFunction) {
        const results = await asyncFunction(filters)
        setLoading(false)
        return exportToExcel(results)
      }
      setLoading(false)
      return exportToExcel()
    }}
    loading={loading}
  >
  {(exportButtonName === '') ? t('buttons.export') : exportButtonName}</ExportButton>
}
