import { Button, Icons, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import moment from 'moment'
import { MouseEvent, useState } from 'react'
import { API_URL, DEFAULT_DATE_TIME_FORMAT } from 'utility/constants'
import { supabaseClient } from 'utility/supabaseClient'

type SendEmailButtonProps = {
  supplement: definitions['supplements']
  refreshTable: Function
  isSupplement: boolean
  isEstimatedCost: boolean
}

export const SendEmailButton: React.FC<SendEmailButtonProps> = ({ supplement, refreshTable, isSupplement, isEstimatedCost }) => {
  const { Text } = Typography
  const t = useTranslate()

  const [loading, setLoading] = useState<boolean>(false)

  const sendEmail = async (e: MouseEvent) => {
    if (supplement.email_sent_at === null) {
      setLoading(true)
      // Prepare request
      const options: RequestInit = {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          type: 'INSERT',
          table: 'supplements',
          record: supplement,
          fromButton: true
        })
      }

      // Request to send email
      const response = await fetch(`${API_URL}/email`, options)

      if (response.ok) {
        const timeMoment = moment()

        await supabaseClient
          .from('supplements')
          .update({ email_sent_at: timeMoment })
          .eq('id', supplement.id)

        supplement.email_sent_at = timeMoment.format(DEFAULT_DATE_TIME_FORMAT)
      }
      setLoading(false)
      refreshTable()
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {(supplement.volumetric && isSupplement) && <Button
        loading={loading}
        disabled={loading || (supplement.email_sent_at !== null) || (supplement.description === 'refundByCancelation') || (supplement.description === 'igic') || (supplement.description === 'shippingInsurancePrice') || (supplement.parent !== null) }
        onClick={(e) => sendEmail(e)}>
          <Icons.MailOutlined />
          {t('buttons.send')}
      </Button>}
      {(supplement.volumetric && isEstimatedCost) && <Button
        loading={loading}
        disabled={loading || (supplement.email_sent_at !== null)}
        onClick={(e) => sendEmail(e)}>
          <Icons.MailOutlined />
          {t('buttons.send')}
      </Button>}
      {supplement.email_sent_at &&
        <Text style={{ marginTop: 10, textAlign: 'center', display: 'flex', alignItems: 'center' }}>
          <Icons.CheckCircleOutlined style={{ color: '#67be23', fontSize: 18, marginRight: 6 }} />
          {moment(supplement.email_sent_at).format(DEFAULT_DATE_TIME_FORMAT)}
        </Text>
      }
    </div>
  )
}
