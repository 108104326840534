import { currencyValue as round } from './currency'

export const ivaQty = (quantity: number, iva: number): number => round(quantity - priceWithoutIva(quantity, iva))

export const priceWithoutIva = (quantity: number, iva: number): number => round((quantity / ((iva / 100) + 1)))

export const calcTariffPrice = (quantity: number, synivaPrice: number, tariffPercent: number) => round(quantity * (synivaPrice * (tariffPercent / 100)))

export const calcIgicPrice = (quantity: number, synivaPrice: number, igicPercent: number) => round(quantity * (synivaPrice * (igicPercent / 100)))

export const getIvaOfAmount = (amount: number, iva: number) => amount / ((iva / 100) + 1)
