import { ReactNode, useState } from 'react'
import { notification, Progress } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

export type EventSourceSyncHookProps = {
  headerText: string
  completedText: string
  errorDescText: string
  refetchFn: () => void
}

export const useEventSourceSync = ({ headerText, completedText, errorDescText, refetchFn }: EventSourceSyncHookProps) => {
  const t = useTranslate()
  const [loading, setLoading] = useState(false)

  const openNotification = (type: 'info' | 'error' | 'success', message: string, description: ReactNode, duration = 0) => {
    notification.open({
      key: 'event-source-sync',
      type,
      message,
      description,
      duration,
      placement: 'bottomRight'
    })
  }

  const handleEventSourceMessage = (eventSource: EventSource) => (event: MessageEvent) => {
    const data = JSON.parse(event.data)
    if (data.error) {
      openNotification('error', t('notifications.errorMsg'), errorDescText)
      eventSource.close()
      refetchFn()
      setLoading(false)
      return
    }

    openNotification('info', headerText, (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Progress percent={data.progress} style={{ flex: 1 }} />
      </div>
    ))

    if (data.status === 'completed') {
      eventSource.close()
      openNotification('success', completedText, (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Progress percent={100} style={{ flex: 1 }} />
        </div>
      ), 5)
      refetchFn()
      setLoading(false)
    }
  }

  const handleEventSourceError = (eventSource: EventSource) => () => {
    eventSource.close()
    openNotification('error', errorDescText, t('notifications.errorMsg'))
    refetchFn()
    setLoading(false)
  }

  const startEventSource = (url: string) => {
    setLoading(true)

    const eventSource = new EventSource(url)

    openNotification('info', headerText, (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Progress percent={0} style={{ flex: 1 }} />
      </div>
    ))

    eventSource.onmessage = handleEventSourceMessage(eventSource)
    eventSource.onerror = handleEventSourceError(eventSource)
  }

  return { loading, startEventSource }
}
