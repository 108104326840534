import {
  ButtonProps,
  Edit,
  Drawer,
  DrawerProps,
  FormProps,
  Grid
} from '@pankod/refine-antd'
import { MarketplaceCategoriesForm } from './form'

import 'react-mde/lib/styles/css/react-mde-all.css'

type EditCategoryProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
};

export const MarketplaceCategoriesEdit: React.FC<EditCategoryProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource='marketplace_categories'
      >
        <MarketplaceCategoriesForm formProps={formProps} />
      </Edit>
    </Drawer>
  )
}
