import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Create,
  Row,
  Col,
  Input
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { DASHBOARD_BUSINESS_USER_ROLE } from 'utility'
import supabaseSuper from 'utility/superClient'

type CreateOrderProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  closeDrawer: Function
  tableQueryResult: any
}

export const CreateUserButton: React.FC<CreateOrderProps> = ({
  drawerProps,
  formProps,
  closeDrawer,
  tableQueryResult
}) => {
  const [userName, setUserName] = useState<string>()
  const [userEmail, setUserEmail] = useState<string>()
  const [userPassword, setUserPassword] = useState<string>()
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const createUser = async () => {
    try {
      const { error } = await supabaseSuper.auth.api.createUser({
        email: userEmail,
        password: userPassword,
        email_confirm: true
      })

      if (error) throw Error(error.message)

      const { error: errorUpdate } = await supabaseSuper
        .from('users')
        .update({
          name: userName,
          role_id: DASHBOARD_BUSINESS_USER_ROLE
        })
        .eq('email', userEmail)

      if (errorUpdate) throw Error(errorUpdate.message)

      tableQueryResult.refetch()
      closeDrawer()
      // window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="users"
        breadcrumb={false}
        goBack={false}
        title={t('users.actions.addUser')}
        saveButtonProps={{
          onClick: () => createUser()
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
        >
          <br />
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('marketplace.fields.name')}
                name="name"
                rules={[{ required: true }]}
              >
                <Input onChange={(value) => { setUserName(value.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item
                label={t('pages.register.fields.email')}
                name="email"
                rules={[{ required: true }]}
              >
                <Input onChange={(value) => { setUserEmail(value.target.value) }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('pages.register.fields.password')}
                name="password"
                rules={[{ required: true }]}
              >
                <Input onChange={(value) => { setUserPassword(value.target.value) }}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
