import { useTranslate, useSelect } from '@pankod/refine-core'

import {
  Form,
  Select,
  useForm
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'
import { useEffect, useState } from 'react'

type MarketplaceInternalsFormProps = {
  marketplaceId: number
  parentSetInternalShopId: Function
}

export const InternalShopForm: React.FC<MarketplaceInternalsFormProps> = ({ marketplaceId, parentSetInternalShopId }) => {
  const { formProps: internalMarketPlaceFormProps } = useForm<any>()
  const [internalShop, setInternalShop] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  const t = useTranslate()

  const { options } = useSelect<definitions['marketplaces']>({
    resource: 'marketplaces',
    fetchSize: 10000,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'is_custom_shop',
        operator: 'eq',
        value: false
      }
    ]
  })

  const getInternalMarketplace = async () => {
    const { data } = await supabaseClient
      .from('marketplaces')
      .select('internal_shop_id')
      .eq('id', marketplaceId).single()

    return data
  }

  useEffect(() => {
    parentSetInternalShopId(internalShop)
  }, [internalShop])

  useEffect(() => {
    if (marketplaceId) {
      getInternalMarketplace().then((data) => {
        setInternalShop(data.internal_shop_id || undefined)
        setIsLoading(false)
      })
    }
  }, [marketplaceId])

  const updateInternalMarketplaces = async (internalMarketplace: any) => {
    await supabaseClient
      .from('marketplaces')
      .update({ internal_shop_id: internalMarketplace })
      .eq('id', marketplaceId)
  }

  if (isLoading) {
    return <></>
  }

  return (
    <>
    {Number.isInteger(marketplaceId) && (
      <Form
        {...internalMarketPlaceFormProps}
        layout='vertical'
      >
        <Form.Item
          label={t('marketplace.fields.internalshop')}
          name="internal_shop_id"
          initialValue={internalShop}
        >
          <Select
            showSearch
            options={options}
            getPopupContainer={trigger => trigger.parentElement}
            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
            onChange={(value) => updateInternalMarketplaces(value)}
          />
        </Form.Item>
      </Form>
    )}
    </>
  )
}
