import {
  IResourceComponentsProps,
  useTranslate
} from '@pankod/refine-core'
import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  ShowButton,
  useModal,
  Tag,
  FilterDropdownProps,
  FilterDropdown,
  Radio
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'

export const IslandList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, filters, setFilters, tableQueryResult } = useTable<definitions['islands']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })
  const { modalProps, show, close } = useModal()
  const t = useTranslate()
  const [id, setId] = useState(0)

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="id"
          dataIndex="id"
          title={t('islands.fields.id')}
          sorter
        />
        <Table.Column
          title={`${t('islands.fields.name')}`}
          dataIndex='name'
          sorter
          key="id"
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='marketplace_categories'
              optionLabel='name'
              optionValue='id'
              filters={[{
                field: 'deleted',
                operator: 'eq',
                value: false
              }]}
            />
          )}
        />
        <Table.Column
          title={t('islands.fields.province')}
          dataIndex='province'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
                <Radio.Group>
                    <Radio value='Las Palmas'>Las Palmas</Radio>
                    <Radio value='Santa Cruz de Tenerife'>Santa Cruz de Tenerife</Radio>
                </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column
          key='plane_extra_cost'
          title= {t('islands.fields.planeExtraCost')}
          dataIndex= 'plane_extra_cost'
          sorter
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='plane_extra_cost'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column
          key='boat_extra_cost'
          title= {t('islands.fields.boatExtraCost')}
          dataIndex='boat_extra_cost'
          sorter
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='boat_extra_cost'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column
          key='cp_prefixes'
          title={t('islands.fields.cpPrefixes')}
          dataIndex='cp_prefixes'
          render={(prefixes) => prefixes.map((prefix:any) => {
            return <Tag key={prefix}>{prefix}</Tag>
          })}
          sorter
        />

        <Table.Column<definitions['islands']>
          title={t('table.actions')}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />

              <ButtonLogicDelete click={() => {
                show()
                setId(record.id)
              }} />
              <DeleteModal
                modalProps={modalProps}
                close={close}
                id={id}
                entity='islands'
                tableQueryResult={tableQueryResult}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
