import { useTranslate } from '@pankod/refine-core'
import {
  Edit,
  Drawer,
  DrawerProps,
  FormProps,
  ButtonProps,
  Grid
} from '@pankod/refine-antd'
import { PriceRangeForm } from './form'

type EditPriceRangeProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const EditPriceRange: React.FC<EditPriceRangeProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource="shipping_method_price_ranges"
        title={t('shippingMethodPriceRanges.titles.edit')}
      >
        <PriceRangeForm formProps={formProps} />
      </Edit>
    </Drawer>
  )
}
