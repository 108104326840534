import { Button, Icons } from '@pankod/refine-antd'

type LogicDeleteProps = {
  click: any,
}

export const ButtonLogicDelete: React.FC<LogicDeleteProps> = ({ click }) => {
  return (
    <>
      <Button onClick={click} size="small" danger>
        <Icons.DeleteOutlined style={{ fontSize: 12 }} />
      </Button>
    </>
  )
}
