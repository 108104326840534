import {
  ButtonProps,
  Col,
  Drawer,
  DrawerProps,
  Edit,
  Form,
  FormProps,
  Grid,
  Icons,
  Input,
  Row,
  Typography
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

type AddressEditProps = {
  type: string
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps | undefined
  updateOrderAddress: Function | undefined
  showError: boolean
}

export const AddressEditDrawer: React.FC<AddressEditProps> = ({
  type,
  drawerProps,
  formProps,
  saveButtonProps,
  updateOrderAddress = () => {},
  showError = false
}) => {
  const breakpoint = Grid.useBreakpoint()
  const t = useTranslate()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '70vw' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        resource="addresses"
        saveButtonProps={saveButtonProps || { onClick: () => updateOrderAddress(formProps) } }
        breadcrumb={false}
        goBack={false}
        title={t('address.editAddress', { type })}
        headerButtons={() => (<></>)}
      >
        <Form {...formProps} layout="vertical" id={`addressForm${type}`}>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.name')}
                name="name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.surnames')}
                name="surnames"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={18}>
            <Form.Item
              label={t('address.fields.street')}
              name="street"
            >
              <Input />
            </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                label={t('address.fields.number')}
                name="number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.city')}
                name='city'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('address.fields.phoneNumber')}
                name='phone_number'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.postalCode')}
                name='postal_code'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('address.fields.cif')}
                name='nif'
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {showError && <Typography.Text style={{ color: 'red' }}>
        <Icons.WarningOutlined /> {t('order.adressesUpdateError')}
        </Typography.Text>}
      </Edit>
    </Drawer>
  )
}
