import { AuthProvider } from '@pankod/refine-core'
import { DASHBOARD_USER_MIN_ROLE_ID } from '../constants'
import { getCookie } from '../getCookie'
import { supabaseClient } from '../supabaseClient'
import { registerAccess } from 'utility/supabaseApi'

export const authProvider: AuthProvider = {
  login: async ({ email, password = undefined, provider = undefined }) => {
    const { user, error } = provider ? await supabaseClient.auth.signIn({ provider }) : await supabaseClient.auth.signIn({ email, password })

    if (error) {
      return Promise.reject(error)
    }

    if (user) {
      if (user) await registerAccess('SIGNED_IN_PANEL', user.id, user.email || null)
      return Promise.resolve()
    }
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut()

    if (error) {
      return Promise.reject(error)
    }

    return Promise.resolve('/')
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    const session = supabaseClient.auth.session()
    const sessionFromURL = await supabaseClient.auth.getSessionFromUrl()

    const user = session?.user || sessionFromURL?.data?.user

    if (user && user.user_metadata.role_id >= DASHBOARD_USER_MIN_ROLE_ID) {
      return Promise.resolve()
    }

    // Check with custom Syniva refresh token Cookie:
    // IMPORTANT: This is made to allow subdomains acess to the auth.
    const cookieSyniva = getCookie('syniva-refresh-token')
    if (cookieSyniva) {
      const { user, error } = await supabaseClient.auth.signIn({ refreshToken: cookieSyniva })
      if (error) {
        return Promise.reject()
      }
      if (user && user.user_metadata.role_id >= DASHBOARD_USER_MIN_ROLE_ID) {
        const synivaCookieSession = supabaseClient.auth.session()
        if (synivaCookieSession) {
          return Promise.resolve()
        }
      }
    }

    return Promise.reject()
  },
  getPermissions: async () => {
    const user = supabaseClient.auth.user()

    if (user) {
      return Promise.resolve(user.role)
    }
  },
  getUserIdentity: async () => {
    const user = supabaseClient.auth.user()

    if (user) {
      await registerAccess('SIGNED_IN_PANEL', user.id, user.email || null)
      return Promise.resolve({
        ...user,
        name: user.email
      })
    }
  }
}
