import {
  Button,
  DatePicker,
  FilterDropdown,
  Icons,
  Input,
  List,
  Radio,
  ShowButton,
  Table
} from '@pankod/refine-antd'
import { PurchaseListProps } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { currency, filter, formatDateTime, getPurchases } from 'utility'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { useEffect, useState } from 'react'
import { tablePropsProps } from './pendingPurchases'

export const SentPurchases: React.FC<PurchaseListProps> = ({ select, dataToExport, dataToExportSameInvoice }) => {
  let callingServer = false

  let controlWidth = window.innerWidth
  // const fixedMobile = controlWidth > 700 ? 'left' : false
  controlWidth = controlWidth < 728 ? 400 : 105

  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [allRows, setAllRows] = useState<any>({ dataSource: [] })

  const [filters, setFilters] = useState<filter[]>([])
  const [currentCheckboxFilter, setCurrentCheckboxFilter] = useState<boolean|undefined>()

  useEffect(() => {
    if (!callingServer) {
      getPurchasesList()
    }
  }, [])

  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const currentDate = new Date()
  const maxCurrentDate = new Date()
  maxCurrentDate.setMonth(maxCurrentDate.getMonth() - 3)

  const [loading, setLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<any[]>([
    `${maxCurrentDate.getFullYear()}-${(maxCurrentDate.getMonth() + 1 > 9) ? maxCurrentDate.getMonth() + 1 : '0' + (maxCurrentDate.getMonth() + 1)}-${(maxCurrentDate.getDate() > 9) ? maxCurrentDate.getDate() : '0' + maxCurrentDate.getDate()}`,
    `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1 > 9) ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)}-${(currentDate.getDate() > 9) ? currentDate.getDate() : '0' + currentDate.getDate()}`
  ])

  const getPurchasesList = async () => {
    callingServer = true
    setLoading(true)
    const gtDate = dates[0] || maxCurrentDate
    const ltDate = dates[1] || currentDate
    const data = await getPurchases(select, true, gtDate, ltDate)
    const tableProps = {
      dataSource: data
    }
    setAllRows(tableProps)
    setTableProps(tableProps)
    setLoading(false)
    callingServer = false
  }

  useEffect(() => {
    if (!callingServer) {
      getPurchasesList()
    }
  }, [])

  const changeDates = (datesInput: any) => {
    if (datesInput === null) {
      setDates([
        `${maxCurrentDate.getFullYear()}-${(maxCurrentDate.getMonth() + 1 > 9) ? maxCurrentDate.getMonth() + 1 : '0' + (maxCurrentDate.getMonth() + 1)}-${(maxCurrentDate.getDate() > 9) ? maxCurrentDate.getDate() : '0' + maxCurrentDate.getDate()}`,
        `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1 > 9) ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)}-${(currentDate.getDate() > 9) ? currentDate.getDate() : '0' + currentDate.getDate()}`
      ])
    }
    const arrayDates = [datesInput[0], datesInput[1]]
    const dateStart = `${arrayDates[0].get('year')}-${(arrayDates[0].get('month') + 1 > 9) ? arrayDates[0].get('month') + 1 : '0' + (arrayDates[0].get('month') + 1)}-${(arrayDates[0].get('date') > 9) ? arrayDates[0].get('date') : '0' + arrayDates[0].get('date')}`
    const dateEnd = `${arrayDates[1].get('year')}-${(arrayDates[1].get('month') + 1 > 9) ? arrayDates[1].get('month') + 1 : '0' + (arrayDates[1].get('month') + 1)}-${(arrayDates[1].get('date') > 9) ? arrayDates[1].get('date') : '0' + arrayDates[1].get('date')}`
    setDates([dateStart, dateEnd])
  }

  const currencyRight = (value: any) => <div style={{ textAlign: 'right' }}>{currency(value)}</div>

  return (
    <List
      title={t('menu.purchases')}
      headerProps={{
        extra:
        <>
          <RangePicker showTime={false} onChange={changeDates} />
          <Button icon={<Icons.SearchOutlined />} onClick={async () => getPurchasesList()}>{}</Button>
          <ExportToExcelButton exportButtonName='Exportar distinto IVA' filename='Compras distinto IVA' jsonData={dataToExportSameInvoice(tableProps.dataSource as any[])} />
          <ExportToExcelButton filename='Compras' jsonData={dataToExport(tableProps.dataSource as any[])} />
        </>
      }}
    >
      <Table {...tableProps}
        loading={loading}
        pagination={{
          current: current,
          pageSize: pageSize,
          onChange (page, pageSize) {
            setCurrent(page)
            setPageSize(pageSize)
          }
        }}
        rowKey="id"
        scroll={{ x: `${controlWidth}%`, y: '60vh' }}
      >
        <Table.Column
            key='invoice_date'
            dataIndex='invoice_date'
            title={t('purchases.fields.invoiceDate') as string}
            width={150}
            align="center"
            defaultSortOrder={'descend'}
            sorter={{
              compare: (a: any, b: any) => {
                const strA = a.invoice_date
                const strB = b.invoice_date
                return strA.localeCompare(strB)
              }
            }}
            render={(value) => formatDateTime(value, true)}
            filterDropdown={() => (
              <DateFilterDropdown
                field='invoice_date'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    const fields = newFilter[0].field?.split('.')
                    const rows = { ...allRows }
                    if (fields?.length === 1) {
                      rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`] >= newFilter[0].value && x[`${fields[0]}`] <= newFilter[1].value)
                    } else if (fields?.length === 2) {
                      rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`][`${fields[1]}`] >= newFilter[0].value && x[`${fields[0]}`][`${fields[1]}`] <= newFilter[1].value)
                    }
                    setTableProps({ ...rows })
                  } else {
                    setTableProps({ ...allRows })
                  }
                }}
              />
            )}
          />
          <Table.Column
            key='invoice_number'
            dataIndex='invoice_number'
            title={t('purchases.fields.invoiceNumber') as string}
            width={150}
            align="center"
            filterDropdown={(props: any) => {
              props.clearFilters = () => {
                setTableProps({ ...allRows })
              }
              props.confirm = () => {
                const rows = { ...allRows }
                if (filters.find((x: filter) => x.field === 'invoice_number')) {
                  const updatedFilters = [...filters]
                  const index = updatedFilters.findIndex((x: filter) => x.field === 'invoice_number')
                  updatedFilters[index].value = props.selectedKeys

                  setFilters([...updatedFilters])
                } else {
                  setFilters([...filters, ...[{ field: 'invoice_number', operator: 'in', value: props.selectedKeys }]])
                }
                console.log(props.selectedKeys)
                rows.dataSource = allRows.dataSource.filter((x: any) => x.invoice_number.includes(props.selectedKeys))
                setTableProps({ ...rows })
              }
              // Add here filter logic
              return (
                <SelectFilterDropdown
                  props={props}
                  entity='market_orders'
                  optionLabel='invoice_number'
                  optionValue='id'
                  type='select'
                  filters={filters as any[]}
                />
              )
            }}
          />
          <Table.Column
            key='iva_type'
            dataIndex='iva_type'
            title={t('purchases.fields.ivaType') as string}
            width={100}
            align='center'
          />
          <Table.Column
            key='purchase_id'
            dataIndex='purchase_id'
            title={t('purchases.fields.purchaseId') as string}
            width={150}
            align="center"
            filterDropdown={(props: any) => {
              props.clearFilters = () => {
                setTableProps({ ...allRows })
              }
              props.confirm = () => {
                const rows = { ...allRows }
                if (props.selectedKeys && props.selectedKeys.length > 0) {
                  if (filters.find((x: filter) => x.field === 'purchase_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'purchase_id')
                    updatedFilters[index].value = props.selectedKeys

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'purchase_id', operator: 'in', value: props.selectedKeys }]])
                  }
                  rows.dataSource = allRows.dataSource.filter((x: any) => props.selectedKeys.includes(x.id))
                  setTableProps({ ...rows })
                }
              }
              // Add here filter logic
              return (
                <SelectFilterDropdown
                  props={props}
                  entity='market_orders'
                  optionLabel='purchase_id'
                  optionValue='id'
                  type='select'
                  filters={filters as any[]}
                />
              )
            }}
          />
          <Table.Column
            key='tracking_id'
            dataIndex='tracking_id'
            title={t('purchases.fields.trackingId') as string}
            width={200}
            align="center"
            filterDropdown={(props: any) => {
              props.clearFilters = () => {
                setTableProps({ ...allRows })
              }
              props.confirm = () => {
                const rows = { ...allRows }
                if (props.selectedKeys && props.selectedKeys.length > 0) {
                  if (filters.find((x: filter) => x.field === 'tracking_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'tracking_id')
                    updatedFilters[index].value = props.selectedKeys

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'tracking_id', operator: 'in', value: props.selectedKeys }]])
                  }
                  rows.dataSource = allRows.dataSource.filter((x: any) => props.selectedKeys.includes(x.id))
                  setTableProps({ ...rows })
                }
              }
              // Add here filter logic
              return (
                <SelectFilterDropdown
                  props={props}
                  entity='market_orders'
                  optionLabel='tracking_id'
                  optionValue='id'
                  type='select'
                  filters={filters as any[]}
                />
              )
            }}
          />
          <Table.Column
            key='is_purchase'
            dataIndex='is_purchase'
            title={t('market_order.fields.type') as string}
            width={150}
            align="center"
            render={(value: any) => value ? t('market_order.fields.purchase') : t('market_order.fields.refund')}
            filterDropdown={(props: any) => {
              return (
                <FilterDropdown {...props}
                  mapValue={(selectedKeys) => {
                    setCurrentCheckboxFilter(selectedKeys.toString() === 'true')
                    return selectedKeys
                  }}
                  clearFilters={() => {
                    setCurrentCheckboxFilter(undefined)
                    setTableProps({ ...allRows })
                  }}
                  confirm={() => {
                    const rows = { ...allRows }
                    if (filters.find((x: filter) => x.field === 'is_purchase')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'is_purchase')
                      updatedFilters[index].value = currentCheckboxFilter

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'is_purchase', operator: 'eq', value: currentCheckboxFilter }]])
                    }
                    rows.dataSource = allRows.dataSource.filter((x: any) => x.is_purchase === currentCheckboxFilter)
                    setTableProps({ ...rows })
                  }}
                >
                  <Radio.Group>
                    <Radio value={true}>{t('market_order.fields.purchase')}</Radio>
                    <Radio value={false}>{t('market_order.fields.refund')}</Radio>
                  </Radio.Group>
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            key='order_id'
            dataIndex={['order_id', 'id']}
            title={t('order.fields.id') as string}
            width={150}
            align='center'
            render={(value: any) => {
              return <ShowButton
                style={{ height: 'fit-content' }}
                onClick={() =>
                  window.open(`/orders/show/${value}`, '_blank', 'noopener,noreferrer')
                }
              >
                <br />
                {value}
              </ShowButton>
            }}
            filterDropdown={() => {
              return <QuantityFilterDropdown field={'id'} filters={filters}
                setFilters={(newFilter: any) => {
                  if (newFilter.length > 0 && Array.isArray(newFilter)) {
                    const fields = newFilter[0].field?.split('.')
                    const rows = { ...allRows }
                    if (fields?.length === 1) {
                      if (newFilter[0].operator === 'eq') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id === parseInt(newFilter[0].value))
                      } else if (newFilter[0].operator === 'gt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id > parseInt(newFilter[0].value))
                      } else if (newFilter[0].operator === 'lt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id < parseInt(newFilter[0].value))
                      }
                    } else if (fields?.length === 2) {
                      if (newFilter[0].operator === 'eq') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id === parseInt(newFilter[0].value))
                      } else if (newFilter[0].operator === 'gt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id > parseInt(newFilter[0].value))
                      } else if (newFilter[0].operator === 'lt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.id < parseInt(newFilter[0].value))
                      }
                    }
                    setTableProps({ ...rows })
                  } else {
                    setTableProps({ ...allRows })
                  }
                }}
              />
            }}
          />
          <Table.Column
            key='status'
            dataIndex={['order_id', 'alias']}
            title={t('order.fields.alias') as string}
            width={150}
          />
          <Table.Column
            key='status'
            dataIndex={['order_id', 'status', 'name']}
            title={t('order.fields.status') as string}
            width={150}
          />
          <Table.Column
            key='marketplace_legal_name'
            dataIndex='marketplace_legal_name'
            title={t('purchases.fields.marketplace') as string}
            width={150}
            filterDropdown={(props: any) => {
              return (
                // Add here filter logic
                <FilterDropdown {...props}
                  mapValue={(selectedKeys) => {
                    return selectedKeys.toString()
                  }}
                  clearFilters={() => {
                    setTableProps({ ...allRows })
                  }}
                  confirm={() => {
                    const rows = { ...allRows }
                    if (filters.find((x: filter) => x.field === 'marketplace_legal_name')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_legal_name')
                      updatedFilters[index].value = props.selectedKeys

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'marketplace_legal_name', operator: 'eq', value: props.selectedKeys }]])
                    }
                    rows.dataSource = allRows.dataSource.filter((x: any) => x.marketplace_legal_name !== null && x.marketplace_legal_name.includes(props.selectedKeys))
                    setTableProps({ ...rows })
                  }}
                >
                  <Input type='text' />
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            key='marketplace_cif'
            dataIndex='marketplace_cif'
            title={t('purchases.fields.cif') as string}
            width={150}
            filterDropdown={(props: any) => {
              return (
                // Add here filter logic
                <FilterDropdown {...props}
                  mapValue={(selectedKeys) => {
                    return selectedKeys.toString()
                  }}
                  clearFilters={() => {
                    setTableProps({ ...allRows })
                  }}
                  confirm={() => {
                    const rows = { ...allRows }
                    if (filters.find((x: filter) => x.field === 'marketplace_cif')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_cif')
                      updatedFilters[index].value = props.selectedKeys

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'marketplace_cif', operator: 'eq', value: props.selectedKeys }]])
                    }
                    rows.dataSource = allRows.dataSource.filter((x: any) => x.marketplace_cif !== null && x.marketplace_cif.includes(props.selectedKeys))
                    setTableProps({ ...rows })
                  }}
                >
                  <Input type='text' />
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            key='marketplace_province'
            dataIndex='marketplace_province'
            title={t('purchases.fields.province') as string}
            width={150}
            filterDropdown={(props: any) => {
              return (
                // Add here filter logic
                <FilterDropdown {...props}
                  mapValue={(selectedKeys) => {
                    return selectedKeys.toString()
                  }}
                  clearFilters={() => {
                    setTableProps({ ...allRows })
                  }}
                  confirm={() => {
                    const rows = { ...allRows }
                    if (filters.find((x: filter) => x.field === 'marketplace_province')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_province')
                      updatedFilters[index].value = props.selectedKeys

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'marketplace_province', operator: 'eq', value: props.selectedKeys }]])
                    }
                    rows.dataSource = allRows.dataSource.filter((x: any) => x.marketplace_province !== null && x.marketplace_province.includes(props.selectedKeys))
                    setTableProps({ ...rows })
                  }}
                >
                  <Input type='text' />
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            key='marketplace_postal_code'
            dataIndex='marketplace_postal_code'
            title={t('purchases.fields.postalCode') as string}
            width={150}
            filterDropdown={(props: any) => {
              return (
                // Add here filter logic
                <FilterDropdown {...props}
                  mapValue={(selectedKeys) => {
                    return selectedKeys.toString()
                  }}
                  clearFilters={() => {
                    setTableProps({ ...allRows })
                  }}
                  confirm={() => {
                    const rows = { ...allRows }
                    if (filters.find((x: filter) => x.field === 'marketplace_postal_code')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_postal_code')
                      updatedFilters[index].value = props.selectedKeys

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'marketplace_postal_code', operator: 'eq', value: props.selectedKeys }]])
                    }
                    rows.dataSource = allRows.dataSource.filter((x: any) => x.marketplace_postal_code !== null && x.marketplace_postal_code.includes(props.selectedKeys))
                    setTableProps({ ...rows })
                  }}
                >
                  <Input type='text' />
                </FilterDropdown>
              )
            }}
          />
          <Table.Column
            key='purchase_amount'
            dataIndex='purchase_amount'
            title={t('purchases.fields.totalInvoice') as string}
            width={150}
            align='center'
            render={(value: any) => currencyRight(value)}
            filterDropdown={() => {
              return <QuantityFilterDropdown field={'purchase_amount'} filters={filters}
                setFilters={(newFilter: any) => {
                  if (typeof newFilter === 'function') setTableProps({ ...allRows })
                  else {
                    const fields = newFilter.find((x: any) => x.field === 'purchase_amount')
                    const rows = { ...allRows }
                    if (fields) {
                      if (fields.operator === 'eq') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.purchase_amount === parseFloat(fields.value))
                      } else if (fields.operator === 'gt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.purchase_amount > parseFloat(fields.value))
                      } else if (fields.operator === 'lt') {
                        rows.dataSource = rows.dataSource.filter((x: any) => x.purchase_amount < parseFloat(fields.value))
                      }
                    }
                    setTableProps({ ...rows })
                  }
                }}
              />
            }}
          />
          <Table.Column
            key='delivered_date'
            dataIndex={['order_id', 'delivered_date']}
            title={t('purchases.fields.delivered_date') as string}
            align="center"
            width={150}
            render={(value) => formatDateTime(value, true)}
            filterDropdown={() => (
              <DateFilterDropdown
                field='delivered_date'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    const rows = { ...allRows }

                    rows.dataSource = rows.dataSource.filter((x: any) => x.order_id.delivered_date !== null && x.order_id.delivered_date >= newFilter[0].value && x.order_id.delivered_date <= newFilter[1].value)
                    setTableProps({ ...rows })
                  } else {
                    setTableProps({ ...allRows })
                  }
                }}
              />
            )}
          />
      </Table>
    </List>
  )
}
