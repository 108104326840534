export const currentSession = () => {
  const token = localStorage.getItem('supabase.auth.token')
  if (token) {
    return JSON.parse(token).currentSession
  }
  return null
}

export const currentUserMetadata = () => currentSession()?.user?.user_metadata

export const currentUserEmail = () => currentSession()?.user?.email
