import { useTranslate } from '@pankod/refine-core'
import {
  Create,
  Form,
  Input,
  Select,
  InputNumber,
  ButtonProps,
  Drawer,
  DrawerProps,
  FormProps,
  Grid
} from '@pankod/refine-antd'

import 'react-mde/lib/styles/css/react-mde-all.css'

type CreateMethodProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const ShipmentMethodsCreate: React.FC<CreateMethodProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create saveButtonProps={saveButtonProps} title={t('shipment_methods.titles.create')}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={t('shipment_methods.fields.name')}
            name='name'
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('shipment_methods.fields.price')}
            name='price'
            rules={[{ required: true }]}
          >
            <InputNumber decimalSeparator=',' addonAfter={'€'} defaultValue={0} min={0} />
          </Form.Item>
          <Form.Item
            label={t('shipment_methods.fields.shipment_type')}
            name='shipment_type'
            rules={[{ required: true }]}
          >
            <Select options={[
              { label: `${t('shipment_methods.shipment_types.plane')}`, value: 'plane' },
              { label: `${t('shipment_methods.shipment_types.boat')}`, value: 'boat' }
            ]} />
          </Form.Item>
          <Form.Item
            label={t('shipment_methods.fields.volumetric_factor')}
            name='volumetric_factor'
          >
            <InputNumber decimalSeparator=',' defaultValue={0} min={0} />
          </Form.Item>
          <Form.Item
            label={t('shipment_methods.fields.price_additional_kg')}
            name='price_additional_kg'
          >
            <InputNumber decimalSeparator=',' addonAfter={'€'} defaultValue={0} min={0} />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  )
}
