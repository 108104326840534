import {
  useTranslate,
  IResourceComponentsProps
} from '@pankod/refine-core'

import {
  List,
  Table,
  BooleanField,
  Avatar,
  Space,
  useModal,
  useTable,
  FilterDropdown,
  Radio,
  FilterDropdownProps,
  useDrawerForm,
  EditButton,
  CreateButton
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { useState } from 'react'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { BannerEditDrawer } from './edit'
import { BannerCreateDrawer } from './create'
import { BannerTextConfigurator } from './textConfigurator'

export const BannerList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable<definitions['banners']>({
    initialSorter: [
      {
        field: 'position',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const {
    formProps: createFormProps,
    drawerProps: createDrawerProps,
    show: createShow,
    saveButtonProps: createSaveButtonProps
  } = useDrawerForm<definitions['banners']>({
    action: 'create',
    resource: 'banners',
    redirect: false
  })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    saveButtonProps: editSaveButtonProps
  } = useDrawerForm<definitions['banners']>({
    action: 'edit',
    resource: 'banners',
    redirect: false
  })

  const {
    drawerProps: editBannerTextDrawerProps,
    show: showBannerTextConfig
  } = useDrawerForm<any>({
    action: 'edit',
    redirect: false
  })

  const t = useTranslate()

  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  return (
    <List headerProps={{
      extra: <>
        <CreateButton onClick={() => createShow()} />
        <EditButton onClick={() => showBannerTextConfig()}>{t('banners.titles.editTextBanner')}</EditButton>
      </>
    }}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title={t('marketplace.fields.id')}
          align='center'
        />
        <Table.Column
          dataIndex="banner_url"
          title={t('item.fields.image')}
          key="image"
          align="center"
          render={(value) => (
            value
              ? <Avatar
                src={value}
                size={64}
                shape="square"
              />
              : null
          )}
        />
        <Table.Column
          key='is_in_home'
          dataIndex="is_in_home"
          title={t('marketplace.fields.isInHome')}
          align='center'
          render={(value) => (
            <BooleanField
              value={value}
              valueLabelTrue={t('status.yes')}
              valueLabelFalse={t('status.no')}
            />
          )}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Radio.Group>
                <Radio value='true'>{t('status.yes')}</Radio>
                <Radio value='false'>{t('status.no')}</Radio>
              </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column
          key='position'
          dataIndex="position"
          title={t('banners.fields.carouselPosition')}
          align='center'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Radio.Group>
                <Radio value='1'>1</Radio>
                <Radio value='2'>2</Radio>
                <Radio value='3'>3</Radio>
                <Radio value='4'>4</Radio>
              </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column
          key='redirect_url'
          dataIndex="redirect_url"
          title={t('banners.fields.redirectUrl')}
          align='center'
          render={(value) => <a href={value} target='_blank' rel='noreferrer'>{value}</a>}
          sorter
        />
        <Table.Column<definitions['banners']>
          fixed="right"
          title={t('table.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record) => (
            <Space>
              <EditButton
                onClick={() => editShow(record.id)}
                hideText
              />
              <ButtonLogicDelete click={() => {
                show()
                setId(record.id)
              }} />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  entity='banners'
                  tableQueryResult={tableQueryResult}
                />
            </Space>
          )}
        />
      </Table>
      <BannerEditDrawer
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <BannerCreateDrawer
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
      <BannerTextConfigurator
        drawerProps={editBannerTextDrawerProps}
      />
    </List>
  )
}
