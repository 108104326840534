import { useTranslate } from '@pankod/refine-core'
import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Radio,
  ButtonProps,
  Grid,
  InputNumber
} from '@pankod/refine-antd'
import MDEditor from '@uiw/react-md-editor'

type CreateCmsProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateCms: React.FC<CreateCmsProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource="cms"
      >
        {Number.isInteger(formProps.initialValues?.category_id) && (
          <Form {...formProps} layout="vertical">
            <Form.Item
              name="category_id"
              hidden={true}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('cms.fields.name')}
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('cms.fields.content')}
              name="content"
              rules={[
                {
                  required: true
                }
              ]}
            >
            <MDEditor
            />
            </Form.Item>
            <Form.Item
              name="is_in_home"
              label={t('marketplace.fields.isInHome')}
              style={{ margin: 0 }}
            >
              <Radio.Group>
                <Radio value={true}>{t('status.yes')}</Radio>
                <Radio value={false}>{t('status.no')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                label={t('cmsCategories.fields.weigth')}
                name="weigth"
                rules={[
                  {
                    required: true
                  }
                ]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                label={t('cmsCategories.fields.cms_parent')}
                name="cms_parent"
                rules={[
                  {
                    required: false
                  }
                ]}
            >
                <InputNumber />
            </Form.Item>
          </Form>
        )}
      </Create>
    </Drawer>
  )
}
