import { FilterDropdown, FilterDropdownProps, Select, useSelect } from '@pankod/refine-antd'

type SelectFilterDropdownProps = {
  props: FilterDropdownProps
  entity: string
  optionValue: string
  optionLabel: string
  type?: string
  filters?: any[]
  sorters?: any[]
  excludeFieldsFilter?: any[]
}

export const SelectFilterDropdown: React.FC<SelectFilterDropdownProps> = ({ props, entity, optionValue, optionLabel, type = 'number', filters = [], sorters = [], excludeFieldsFilter = [] }) => {
  const { selectProps } = useSelect({
    resource: entity,
    fetchSize: 5000000,
    optionValue: optionValue,
    optionLabel: optionLabel,
    filters: [
      ...filters
    ],
    sort: [
      ...sorters
    ]
  })

  if (selectProps.options?.some(o => excludeFieldsFilter.includes(o.label))) {
    excludeFieldsFilter.forEach(f => {
      selectProps.options = selectProps.options?.filter(x => x.label !== f)
    })
  }

  return (
    <FilterDropdown
      {...props}
      mapValue={(selectedKeys) =>
        type === 'string' ? selectedKeys.map((i) => i.toString()) : selectedKeys.map((i) => parseInt(i.toString()))
      }
    >
      <Select
        style={{ minWidth: 200 }}
        mode="multiple"
        {...selectProps}
      />
    </FilterDropdown>
  )
}
