import { useTranslate } from '@pankod/refine-core'

import {
  Form,
  Radio,
  FormProps,
  Upload,
  RcFile,
  Input
} from '@pankod/refine-antd'
import { supabaseClient } from 'utility/supabaseClient'
import { normalizeFile } from 'utility/normalize'

type BannersFormProps = {
    formProps: FormProps<{}>
};

export const BannersForm: React.FC<BannersFormProps> = (data) => {
  const t = useTranslate()

  return (
    <Form
      {...data.formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...data.formProps.initialValues
      }}
    >
      <Form.Item label="Images" >
        <Form.Item
          name="banner"
          valuePropName="fileList"
          normalize={normalizeFile}
          noStyle
        >
          <Upload.Dragger
            name="banner"
            listType="picture"
            customRequest={async ({
              file,
              onError,
              onSuccess
            }) => {
              const rcFile = file as RcFile
              const fileUrl = `${rcFile.name}`

              const { error } = await supabaseClient.storage
                .from('banners')
                .upload(fileUrl, file, {
                  cacheControl: '3600',
                  upsert: true
                })

              if (error) {
                return onError?.(error)
              }
              const { data, error: urlError } =
                await supabaseClient.storage
                  .from('banners')
                  .getPublicUrl(fileUrl)

              if (urlError) {
                return onError?.(urlError)
              }

              onSuccess?.(
                { url: data?.publicURL },
                new XMLHttpRequest()
              )
            }}
          >
            <p className="ant-upload-text">
              {t('actions.dragFile')}
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={t('marketplace.fields.isInHome')}
        name="is_in_home"
      >
        <Radio.Group>
          <Radio value={true}>{t('status.yes')}</Radio>
          <Radio value={false}>{t('status.no')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t('banners.fields.carouselPosition')}
        name="position"
      >
        <Radio.Group>
          <Radio value='1'>1</Radio>
          <Radio value='2'>2</Radio>
          <Radio value='3'>3</Radio>
          <Radio value='4'>4</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t('banners.fields.redirectUrl')}
        name="redirect_url"
      >
        <Input />
      </Form.Item>
    </Form>
  )
}
