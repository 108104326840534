import { Tabs } from '@pankod/refine-antd'
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { useEffect, useState } from 'react'
import { LOGS_TABLES, supabaseClient } from 'utility'

type Audit = {
  id: number
  operation: string
  record: unknown
  'created_at': string
  'resource_id': number
}

const LogsTable: React.FC<IResourceComponentsProps & { tableName: string }> = ({ tableName }) => {
  const [logs, setLogs] = useState<Audit[]>([])

  useEffect(() => {
    const loadData = async () => {
      const { data, error } = await supabaseClient
        .from(`audit_${tableName}`)
        .select('*')
        .order('created_at', { ascending: false })

      if (error || !data) {
        setLogs([])
        return
      }

      setLogs(data)
    }

    loadData()
  }, [])

  return (
    <div style={{ backgroundColor: 'black', padding: '10px', display: 'flex', flexDirection: 'column', gap: 4, height: '80svh', overflow: 'auto' }}>
      {logs.map((log) => {
        return <span key={log.id} style={{ display: 'block', color: 'white', wordBreak: 'break-all' }}>
          {`SYNIVA - ${log.operation} - ${log.created_at} --> ${JSON.stringify(log.record)}`}
        </span>
      })}
    </div>
  )
}

export const LogsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  return (
    <Tabs defaultActiveKey="0" size="middle">
      {LOGS_TABLES.map((tableName, index) => {
        return (
          <Tabs.TabPane tab={t(`menu.${tableName}`)} key={index}>
            <LogsTable tableName={tableName} />
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}
