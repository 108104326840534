import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  ButtonProps,
  Grid,
  Create,
  Row,
  Col,
  Select,
  Alert,
  Spin
} from '@pankod/refine-antd'
import { useTranslate, useSelect } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { useState, useEffect } from 'react'
import { CREDIT_CARD_METHOD_ID, findMarketplacesByText, findUsersByText, getShippingMethod, getUserAddresses, ORDER_TYPE, supabaseClient } from 'utility'

type CreateOrderProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
  closeDrawer: Function
  // tableQueryResult: QueryObserverResult
}

export const CreateOrder: React.FC<CreateOrderProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  closeDrawer
  // tableQueryResult
}) => {
  const [userId, setUserId] = useState(0)
  const [marketplaceId, setMarketplaceId] = useState(0)
  const [statusId, setStatusId] = useState(0)
  const [shippingAddressId, setShippingAddressId] = useState<number | null>(null)
  const [billingAddressId, setBillingAddressId] = useState<number | null>(null)
  const [shippingMethodId, setShippingMethodId] = useState<number | null>(null)
  const [showAddressError, setShowAddressError] = useState(false)
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  const [fetching, setFetching] = useState<boolean>(false)

  const [userOptions, setUserOptions] = useState<definitions['users'][]>([])
  const [marketplacesOptions, setMarketplacesOptions] = useState<definitions['marketplaces'][]>([])

  useEffect(() => {
    getUserAddresses(userId).then((res) => {
      const [shippingAddress, billingAddress] = res

      setShippingAddressId(shippingAddress)
      setBillingAddressId(billingAddress)
    })
  }, [userId])

  const handleSearchUser = async (newValue: string) => {
    if (newValue.length > 3) {
      setFetching(true)
      const res = await findUsersByText(newValue)
      if (res) {
        setUserOptions(res || [])
      }
      setFetching(false)
    }
  }

  const handleSearchMarketplaces = async (newValue: string) => {
    if (newValue.length > 0) {
      setFetching(true)
      const res = await findMarketplacesByText(newValue)
      if (res) {
        setMarketplacesOptions(res || [])
      }
      setFetching(false)
    }
  }

  const { options: shippingMethodOptions } = useSelect<definitions['shipment_methods']>({
    resource: 'shipment_methods',
    fetchSize: 20,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [{
      field: 'deleted',
      operator: 'eq',
      value: false
    }],
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const { options: statusOptions } = useSelect<definitions['status']>({
    resource: 'status',
    fetchSize: 20,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const createOrder = async () => {
    if (!shippingAddressId || !billingAddressId) {
      setShowAddressError(true)
      return
    }

    const shippingMethod = await getShippingMethod(shippingMethodId)

    await supabaseClient
      .from('orders')
      .insert([{
        user_id: userId,
        marketplace_id: marketplaceId,
        shipping_address_id: shippingAddressId,
        billing_address_id: billingAddressId,
        shipping_method_id: shippingMethodId,
        status_id: statusId,
        payment_method_id: CREDIT_CARD_METHOD_ID,
        type: marketplaceId ? ORDER_TYPE.intern : ORDER_TYPE.custom,
        subtotal: 0,
        syniva_price: 0,
        total_saving: 0,
        igic: 0,
        shipping_price: shippingMethod?.price || 0,
        total: shippingMethod?.price || 0,
        updated_by: supabaseClient.auth.user()?.email
      }])

    setShowAddressError(false)
    closeDrawer()
    window.location.reload()
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="orders"
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        goBack={false}
        title={t('order.actions.addOrder')}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={createOrder}
        >
          {showAddressError && <Alert message={t('address.notAddressesError')} type="warning" />}
          <br />
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('order.fields.userEmail')}
                name="user_id"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  onSearch={handleSearchUser}
                  onChange={(value) => setUserId(value)}
                  options={(userOptions || []).map(u => ({
                    value: u.id,
                    label: u.email
                  }))}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item
                label={t('order.fields.marketplace')}
                name="marketplace_id"
                rules={[{ required: true }]}
              >
                {/* <Select
                  options={marketplacesOptions}
                  showSearch
                  onChange={(value) => setMarketplaceId(value)}
                  getPopupContainer={trigger => trigger.parentElement}
                  filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                /> */}
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  onSearch={handleSearchMarketplaces}
                  onChange={(value) => setMarketplaceId(value)}
                  options={(marketplacesOptions || []).map(u => ({
                    value: u.id,
                    label: u.name
                  }))}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('order.fields.shipmentMethod')}
                name="shipping_method_id"
              >
                <Select
                  options={shippingMethodOptions}
                  showSearch
                  onChange={(value) => setShippingMethodId(value)}
                  getPopupContainer={trigger => trigger.parentElement}
                  filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item
                label={t('order.fields.status')}
                name="status_id"
                rules={[{ required: true }]}
              >
                <Select
                  options={statusOptions}
                  showSearch
                  onChange={(value) => setStatusId(value)}
                  getPopupContainer={trigger => trigger.parentElement}
                  filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
