import { Button, DatePicker, Icons, List, Table } from '@pankod/refine-antd'
import { Sale, SalesListProps, Supplement } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { currency, currencyValue } from 'utility/currency'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { MAX_IGIC_VALUE, filter, getSales, supabaseClient } from 'utility'
import { useEffect, useState } from 'react'

const select = 'id, name, quantity, price, syniva_price, order_id, reviewed, disabled,' +
  'category(description, igic_type(value)),' +
  'marketplace(id, name, igic_types(value)),' +
  'order!inner(id, invoice, invoice_date, status(name), delivered_date, syniva_price, total, shipping_insurance_price, shipping_method_id, shipping_price, discount, wallet_amount_used,' +
  'billing_address(id, name, surnames, nif, city, postal_code),' +
  'payment_method(name),' +
  'supplements(id, description, supplement, deleted, reviewed, volumetric)' +
  ')'

export type tablePropsProps = {
  dataSource: any[]
}

export const ReviewedSalesList: React.FC<SalesListProps> = ({ columns, dataToExport, supplementsNewRow, shippingCostNewRows, insuranceNewRows }) => {
  let callingServer = false

  const currentDate = new Date()
  const maxCurrentDate = new Date()
  maxCurrentDate.setMonth(maxCurrentDate.getMonth() - 3)

  const [loading, setLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<any[]>([
    `${maxCurrentDate.getFullYear()}-${(maxCurrentDate.getMonth() + 1 > 9) ? maxCurrentDate.getMonth() + 1 : '0' + (maxCurrentDate.getMonth() + 1)}-${(maxCurrentDate.getDate() > 9) ? maxCurrentDate.getDate() : '0' + maxCurrentDate.getDate()}`,
    `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1 > 9) ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)}-${(currentDate.getDate() > 9) ? currentDate.getDate() : '0' + currentDate.getDate()}`
  ])

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [allRows, setAllRows] = useState<any>({ dataSource: [] })
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [economyShimentId, setEconomyShipmentId] = useState<number>(-1)

  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState<filter[]>([])

  const getEconomyId = async () => {
    const { data: economyMethod } = await supabaseClient.from('shipment_methods')
      .select('id')
      .eq('deleted', false)
      .eq('is_in_home', true)
      .eq('shipMode', true)
      .single()

    setEconomyShipmentId(economyMethod.id)
  }

  const getSalesList = async () => {
    callingServer = true
    setLoading(true)
    const gtDate = dates[0] || maxCurrentDate
    const ltDate = dates[1] || currentDate
    const data = await getSales(select, true, gtDate, ltDate)
    const tableProps = {
      dataSource: data
    }

    const supplementsRows = supplementsNewRow(tableProps.dataSource as any)
    const shippingCostRows = shippingCostNewRows(tableProps.dataSource as any)
    const insuranceRows = insuranceNewRows(tableProps.dataSource as any)

    tableProps.dataSource = tableProps.dataSource?.concat(supplementsRows)
    tableProps.dataSource = tableProps.dataSource?.concat(shippingCostRows)
    tableProps.dataSource = tableProps.dataSource?.concat(insuranceRows)

    tableProps.dataSource = tableProps.dataSource?.filter((row) => row.reviewed)
    tableProps.dataSource = tableProps.dataSource?.filter((row: any) => !row.disabled)

    tableProps.dataSource.forEach((d: Sale, index: number) => {
      d.saleId = index
    })

    setAllRows(tableProps)
    setTableProps(tableProps)
    setLoading(false)
    callingServer = false
  }

  useEffect(() => {
    if (!callingServer) {
      getEconomyId()
      getSalesList()
    }
  }, [])

  const { t } = useTranslation()
  const { RangePicker } = DatePicker

  const currencyRight = (value: any) => <div style={{ textAlign: 'right' }}>{currency(value)}</div>

  let controlWidth = window.innerWidth
  // const fixedMobile = controlWidth > 700 ? 'left' : false
  controlWidth = controlWidth < 728 ? 400 : 105

  const changeDates = (datesInput: any) => {
    if (datesInput === null) {
      setDates([
        `${maxCurrentDate.getFullYear()}-${(maxCurrentDate.getMonth() + 1 > 9) ? maxCurrentDate.getMonth() + 1 : '0' + (maxCurrentDate.getMonth() + 1)}-${(maxCurrentDate.getDate() > 9) ? maxCurrentDate.getDate() : '0' + maxCurrentDate.getDate()}`,
        `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1 > 9) ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)}-${(currentDate.getDate() > 9) ? currentDate.getDate() : '0' + currentDate.getDate()}`
      ])
    }
    const arrayDates = [datesInput[0], datesInput[1]]
    const dateStart = `${arrayDates[0].get('year')}-${(arrayDates[0].get('month') + 1 > 9) ? arrayDates[0].get('month') + 1 : '0' + (arrayDates[0].get('month') + 1)}-${(arrayDates[0].get('date') > 9) ? arrayDates[0].get('date') : '0' + arrayDates[0].get('date')}`
    const dateEnd = `${arrayDates[1].get('year')}-${(arrayDates[1].get('month') + 1 > 9) ? arrayDates[1].get('month') + 1 : '0' + (arrayDates[1].get('month') + 1)}-${(arrayDates[1].get('date') > 9) ? arrayDates[1].get('date') : '0' + arrayDates[1].get('date')}`
    setDates([dateStart, dateEnd])
  }

  return (
    <List
      title={t('menu.sales')}
      headerProps={{
        extra: <>
          <RangePicker showTime={false} onChange={changeDates} />
          <Button icon={<Icons.SearchOutlined />} onClick={async () => getSalesList()}>{}</Button>
          <ExportToExcelButton filename='Ventas' jsonData={dataToExport(tableProps.dataSource as any[], true, economyShimentId)} />
        </>
      }}
    >
      <Table {...tableProps}
        loading={loading}
        pagination={{
          current: current,
          pageSize: pageSize,
          onChange (page, pageSize) {
            setCurrent(page)
            setPageSize(pageSize)
          }
        }}
        scroll={{ x: `${controlWidth}%`, y: '60vh' }}
        rowKey='saleId'>
        {columns.map((column) => {
          return (
            <>
              <Table.Column
                key={column?.key || column?.type}
                title={column?.title}
                dataIndex={column?.dataIndex}
                defaultSortOrder={column?.key === 'order.invoice_date' ? 'descend' : null}
                sorter={column?.key === 'order.invoice_date'
                  ? {
                      compare: (a:any, b:any) => {
                        const strA = a.order?.invoice_date
                        const strB = b.order?.invoice_date

                        return strA.localeCompare(strB)
                      }
                    }
                  : column.sorter}
                render={(value, record: any) => {
                  const synivaPrice = (record?.order?.shipping_method_id && economyShimentId === record?.order?.shipping_method_id) ? record?.price || record?.syniva_price || 0 : record?.syniva_price || 0
                  const itemPrice = synivaPrice * record?.quantity
                  const marketplaceIgic = record?.marketplace?.igic_types?.value || 0
                  const itemIgic = record?.igic_percent || marketplaceIgic
                  const orderTotal = record?.order?.syniva_price || 0
                  const clientName = (record?.order?.billing_address?.name || '') + ' ' + (record?.order?.billing_address?.surnames || '')

                  if (column.dataIndex?.toString() === ['order', 'billing_address', 'name'].toString()) {
                    return clientName
                  }

                  if (column.dataIndex === 'syniva_price') {
                    return currencyRight(itemPrice)
                  }

                  if (column.type === 'igicValue') {
                    const result = (orderTotal > MAX_IGIC_VALUE) ? record?.igic_price || ((itemPrice * marketplaceIgic) / 100) : 0
                    return currencyRight(result)
                  }

                  if (column.dataIndex?.toString() === ['order', 'delivered_date'].toString()) {
                    return value || ''
                  }

                  if (column.type === 'subtotal') {
                    const result = orderTotal > MAX_IGIC_VALUE ? (itemPrice * marketplaceIgic) / 100 : 0
                    return currencyRight(currencyValue(result + itemPrice))
                  }

                  if (column.type === 'date') {
                    return new Date(value).toLocaleDateString()
                  }

                  if (column.type === 'currency') {
                    return currencyRight(value)
                  }

                  if (column.type === 'igicPercent') {
                    return (value && orderTotal > MAX_IGIC_VALUE) ? itemIgic : 0
                  }

                  if (column.type === 'orderTotal') {
                    const supplementAmount = record?.order?.supplements
                      .map((s: Supplement) => s.supplement || 0)
                      .reduce((a: number, b: number) => a + b, 0)

                    return currency(value + supplementAmount)
                  }

                  return value
                }}
                {...(column?.filterType && {
                  filterDropdown: (props) => {
                    if (column.filterType === 'date') {
                      return <DateFilterDropdown field={column?.key || ''} filters={filters} setFilters={(newFilter: any, method: string) => {
                        if (newFilter.length > 0 && method === 'merge') {
                          console.log(newFilter)
                          const fields = newFilter[0].field?.split('.')
                          const rows = { ...allRows }

                          if (fields?.length === 1) {
                            rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`] >= newFilter[0].value && x[`${fields[0]}`] <= newFilter[1].value)
                          } else if (fields?.length === 2) {
                            rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`][`${fields[1]}`] >= newFilter[0].value && x[`${fields[0]}`][`${fields[1]}`] <= newFilter[1].value)
                          }

                          setTableProps({ ...rows })
                        } else {
                          setTableProps({ ...allRows })
                        }
                      }} />
                    }

                    if (column.filterType === 'number') {
                      return <QuantityFilterDropdown field={column?.key || ''} filters={filters} setFilters={(newFilter: any) => {
                        if (newFilter.length > 0 && Array.isArray(newFilter)) {
                          const fields = newFilter[0].field?.split('.')
                          const rows = { ...allRows }
                          console.log(rows, newFilter)
                          if (fields?.length === 1) {
                            if (newFilter[0].operator === 'eq') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`] === parseInt(newFilter[0].value))
                            } else if (newFilter[0].operator === 'gt') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`] > parseInt(newFilter[0].value))
                            } else if (newFilter[0].operator === 'lt') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`] < parseInt(newFilter[0].value))
                            }
                          } else if (fields?.length === 2) {
                            if (newFilter[0].operator === 'eq') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`][`${fields[1]}`] === parseInt(newFilter[0].value))
                            } else if (newFilter[0].operator === 'gt') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`][`${fields[1]}`] > parseInt(newFilter[0].value))
                            } else if (newFilter[0].operator === 'lt') {
                              rows.dataSource = rows.dataSource.filter((x: any) => x[`${fields[0]}`][`${fields[1]}`] < parseInt(newFilter[0].value))
                            }
                          }
                          setTableProps({ ...rows })
                        } else {
                          setTableProps({ ...allRows })
                        }
                      }} />
                    }

                    if (column.filterType === 'select') {
                      return <SelectFilterDropdown props={props} entity={column?.entity || ''} optionLabel={column?.label || ''} optionValue={column?.value || ''} type={column?.selectType || 'number'} filters={column?.selectFilters || [{ field: column?.label, operator: 'nnull', value: 'null' }]} />
                    }
                  }
                })}
                width={150}
              />
            </>
          )
        })}
      </Table>
    </List>
  )
}
