import {
  ButtonProps,
  Create,
  Drawer,
  DrawerProps,
  FormProps,
  Grid
} from '@pankod/refine-antd'
import { MarketplaceForm } from './form'

type MarketplaceCreateProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
}

export const MarketplaceCreateDrawer: React.FC<MarketplaceCreateProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '70vw' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={saveButtonProps}
      >
        <MarketplaceForm
          formProps={formProps}
        />
      </Create>
    </Drawer>
  )
}
