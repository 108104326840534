import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters
} from '@pankod/refine-core'

import { definitions } from 'interfaces'
import { Tabs, useTable } from '@pankod/refine-antd'
import { useState } from 'react'
import { MarketFilter } from './marketFilter'
import { MarketTable } from './marketTable'

export const MarketplaceList: React.FC<IResourceComponentsProps> = () => {
  const [tab, setTab] = useState('1')

  const t = useTranslate()

  const handleClick = (key: string, e: any) => {
    e.preventDefault()
    setTab(key)
  }

  const { tableProps, filters, setFilters, searchFormProps, tableQueryResult } = useTable<definitions['marketplaces']>({
    metaData: {
      select: 'id, name, logo_url, description, return_days, status, url, is_in_home, extra_info, is_in_app, shipment_price, free_shipment_price, weight, iva_percent, created_at, updated_at, original_url, is_custom_shop, internal_shop_id,' +
        'igic_types(id, name, value),' +
        'marketplace_category_relations(' +
          'marketplace_categories(name))'
    },
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { name, createdAt } = params

      filters.push(
        {
          field: 'name',
          operator: 'contains',
          value: name || undefined
        },
        {
          field: 'created_at',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString().split('T')[0] + ' 00:00:00' : undefined
        },
        {
          field: 'created_at',
          operator: 'lte',
          value: createdAt ? createdAt[1] : undefined
        }
      )
      return filters
    },
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'is_custom_shop',
        operator: 'eq',
        value: !(tab === '1')
      }
    ],
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  return (
    <>
      <MarketFilter searchFormProps={searchFormProps} />

      <Tabs defaultActiveKey='1' activeKey={tab} size='middle' onTabClick={handleClick}>
      <Tabs.TabPane tab={t('marketplace.marketplaces')} key='1'>
        <MarketTable
          tableProps={tableProps}
          filters={filters}
          setFilters={setFilters}
          tableQueryResult={tableQueryResult}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('marketplace.titles.custom')} key='2'>
        <MarketTable isCustom={true}
          tableProps={tableProps}
          filters={filters}
          setFilters={setFilters}
          tableQueryResult={tableQueryResult}
        />
      </Tabs.TabPane>
    </Tabs>
    </>
  )
}
