import { Button, Icons, Modal, Select, Spin, useModal } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { definitions, PendingMarketOrders } from 'interfaces'
import { useContext, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { findPendingMarketOrdersWithoutInvoice } from 'utility'
import { OrderInfo } from './OrderInfo'
import { fetchBiller } from 'utility/biller'
import { InvoicesMatchingsContext } from 'utility/contexts'

type Props = {
  searchOrder: boolean
  record: definitions['invoices_matchings']
  closeFn?: () => void
}

export const AssignInvoiceModal: React.FC<Props> = ({ searchOrder, record, closeFn }) => {
  const t = useTranslate()
  const invalidateAll = useContext(InvoicesMatchingsContext)
  const { modalProps, show, close } = useModal()
  const { open } = useNotification()
  const debouncedSearchOrders = useDebouncedCallback((value) => handleSearchOrders(value), 500)
  const [fetching, setFetching] = useState(false)
  const [orders, setOrders] = useState<PendingMarketOrders[]>([])
  const [selectedOrder, setSelectedOrder] = useState<PendingMarketOrders>()
  const [loading, setLoading] = useState(false)

  async function handleSearchOrders (newValue: string) {
    if (newValue.length > 0) {
      setFetching(true)
      const res = await findPendingMarketOrdersWithoutInvoice(newValue)
      if (res) {
        setOrders(res || [])
      }
      setFetching(false)
    }
  }

  function errorNotification () {
    open?.({
      type: 'error',
      message: t('invoices_matchings.assign_invoice_error'),
      description: t('notifications.errorMsg')
    })
  }

  async function handleSubmit () {
    setLoading(true)

    if (searchOrder && !selectedOrder) return

    try {
      const url = searchOrder
        ? `/assign_invoice_to_market_order/${record.id}/${selectedOrder!.market_order_id}`
        : `/assign_invoice/${record.id}`

      const response = await fetchBiller(url, { method: 'PATCH' })

      if (response.status !== 200) {
        return errorNotification()
      }

      open?.({
        type: 'success',
        message: t('notifications.successMsg')
      })

      close()
      closeFn?.()
    } catch (error) {
      errorNotification()
    } finally {
      invalidateAll()
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        onClick={show}
        icon={<Icons.LinkOutlined />}
      />

      <Modal
        {...modalProps}
        title={t('invoices_matchings.titles.assign_invoice')}
        centered
        onOk={handleSubmit}
        okButtonProps={{ loading: loading, disabled: searchOrder && !selectedOrder }}
        style={{ minWidth: '30svw' }}
      >
        {searchOrder
          ? (
              <>
                <Trans
                  i18nKey="invoices_matchings.are_you_sure_assign_search_order"
                  components={{ b: <b /> }}
                />
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  onSearch={debouncedSearchOrders}
                  options={(orders || []).map(order => ({
                    value: order.market_order_id,
                    label: `${order.purchase_id} - ${order.purchase_amount}`
                  }))}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onChange={(value) => setSelectedOrder(orders.find(order => order.market_order_id === value))}
                  style={{ width: '100%', marginTop: 22 }}
                />
                <OrderInfo record={selectedOrder} />
              </>
            )
          : (
              <Trans
                i18nKey="invoices_matchings.are_you_sure_assign"
                components={{ b: <b /> }}
              />
            )
        }
      </Modal>
    </>
  )
}
