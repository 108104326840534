import { Button, Icons, Modal, useModal } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { useContext, useState } from 'react'
import { Trans } from 'react-i18next'
import { fetchBiller } from 'utility/biller'
import { InvoicesMatchingsContext } from 'utility/contexts'

type Props = {
  record: definitions['invoices_matchings']
  closeFn?: () => void
}

export const UnassignInvoiceModal: React.FC<Props> = ({ record, closeFn }) => {
  const t = useTranslate()
  const invalidateAll = useContext(InvoicesMatchingsContext)
  const { modalProps, show, close } = useModal()
  const { open } = useNotification()
  const [loading, setLoading] = useState(false)

  function errorNotification () {
    open?.({
      type: 'error',
      message: t('invoices_matchings.unassign_invoice_error'),
      description: t('notifications.errorMsg')
    })
  }

  async function handleSubmit () {
    setLoading(true)

    try {
      const response = await fetchBiller(`/unassign_invoice/${record.id}`, { method: 'PATCH' })

      if (response.status !== 200) {
        return errorNotification()
      }

      open?.({
        type: 'success',
        message: t('notifications.successMsg')
      })

      close()
      closeFn?.()
    } catch (error) {
      errorNotification()
    } finally {
      invalidateAll()
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        onClick={show}
        icon={<Icons.ApiOutlined />}
        danger
      />

      <Modal
        {...modalProps}
        title={t('invoices_matchings.titles.unassign_file')}
        centered
        onOk={handleSubmit}
        okButtonProps={{ loading: loading }}
      >
        <Trans
          i18nKey="invoices_matchings.are_you_sure_unassign"
          components={{ b: <b /> }}
        />
      </Modal>
    </>
  )
}
