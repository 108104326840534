import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core'

import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  BooleanField,
  useModal,
  Grid,
  useDrawerForm,
  CreateButton,
  FilterDropdown,
  FilterDropdownProps,
  Radio
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { ChildrenCategories } from './childrenCategories'
import { MarketplaceCategoriesCreate } from './create'
import { MarketplaceCategoriesEdit } from './edit'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'

const expandedRowRender = (record: definitions['marketplace_categories']) => {
  return <ChildrenCategories record={record} />
}

export const MarketplaceCategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, filters, setFilters, tableQueryResult } = useTable<definitions['marketplace_categories']>({
    initialSorter: [
      {
        field: 'weight',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'parent_id',
        operator: 'null',
        value: ''
      }
    ],
    metaData: {
      select: 'id, name, description, weight, is_in_home, parent_id(id, name)'
    }
  })

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<definitions['marketplace_categories']>({
    action: 'edit',
    resource: 'marketplace_categories',
    redirect: false
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['marketplace_categories']>({
    action: 'create',
    resource: 'marketplace_categories',
    redirect: false
  })

  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const breakpoint = Grid.useBreakpoint()

  return (
    <List
      pageHeaderProps={{
        extra:
          <CreateButton onClick={() => createShow()}>
            {t('actions.create')}
          </CreateButton>
      }}
    >
      <Table {...tableProps} rowKey="id"
        expandable={{
          expandedRowRender: !breakpoint.xs
            ? expandedRowRender
            : undefined
        }}
      >
        <Table.Column
          key="id"
          dataIndex="id"
          title={t('marketplace_categories.fields.id')}
          align='center'
          sorter
        />
        <Table.Column
          key="id"
          dataIndex="name"
          title={t('marketplace_categories.fields.name')}
          align='center'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='marketplace_categories'
              optionLabel='name'
              optionValue='id'
              filters={[
                {
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                },
                {
                  field: 'parent_id',
                  operator: 'null',
                  value: ''
                }
              ]}
            />
          )}
        />
        <Table.Column
          key="description"
          dataIndex="description"
          title={t('marketplace_categories.fields.description')}
          align='center'
          sorter
        />
        <Table.Column
          key="weight"
          dataIndex="weight"
          title={t('marketplace_categories.fields.weight')}
          align='center'
          sorter
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='weight'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column
          key="is_in_home"
          dataIndex="is_in_home"
          title={t('marketplace.fields.isInHome')}
          align='center'
          render={(value) => (
            <BooleanField
              value={value}
              valueLabelTrue={t('status.yes')}
              valueLabelFalse={t('status.no')}
            />
          )}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Radio.Group>
                <Radio value='true'>{t('status.yes')}</Radio>
                <Radio value='false'>{t('status.no')}</Radio>
              </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column<definitions['marketplace_categories']>
          title={t('table.actions')}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                onClick={() => editShow(record.id)}
                hideText
              />
              <ButtonLogicDelete click={() => {
                show()
                setId(record.id)
              }} />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  entity='marketplace_categories'
                  tableQueryResult={tableQueryResult}
                />

            </Space>
          )}
        />
      </Table>
      <MarketplaceCategoriesEdit
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <MarketplaceCategoriesCreate
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </List>
  )
}
