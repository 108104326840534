/* eslint-disable eqeqeq */
import { useTranslate } from '@pankod/refine-core'

import {
  List,
  Table,
  Tag,
  DateField,
  ShowButton,
  FilterDropdownProps,
  FilterDropdown,
  Typography,
  Radio,
  useSelect,
  Input,
  Icons,
  CreateButton,
  useDrawerForm,
  Space,
  AutoComplete,
  Select
} from '@pankod/refine-antd'

import { definitions, Order } from 'interfaces'
import { ADMITTED_STATUS_TEXT, DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, ORDER_STATUS_ID_PENDING_PAYMENT, PENDING_PAYMENT_STATUS_TEXT } from 'utility/constants'
import { currency } from 'utility/currency'
import { getColorLabel } from 'utility/colorLabel'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { Key, useEffect, useState } from 'react'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { getOrderExport, getOrderIdAlias, getOrders, supabaseClient, filter, sorter } from 'utility'
import '../../custom.css'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { GenerateBIReportButton } from 'components/GenerateBIReportButton/GenerateBIReportButton'
import { HorizontalAuditButton } from 'components/horizontalAuditButton/horizontalAuditButton'
import { currentUserEmail } from 'utility/authLocal'
import { OrdersListProps } from './list'
import { CreateOrder } from './create'
import { EditableRowField } from 'components/EditableRowField/EditableRowField'
import { BankReportButton } from 'components/BankReportButton/BankReportButton'
import { ESTIMATED_COSTS_STATUS_MAPPER } from 'utility/mapper'
import { EstimatedCostsStatusIdTypes } from 'types/types'

export type tablePropsProps = {
  dataSource: any[]
}

export const OrdersList: React.FC<OrdersListProps> = ({ select, dataToExport, getConditionalClassName, onlyPaidOrders, statusOrder }) => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 3)

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [filters, setFilters] = useState<filter[]>((statusOrder === null)
    ? [{ field: 'supplements.deleted', operator: 'eq', value: false }, { field: 'status_id', operator: 'gt', value: 0 }]
    : [{ field: 'supplements.deleted', operator: 'eq', value: false }, { field: 'status_id', operator: Array.isArray(statusOrder) ? 'in' : 'eq', value: statusOrder }]
  )
  const [sorter, setSorter] = useState<sorter>({ field: 'id', ascending: false })

  const getOrdersList = async (resetPages: boolean = false) => {
    setLoading(true)
    const cPage = (resetPages) ? 1 : currentPage
    const { data: dataSource, count } = await getOrders(select, cPage, pageSize, filters, sorter)
    const tableProps = {
      dataSource: dataSource
    }
    tableProps.dataSource = (tableProps.dataSource as any)?.map((row: any) => {
      row.action_dates = { cancelation_date: row.cancelation_date, return_date: row.return_date }
      row.purchase_url = (row.marketplace_id?.id === 0)
        ? row.items?.find((x: any) => x.custom_order_site !== null)?.custom_order_site || ''
        : row.marketplace_id?.original_url || ''
      return row
    })

    if (onlyPaidOrders) {
      tableProps.dataSource = tableProps.dataSource?.filter((x: any) => x.status_id && x.status_id > 0)
    }
    setTableProps(tableProps)
    setTotalData(count || 0)
    setLoading(false)
  }

  const [totalData, setTotalData] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)

  // filter values
  const [filterId, setFilterId] = useState<number|undefined>(undefined)
  const [filteredIdState, setFilteredIdState] = useState<boolean>(false)
  const [filterAlias, setFilterAlias] = useState<string|undefined>(undefined)
  const [filteredAliasState, setFilteredAliasState] = useState<boolean>(false)
  const [filterLocation, setFilterLocation] = useState<string|undefined>(undefined)
  const [filteredLocationState, setFilteredLocationState] = useState<boolean>(false)
  const [filterEmail, setFilterEmail] = useState<Key[]|undefined>(undefined)
  const [filteredEmailState, setFilteredEmailState] = useState<boolean>(false)
  const [filterDevice, setFilterDevice] = useState<string|undefined>(undefined)
  const [filteredDeviceState, setFilteredDeviceState] = useState<boolean>(false)
  const [filterMarketplaceId, setFilterMarketplaceId] = useState<Key[]|undefined>(undefined)
  const [filteredMarketplaceIdState, setFilteredMarketplaceIdState] = useState<boolean>(false)
  const [filterPaymentMethodId, setFilterPaymentMethodId] = useState<Key[]|undefined>(undefined)
  const [filteredPaymentMethodIdState, setFilteredPaymentMethodIdState] = useState<boolean>(false)
  const [filterStatusId, setFilterStatusId] = useState<Key[]|undefined>(undefined)
  const [filteredStatusIdState, setFilteredStatusIdState] = useState<boolean>(false)
  const [filterType, setFilterType] = useState<string|undefined>(undefined)
  const [filteredTypeState, setFilteredTypeState] = useState<boolean>(false)
  const [filterShippingMethodId, setFilterShippingMethodId] = useState<Key[]|undefined>(undefined)
  const [filteredShippingMethodIdState, setFilteredShippingMethodIdState] = useState<boolean>(false)
  const [filterAdminId, setFilterAdminId] = useState<Key[]|undefined>(undefined)
  const [filteredAdminIdState, setFilteredAdminIdState] = useState<boolean>(false)
  const [filteredPurchaseId, setFilteredPurchaseId] = useState<boolean>(false)
  const [filteredDeliveryNote, setFilteredDeliveryNote] = useState<boolean>(false)
  const [filterPurchaseUrl, setFilterPurchaseUrl] = useState<string|undefined>(undefined)
  const [filteredPurchaseUrlState, setFilteredPurchaseUrlState] = useState<boolean>(false)
  const [estimatedCostsStatusId, setFilterEstimatedCostsStatusId] = useState<Key[]|undefined>(undefined)

  const [estimatedCostsStatusIdState, setFilteredEstimatedCostsStatusIdState] = useState<boolean>(false)
  const [filteredCreatedAtState, setFilteredCreatedAtState] = useState<boolean>(false)
  const [filteredTotalState, setFilteredTotalState] = useState<boolean>(false)
  const [filteredCancelDateState, setFilteredCancelDateState] = useState<boolean>(false)

  // Aux states
  const [purchaseOptions, setPurchaseOptions] = useState<{ value: string, label: string, order: number }[]>([])
  const [deliveryOptions, setDeliveryOptions] = useState<{ value: string, label: string, order: number }[]>([])
  const [purchaseFilterValue, setPurchaseFilterValue] = useState<string | undefined>(undefined)
  const [deliveryFilterValue, setDeliveryFilterValue] = useState<{ value: string, order: number } | undefined>(undefined)

  const t = useTranslate()

  useEffect(() => {
    const current = localStorage.getItem('ordersPage')
    setCurrentPage(current ? parseInt(current) : 1)
  }, [])

  useEffect(() => {
    // console.log('filters -->', filters)
    getOrdersList(true)
    setCurrentPage(1)
  }, [filters])

  useEffect(() => {
    // console.log('sorter -->', sorter)
    getOrdersList()
  }, [sorter])

  useEffect(() => {
    getOrdersList()
  }, [currentPage, pageSize])

  const { Text } = Typography

  const handlePurchaseSearch = async (value: string) => {
    // let res: { value: string; label: string }[] = []
    const { data: purchaseOpt } = await supabaseClient.from('market_orders')
      .select('*')
      .ilike('purchase_id', `%${value}%`)
    if (purchaseOpt) {
      setPurchaseOptions(purchaseOpt.map((p: definitions['market_orders']) => { return { value: p.purchase_id, label: p.purchase_id, order: p.order_id } }) as any)
    } else {
      setPurchaseOptions([])
    }
  }

  const handleDeliveryNoteSearch = async (value: string) => {
    // let res: { value: string; label: string }[] = []
    const { data: deliveryOpts } = await supabaseClient.from('shipments')
      .select('id, order_id, delivery_note')
      .ilike('delivery_note', `%${value}%`)
    if (deliveryOpts) {
      setDeliveryOptions(deliveryOpts.map((p: any) => { return { value: p.delivery_note, order: p.order_id } }) as any)
    } else {
      setDeliveryOptions([])
    }
  }

  const { selectProps } = useSelect({
    resource: 'orders',
    fetchSize: 9999999999999,
    optionLabel: 'id',
    optionValue: 'id'
  })

  const excludedFields = (onlyPaidOrders) ? [PENDING_PAYMENT_STATUS_TEXT] : ['']

  selectProps.options = selectProps.options?.map((option) => {
    return { label: getOrderIdAlias(Number(option.label)), value: option.value }
  })
  selectProps.options = selectProps.options?.sort((a: any, b: any) => a.value - b.value)

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['orders']>({
    action: 'create',
    resource: 'orders',
    redirect: false
  })

  let controlWidth = window.innerWidth
  const fixedMobile = controlWidth > 700 ? 'left' : false
  controlWidth = controlWidth < 728 ? 750 : 200

  const handleLocationChange = async (value: any, record: any, func: Function) => {
    if (record.id) {
      await supabaseClient
        .from('orders')
        .update({
          location: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)
      const val = tableProps?.dataSource?.find(x => x.id === record.id)
      if (val) {
        val.location = value
      }
      func(true)
    }
  }

  async function exportOrders () {
    let data = await getOrderExport(select, filters, sorter)
    data = (data as any)?.map((row: any) => {
      row.action_dates = { cancelation_date: row.cancelation_date, return_date: row.return_date }
      return row
    })

    if (onlyPaidOrders) {
      data = data?.filter((x: any) => x.status_id && x.status_id > 0)
    }

    return dataToExport(data as any[])
  }

  return (
    <>
      <List
        title={
          <>
            {t('order.orders')}
            <Text type='secondary' style={{ fontSize: 10, display: 'block', lineHeight: 2 }}>{t('order.cancelHelper')}</Text>
            <Text type='secondary' style={{ fontSize: 10, display: 'block', lineHeight: 2 }}>{t('order.returnHelper')}</Text>
          </>
        }
        headerProps={{
          extra: <>
            <HorizontalAuditButton filename='horizontal audit report' />
            <BankReportButton filename='Banks report' />
            <GenerateBIReportButton filename='BI report' />
            <CreateButton onClick={() => createShow()}>{t('order.actions.addOrder')}</CreateButton>
            <ExportToExcelButton filename='Pedidos' asyncFunction={() => exportOrders()} />
          </>
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          rowClassName={(record) => getConditionalClassName(record)}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            onChange (page, pageSize) {
              setCurrentPage(page)
              setPageSize(pageSize)
              localStorage.setItem('ordersPage', page.toString() || '')
              localStorage.setItem('ordersPageSize', pageSize.toString() || '')
            }
          }}
          onChange={(_pagination: any, _filters: any, sorter: any, _extra: any) => {
            if (sorter && sorter.order) {
              setSorter({ field: sorter.field, ascending: (sorter.order === 'ascend') })
            } else {
              setSorter({ field: 'id', ascending: false })
            }
          }}
          scroll={{ x: `${controlWidth}vw`, y: '60vh' }}
          >
          <Table.Column
            key='id'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="id"
            align="center"
            title={t('order.fields.id')}
            filtered={filteredIdState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterId(Number(selectedKeys))
                  return selectedKeys
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'id')])
                  setFilterId(undefined)
                  setFilteredIdState(false)
                }}
                confirm={() => {
                  if (filterId) {
                    if (filters.find((x: filter) => x.field === 'id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'id')
                      updatedFilters[index].value = filterId

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'id', operator: 'eq', value: filterId }]])
                    }
                    setFilteredIdState(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
            sorter
          />
          <Table.Column
            key='alias'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="alias"
            align="center"
            title={t('order.fields.alias')}
            sorter
            width={100}
            filtered={filteredAliasState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterAlias(selectedKeys.toString().toUpperCase())
                  return selectedKeys.toString().toUpperCase()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'alias')])
                  setFilterId(undefined)
                  setFilteredAliasState(false)
                }}
                confirm={() => {
                  if (filterAlias) {
                    if (filters.find((x: filter) => x.field === 'alias')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'alias')
                      updatedFilters[index].value = filterAlias

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'alias', operator: 'eq', value: filterAlias }]])
                    }
                    setFilteredAliasState(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
            // render= {(text) => <div style={{ width: '70px' }}>{text}</div>}
          />
          <Table.Column
            key='package_number'
            dataIndex="package_number"
            align="center"
            title={t('order.fields.package_number')}
            width={100}
            // render= {(text) => <div style={{ width: '50px' }}>{text}</div>}
          />
          <Table.Column
            key='location'
            dataIndex="location"
            align="center"
            title={t('order.fields.location')}
            render={(value, record: definitions['orders']) => (
              <Space style={{ width: '75px', justifyContent: 'center' }}>
                <EditableRowField key={value} record={record} handleSave={handleLocationChange} />
              </Space>
            )}
            filtered={filteredLocationState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterLocation(selectedKeys.toString() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'location')])
                  setFilterLocation(undefined)
                  setFilteredLocationState(false)
                }}
                confirm={() => {
                  if (filterLocation) {
                    if (filters.find((x: filter) => x.field === 'location')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'location')
                      updatedFilters[index].value = filterLocation

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'location', operator: 'eq', value: filterLocation }]])
                    }
                    setFilteredLocationState(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='created_at'
            dataIndex="created_at"
            align="center"
            title={t('order.fields.createdAt')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
            sorter
            filtered={filteredCreatedAtState}
            filterDropdown={() => (
              <DateFilterDropdown
                field='created_at'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    let filtersToAdd = [...filters]

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'gte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'gte')
                      updatedFilters[index].value = newFilter[0].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                    }

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'lte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'lte')
                      updatedFilters[index].value = newFilter[1].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                    }

                    setFilters([...filtersToAdd])
                    setFilteredCreatedAtState(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== 'created_at')])
                    setFilteredCreatedAtState(false)
                  }
                }}
              />
            )}
          />
          <Table.Column
            key='user_id'
            dataIndex={['user', 'email']}
            align="center"
            title={t('order.fields.userEmail')}
            sorter
            filtered={filteredEmailState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterEmail
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'user_id')])
                setFilterEmail(undefined)
                setFilteredEmailState(false)
              }
              props.confirm = () => {
                if (filterEmail && filterEmail.length > 0) {
                  if (filters.find((x: filter) => x.field === 'user_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'user_id')
                    updatedFilters[index].value = filterEmail

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'user_id', operator: 'in', value: filterEmail }]])
                  }
                  setFilteredEmailState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='users'
                optionLabel='email'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='device'
            dataIndex="device"
            align="center"
            title={t('order.fields.device')}
            sorter
            width={150}
            filtered={filteredDeviceState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterDevice(selectedKeys.toString().toLowerCase() || undefined)
                  return selectedKeys.toString().toLowerCase()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'device')])
                  setFilterDevice(undefined)
                  setFilteredDeviceState(false)
                }}
                confirm={() => {
                  if (filterDevice) {
                    if (filters.find((x: filter) => x.field === 'device')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'device')
                      updatedFilters[index].value = filterDevice

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'device', operator: 'eq', value: filterDevice }]])
                    }
                    setFilteredDeviceState(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={['marketplace_id', 'name']}
            align="center"
            title={t('order.fields.marketplace')}
            sorter
            key="marketplace_id"
            filtered={filteredMarketplaceIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterMarketplaceId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'marketplace_id')])
                setFilterMarketplaceId(undefined)
                setFilteredMarketplaceIdState(false)
              }
              props.confirm = () => {
                if (filterMarketplaceId && filterMarketplaceId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'marketplace_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_id')
                    updatedFilters[index].value = filterMarketplaceId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'marketplace_id', operator: 'in', value: filterMarketplaceId }]])
                  }
                  setFilteredMarketplaceIdState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='marketplaces'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='prucharse_url'
            dataIndex='purchase_url'
            align="center"
            title={t('order.fields.purchaseUrl')}
            width={150}
            filtered={filteredPurchaseUrlState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterPurchaseUrl(selectedKeys.toString().toLowerCase() || undefined)
                  return selectedKeys.toString().toLowerCase()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'items.custom_order_site')])
                  // setFilters([...filters.filter((x: filter) => x.field !== 'marketplace_id')])
                  setFilterPurchaseUrl(undefined)
                  setFilteredPurchaseUrlState(false)
                  // setFilterMarketplaceId(undefined)
                  // setFilteredMarketplaceIdState(false)
                }}
                confirm={() => {
                  if (filterPurchaseUrl) {
                    if (filters.find((x: filter) => x.field === 'items.custom_order_site')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'items.custom_order_site')
                      updatedFilters[index].value = filterPurchaseUrl

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[
                        // { field: 'marketplace_id', operator: 'in', value: [0] },
                        { field: 'items.custom_order_site', operator: 'ilike', value: `%${filterPurchaseUrl}%` }
                      ]])
                    }
                    setFilteredPurchaseUrlState(true)
                    // setFilteredMarketplaceIdState(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='total'
            dataIndex="total"
            align="center"
            title={t('order.fields.billingAmount')}
            sorter
            render={(value) => currency(value)}
            filtered={filteredTotalState}
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='total'
                filters={filters}
                setFilters={(newFilter: any) => {
                  if (Array.isArray(newFilter)) {
                    setFilters([...newFilter])
                    setFilteredTotalState(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== 'total')])
                    setFilteredTotalState(false)
                  }
                }}
              />
            )}
          />
          <Table.Column
            key='payment_method_id'
            dataIndex={['payment_method', 'name']}
            align="center"
            title={t('order.fields.paymentMethod')}
            sorter
            filtered={filteredPaymentMethodIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterPaymentMethodId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'payment_method_id')])
                setFilterPaymentMethodId(undefined)
                setFilteredPaymentMethodIdState(false)
              }
              props.confirm = () => {
                if (filterPaymentMethodId && filterPaymentMethodId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'payment_method_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'payment_method_id')
                    updatedFilters[index].value = filterPaymentMethodId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'payment_method_id', operator: 'in', value: filterPaymentMethodId }]])
                  }
                  setFilteredPaymentMethodIdState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='payment_methods'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key="status_id"
            dataIndex={['status', 'name']}
            align="center"
            title={t('order.fields.status')}
            render={(value, record: definitions['orders']) => (
              <Tag color={getColorLabel(value, record?.prev_status_id)}>
                {capitalizeFirstLetter(value)}
              </Tag>
            )}
            sorter
            filtered={filteredStatusIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterStatusId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'status_id' && x.operator === 'in')])
                setFilterStatusId(undefined)
                setFilteredStatusIdState(false)
              }
              props.confirm = () => {
                if (filterStatusId && filterStatusId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'status_id' && x.operator === 'in')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'status_id' && x.operator === 'in')
                    updatedFilters[index].value = filterStatusId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'status_id', operator: 'in', value: filterStatusId }]])
                  }
                  setFilteredStatusIdState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='status'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
                excludeFieldsFilter={excludedFields}
              />
            }}
          />
          <Table.Column
            key="supplements.status_id"
            dataIndex='supplements'
            align="center"
            title={t('order.fields.supplementStatus')}
            render={(supplements) => {
              if (supplements.length > 0) {
                const pendingPaymentSupplements = supplements?.filter((supplement: any) => supplement.status.id === ORDER_STATUS_ID_PENDING_PAYMENT)
                if (pendingPaymentSupplements.length > 0) {
                  return (
                    <Tag color={getColorLabel(PENDING_PAYMENT_STATUS_TEXT)}>
                      {capitalizeFirstLetter(PENDING_PAYMENT_STATUS_TEXT)}
                    </Tag>
                  )
                } else {
                  return (
                    <Tag color={getColorLabel(ADMITTED_STATUS_TEXT)}>
                      {capitalizeFirstLetter(ADMITTED_STATUS_TEXT)}
                    </Tag>
                  )
                }
              }
            }}
          />
          <Table.Column
            key="action_dates"
            dataIndex="action_dates"
            align="center"
            title={t('order.fields.cancellationReturnDate')}
            width={210}
            render={({ cancelation_date: cancelationDate, return_date: returnDate }) => {
              if (cancelationDate && !returnDate) {
                return <DateField format={DEFAULT_DATE_FORMAT} value={cancelationDate} />
              }
              if (returnDate && !cancelationDate) {
                return <DateField format={DEFAULT_DATE_FORMAT} value={returnDate} />
              }
              return ''
            }}
            filtered={filteredCancelDateState}
            filterDropdown={() => (
              <>
                <DateFilterDropdown
                  field='cancelation_date'
                  filters={filters}
                  setFilters={(newFilter: any, method: string) => {
                    if (newFilter.length > 0 && method === 'merge') {
                      let filtersToAdd = [...filters]

                      if (filters.find((x: filter) => x.field === 'cancelation_date' && x.operator === 'gte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'cancelation_date' && x.operator === 'gte')
                        updatedFilters[index].value = newFilter[0].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                      }

                      if (filters.find((x: filter) => x.field === 'cancelation_date' && x.operator === 'lte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'cancelation_date' && x.operator === 'lte')
                        updatedFilters[index].value = newFilter[1].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                      }

                      setFilters([...filtersToAdd])
                      setFilteredCancelDateState(true)
                    } else {
                      setFilters([...filters.filter((x: filter) => x.field !== 'cancelation_date')])
                      setFilteredCancelDateState(false)
                    }
                  }}
                  label={t('order.fields.cancelationDate')}
                />
                <DateFilterDropdown
                  field='return_date'
                  filters={filters}
                  setFilters={(newFilter: any, method: string) => {
                    if (newFilter.length > 0 && method === 'merge') {
                      let filtersToAdd = [...filters]

                      if (filters.find((x: filter) => x.field === 'return_date' && x.operator === 'gte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'return_date' && x.operator === 'gte')
                        updatedFilters[index].value = newFilter[0].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                      }

                      if (filters.find((x: filter) => x.field === 'return_date' && x.operator === 'lte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'return_date' && x.operator === 'lte')
                        updatedFilters[index].value = newFilter[1].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                      }

                      setFilters([...filtersToAdd])
                      setFilteredCancelDateState(true)
                    } else {
                      setFilters([...filters.filter((x: filter) => x.field !== 'return_date')])
                      setFilteredCancelDateState(false)
                    }
                  }}
                  label={t('order.fields.returnDate')}
                />
              </>
            )}
          />
          <Table.Column
            key="type"
            dataIndex="type"
            align="center"
            title={t('order.fields.type')}
            sorter
            filtered={filteredTypeState}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterType(selectedKeys.toString() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'type')])
                  setFilterType(undefined)
                  setFilteredTypeState(false)
                }}
                confirm={() => {
                  if (filterType) {
                    if (filters.find((x: filter) => x.field === 'type')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'type')
                      updatedFilters[index].value = filterType

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'type', operator: 'eq', value: filterType }]])
                    }
                    setFilteredTypeState(true)
                  }
                }}
              >
                <Radio.Group>
                  <Radio value='Interno'>Interno</Radio>
                  <Radio value='Personalizado'>Personalizado</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="estimated_costs_status"
            dataIndex="estimated_costs_status"
            align="center"
            title={t('order.fields.estimatedStatus')}
            sorter
            width={150}
            render={(value) => ESTIMATED_COSTS_STATUS_MAPPER[value as EstimatedCostsStatusIdTypes]}
            filtered={estimatedCostsStatusIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              return <FilterDropdown {...props}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'estimated_costs_status' && x.operator === 'in')])
                  setFilterEstimatedCostsStatusId(undefined)
                  setFilteredEstimatedCostsStatusIdState(false)
                }}
                confirm={() => {
                  console.log(estimatedCostsStatusId)
                  if (estimatedCostsStatusId) {
                    if (filters.find((x: filter) => x.field === 'estimated_costs_status' && x.operator === 'in')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'estimated_costs_status' && x.operator === 'in')
                      updatedFilters[index].value = estimatedCostsStatusId

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'estimated_costs_status', operator: 'in', value: estimatedCostsStatusId }]])
                    }
                    setFilteredStatusIdState(true)
                  }
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  mode="multiple"
                  options={Object.entries(ESTIMATED_COSTS_STATUS_MAPPER).map(([key, value]) => ({
                    value: parseInt(key),
                    label: value
                  }))}
                  onSelect={(value: any, option: any) => {
                    setFilterEstimatedCostsStatusId(value)
                    return null
                  }}
                />
              </FilterDropdown>
            }}
          />
          <Table.Column
            key='shipping_method_id'
            dataIndex={['shipping_method', 'name']}
            align="center"
            title={t('order.fields.shipmentMethod')}
            sorter
            filtered={filteredShippingMethodIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterShippingMethodId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'shipping_method_id')])
                setFilterShippingMethodId(undefined)
                setFilteredShippingMethodIdState(false)
              }
              props.confirm = () => {
                if (filterShippingMethodId && filterShippingMethodId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'shipping_method_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'shipping_method_id')
                    updatedFilters[index].value = filterShippingMethodId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'shipping_method_id', operator: 'in', value: filterShippingMethodId }]])
                  }
                  setFilteredShippingMethodIdState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='shipment_methods'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='shipments.type'
            dataIndex="shipments"
            align="center"
            title={t('order.fields.shipmentType')}
            render={(shipment) => capitalizeFirstLetter(shipment[0]?.type)}
            sorter={{
              compare: (a:Order, b:Order) => {
                const strA = a.shipments[0]?.type || 'z'
                const strB = b.shipments[0]?.type || 'z'

                return strA.localeCompare(strB)
              }
            }}
          />
          <Table.Column
            key='shipments.shipper_type'
            dataIndex="shipments"
            title={t('order.fields.shipperType')}
            align="center"
            render={(shipment) => shipment[0]?.shipper_type}
          />
          <Table.Column
            key='delivery_note'
            dataIndex='shipments'
            title={t('shipments.fields.deliveryNote')}
            align="center"
            render={(shipment) => shipment[0]?.delivery_note}
            filtered={filteredDeliveryNote}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'id')])
                  setDeliveryFilterValue(undefined)
                  setFilteredDeliveryNote(false)
                }}
                confirm={() => {
                  if (filters.find((x: filter) => x.field === 'id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'id')
                    updatedFilters[index].value = deliveryOptions.find((x: any) => x.value.includes(deliveryFilterValue?.value?.toString()))

                    setFilters([...updatedFilters])
                  } else {
                    const val = deliveryOptions.find((x: any) => x.value.includes(deliveryFilterValue?.value?.toString()))
                    setFilters([...filters, ...[{ field: 'id', operator: 'eq', value: val?.order }]])
                  }
                  setFilteredDeliveryNote(true)
                }}
              >
                <AutoComplete style={{ width: 200 }}
                  onSearch={(value: string) => {
                    if (value.length > 3) {
                      handleDeliveryNoteSearch(value)
                    }
                  }}
                  onSelect={(value: any, option: any) => {
                    setDeliveryFilterValue(option)
                    return null
                  }}
                  options={deliveryOptions} value={deliveryFilterValue?.value} />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='administrator_id'
            dataIndex={['administrator', 'name']}
            align="center"
            title={t('order.fields.administrator')}
            sorter
            width={175}
            filtered={filteredAdminIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterAdminId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'administrator_id')])
                setFilterAdminId(undefined)
                setFilteredAdminIdState(false)
              }
              props.confirm = () => {
                if (filterAdminId && filterAdminId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'administrator_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'administrator_id')
                    updatedFilters[index].value = filterAdminId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'administrator_id', operator: 'in', value: filterAdminId }]])
                  }
                  setFilteredAdminIdState(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='users'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='purchaseId'
            dataIndex='market_orders'
            title={t('market_order.fields.purchaseId')}
            render={(marketOrders) => marketOrders.map((marketOrder:any, index:number) => {
              return (
                <Tag key={index}>{marketOrder?.purchase_id}</Tag>
              )
            })}
            filtered={filteredPurchaseId}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys: any) => {
                  setPurchaseFilterValue(purchaseOptions[0]?.order?.toString())
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'id')])
                  setPurchaseFilterValue(undefined)
                  setFilteredPurchaseId(false)
                }}
                confirm={() => {
                  if (purchaseFilterValue) {
                    if (filters.find((x: filter) => x.field === 'id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'id')
                      updatedFilters[index].value = purchaseFilterValue

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'id', operator: 'eq', value: purchaseFilterValue }]])
                    }
                    setFilteredPurchaseId(true)
                  }
                }}
              >
                <AutoComplete style={{ width: 200 }} onSearch={(value: string) => {
                  if (value.length > 3) {
                    handlePurchaseSearch(value)
                  }
                }} options={purchaseOptions} value={purchaseFilterValue} />
              </FilterDropdown>
            )}
          />
          {/* Filter by url <Table.Column
            dataIndex="market_orders"
            title={t('market_order.fields.purchaseId')}
            render={(marketOrders) => marketOrders.map((marketOrder:any, index:number) => {
              return (
                <Tag key={index}>{marketOrder?.purchase_id}</Tag>
              )
            })}
          /> */}
          <Table.Column
            dataIndex="market_orders"
            title={t('market_order.fields.transactionId')}
            render={(marketOrders) => marketOrders.map((marketOrder:any, index:number) => {
              return (
                <Tag key={index}>{marketOrder?.transaction_id}</Tag>
              )
            })}
          />
          <Table.Column
            dataIndex="market_orders"
            align="center"
            title={t('market_order.fields.trackingId')}
            render={(marketOrders) => marketOrders.map((marketOrder:any, index:number) => {
              return (
                <Tag key={index}>{marketOrder?.tracking_id}</Tag>
              )
            })}
          />
          <Table.Column
            dataIndex="market_orders"
            align="center"
            title={t('market_order.fields.purchaseAmount')}
            render={(marketOrders) => {
              let total:number = 0
              marketOrders.forEach((marketOrder:any) => {
                total = total + marketOrder?.purchase_amount
              })
              return currency(total)
            }}
          />
          <Table.Column
            dataIndex="market_orders"
            title={t('market_order.fields.invoiceNumber')}
            render={(marketOrders) => marketOrders.map((marketOrder:any, index:number) => {
              return (
                <Tag key={index}>{marketOrder?.invoice_number}</Tag>
              )
            })}
          />
          <Table.Column<definitions['orders']>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            width= {fixedMobile ? 100 : 70}
            render={(_, record) => {
              if (record.locked) {
                return (
                  <>
                    <Icons.LockOutlined style={{ color: '#f5222d', fontSize: 20 }} />
                    {record.locked_by === currentUserEmail() && (
                      <ShowButton
                        hideText
                        style={{ color: 'green', fontSize: '15px', marginLeft: 5 }}
                        recordItemId={record.id}
                      />
                    )}
                    <p>{record?.locked_by}</p>
                  </>
                )
              }
              return (
                <ShowButton
                  hideText
                  style={{ color: 'green', fontSize: '15px' }}
                  recordItemId={record.id}
                />
              )
            }}
          />
        </Table>
        <CreateOrder
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          closeDrawer={createClose}
        />
      </List>
    </>
  )
}
