import { Button, Drawer, ExportButton, Grid, Input, ModalProps, Space, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { BUSINESS_STATUS_REQUESTED_ID, supabaseClient } from 'utility'
import { postalCodeToIsle } from 'utility/postalCodeToIsle'
import { read, utils, writeFile } from 'xlsx'

type drawerUploadExcelProp = {
  drawerProps: ModalProps,
  close: any,
  setNewExcelOrders: Function
}

export const ModalUploadExcel: React.FC<drawerUploadExcelProp> = ({
  drawerProps,
  close,
  setNewExcelOrders
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [loading, setLoading] = useState(false)

  const [updateFile, setUpdateFile] = useState(null)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updateOrderCost = async (ID: Number, reference: string, name: string, surnames: string, email: string, street: string, number: Number, postalCode: string, city: string, nif: string, phoneNumber: Number) => {
    const isleCode = postalCode.slice(0, 3)
    const isleId = Number(postalCodeToIsle(Number(isleCode)))
    const { data: addressesData } = await supabaseClient.from('addresses')
      .upsert({ name: name, surnames: surnames, email: email, street: street, number: number, postal_code: Number(postalCode), city: city, nif: nif, phone_number: phoneNumber, island_id: isleId })
    await supabaseClient.from('company_order')
      .upsert({ shipping_address_id: addressesData![0].id, user_id: ID, reference: reference, status_id: BUSINESS_STATUS_REQUESTED_ID })
  }

  const updateOrdersCosts = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setUpdateFinished(null)

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = utils.sheet_to_json(ws, { header: 1 })

        for (const m of dataParse) {
          if (typeof m[0] !== 'string') {
            const row = { ID: Number(m[0]), reference: m[1], name: m[2], surnames: m[3], email: m[4], street: m[5], number: Number(m[6]), postal_code: m[7], city: m[8], nif: m[9], phone_number: Number(m[10]) }
            await updateOrderCost(row.ID, row.reference, row.name, row.surnames, row.email, row.street, row.number, row.postal_code, row.city, row.nif, row.phone_number)
          }
        }
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('ok')
        setNewExcelOrders(true)
      } catch (err) {
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  const downloadTemplate = () => {
    const templateData = [
      [1, 'Referencia', 'Edgar', 'Arcos Martin', 'edgar611@gmail.com', 'Avenida Jose Mesa y Lopez', 23, '35010', 'Las Palmas', '54387965Z', 664356787],
      [2, 'Referencia', 'Matias', 'Rodriguez Mira', 'matoiasRM@gmail.com', 'Escaleritas', 2, '35121', 'Las Palmas', '45839756A', 664678990]
    ]
    const rowList = templateData.map((x: any) => {
      return { 'Id usuario': x[0], Referencia: x[1], Nombre: x[2], Apellidos: x[3], Email: x[4], Direccion: x[5], Numero: x[6], 'Codigo postal': x[7], Poblacion: x[8], DNI: x[9], 'Numero de telefono': x[10] }
    })
    const worksheet = utils.json_to_sheet(rowList, { skipHeader: false })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    writeFile(workbook, 'create_orders_template.xlsx')
  }

  return <>
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('company_order.uploadOrdersExcel.donwloadOrderTemplate')}
        </Typography.Text>

        <ExportButton
          style={{ marginBottom: '1rem' }}
          onClick={() => downloadTemplate()}
          size='small'
        >
          {t('company_order.applyCosts.labelTemplate')}
        </ExportButton>

        <Typography.Text>{t('marketplace.actions.uploadExcel')}</Typography.Text>
        <Input
          accept='.xlsx'
          bordered={false}
          size='middle'
          type='file'
          key='exceluploader'
          onChange={handleFile}
        />
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => updateOrdersCosts(e)}
        >
          {t('company_order.uploadOrdersExcel.createOrders')}
        </Button>

        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('company_order.uploadOrdersExcel.labelCreate')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('company_order.uploadOrdersExcel.createFinishok')}
        </Typography>}
      </Space>
    </Drawer>
  </>
}
