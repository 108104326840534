import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  ButtonProps,
  Grid,
  Create,
  Edit,
  InputNumber,
  Select,
  Typography
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility/supabaseClient'
import { ORDER_STATUS_ID_PENDING_PAYMENT, REFUND_TYPE } from 'utility/constants'
import { QueryObserverResult } from '@tanstack/react-query'

type RefundActionsProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
  closeDrawer: Function
  edit?: boolean
  refundsQuery: QueryObserverResult
  orderQuery: QueryObserverResult
}

export const RefundActions: React.FC<RefundActionsProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  closeDrawer,
  edit = false,
  orderQuery,
  refundsQuery
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [description, setDescription] = useState('')
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState<string | null>('')
  const [statusId, setStatusId] = useState(ORDER_STATUS_ID_PENDING_PAYMENT)
  const [validTypeForm, setValidTypeForm] = useState(true)
  const [validDescForm, setValidDescForm] = useState(true)

  const refundId = Number.isInteger(formProps.initialValues?.id) && formProps.initialValues?.id
  const orderId = Number.isInteger(formProps.initialValues?.order_id) && formProps.initialValues?.order_id

  useEffect(() => {
    setValidTypeForm(true)
    setValidDescForm(true)
    if (edit) {
      setDescription(formProps.initialValues?.description || '')
      setAmount(formProps.initialValues?.amount || 0)
      setType(formProps.initialValues?.type || REFUND_TYPE.client)
      setStatusId(formProps.initialValues?.status_id || ORDER_STATUS_ID_PENDING_PAYMENT)
    } else {
      setDescription('')
      setAmount(0)
    }
  }, [formProps.initialValues])

  const states = {
    order_id: orderId,
    description: description,
    amount: amount,
    status_id: statusId
  }

  const validateForm = (state: any) => {
    let isValid = true
    if (!state.type || state.type === '') {
      isValid = false
      setValidTypeForm(false)
    }
    if (!state.description || state.description === '') {
      isValid = false
      setValidDescForm(false)
    }
    return isValid
  }

  const insertRefund = async () => {
    const state = Object.assign({ type: type }, states)

    if (!validateForm(state)) {
      return
    }

    await supabaseClient
      .from('refunds')
      .insert([state])

    refundsQuery.refetch()
    orderQuery.refetch()
    closeDrawer()
  }

  const updateRefund = async () => {
    const state = Object.assign({ type: type }, states)

    await supabaseClient
      .from('refunds')
      .update(state)
      .eq('id', refundId)

    refundsQuery.refetch()
    orderQuery.refetch()
    closeDrawer()
  }

  const Action: any = edit ? Edit : Create
  const REFUND_TYPES: Array<Object> = [{ value: 'client', label: t('refund.client') }, { value: 'supplier', label: t('refund.supplier') }]

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      {Number.isInteger(orderId) && (
        <Action
          resource="refunds"
          saveButtonProps={saveButtonProps}
          breadcrumb={false}
          goBack={false}
          title={edit ? t('order.actions.editRefund') : t('order.actions.addRefund')}
          headerButtons={() => (<></>)}
        >
          <Form {...formProps} layout="vertical" onFinish={edit ? updateRefund : insertRefund}>
            <Form.Item
              hidden={true}
              name="order_id"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('refund.fields.refundType')}
              name="refundType"
              validateStatus={validTypeForm ? 'success' : 'error'}
              required
            >
              <Select
                options={REFUND_TYPES}
                defaultValue={type}
                getPopupContainer={trigger => trigger.parentElement}
                onChange={(value) => setType(value)}
                disabled={edit}
              />
            </Form.Item>

            <>
              <Form.Item
                label={t('refund.fields.description')}
                getValueProps={(value) => value}
                validateStatus={validDescForm ? 'success' : 'error'}
                required
              >
                <Input value={description} onChange={(event) => setDescription(event.target?.value)} />
              </Form.Item>
              <Form.Item
                label={t('refund.fields.amount')}
                name="amount"
                getValueProps={(value) => value}
              >
                <InputNumber value={amount} min={0} onChange={(value) => setAmount(value ?? 0)} decimalSeparator=',' />
              </Form.Item>

              {(!validTypeForm || !validDescForm) ? <Typography.Text>{t('refund.errorValidation')}</Typography.Text> : null}
            </>
          </Form>
        </Action>
      )}
    </Drawer>
  )
}
