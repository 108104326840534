import { Icons } from '@pankod/refine-antd'
import { CSSProperties, ComponentProps, useEffect, useState } from 'react'

type Props = {
  ext: string
} & ComponentProps<typeof Icons.FileFilled>

export const IconByExt = ({ ext, style, ...props }: Props) => {
  const [IconComponent, setIconComponent] = useState<any>()
  const [iconStyle, setIconStyle] = useState<CSSProperties>(style || {})

  useEffect(() => {
    switch (ext) {
      case 'pdf':
        setIconComponent(Icons.FilePdfOutlined)
        setIconStyle({ ...style, color: 'red' })
        break
      default:
        setIconComponent(Icons.FileFilled)
        setIconStyle(style || {})
    }
  }, [ext])

  if (!IconComponent) return null
  else return <IconComponent style={iconStyle} {...props} />
}
