import { Button, Descriptions, Icons, Input } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'

type EditableRowFieldProps = {
  record: any,
  handleSave: any
}

export const EditableRowField = ({ record, handleSave }: EditableRowFieldProps) => {
  const t = useTranslate()

  const [editfield, setEditField] = useState(false)
  const [currentValue, setCurrentValue] = useState<string>(record?.location)

  const handleEditValue = (isClose: boolean = false) => {
    if (isClose) {
      setCurrentValue(record?.location)
      setEditField(false)
    } else {
      setEditField(!editfield)
    }
  }

  const onChangeValue = (value: any) => {
    setCurrentValue(value)
  }

  return (!editfield)
    ? <Descriptions.Item key={(record.id || record.order_id) + currentValue} label={t('order.fields.location')}>
        <div style={{ display: 'flex', alignItems: 'initial' }}>
          {currentValue}
          <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 20 }} icon={<Icons.EditOutlined />} onClick={() => handleEditValue()} />
        </div>
      </Descriptions.Item>
    : <Descriptions.Item label={t('order.fields.location')}>
        <div style={{ display: 'flex', alignItems: 'initial' }}>
          <Input style={{ minWidth: '50px', maxWidth: '100px', height: '32px', marginLeft: 10, marginTop: -5 }} defaultValue={currentValue} onChange={(e) => onChangeValue(e.target.value)}/>
          <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 10 }} icon={<Icons.SaveOutlined />} onClick={() => handleSave(currentValue, record, handleEditValue) } />
          <Button shape='circle' style={{ border: 'none', top: -5, marginLeft: 0 }} icon={<Icons.CloseOutlined />} onClick={() => handleEditValue(true)} />
        </div>
      </Descriptions.Item>
}
