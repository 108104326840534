import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { List, Table, useTable, ShowButton, FilterDropdownProps, useDrawerForm } from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { BASIC_USER_ROLE_ID } from 'utility'
import { SelectFilterDropdown } from 'components/filterDropdown'
import { AddressEditDrawer } from './edit'

export const AddressesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<definitions['users']>({
    resource: 'users',
    metaData: {
      select: 'id, name, email, default_shipping_address_id, default_billing_address_id'
    },
    permanentFilter: [
      {
        field: 'role_id',
        operator: 'eq',
        value: BASIC_USER_ROLE_ID
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })
  const t = useTranslate()

  const {
    formProps: shippingFormProps,
    drawerProps: shippingDrawerProps,
    show: shippingShow,
    saveButtonProps: shippingSaveButtonProps
  } = useDrawerForm<definitions['addresses']>({
    action: 'edit',
    resource: 'addresses',
    redirect: false
  })

  const {
    formProps: billingFormProps,
    drawerProps: billingDrawerProps,
    show: billingShow,
    saveButtonProps: billingSaveButtonProps
  } = useDrawerForm<definitions['addresses']>({
    action: 'edit',
    resource: 'addresses',
    redirect: false
  })

  return (
    <List>
      <Table {...tableProps} rowKey='id'>
        <Table.Column key='id' dataIndex='id' title={t('users.id')} align='center' sorter />
        <Table.Column
          key='id'
          dataIndex='name'
          title={t('users.name')}
          align='center'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='users'
              optionLabel='name'
              optionValue='id'
              filters={[
                {
                  field: 'role_id',
                  operator: 'eq',
                  value: BASIC_USER_ROLE_ID
                },
                {
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }
              ]}
            />
          )}
        />
        <Table.Column
          key='id'
          dataIndex='email'
          title={t('users.email')}
          align='center'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='users'
              optionLabel='email'
              optionValue='id'
              filters={[
                {
                  field: 'role_id',
                  operator: 'eq',
                  value: BASIC_USER_ROLE_ID
                },
                {
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }
              ]}
            />
          )}
        />
        <Table.Column<definitions['users']>
          title={t('address.addresses')}
          dataIndex='actions'
          align='center'
          render={(_, record) => (
            <>
              {record.default_shipping_address_id && (
                <ShowButton
                  size='small'
                  recordItemId={record.id}
                  style={{ height: 'fit-content' }}
                  onClick={() => shippingShow(record.default_shipping_address_id)}
                >
                  <br />
                  {t('address.fields.shipping')}
                </ShowButton>
              )}
              {record.default_billing_address_id && (
                <ShowButton
                  size='small'
                  recordItemId={record.id}
                  style={{ height: 'fit-content', marginLeft: 3 }}
                  onClick={() => billingShow(record.default_billing_address_id)}
                >
                  <br />
                  {t('address.fields.billing')}
                </ShowButton>
              )}
            </>
          )}
        />
      </Table>
      <AddressEditDrawer
        drawerProps={billingDrawerProps}
        formProps={billingFormProps}
        saveButtonProps={billingSaveButtonProps}
        type={t('address.fields.billing')}
        updateOrderAddress={undefined}
        showError={false}
      />
      <AddressEditDrawer
        drawerProps={shippingDrawerProps}
        formProps={shippingFormProps}
        saveButtonProps={shippingSaveButtonProps}
        type={t('address.fields.shipping')}
        updateOrderAddress={undefined}
        showError={false}
      />
    </List>
  )
}
