import { useTranslate } from '@pankod/refine-core'
import {
  Form,
  FormProps,
  Input,
  InputNumber
} from '@pankod/refine-antd'

type PriceRangeFormProps = {
  formProps: FormProps;
};

export const PriceRangeForm: React.FC<PriceRangeFormProps> = ({ formProps }) => {
  const t = useTranslate()

  return (
    <>
      {Number.isInteger(formProps.initialValues?.shipping_method_id) && (
        <Form {...formProps} layout="vertical">
          <Form.Item
            name="shipping_method_id"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('shippingMethodPriceRanges.fields.from')}
            name="from_weight"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber addonAfter='Kg' decimalSeparator=',' />
          </Form.Item>
          <Form.Item
            label={t('shippingMethodPriceRanges.fields.to')}
            name="to_weight"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber addonAfter='Kg' decimalSeparator=',' />
          </Form.Item>
          <Form.Item
            label={t('shippingMethodPriceRanges.fields.price')}
            name="price"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber addonAfter='€' decimalSeparator=',' />
          </Form.Item>
        </Form>
      )}
    </>
  )
}
