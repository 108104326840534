import { useTranslate } from '@pankod/refine-core'

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  ButtonProps,
  Grid,
  Input,
  Row,
  Col
} from '@pankod/refine-antd'

type EditAddressesProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
}

export const AddressesEditDrawer: React.FC<EditAddressesProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        title={t('company_order.editBusinessOrder')}
        breadcrumb={false}
        goBack={false}
        saveButtonProps={{
          onClick: async (e: any) => {
            if (saveButtonProps && saveButtonProps.onClick) saveButtonProps?.onClick(e)
          }
        }}
        pageHeaderProps={{ extra: null }}
      >
        <Form {...formProps} layout="vertical">
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.name')}
                name="name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.surnames')}
                name="surnames"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={25}>
            <Form.Item
              label={t('address.fields.street')}
              name="street"
            >
              <Input />
            </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.phoneNumber')}
                name='phone_number'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('company_order.fields.email')}
                name='email'
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('address.fields.postalCode')}
                name='postal_code'
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('address.fields.cif')}
                name='nif'
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Edit>
    </Drawer>
  )
}
