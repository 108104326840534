import {
  Typography,
  Space,
  useDrawerForm,
  EditButton,
  Icons,
  Form,
  Select,
  Card,
  Button,
  Tooltip,
  useModal
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { DownloadOutlined, BarcodeOutlined } from '@ant-design/icons'
import { definitions } from 'interfaces'
import { CompanyOrderEditDrawer } from './edit'
import { SHIPMENT_TYPE_DIRECT, TIPSA_AGENCY_CODE, TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED, TIPSA_AGENCY_SERVICE_TYPE_DIRECT, formatDateTime, getShipmentData, supabaseClient } from 'utility'
import { BUSINESS_STATUS_MAPPER } from 'utility/mapper'
import { ShipmentModal } from 'pages/company_order/shipmentModal'
import { AddressesEditDrawer } from './addressesEditDrawer'
import { useEffect, useState } from 'react'

type CompanyOrderProps = {
  companyOrder: any;
  refetch: () => void
};

export const CompanyOrder: React.FC<CompanyOrderProps> = (props) => {
  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const {
    formProps: companyOrderFormProps,
    drawerProps: companyOrderDrawerProps,
    saveButtonProps: companyOrderButtonProps,
    show: companyOrderShow
  } = useDrawerForm<definitions['company_order']>({
    action: 'edit',
    resource: 'company_order',
    redirect: false
  })
  const [shipmentType, setShipmentType] = useState<string>()

  const {
    formProps: addressesFormProps,
    drawerProps: addressesDrawerProps,
    saveButtonProps: addressesButtonProps,
    show: addressesFormShow
  } = useDrawerForm<definitions['addresses']>({
    action: 'edit',
    resource: 'addresses',
    redirect: false,
    onMutationSuccess: props.refetch
  })

  const companyOrder = props.companyOrder

  const downloadInvoice = (record: any) => {
    const { data: uploadedData } = supabaseClient.storage
      .from('businessinvoices')
      .getPublicUrl(record.invoice_id)

    window.open(uploadedData?.publicURL)
  }

  const retrieveShipment = async () => {
    setShipmentType(await getShipmentData(companyOrder?.id))
  }

  useEffect(() => {
    if (!shipmentType) {
      retrieveShipment()
    }
  }, [])

  const showShipmentData = () => {
    if (!companyOrder?.tracking_url) return ''
    return <>
        <Button icon={<Icons.InfoCircleOutlined />} onClick={() => window.open(companyOrder.tracking_url, '_blank')}>
          {t('order.fields.shipmentDataAvailable')}
        </Button>
        {<Typography.Text>
          <strong>{t('order.fields.shipmentDataAvailable')}: </strong> {TIPSA_AGENCY_CODE}{(shipmentType?.toLowerCase().includes(SHIPMENT_TYPE_DIRECT) ? TIPSA_AGENCY_SERVICE_TYPE_DIRECT : TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED)}{companyOrder?.delivery_note}
        </Typography.Text>}
      </>
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ paddingBottom: '1rem', display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '0.5rem' }}>
        <Tooltip
          style={{ fontSize: '1px' }}
          title={
            !companyOrder.invoice_id ? t('notifications.errorTooltip') : ''
          }
        >
          <Button
            disabled={!companyOrder.invoice_id}
            onClick={() => {
              downloadInvoice(companyOrder)
            }}
            style={{
              fontSize: '15px',
              color: '#00B6FF',
              borderColor: '#00B6FF',
              marginLeft: '3px',
              paddingLeft: '1.5rem'
            }}
          >
            <DownloadOutlined style={{ margin: '-10px' }} />
            <span style={{ marginLeft: '1rem' }}>{t('Descargar factura')}</span>
          </Button>
        </Tooltip>
        <Tooltip
          style={{ fontSize: '1px' }}
          title={t('shipments.actions.prepare')}
        >
          <Button
            onClick={() => {
              show()
            }}
            style={{
              fontSize: '15px',
              color: '#00B6FF',
              borderColor: '#00B6FF',
              marginLeft: '3px',
              paddingLeft: '1.5rem'
            }}
          >
            <BarcodeOutlined style={{ margin: '-10px' }} />
            <span style={{ marginLeft: '1rem' }}>{t('shipments.actions.prepare')}</span>
          </Button>
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          gap: '1rem'
        }}
      >
        <Card bordered={false} style={{ width: '100%' }}>
          <div>
            <Typography.Title level={4}>
              {t('company_order.generalData')}
            </Typography.Title>
          </div>
          <div style={{ marginTop: '1rem', width: '100%', display: 'flex' }}>
            <Space direction="vertical">
              <Typography.Text>
                <b>{t('company_order.fields.id')}</b>: {companyOrder?.id}
              </Typography.Text>
              <Form.Item label={t('order.fields.status')} name="status_id">
                <Select
                  defaultValue={companyOrder?.status_id}
                  options={
                    Object.entries(BUSINESS_STATUS_MAPPER).map(([key, value]) => ({
                      value: parseInt(key),
                      label: t(`company_order.status.${value}`)
                    }))
                  }
                  getPopupContainer={(trigger) => trigger.parentElement}
                  style={{ width: 'min-content', minWidth: '8rem' }}
                  onChange={async (selectedOption) => {
                    await supabaseClient
                      .from('company_order')
                      .update({
                        updated_at: new Date(Date.now()).toISOString(),
                        updated_by: supabaseClient.auth.user()?.email || null,
                        status_id: selectedOption
                      })
                      .eq('id', companyOrder?.id)
                  }}
                />
              </Form.Item>
              <Typography.Text>
                <b>{t('company_order.fields.invoice_number')}</b>:{' '}
                {companyOrder?.invoice_number}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.brand')}</b>:{' '}
                  {companyOrder?.user_id.name}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.reference')}</b>:{' '}
                {companyOrder?.reference}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.createdAt')}</b>:{' '}
                {formatDateTime(companyOrder?.created_at)}
              </Typography.Text>
            </Space>
          </div>
          <Space direction="vertical" style={{ width: '100%', height: '100%', paddingTop: '30px' }}>
            {showShipmentData()}
          </Space>
        </Card>
        <Card bordered={false} style={{ width: '100%' }}>
          <div style={{ marginRight: '2rem' }}>
            <Typography.Title level={4} style={{ marginRight: '1%' }}>
              {t('company_order.sentData')}
            </Typography.Title>
            <EditButton
              style={{ marginLeft: 225, marginTop: -37.5, float: 'left' }}
              hideText={true}
              type={'text'}
              shape={'circle'}
              onClick={() => companyOrderShow(companyOrder?.id)}
            />
          </div>
          <div style={{ marginTop: '1rem', width: '100%', display: 'flex' }}>
            <Space direction="vertical">
              <Typography.Text>
                <b>{t('company_order.fields.tracking_id')}</b>:{' '}
                {companyOrder?.tracking_id}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.delivery_note')}</b>:{' '}
                {companyOrder?.delivery_note}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.type')}</b>: {companyOrder?.type}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.shipper')}</b>:{' '}
                {companyOrder?.shipper}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.vol_weight')}</b>:{' '}
                {companyOrder.vol_weight}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.vol_price')}</b>:{' '}
                {companyOrder.vol_price}
              </Typography.Text>
              <Typography.Text>
                <b>{t('company_order.fields.location')}</b>:{' '}
                {companyOrder?.location}
              </Typography.Text>
            </Space>
          </div>
        </Card>
        <Card bordered={false} style={{ width: '100%' }}>
          <div style={{ marginRight: '2rem' }}>
            <Typography.Title level={4} style={{ marginRight: '1%' }}>
              {t('order.fields.shippingAddress')}
            </Typography.Title>
            <EditButton
              style={{ marginLeft: 225, marginTop: -37.5, float: 'left' }}
              hideText={true}
              type={'text'}
              shape={'circle'}
              onClick={() => addressesFormShow(companyOrder.shipping_address_id.id)}
            />
          </div>
          <div style={{ marginTop: '1rem', width: '100%', display: 'flex' }}>
            <Space direction="vertical">
              <Typography.Text>
                <Icons.UserOutlined /> {companyOrder.shipping_address_id?.name}{' '}
                {companyOrder.shipping_address_id?.surnames}
              </Typography.Text>
              <Typography.Text>
                <Icons.IdcardOutlined /> {companyOrder.shipping_address_id?.nif}
              </Typography.Text>
              <Typography.Text>
                <Icons.PhoneOutlined />{' '}
                {companyOrder.shipping_address_id?.phone_number}
              </Typography.Text>
              <Typography.Text>
                <Icons.MailOutlined /> {companyOrder.shipping_address_id?.email}
              </Typography.Text>
              <Typography.Text>
                <Icons.HomeOutlined />{' '}
                {companyOrder.shipping_address_id?.full_address}
              </Typography.Text>
            </Space>
          </div>
          {/* <CompanyAddressInfo title={'Direccion envio'} address={companyOrder.shipping_address_id as any} orderId={0} type={'shipping'} /> */}
        </Card>
      </div>
      <CompanyOrderEditDrawer
        drawerProps={companyOrderDrawerProps}
        formProps={companyOrderFormProps}
        saveButtonProps={companyOrderButtonProps}
      />
      <AddressesEditDrawer
        drawerProps={addressesDrawerProps}
        formProps={addressesFormProps}
        saveButtonProps={addressesButtonProps}
      />
      <ShipmentModal modalProps={modalProps} close={close} orderId={companyOrder.id} />
    </div>
  )
}
