import { useTranslate } from '@pankod/refine-core'

import {
  DrawerProps,
  Drawer,
  Grid,
  Create,
  Row,
  Col,
  Typography,
  Icons,
  InputNumber
} from '@pankod/refine-antd'
import { useState } from 'react'
import { cloneOrderItems } from 'utility'

type OrderItemsChangerProps = {
  drawerProps: DrawerProps
  orderId: number
  userId: number
  marketplaceId: number
}

export const OrderItemsChanger: React.FC<OrderItemsChangerProps> = ({
  drawerProps,
  orderId,
  userId,
  marketplaceId
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [orderFrom] = useState(orderId)
  const [orderTo, setOrderTo] = useState<number|undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={{
          onClick: async () => {
            setError(false)
            setSuccess(false)
            setLoading(true)

            if (typeof orderTo !== 'number' || orderTo === 0) {
              setError(true)
              setLoading(false)
              return
            }

            const results = await cloneOrderItems(orderFrom, orderTo as number, userId, marketplaceId)

            if (!results.success) {
              setError(true)
              setLoading(false)
              return
            }

            setSuccess(true)
            setLoading(false)
          }
        }}

        isLoading={loading}
        breadcrumb={false}
        goBack={false}
        title={t('order.actions.changer')}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={11} lg={11}>
            <Typography>{t('order.actions.changerOrderFrom')}</Typography>
            <InputNumber disabled={true} value={orderFrom} />
          </Col>
          <Col xs={1} lg={1}>
            <Icons.ArrowRightOutlined style={{ marginTop: '1.5rem' }} />
          </Col>
          <Col xs={11} lg={11}>
            <Typography>{t('order.actions.changerOrderTo')}</Typography>
            <InputNumber min={1} value={orderTo} onChange={(e: any) => setOrderTo(e)} />
          </Col>
        </Row>
        {error && <Row gutter={[64, 0]} wrap>
          <Typography style={{ margin: '2rem', color: 'red' }}>
            {t('order.actions.errorDetected')}
          </Typography>
        </Row>}
        {success && <Row gutter={[64, 0]} wrap>
          <Typography style={{ margin: '2rem', color: 'green' }}>
            {t('order.actions.success')}
            <br /><br />
            {t('order.actions.advise')}
          </Typography>
        </Row>}
      </Create>
    </Drawer>
  )
}
