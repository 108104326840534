import { useTranslate } from '@pankod/refine-core'

import {
  Collapse,
  Button,
  Select,
  Typography
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { useEffect, useState } from 'react'
import { DELIVERED_STATUS, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, supabaseClient } from 'utility'

type EditOrderDetailsCollapseProps = {
  order: definitions['Order'] | undefined
};

export const EditOrderDetailsCollapse: React.FC<EditOrderDetailsCollapseProps> = ({
  order
}) => {
  const t = useTranslate()

  const CANCEL_OPTIONS = [
    { label: t('order.fields.options.noWantIt'), value: 'noWantIt' },
    { label: t('order.fields.options.priceChange'), value: 'priceChange' },
    { label: t('order.fields.options.ivaChange'), value: 'ivaChange' },
    { label: t('order.fields.options.tariff'), value: 'tariff' },
    { label: t('order.fields.options.transportSup'), value: 'transportSup' },
    { label: t('order.fields.options.volumetricSup'), value: 'volumetricSup' },
    { label: t('order.fields.options.waitTime'), value: 'waitTime' },
    { label: t('order.fields.options.noStock'), value: 'noStock' }
  ]

  const RETURN_OPTIONS = [
    { label: t('order.fields.options.badSize'), value: 'badSize' },
    { label: t('order.fields.options.noWantIt'), value: 'noWantIt' },
    { label: t('order.fields.options.badDescription'), value: 'badDescription' },
    { label: t('order.fields.options.noWork'), value: 'noWork' },
    { label: t('order.fields.options.badProduct'), value: 'badProduct' }
  ]

  const { Panel } = Collapse

  const [optionCancelSelected, setoptionCancelSelected] = useState<string>('')
  const [optionReturnSelected, setoptionReturnSelected] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [returnResult, setReturnResult] = useState<string>('')

  useEffect(() => {

  }, [])

  const canCancel = () => (order?.status_id === ORDER_STATUS_ID_ON_HOLD || order?.status_id === ORDER_STATUS_ID_REQUESTED || order?.status_id === PROCESSED_STATUS || order?.status_id === SUPPLEMENT_STATUS)
  const canReturn = () => (order?.status_id === DELIVERED_STATUS)

  const handleSubmit = async () => {
    const currentDate = new Date().toISOString().split('T')[0]
    setReturnResult('')
    setLoading(true)
    if (canCancel()) {
      const data = await supabaseClient.from('orders')
        .update({
          cancelation_date: currentDate,
          cancelation_reason: optionCancelSelected,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', order?.id)
      if (data) {
        setReturnResult('ok')
      } else {
        setReturnResult('error')
      }
    } else if (canReturn()) {
      const data = await supabaseClient.from('orders')
        .update({
          return_date: currentDate,
          return_reason: optionReturnSelected,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', order?.id)
      if (data) {
        setReturnResult('ok')
      } else {
        setReturnResult('error')
      }
    }
    setLoading(false)
  }

  return (
    <Collapse bordered={false} ghost={false} defaultActiveKey={[]}>
      <Panel style={{ margin: '1rem 2.5rem', height: 500 }} header={t('order.optionalOpsTitle')} key='optionalOps'>
        {(canCancel()) && <>
          <h3>{t('order.titleInfoCancel')}</h3>
          <div>
            <Select
              direction='ltr'
              style={{ width: 300, margin: '1rem 0rem' }}
              id="select-helper"
              value={optionCancelSelected}
              onChange={(e: any) => setoptionCancelSelected(e)}
              options={CANCEL_OPTIONS}
            />
          </div>
          <Button style={{ width: 300 }} loading={loading} disabled={optionCancelSelected === ''} onClick={handleSubmit}>
            {(!loading) && <Typography>{t('order.actions.cancelOrder')}</Typography>}
          </Button>
        </>}

        {(canReturn()) && <>
          <h3>{t('order.titleInfoReturn')}</h3>
          <div>
            <Select
              direction='ltr'
              style={{ width: 300, margin: '1rem 0rem' }}
              id="select-helper"
              value={optionReturnSelected}
              onChange={(e: any) => setoptionReturnSelected(e)}
              options={RETURN_OPTIONS}
            />
          </div>
          <Button style={{ width: 300 }} loading={loading} disabled={optionReturnSelected === ''} onClick={handleSubmit}>
            {(!loading) && <Typography>{t('order.actions.returnOrder')}</Typography>}
          </Button>
        </>}
        {(returnResult !== '') && <Typography>{t(`order.return${returnResult}`)}</Typography>}
      </Panel>
    </Collapse>
  )
}
