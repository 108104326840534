import { Card, Space, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { PendingMarketOrders } from 'interfaces'
import { currency } from 'utility'

type Props = {
  record?: PendingMarketOrders
}

export const OrderInfo: React.FC<Props> = ({ record }) => {
  const t = useTranslate()

  const { purchase_id: purchaseId, tracking_id: trackingId, purchase_amount: purchaseAmount, iva_type: ivaType, iva_amount: ivaAmount, marketplace } = record || {}

  return (
    <Card bordered={false} style={{ width: '100%' }}>
      <div>
        <Typography.Title level={4}>
          {t('invoices_matchings.titles.order_info')}
        </Typography.Title>
      </div>

      <div style={{ marginTop: '1rem', display: 'flex', gap: 30 }}>
        <Space direction='vertical'>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.purchase_id')}</b>:{' '}
            {purchaseId || '---'}
          </Typography.Text>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.tracking_id')}</b>:{' '}
            {trackingId || '---'}
          </Typography.Text>
        </Space>
        <Space direction='vertical'>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.purchase_amount')}</b>:{' '}
            {purchaseAmount ? currency(purchaseAmount) : '---'}
          </Typography.Text>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.iva_type')}</b>:{' '}
            {ivaType || '---'}
          </Typography.Text>
        </Space>
        <Space direction='vertical'>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.iva_amount')}</b>:{' '}
            {ivaAmount ? currency(ivaAmount) : '---'}
          </Typography.Text>
          <Typography.Text>
            <b>{t('invoices_matchings.fields.marketplace')}</b>:{' '}
            {marketplace || '---'}
          </Typography.Text>
        </Space>
      </div>
    </Card>
  )
}
