import { Typography, Space, Icons, useDrawerForm, EditButton } from '@pankod/refine-antd'
import { t } from 'i18next'

import { AddressWithIsland, definitions } from 'interfaces'
import { AddressEditDrawer } from 'pages/addresses/edit'
import { useState } from 'react'
import { updateOrderAddresses } from 'utility'

type AddressInfoProps = {
  orderId?: number
  address: AddressWithIsland | undefined
  title: string
  type: string
  updateAddressInfo: Function
  canEdit?: boolean
};

export const AddressInfo: React.FC<AddressInfoProps> = (props) => {
  const {
    formProps: addressFormProps,
    drawerProps: addressDrawerProps,
    show: addressShow,
    close: addressClose
  } = useDrawerForm<definitions['addresses']>({
    action: 'edit',
    resource: 'addresses',
    redirect: false
  })

  const address = props?.address
  const title = props?.title
  const type = props?.type
  const canEdit = typeof props?.canEdit === 'undefined' ? true : props.canEdit

  const [showError, setShowError] = useState(false)

  const reloadOnSuccess = (dataSuccess: any, newId: number = 0) => {
    const data = dataSuccess

    const dataToUpdate = {
      additional_info: data.additional_info,
      email: data.email,
      full_address: `${data.street} ${data.number}, ${data.city} ${data.postal_code}`,
      id: newId || data.id,
      is_company: data.is_company,
      island: (address?.island) ? address?.island : data.island_id,
      name: data.name,
      nif: data.nif,
      phone_number: data.phone_number,
      surnames: data.surnames
    }

    props.updateAddressInfo(dataToUpdate, type)
    setShowError(false)
    addressClose()
  }

  const updateOrderAddress = async (form: any) => {
    if (!props.orderId) return
    const results = await updateOrderAddresses(form, type, props.orderId)

    if (results?.dataAddress && results?.newAddr) {
      await reloadOnSuccess(results.dataAddress, results.newAddr.data[0].id)
    } else {
      await setShowError(true)
    }
  }

  return (
    <>
      <Space
        direction="vertical"
        style={{
          marginTop: 30,
          width: '100%'
        }}
      >
        <div style={{ display: 'flex' }}>
          <Typography.Title level={4} style={{ marginRight: '1%' }}>{title && title}</Typography.Title>
          {canEdit && <EditButton hideText={true} type={'text'} shape={'circle'} onClick={() => addressShow(address?.id)} />}
        </div>
      </Space>
      <Space
        direction="vertical"
        style={{
          width: '100%'
        }}
      >
        <Typography.Text>
          <Icons.UserOutlined /> {address?.name} {address?.surnames}
        </Typography.Text>
        <Typography.Text>
          <Icons.IdcardOutlined /> {address?.nif}
        </Typography.Text>
        <Typography.Text>
          <Icons.PhoneOutlined /> {address?.phone_number}
        </Typography.Text>
        <Typography.Text>
          <Icons.MailOutlined /> {address?.email}
        </Typography.Text>
        <Typography.Text>
          <Icons.HomeOutlined /> {address?.full_address}
        </Typography.Text>
        {address?.additional_info && (
          <Typography.Text>
            <Icons.CommentOutlined /> {address?.additional_info}
          </Typography.Text>
        )}
        {address?.is_company && (
          <Typography.Text><>
            <Icons.BankOutlined /> {t('address.fields.is_company')}
            </>
          </Typography.Text>
        )}
      </Space>
      {canEdit && (
        <AddressEditDrawer
          drawerProps={addressDrawerProps}
          formProps={addressFormProps}
          saveButtonProps={undefined}
          type={t(`address.fields.${type}`)}
          updateOrderAddress={updateOrderAddress}
          showError={showError}
        />
      )}
    </>
  )
}
