import { useTranslate, IResourceComponentsProps, useNotification } from '@pankod/refine-core'
import '../../custom.css'
import { useEffect, useState } from 'react'
import { DEFAULT_DATE_TIME_FORMAT, Filter, MAX_ROWS_SALES, capitalizeFirstLetter, getColorBusinessLabel, queryFilters, sorter, supabaseClient, viewTicket } from 'utility'
import { tablePropsProps } from 'pages/orders/ordersList'
import { Button, CreateButton, DateField, EditButton, FilterDropdown, Icons, Input, List, Select, ShowButton, Table, Tag, Tooltip, useDrawerForm, useModal } from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { CreateCompanyOrder } from './create'
import { DownloadOutlined, BarcodeOutlined, AuditOutlined } from '@ant-design/icons'
import { ApplyCostsButton } from 'components/ApplyCostsButton/ApplyCostsButton'
import { ShipmentModal } from './shipmentModal'
import { BUSINESS_STATUS_MAPPER } from 'utility/mapper'
import { BusinessStatusIdTypes } from 'types/types'
import { ModalChangeBusinessOrdersStatus } from './changeBusinessOrdersStatusModal'
import { ExportDrawerButton } from 'components/ExportDrawerButton/ExportDrawerButton'
import { Filters } from 'components/ExportToExcelButton/ExportToExcelButton'
import { DateFilterDropdown } from 'components/filterDropdown'
import { ModalUploadExcel } from './modalUploadExcel'

type CompanyOrder = Omit<definitions['company_order'], 'user_id' | 'shipping_address_id'> & { 'user_id': definitions['users'], 'shipping_address_id': definitions['addresses'] }
type CompanyOrderFilter = Filter<CompanyOrder>

export const CompanyOrderList: React.FC<IResourceComponentsProps> = (statusOrder) => {
  let callingServer = false
  const select = '*, user_id!inner(id, name), shipping_address_id(*)'

  const t = useTranslate()

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })

  const [filters, setFilters] = useState<CompanyOrderFilter[]>([])
  const [filterId, setFilterId] = useState<number>()
  const [filterName, setFilterName] = useState<string>()
  const [filterStatus, setFilterStatus] = useState<number[]>()
  const [filterReference, setFilterReference] = useState<string>()
  const [filterType, setFilterType] = useState<string>()
  const [filterShipper, setFilterShipper] = useState<string>()

  // eslint-disable-next-line no-unused-vars
  const [sorter, setSorter] = useState<sorter>({ field: 'id', ascending: false })
  const [totalData, setTotalData] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('companyOrdersPage') ? parseInt(sessionStorage.getItem('companyOrdersPage') || '1') : 1)
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('companyOrdersPageSize') ? parseInt(sessionStorage.getItem('companyOrdersPageSize') || '10') : 10)
  const [loading, setLoading] = useState(false)
  const { open } = useNotification()
  const [selectedRows, setSelectedRows] = useState<definitions['company_order'][]>([])

  const { modalProps, show, close } = useModal()
  const [orderId, setOrderId] = useState(0)
  const [newExcelOrders, setNewExcelOrders] = useState(Boolean)
  const {
    modalProps: modalPropsChangeOrder,
    show: showChangeOrder,
    close: closeChangeOrder
  } = useModal()

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['company_order']>({
    action: 'create',
    resource: 'company_order',
    redirect: false
  })

  const {
    drawerProps: modalUploadExcel,
    show: showUploadExcel,
    close: uploadExcelClose
  } = useDrawerForm<definitions['company_order']>({
    action: 'create',
    redirect: false
  })

  const getCompanyOrderList = async () => {
    if (!callingServer) {
      setLoading(true)
      callingServer = true

      let countQuery = supabaseClient.from('company_order')
        .select(select, { count: 'exact', head: true })
        .eq('deleted', false)

      countQuery = queryFilters(countQuery, filters)

      const { count } = await countQuery

      const counter = count || 0

      let maxIterations = Math.floor((counter || 0) / MAX_ROWS_SALES)

      if ((counter || 0) % MAX_ROWS_SALES > 0) {
        maxIterations += 1
      }

      const iterateArray = new Array(maxIterations)
      const promiseArray = []
      const range = [0, MAX_ROWS_SALES - 1]

      let query = supabaseClient.from('company_order')
        .select(select)
        .eq('deleted', false)
        .order('created_at', { ascending: false })

      // eslint-disable-next-line no-unused-vars
      for (const index of iterateArray) {
        query.range(range[0], range[1])
        query = queryFilters(query, filters)

        promiseArray.push(query)

        range[0] += MAX_ROWS_SALES
        range[1] += MAX_ROWS_SALES
      }

      const results = await Promise.all(promiseArray)
      let querySucess: any[] = []

      results.forEach((r) => {
        if (r.data) {
          querySucess = [...querySucess, ...r.data]
        }
      })
      const tableProps = {
        dataSource: querySucess
      }
      setTableProps(tableProps)
    }

    setTotalData(0)
    setLoading(false)
    callingServer = false
  }

  useEffect(() => {
    if (!callingServer) {
      getCompanyOrderList()
      setNewExcelOrders(false)
    }
  }, [filters, newExcelOrders])

  useEffect(() => {
    if (!callingServer) {
      if (tableProps.dataSource.length > 0) {
        getCompanyOrderList()
      }
    }
  }, [currentPage, pageSize])

  let controlWidth = window.innerWidth
  controlWidth = controlWidth < 728 ? 400 : 105

  const downloadInvoice = (record: any) => {
    const { data: uploadedData, error: urlError } = supabaseClient.storage
      .from('businessinvoices')
      .getPublicUrl(record.invoice_id)

    if (urlError || !uploadedData) {
      open?.({
        type: 'error',
        message: t('banners.fields.error'),
        description: t('notification.error')
      })
    }

    window.open(uploadedData?.publicURL)
  }

  function dataToExport (companyOrders: CompanyOrder[]) {
    return companyOrders.map((companyOrder) => {
      return {
        [t('company_order.fields.id')]: companyOrder.id || '',
        [t('company_order.fields.status_id')]: companyOrder.status_id || '',
        [t('company_order.fields.reference')]: companyOrder.reference || '',
        [t('company_order.fields.price')]: companyOrder.price || '',
        [t('company_order.fields.tracking_id')]: companyOrder.tracking_id || '',
        [t('company_order.fields.type')]: companyOrder.type || '',
        [t('company_order.fields.shipper')]: companyOrder.shipper || '',
        [t('company_order.fields.vol_weight')]: companyOrder.vol_weight || '',
        [t('company_order.fields.clientName')]: companyOrder.shipping_address_id.name || '',
        [t('company_order.fields.clientSurname')]: companyOrder.shipping_address_id.surnames || '',
        [t('company_order.fields.clientEmail')]: companyOrder.shipping_address_id.email || '',
        [t('company_order.fields.clientAddress')]: companyOrder.shipping_address_id.full_address || '',
        [t('company_order.fields.clientDNI')]: companyOrder.shipping_address_id.nif || '',
        [t('company_order.fields.clientPhone')]: companyOrder.shipping_address_id.phone_number || '',
        [t('company_order.fields.invoice_id')]: companyOrder.invoice_id || '',
        [t('company_order.fields.vol_price')]: companyOrder.vol_price || '',
        [t('company_order.fields.delivery_note')]: companyOrder.delivery_note || '',
        [t('company_order.fields.tracking_url')]: companyOrder.tracking_url || '',
        [t('company_order.fields.location')]: companyOrder.location || '',
        [t('company_order.fields.deleted')]: companyOrder.deleted ? t('status.yes') : t('status.no'),
        [t('company_order.fields.createdAt')]: new Date(companyOrder.created_at) || '',
        [t('company_order.fields.updated_at')]: companyOrder.updated_at ? new Date(companyOrder.updated_at) : '',
        [t('company_order.fields.updated_by')]: companyOrder.updated_by || ''
      }
    })
  }

  async function exportCompanyOrders (filters?: Filters) {
    let query = supabaseClient
      .from('company_order')
      .select(select)
      .eq('deleted', false)

    if (filters?.dates) {
      query = query.gte('created_at', filters.dates[0]).lte('created_at', filters.dates[1])
    }

    const { data } = await query

    return dataToExport(data || [])
  }

  return (
    <>
    <List
        resource='company_order'
        title={
          <>
            {t('company_order.company_order')}
          </>
        }
        headerProps={{
          extra: <>
            <EditButton onClick={() => showChangeOrder()}>{t('actions.changeOrdersStatus')}</EditButton>
            <ApplyCostsButton refetch={getCompanyOrderList} />
            <CreateButton onClick={() => createShow()}>{t('company_order.addOrder')}</CreateButton>
            <ExportDrawerButton filename={t('company_order.company_order')} asyncFunction={exportCompanyOrders} />
            <Button onClick={() => { showUploadExcel() } } icon={<Icons.UploadOutlined />}>{t('company_order.upload')}</Button>
          </>
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            onChange (page, pageSize) {
              setCurrentPage(page)
              setPageSize(pageSize)
              localStorage.setItem('companyOrdersPage', page.toString() || '')
              localStorage.setItem('companyOrdersPageSize', pageSize.toString() || '')
            }
          }}
          onChange={(_pagination: any, _filters: any, sorter: any, _extra: any) => {
            if (sorter && sorter.order) {
              setSorter({ field: sorter.field, ascending: (sorter.order === 'ascend') })
            } else {
              setSorter({ field: 'id', ascending: false })
            }
          }}
          scroll={{ x: `${controlWidth}%`, y: '60vh' }}
          rowSelection={{
            type: 'checkbox',
            onChange: (
              _selectedRowKeys: React.Key[],
              selectedRows: definitions['company_order'][]
            ) => {
              setSelectedRows(selectedRows)
            },
            getCheckboxProps: (record: definitions['company_order']) => ({
              id: record.id.toString()
            })
          }}
          >
          <Table.Column
            key='id'
            dataIndex="id"
            align="center"
            title={t('company_order.fields.id')}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterId(Number(selectedKeys))
                  return selectedKeys
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'id')])
                  setFilterId(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterId) {
                    if (filters.find((x) => x.field === 'id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'id')
                      updatedFilters[index].value = filterId

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'id', operator: 'eq', value: filterId }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
            sorter
          />
          <Table.Column
            key='user_id'
            dataIndex={['user_id', 'name']}
            align="center"
            title={t('company_order.fields.brand')}
            sorter
            render={(value) => (
              <Tag>
                {capitalizeFirstLetter(value)}
              </Tag>
            )}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterName(selectedKeys.toString().trim() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'user_id.name')])
                  setFilterName(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterName) {
                    if (filters.find((x) => x.field === 'user_id.name')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'user_id.name')
                      updatedFilters[index].value = filterName

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'user_id.name', operator: 'eq', value: filterName }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="status_id"
            dataIndex="status_id"
            align="center"
            title={t('company_order.fields.status_id')}
            render={(value) => (
              <Tag color={getColorBusinessLabel(value)}>
                {t(`company_order.status.${BUSINESS_STATUS_MAPPER[value as BusinessStatusIdTypes]}`)}
              </Tag>
            )}
            sorter
            filterDropdown={(props) => (
              <FilterDropdown
                {...props}
                mapValue={(selectedKeys) => {
                  const ids = selectedKeys as number[]
                  setFilterStatus(ids.length ? ids : undefined)
                  return ids
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'status_id')])
                  setFilterStatus(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterStatus) {
                    if (filters.find((x) => x.field === 'status_id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'status_id')
                      updatedFilters[index].value = filterStatus

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'status_id', operator: 'in', value: filterStatus }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  mode="multiple"
                  options={Object.entries(BUSINESS_STATUS_MAPPER).map(([key, value]) => ({
                    value: parseInt(key),
                    label: t(`company_order.status.${value}`)
                  }))}
                />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='Location'
            dataIndex="location"
            align="center"
            title={t('company_order.fields.location')}
          />
          <Table.Column
            key='reference'
            dataIndex="reference"
            align="center"
            title={t('company_order.fields.reference')}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterReference(selectedKeys.toString().trim() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'reference')])
                  setFilterReference(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterReference) {
                    if (filters.find((x) => x.field === 'reference')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'reference')
                      updatedFilters[index].value = filterReference

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'reference', operator: 'eq', value: filterReference }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='invoice_number'
            dataIndex="invoice_number"
            align="center"
            title={t('company_order.fields.invoice_number')}
          />
          <Table.Column
            key='created_at'
            dataIndex="created_at"
            align="center"
            title={t('company_order.fields.createdAt')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
            filterDropdown={() => (
              <DateFilterDropdown
                field='created_at'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    let filtersToAdd = [...filters]

                    if (filters.find((x) => x.field === 'created_at' && x.operator === 'gte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x) => x.field === 'created_at' && x.operator === 'gte')
                      updatedFilters[index].value = newFilter[0].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                    }

                    if (filters.find((x) => x.field === 'created_at' && x.operator === 'lte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x) => x.field === 'created_at' && x.operator === 'lte')
                      updatedFilters[index].value = newFilter[1].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                    }

                    setFilters([...filtersToAdd])
                  } else {
                    setFilters([...filters.filter((x) => x.field !== 'created_at')])
                  }
                }}
              />
            )}
          />
          <Table.Column
            key="tracking_id"
            dataIndex="tracking_id"
            align="center"
            title={t('company_order.fields.tracking_id')}
          />
          <Table.Column
            key="delivery_note"
            dataIndex="delivery_note"
            align="center"
            title={t('company_order.fields.delivery_note')}
          />
          <Table.Column
            key="type"
            dataIndex="type"
            align="center"
            title={t('company_order.fields.type')}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterType(selectedKeys.toString().trim() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'type')])
                  setFilterType(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterType) {
                    if (filters.find((x) => x.field === 'type')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'type')
                      updatedFilters[index].value = filterType

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'type', operator: 'eq', value: filterType }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="shipper"
            dataIndex="shipper"
            align="center"
            title={t('company_order.fields.shipper')}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterShipper(selectedKeys.toString().trim() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x) => x.field !== 'shipper')])
                  setFilterShipper(undefined)
                  props.setSelectedKeys([])
                  props.confirm()
                }}
                confirm={() => {
                  if (filterShipper) {
                    if (filters.find((x) => x.field === 'shipper')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x) => x.field === 'shipper')
                      updatedFilters[index].value = filterShipper

                      setFilters([...updatedFilters])
                    } else {
                      const newFilter: CompanyOrderFilter = { field: 'shipper', operator: 'eq', value: filterShipper }
                      setFilters([...filters, ...[newFilter]])
                    }
                  }
                  props.confirm()
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="vol_weight"
            dataIndex="vol_weight"
            align="center"
            title={t('company_order.fields.vol_weight')}
          />
          <Table.Column
            key="vol_price"
            dataIndex="vol_price"
            align="center"
            title={t('company_order.fields.vol_price')}
          />
          <Table.Column<definitions['company_order']>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            width={180}
            render={(_, record) => {
              return (
                <div style={{ display: 'flex' }}>
                  <ShowButton
                    resourceNameOrRouteName='company_order'
                    hideText
                    style={{ color: 'green', fontSize: '15px' }}
                    recordItemId={record.id}
                  />
                  <Tooltip style={{ fontSize: '1px' }} title={!record.invoice_id ? t('notifications.errorTooltip') : ''}>
                    <Button disabled={!record.invoice_id} onClick={() => { downloadInvoice(record) }} style={{ fontSize: '15px', color: '#00B6FF', borderColor: '#00B6FF', marginLeft: '3px' }}>
                      <DownloadOutlined style={{ margin: '-10px' }}/>
                    </Button>
                  </Tooltip>
                  <Tooltip style={{ fontSize: '1px' }} title={t('shipments.actions.prepare')}>
                    <Button onClick={() => {
                      setOrderId(record.id || 0)
                      show()
                    }} style={{ fontSize: '15px', color: '#00B6FF', borderColor: '#00B6FF', marginLeft: '3px' }}>
                      <BarcodeOutlined style={{ margin: '-10px' }}/>
                    </Button>
                  </Tooltip>
                  <Tooltip style={{ fontSize: '1px' }} title={t('shipments.actions.showTicket')}>
                    <Button disabled={!record.ticket_name} onClick={() => { viewTicket(record, undefined, true) }} style={{ fontSize: '15px', color: '#00B6FF', borderColor: '#00B6FF', marginLeft: '3px' }}>
                      <AuditOutlined style={{ margin: '-10px' }}/>
                    </Button>
                  </Tooltip>
                </div>
              )
            }}
          />
        </Table>
        <CreateCompanyOrder
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          createClose={createClose}
        />
        <ModalChangeBusinessOrdersStatus
          getCompanyOrderList={getCompanyOrderList}
          modalProps={modalPropsChangeOrder}
          close={closeChangeOrder}
          selectedRows={selectedRows}
        />
        <ModalUploadExcel
          drawerProps={modalUploadExcel}
          close={uploadExcelClose}
          setNewExcelOrders={setNewExcelOrders}
        />
      </List>
      <ShipmentModal modalProps={modalProps} close={close} orderId={orderId} setOrderId={setOrderId}/>
    </>
  )
}
