import { ButtonProps, Col, Create, Drawer, DrawerProps, Form, FormProps, Grid, Input, Row, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { UserFinder } from 'components/company_order/userFinder'
import { useState } from 'react'
import { BUSINESS_STATUS_REQUESTED_ID, supabaseClient } from 'utility'

type CreateCategoryProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
  createClose: Function
};

export const CreateCompanyOrder: React.FC<CreateCategoryProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  createClose
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  const [reference, setReference] = useState<string>()
  const [clientAddress, setClientAddress] = useState<string>()
  const [clientNumber, setClientNumber] = useState<string>()
  const [clientName, setClientName] = useState<string>()
  const [clientSurnames, setClientSurnames] = useState<string>()
  const [clientCP, setClientCP] = useState<string>()
  const [clientPopulation, setClientPopulation] = useState<string>()
  const [clientDNI, setClientDNI] = useState<string>()
  const [clientPhone, setClientPhone] = useState<string>()
  const [clientEmail, setClientEmail] = useState<string>()
  const [clientUser, setClientUser] = useState<{ id: number, email: string, name: string } | undefined>(undefined)

  const createCompanyOrder = async () => {
    let addressId

    // const { data: userData } = await supabaseClient.from<any>('users')
    //   .select('id')
    //   .eq('email', clientEmail)
    //   .single()

    const { data: updateData } = await supabaseClient.from('addresses')
      .update({ user_id: clientUser?.id, name: clientName, surnames: clientSurnames, postal_code: clientCP, city: clientPopulation, nif: clientDNI, phone_number: clientPhone, email: clientEmail, street: clientAddress, number: clientNumber })
      .eq('user_id', clientUser?.id)

    if (updateData![0] !== undefined) {
      addressId = updateData![0].id
    }

    if (updateData![0] === undefined) {
      const { data } = await supabaseClient.from('addresses')
        .upsert({ user_id: clientUser?.id, name: clientName, surnames: clientSurnames, postal_code: clientCP, city: clientPopulation, nif: clientDNI, phone_number: clientPhone, email: clientEmail, street: clientAddress, number: clientNumber, island_id: 1 })
      addressId = data![0].id
    }

    const { error } = await supabaseClient.from('company_order')
      .upsert({
        reference: (String(reference).toLowerCase().startsWith('int_')) ? reference : `INT_${reference}`,
        status_id: BUSINESS_STATUS_REQUESTED_ID,
        shipping_address_id: addressId,
        user_id: clientUser?.id,
        updated_at: new Date(Date.now()).toISOString(),
        updated_by: supabaseClient.auth.user()?.email || null
      })

    if (error) {
      console.error('Error posting data:', error)
      return ''
    }
    createClose()
  }

  const updateUser = (user: { id: number, email: string, name: string }) => {
    setClientUser(user)
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <div style={{ margin: '1rem 3rem 0rem 3rem' }}>
        <UserFinder updateUser={updateUser} />
        <div>
          {clientUser?.name && <Typography.Text key={clientUser.email || 'noUser'}>
            <b>{t('company_order.fields.userSelected')}</b> {clientUser.name}
          </Typography.Text>}
        </div>
      </div>
      <Create
        resource="orders"
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        goBack={false}
        title={t('order.actions.addOrder')}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={createCompanyOrder}
        >
          <br />
          <Row gutter={[64, 0]} wrap>
            <Col xs={1} lg={8}>
              <Form.Item
                label={t('company_order.fields.reference')}
                name="Reference"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setReference(e.target.value) }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientName')}
                name="Name"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientName(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientSurname')}
                name="Surnames"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientSurnames(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientEmail')}
                name="email"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientEmail(e.target.value) }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={2} lg={8}>
              <Form.Item
                  label={t('company_order.fields.clientAddress')}
                  name="Address"
                  rules={[{ required: true }]}
                >
                  <Input onChange={(e) => { setClientAddress(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                  label={t('company_order.fields.clientNumber')}
                  name="Number"
                  rules={[{ required: true }]}
                >
                  <Input onChange={(e) => { setClientNumber(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientCP')}
                name="CP"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientCP(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientPopulation')}
                name="Population"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientPopulation(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientDNI')}
                name="DNI"
              >
                <Input onChange={(e) => { setClientDNI(e.target.value) }}/>
              </Form.Item>
            </Col>
            <Col xs={2} lg={8}>
              <Form.Item
                label={t('company_order.fields.clientPhone')}
                name="Phone"
                rules={[{ required: true }]}
              >
                <Input onChange={(e) => { setClientPhone(e.target.value) }}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
