import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { Tabs } from '@pankod/refine-antd'

import { ShipmentsShippedList } from './shippedList'
import { ShipmentsNotShippedList } from './notShippedList'

export const ShipmentsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  return (
    <Tabs defaultActiveKey='1' size='middle'>
      <Tabs.TabPane tab={t('shipments.notShippedOrders')} key='1'>
        <ShipmentsNotShippedList />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('shipments.shippedOrders')} key='2'>
        <ShipmentsShippedList />
      </Tabs.TabPane>
    </Tabs>
  )
}
