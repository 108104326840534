import {
  Drawer,
  DrawerProps,
  Edit,
  FormProps
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { DecreaseForm } from './form'

type DecreaseProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  close: () => void
  orderId?: number
}

export const DecreaseDrawer: React.FC<DecreaseProps> = ({
  drawerProps,
  formProps,
  close,
  orderId
}) => {
  const t = useTranslate()
  const { open } = useNotification()
  const Action = Edit
  const [decreaseTypes, setdecreaseTypes] = useState<Array<number>>()
  const [decreaseCosts, setdecreaseCosts] = useState<number>()

  const successNotification = () => open?.({
    type: 'success',
    message: t('notifications.successMsg')
  })

  const errorNotification = () => open?.({
    type: 'error',
    message: t('notifications.errorMsg')
  })

  useEffect(() => {
    supabaseClient
      .from('orders')
      .select('decrease_types, decrease_costs')
      .eq('id', orderId)
      .then(data => {
        if (data.data) {
          setdecreaseTypes(data.data[0].decrease_types)
          setdecreaseCosts(data.data[0].decrease_costs)
        } else {
          return null
        }
      })
  }, [])
  return (
    <Drawer
      {...drawerProps}
      width={'400px'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
    <Action
      saveButtonProps={{
        onClick: async () => {
          const { error } = await supabaseClient
            .from('orders')
            .update({ decrease_types: formProps.form?.getFieldValue('decrease_types_ids'), decrease_costs: formProps.form?.getFieldValue('decrease_costs') })
            .eq('id', orderId)

          if (error) {
            errorNotification()
            return null
          } else {
            successNotification()
            window.location.reload()
            return null
          }
        }
      }}
      title={t('decrease.decrease_button')}
      breadcrumb={false}
      goBack={false}
      headerProps={{ extra: null }}
    >
        <DecreaseForm
          formProps={formProps}
          decreaseTypes={decreaseTypes}
          decreaseCosts={decreaseCosts}
        />
    </Action>
    </Drawer>
  )
}
