import { Icons } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { EventSourceButton } from 'components/EventSourceButton/EventSourceButton'
import { useContext } from 'react'
import { InvoicesMatchingsContext } from 'utility/contexts'

export const SyncInvoicesButton: React.FC = () => {
  const t = useTranslate()
  const invalidateAll = useContext(InvoicesMatchingsContext)

  return (
    <EventSourceButton
      endpoint="/sync_invoices"
      buttonText={t('invoices_matchings.buttons.sync_invoices')}
      headerText={t('invoices_matchings.syncing_invoices')}
      completedText={t('invoices_matchings.sync_completed')}
      errorDescText={t('invoices_matchings.sync_invoices_error')}
      refetchFn={invalidateAll}
      icon={<Icons.SyncOutlined />}
    />
  )
}
