import { Typography, Table, Card, DateField } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { BusinessStatusIdTypes, isBusinessStatusId } from 'types/types'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, supabaseClient } from 'utility'
import { BUSINESS_STATUS_MAPPER } from 'utility/mapper'

interface AuditCompanyRegistry {
  id: number
  // eslint-disable-next-line camelcase
  created_at: string
  operation: string
  record: any
  // eslint-disable-next-line camelcase
  resource_id: number
}

type AuditCompanyOrderProps = {
  orderId: number
}
type RecordChanges = { 'old_value': any, 'new_value': any }
type ModifiedRecord = { [key: string]: RecordChanges }
type AuditCompanyRegistryWithMod = AuditCompanyRegistry & { modifiedRecord: ModifiedRecord }
type Record = { [key: string]: any }

const EMPTY_TEXT = '---'

export const AuditCompanyOrder: React.FC<AuditCompanyOrderProps> = (props) => {
  const t = useTranslate()
  const [dataSource, setDataSource] = useState<AuditCompanyRegistryWithMod[]>([])

  const retrieveAuditData = async () => {
    const { data } = await supabaseClient.from('audit_company_order').select('*').eq('resource_id', props.orderId).order('id', { ascending: false })

    const processedData = processChanges(data || [])
    setDataSource(processedData)
  }

  function processChanges (data: AuditCompanyRegistry[]) {
    const results: AuditCompanyRegistryWithMod[] = []

    data.forEach((item, index) => {
      if (item.operation === 'UPDATE') {
        const previousRecord = data[index + 1]
        if (previousRecord) {
          const diff = findDifferences(previousRecord.record as Record, item.record as Record)
          if (Object.keys(diff).length > 0) {
            results.push({
              ...item,
              modifiedRecord: diff
            })
          }
        }
      }
    })

    return results
  }

  function findDifferences (prev: Record, curr: Record) {
    const diff: ModifiedRecord = {}
    Object.keys(curr).forEach(key => {
      if (prev[key] !== curr[key]) {
        diff[key] = {
          old_value: prev[key] === undefined || prev[key] === null ? EMPTY_TEXT : prev[key],
          new_value: curr[key]
        }
      }
    })
    return diff
  }

  useEffect(() => {
    retrieveAuditData()
  }, [])

  function parseValue (column: string, change: any, color?: string) {
    if (!change || change === EMPTY_TEXT) return <b>{EMPTY_TEXT}</b>
    else if (column === 'status_id' && !isBusinessStatusId(change)) return <b>{EMPTY_TEXT}</b>
    else if (column === 'status_id') return t(`company_order.status.${BUSINESS_STATUS_MAPPER[change as BusinessStatusIdTypes]}`)
    else if (column === 'updated_at' && dayjs(change).isValid()) return <DateField format={['delivery_deadline', 'issue_deadline'].includes(column) ? DEFAULT_DATE_FORMAT : DEFAULT_DATE_TIME_FORMAT} value={change} style={{ color }} />
    else return change
  }

  function renderHistoryChange (column: string, changes: RecordChanges) {
    if (!changes) return ''
    return (
      <>
        <span style={{ color: 'red', wordBreak: 'break-word' }}>{parseValue(column, changes.old_value, 'red')}</span>
        <b>{' | '}</b>
        <span style={{ color: 'green', wordBreak: 'break-word' }}>{parseValue(column, changes.new_value, 'green')}</span>
      </>
    )
  }

  return (
    <>
      <Card style={{ padding: '1%', width: '100%' }} >
        <Typography.Title level={4} >{t('company_order.history_title')}</Typography.Title>
        <Table
          dataSource={dataSource}
          rowKey={(row) => `audit_company_order-${row.id}`}
        >
          <Table.Column
            key="modifiedRecord"
            dataIndex="modifiedRecord"
            title={t('company_order.record')}
            render={(modifiedRecord, record: AuditCompanyRegistryWithMod, index) => {
              return <>
              {Object.entries(modifiedRecord).map(([key, changes]) => {
                if (['deleted', 'updated_by'].includes(key)) return null
                return <div key={`${index}-${key}`}>
                  <b>{t(`company_order.fields.${key}`)}</b>
                  {': '}
                  {renderHistoryChange(key, changes as RecordChanges)}
                </div>
              })}
            </>
            }}
          />
          <Table.Column
            key="created_at"
            dataIndex="created_at"
            title={t('company_order.created_at')}
            render={(createdAt) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={createdAt} /> }
          />
          <Table.Column
            key="updated_by"
            dataIndex="updated_by"
            title={t('company_order.updated_by')}
            align="center"
            render={(_, record: AuditCompanyRegistry) => {
              return record.record.updated_by ? record.record.updated_by : record.record.deleted_by
            }}
            className='content-start'
          />
        </Table>
      </Card>
    </>
  )
}
