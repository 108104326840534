import { Button, Form, Space, Icons, DatePicker, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'

type DateFilterDropdownProps = {
  field: string
  filters: any
  setFilters: Function
  label?: string
}

export const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({ field, filters, setFilters, label }) => {
  const [dateRange, setDateRange] = useState<[string, string]>()

  const { RangePicker } = DatePicker

  const t = useTranslate()

  const handleSubmit = () => {
    const newFilter = [
      {
        field: field,
        operator: 'gte',
        value: dateRange ? `${dateRange[0]} 00:00:00` : ''
      },
      {
        field: field,
        operator: 'lte',
        value: dateRange ? `${dateRange[1]} 23:59:59` : ''
      }
    ]

    setFilters(newFilter, 'merge')
  }

  const handleClear = () => {
    setFilters((prev: any) => prev.filter((filter: any) => filter.field !== field))
  }

  return (
    <Space direction='vertical' style={{ padding: '14px' }}>
      <Typography.Text>{label}</Typography.Text>
      <Space>
        <Form.Item name={field}>
          <RangePicker
            placeholder={[t('buttons.startDate'), t('buttons.endDate')]}
            onChange={(_, dateString) => setDateRange(dateString)}
          />
        </Form.Item>
      </Space>
      <Space align='end'>
        <Button
          icon={<Icons.FilterOutlined />}
          type='primary'
          size='small'
          onClick={() => handleSubmit()}
        >
          {t('buttons.filter')}
        </Button>
        <Button
          type='primary'
          size='small'
          danger
          onClick={() => handleClear()}
        >
          {t('buttons.clear')}
        </Button>
      </Space>
    </Space>
  )
}
