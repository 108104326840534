import { SupersetDashboard } from './dashboard'

export const Reports: React.FC = () => {
  return <SupersetDashboard
    dashboardId={process.env.REACT_APP_SUPERSET_DASHBOARD_ID}
    url={process.env.REACT_APP_SUPERSET_URL}
    granterUrl={process.env.REACT_APP_SUPERSET_GRANTER_URL}
    granterToken={process.env.REACT_APP_SUPERSET_GRANTER_TOKEN}
  />
}
