import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import {
  List,
  Table,
  Form,
  Space,
  Button,
  SaveButton,
  EditButton,
  Input,
  useEditableTable,
  FilterDropdownProps,
  useModal,
  InputNumber
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { currency } from 'utility/currency'
import { useState } from 'react'
import { DeleteModal } from 'components/DeleteModal/modal'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'

export const IgicTypeList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    filters,
    setFilters,
    tableQueryResult
  } = useEditableTable<definitions['igic_types']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const t = useTranslate()
  const { modalProps, show, close } = useModal()

  const [id, setId] = useState(0)
  return (
    <List title={t('igic_types.titles.list')}>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column dataIndex="id" title={t('igic_types.fields.id')} sorter/>
          <Table.Column
            dataIndex="name"
            title={t('igic_types.fields.name')}
            render={(value, data: definitions['igic_types']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="name"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return value
            }}
            sorter
            key="id"
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='igic_types'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column
            key='value'
            dataIndex="value"
            title={t('igic_types.fields.value')}
            render={(value, data: definitions['igic_types']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="value"
                    style={{ margin: 0 }}
                  >
                    <InputNumber min={0} decimalSeparator=',' />
                  </Form.Item>
                )
              }
              return currency(value, '%')
            }}
            sorter
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='value'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column<definitions['igic_types']>
            title={t('table.actions')}
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                <Space>
                  <EditButton
                    {...editButtonProps(record.id)}
                    hideText
                    size="small"
                  />

                <ButtonLogicDelete click={() => {
                  show()
                  setId(record.id)
                }} />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  entity='igic_types'
                  tableQueryResult={tableQueryResult}
                />
                </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
