import { Icons } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

type Props = {
  label: string
  onClick?: () => void
}

export const PreviewMask: React.FC<Props> = ({ label, onClick }) => {
  const t = useTranslate()
  return (
    <span role="button" onClick={onClick} aria-label="eye" className="anticon">
      <div className="ant-image-mask">
        <div className="ant-image-mask-info">
          <Icons.EyeOutlined />
          {t(label)}
        </div>
      </div>
    </span>
  )
}
