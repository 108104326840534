import { useTranslate, useSelect } from '@pankod/refine-core'
import { Alert, Form, Modal, ModalProps, Select } from '@pankod/refine-antd'
import { ADMITTED_STATUS } from 'utility/constants'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'
import { useState } from 'react'

type ModalChangeOrdersStatusProps = {
  modalProps: ModalProps,
  close: any,
  tableQuery: any
  selectedRows: definitions['shipments'][]
}

export const ModalChangeOrdersStatus: React.FC<ModalChangeOrdersStatusProps> = ({ modalProps, close, tableQuery, selectedRows }) => {
  const t = useTranslate()
  const [statusId, setStatusId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showUpdateError, setShowUpdateError] = useState<boolean>(false)

  const { options } = useSelect({
    resource: 'status',
    fetchSize: 20,
    optionValue: 'id',
    optionLabel: 'name',
    filters: [{
      field: 'id',
      operator: 'gte',
      value: ADMITTED_STATUS
    }],
    sort: [{
      field: 'id',
      order: 'asc'
    }]
  })

  const updateOrdersStatus = async () => {
    setLoading(true)
    setShowUpdateError(false)
    const data = selectedRows?.map((row: any) => { return { orderId: row.order_id, prevStatusId: row.order?.prev_status_id } })

    const promiseArray = []

    const userEmail = supabaseClient.auth.user()?.email

    for (const o of data) {
      promiseArray.push(supabaseClient.from('orders')
        .update({
          status_id: statusId,
          prev_status_id: o.prevStatusId,
          updated_by: userEmail
        })
        .eq('id', o.orderId)
      )
    }

    const results = await Promise.all(promiseArray)

    let querySucess: any[] = []
    results.forEach((r) => {
      if (r.error) {
        setShowUpdateError(true)
      } else if (r.data) {
        querySucess = [...querySucess, ...r.data]
      }
    })

    setLoading(false)

    tableQuery.refetch()
    close()
  }

  return (
    <Modal
      {...modalProps}
      title={t('actions.changeOrdersStatus')}
      centered
      onOk={updateOrdersStatus}
      onCancel={() => {
        close()
        tableQuery.refetch()
      }}
      confirmLoading={loading}
    >
      {`${t('menu.orders')}: ${selectedRows?.map((row) => row.order_id).join(', ')}`}
      <Form layout='vertical'>
        <Form.Item
          label={t('order.fields.status')}
        >
          <Select options={options} onChange={(value) => setStatusId(value)} />
        </Form.Item>
        {showUpdateError && <Alert message={t('shipments.errors.errorUpdating')} type="warning" />}
      </Form>
    </Modal>
  )
}
