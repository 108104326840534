import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  useDrawerForm,
  CreateButton,
  useModal,
  RefreshButton,
  Tag,
  Button
} from '@pankod/refine-antd'
import { CrudFilters, GetOneResponse, HttpError, useTranslate } from '@pankod/refine-core'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { SupplementActions } from 'components/supplement/actions'
import { definitions, Order } from 'interfaces'
import { useState, useEffect } from 'react'
import { getColorLabelEstimatedCost } from 'utility/colorLabel'
import { currency } from 'utility/currency'
import { QueryObserverResult } from '@tanstack/react-query'
import { ADVERTISEMENT_STATUS_ID, API_URL, MapEstimatedCostsStatusIDToText, supabaseClient } from 'utility'

type EstimatedCostsProps = {
  order: definitions['Order'] | undefined;
  orderQuery: QueryObserverResult<GetOneResponse<Order>, unknown>
  setSupQuery?: any
};

export const EstimatedCosts: React.FC<EstimatedCostsProps> = ({ order, orderQuery, setSupQuery }) => {
  const { modalProps, show, close } = useModal()
  const t = useTranslate()

  const [id, setId] = useState(0)
  const [loading, setLoading] = useState(false)

  const [adviseSent, setAdviseSent] = useState(false)

  const permFilters = [
    {
      field: 'order_id',
      operator: 'eq',
      value: order?.id
    },
    {
      field: 'deleted',
      operator: 'eq',
      value: false
    }
  ] as CrudFilters

  const { tableProps, tableQueryResult } = useTable<definitions['estimated_costs'], HttpError>({
    resource: 'estimated_costs',
    initialSorter: [
      {
        field: 'id',
        order: 'desc'
      }
    ],
    permanentFilter: permFilters,
    initialPageSize: 2,
    queryOptions: {
      enabled: order !== undefined
    },
    syncWithLocation: false,
    metaData: {
      select: '*'
    }
  })

  useEffect(() => {
    if (tableQueryResult.status === 'success') {
      setSupQuery(tableQueryResult)
    }
  }, [tableQueryResult])

  const handleRefresh = () => {
    tableQueryResult.refetch()
  }

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['estimated_costs']>({
    action: 'create',
    resource: 'estimated_costs',
    redirect: false
  })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['estimated_costs']>({
    action: 'edit',
    resource: 'estimated_costs',
    redirect: false
  })

  createFormProps.initialValues = {
    order_id: order?.id
  }

  editFormProps.initialValues = {
    ...editFormProps.initialValues,
    order_id: order?.id
  }

  const sendAdvise = async () => {
    setLoading(true)
    // Prepare request
    const options: RequestInit = {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        type: 'INSERT',
        table: 'estimated_costs',
        record: `advise_${order?.id}`,
        fromButton: true
      })
    }

    // Request to send email
    const response = await fetch(`${API_URL}/email`, options)

    if (response.ok) {
      await supabaseClient.from('orders')
        .update({
          estimated_costs_status: ADVERTISEMENT_STATUS_ID,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', order?.id)

      setAdviseSent(true)
    }
    setLoading(false)
  }

  const removeAdvise = async () => {
    setLoading(true)

    const { error } = await supabaseClient.from('orders')
      .update({
        estimated_costs_status: null
      })
      .eq('id', order?.id)

    if (error) {
      console.error('Error updating estimated_costs_status', error)
    }
    setAdviseSent(false)
    setLoading(false)
  }

  return (
    <>
      <List
        title={t('estimated_costs.estimated_costs')}
        breadcrumb={false}
        pageHeaderProps={{
          extra: (
            <>
              {((tableProps.dataSource && tableProps.dataSource.length === 0) && !(order!.estimated_costs_status === null))
                ? <Button loading={loading} onClick={() => removeAdvise()}>
                  {t('estimated_costs.removeAdvise')}
                </Button>
                : null
              }
              <Button disabled={!(order!.estimated_costs_status === null) || adviseSent} loading={loading} onClick={() => sendAdvise()}>
                {t('estimated_costs.sentAdvise')}
              </Button>
              <CreateButton disabled={tableProps.dataSource && tableProps.dataSource.length > 0} onClick={() => createShow()}>
                {t('estimated_costs.addCost')}
              </CreateButton>
              <RefreshButton onClick={handleRefresh} />
            </>
          )
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            key="weigth"
            dataIndex="weigth"
            title={t('estimated_costs.fields.weigth')}
            align='center'
            sorter
          />
          <Table.Column
            key="length"
            dataIndex="length"
            title={t('estimated_costs.fields.length')}
            align='center'
            sorter
          />
          <Table.Column
            key="heigth"
            dataIndex="heigth"
            title={t('estimated_costs.fields.heigth')}
            align='center'
            sorter
          />
          <Table.Column
            key="depth"
            dataIndex="depth"
            title={t('estimated_costs.fields.depth')}
            align='center'
            sorter
          />
          <Table.Column
            key="volume"
            dataIndex="volume"
            title={t('estimated_costs.fields.volume')}
            align='center'
            sorter
          />
          <Table.Column
            key="billable_volume"
            dataIndex="billable_volume"
            title={t('estimated_costs.fields.billableVolume')}
            align='center'
            sorter
          />
          <Table.Column
            key="weigthExcess"
            dataIndex="weigth_excess"
            title={t('estimated_costs.fields.weigthExcess')}
            align='center'
            sorter
          />
          <Table.Column
            key="supplement"
            dataIndex="supplement"
            title={t('estimated_costs.fields.supplement')}
            render={(value) => currency(value)}
            align='center'
            sorter
          />
          <Table.Column
            key="status_id"
            dataIndex="status_id"
            align="center"
            title={t('estimated_costs.fields.status')}
            render={(value) => {
              return <Tag color={getColorLabelEstimatedCost(value)}>
                {MapEstimatedCostsStatusIDToText(value)}
              </Tag>
            }}
            sorter
          />
          <Table.Column<definitions['estimated_costs']>
            title={t('table.actions')}
            dataIndex="actions"
            align='center'
            render={(_, record: any) => (
              <Space>
                <EditButton onClick={() => editShow(record.id)} hideText />
                <ButtonLogicDelete click={() => {
                  show()
                  setId(record.id)
                }} />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  orderId={record.order_id}
                  entity='estimated_costs'
                  tableQueryResult={tableQueryResult}
                />
              </Space>
            )}
          />
        </Table>
        {order && <>
          <SupplementActions
            drawerProps={createDrawerProps}
            formProps={createFormProps}
            closeDrawer={createClose}
            tableQueryResult={tableQueryResult}
            orderId={order.id}
            shippingMethod={order.shipping_method}
            island={order.shipping_address.island}
            orderQuery={orderQuery}
            packageNumber={order.package_number}
            order={order}
            isEstimatedCost
          />
          <SupplementActions
            edit={true}
            drawerProps={editDrawerProps}
            formProps={editFormProps}
            closeDrawer={editClose}
            tableQueryResult={tableQueryResult}
            orderId={order.id}
            shippingMethod={order.shipping_method}
            island={order.shipping_address.island}
            orderQuery={orderQuery}
            packageNumber={order.package_number}
            order={order}
            isEstimatedCost
          />
        </>}
      </List>
    </>
  )
}
