import { useTranslate, useSelect } from '@pankod/refine-core'

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Radio,
  FormProps,
  InputNumber,
  Upload,
  RcFile,
  Divider
} from '@pankod/refine-antd'
import { supabaseClient } from 'utility/supabaseClient'
import { normalizeFile } from 'utility/normalize'
import { definitions } from 'interfaces'
import { CategoriesRelationsForm } from './categoriesRelationsForm'
import { MarketplaceRelationsForm } from './marketplaceRelationsForm'
import { ExtraInfoMarkeplaces } from 'components/ExtraInfoMarkeplaces/ExtraInfoMarkeplaces'
import { InternalShopForm } from './internalShopForm'
import { useState } from 'react'

type MarketplaceFormProps = {
    formProps: FormProps<{}>
    edit?: boolean
};

export const MarketplaceForm: React.FC<MarketplaceFormProps> = ({ formProps, edit = false }) => {
  const t = useTranslate()

  const [internalShopId, setInternalShopId] = useState<number | undefined>(undefined)

  const igicTypes = useSelect<definitions['igic_types']>({
    resource: 'igic_types',
    optionLabel: 'name',
    optionValue: 'id'
  })

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...formProps.initialValues,
        updated_by: supabaseClient.auth.user()?.email
      }}
    >
      <Row gutter={[64, 0]} wrap>
        <Col xs={24} lg={8}>
          {edit && (
            <Form.Item
              name='updated_by'
              hidden={true}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label={t('marketplace.fields.name')}
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={(edit && formProps?.initialValues?.is_custom_shop)} />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.description')}
            name="description"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.status')}
            name="status"
          >
            <Radio.Group>
              <Radio value={true}>{t('status.enable')}</Radio>
              <Radio value={false}>{t('status.disable')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('marketplace.fields.isInHome')}
                name="is_in_home"
              >
                <Radio.Group>
                  <Radio value={true}>{t('status.yes')}</Radio>
                  <Radio value={false}>{t('status.no')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('marketplace.fields.isInApp')}
                  name="is_in_app"
                >
                  <Radio.Group>
                    <Radio value={true}>{t('status.yes')}</Radio>
                    <Radio value={false}>{t('status.no')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
          </Row>
          <Form.Item
            label={t('marketplace.fields.ivaPercent')}
            name="iva_percent"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.igicType')}
            name="igic_type_id"
            rules={[{ required: true }]}
          >
            <Select options={igicTypes.options} />
          </Form.Item>
          <Form.Item
            label={t('cmsCategories.fields.weigth')}
            name="weight"
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={t('marketplace.fields.freeShipmentPrice') + ' (€)'}
            name="free_shipment_price"
            rules={[{ required: true }]}
          >
            <InputNumber decimalSeparator=',' />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.shipmentPrice') + ' (€)'}
            name="shipment_price"
            rules={[{ required: true }]}
          >
            <InputNumber decimalSeparator=',' />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.returnDays')}
            name="return_days"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item label="Images">
            <Form.Item
              name="logo"
              valuePropName="fileList"
              normalize={normalizeFile}
              noStyle
            >
              <Upload.Dragger
                name="logo"
                listType="picture"
                customRequest={async ({
                  file,
                  onError,
                  onSuccess
                }) => {
                  const rcFile = file as RcFile
                  const fileUrl = `${rcFile.name}`

                  const { error } = await supabaseClient.storage
                    .from('logos')
                    .upload(fileUrl, file, {
                      cacheControl: '3600',
                      upsert: true
                    })

                  if (error) {
                    return onError?.(error)
                  }
                  const { data, error: urlError } = await supabaseClient.storage
                    .from('logos')
                    .getPublicUrl(fileUrl)

                  if (urlError) {
                    return onError?.(urlError)
                  }

                  onSuccess?.({ url: data?.publicURL }, new XMLHttpRequest())
                }}
              >
                <p className="ant-upload-text">
                  {t('actions.dragFile')}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <CategoriesRelationsForm
            key={formProps?.initialValues?.marketplace_category_relations?.map((r:any) => r.id)?.join('-') + Date.now()}
            marketplaceId={formProps?.initialValues?.id}
            relations={formProps?.initialValues?.marketplace_category_relations}
          />
          <MarketplaceRelationsForm
            key={formProps?.initialValues?.id}
            marketplaceId={formProps?.initialValues?.id}
          />
          {(formProps?.initialValues?.is_custom_shop) && <InternalShopForm
            key={`internalShop${formProps?.initialValues?.id}`}
            marketplaceId={formProps?.initialValues?.id}
            parentSetInternalShopId={setInternalShopId}
          />}
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={t('marketplace.fields.originalUrl')}
            name="original_url"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.CIF')}
            name="cif"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.legalName')}
            name="legal_name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.legalAddress')}
            name="legal_address"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.province')}
            name="province"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('marketplace.fields.postalCode')}
            name="postal_code"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {edit && (<><Divider style={{ marginTop: '0px' }} /><Row>
        <Col key={`colextraInfo-${internalShopId}`} style={{ width: '100%' }}>
          <Form.Item label={t('marketplace.fields.extraInput')} name="extraInfo">
            <ExtraInfoMarkeplaces
              extraInfo={(formProps && formProps.initialValues && formProps.initialValues.extra_info) ? formProps.initialValues.extra_info : null}
              id={(formProps && formProps.initialValues && formProps.initialValues.id) ? formProps.initialValues.id : null}
              showInternalShopId={internalShopId}
            />
          </Form.Item>
        </Col>
      </Row></>)}
    </Form>
  )
}
