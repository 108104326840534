import { useTranslate } from '@pankod/refine-core'
import { Timeline, Modal, ModalProps, Switch, Typography } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { formatDateTime, supabaseClient } from 'utility'

type HistoryModalProps = {
    modalProps: ModalProps,
    close: () => void,
    orderId: number
}

export const HistoryModal: React.FC<HistoryModalProps> = ({ modalProps, close, orderId }) => {
  const t = useTranslate()
  const [onlyStates, setOnlyStates] = useState(true)
  const [items, setItems] = useState([{}])
  const [allItems, setAllItems] = useState([{ table: null }])

  const handleOk = () => {
    close()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const auditArrayPromises = []

    auditArrayPromises.push(supabaseClient.from('audit_orders').select('created_at, operation, record, resource_id').order('created_at', { ascending: true }).eq('resource_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_items').select('created_at, operation, record, resource_id, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_market_orders').select('created_at, operation, resource_id, record, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_supplements').select('created_at, operation, resource_id, record, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))
    auditArrayPromises.push(supabaseClient.from('audit_refunds').select('created_at, operation, record, resource_id, order_id').order('created_at', { ascending: true }).eq('order_id', orderId))

    const data = await Promise.all(auditArrayPromises)

    let arrayOfChanges: any[] = []

    if (data && data.length > 0) {
      const orders = (data[0] && data[0].data) ? data[0].data : []
      let items = (data[1] && data[1].data) ? data[1].data : []
      let marketOrders = (data[2] && data[2].data) ? data[2].data : []
      let supplements = (data[3] && data[3].data) ? data[3].data : []
      let refunds = (data[4] && data[4].data) ? data[4].data : []

      const statusChanges: any[] = []
      orders?.forEach((o, index) => {
        if (o.operation === 'INSERT') statusChanges.push({ ...o, table: 'orders' })

        if (index > 1 && o.record.status_id !== orders[index - 1].record.status_id) {
          const record = Object.assign({}, o, { table: 'orders' })
          record.old_record = orders[index - 1].record
          statusChanges.push(record)
        }
      })

      items = items?.map((i) => Object.assign({}, i, { table: 'items' }))
      marketOrders = marketOrders?.map((mo) => Object.assign({}, mo, { table: 'market_orders' }))
      supplements = supplements?.map((s) => Object.assign({}, s, { table: 'supplements' }))

      supplements?.forEach((s, index) => {
        if (index > 1 && s.record.deleted !== supplements[index - 1].record.deleted) {
          const record = Object.assign({}, s, { table: 'supplements', type: 'remove' })
          statusChanges.push(record)
        }
      })

      refunds = refunds?.map((r) => Object.assign({}, r, { table: 'refunds' }))

      refunds?.forEach((r, index) => {
        if (index > 1 && r.record.deleted !== refunds[index - 1].record.deleted) {
          const record = Object.assign({}, r, { table: 'refunds', type: 'remove' })
          statusChanges.push(record)
        }
      })

      // Merge and order by created_at
      arrayOfChanges = arrayOfChanges.concat(statusChanges, items, marketOrders, supplements, refunds)

      arrayOfChanges.sort((prev, next) => Date.parse(prev.created_at) - Date.parse(next.created_at))
      setAllItems(arrayOfChanges)
      if (onlyStates) {
        const onlyStateArray = arrayOfChanges.filter(x => x.table === 'orders')
        setItems(onlyStateArray)
      } else {
        setItems(arrayOfChanges)
      }
    }
  }

  const printUser = (value: any) => {
    if (value?.record?.updated_by !== null && value?.record?.updated_by !== undefined) {
      return value.record.updated_by
    }
    return t('order.modalHistory.system')
  }

  const printRow = (value: any) => {
    if (value.table === 'orders') {
      return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {t('order.modalHistory.updateStatusFrom')} <b>{t(`order.status.${value.old_record?.status_id}`)}</b> {t('order.modalHistory.updateStatusTo')} <b>{t(`order.status.${value.record?.status_id}`)}</b></>
    } else if (value.table === 'items') {
      return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {(value.operation === 'INSERT') ? t('order.modalHistory.insertMarketOrder') : t('order.modalHistory.updateMarketOrder')} <b>{value.resource_id}</b></>
    } else if (value.table === 'market_orders') {
      return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {(value.operation === 'INSERT') ? t('order.modalHistory.insertMarketOrder') : t('order.modalHistory.updateMarketOrder')} <b>{value.record.purchase_id}</b></>
    } else if (value.table === 'supplements') {
      if (value.type === 'remove') {
        return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {t('order.modalHistory.removeSupplement')} {(value.record.volumetric) ? `${t('order.modalHistory.volumetric')} ${t('order.modalHistory.withValues')} ${value.record.supplement}` : `${value.record.description} ${t('order.modalHistory.withValues')} ${value.record.supplement}`}</>
      } else {
        return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {(value.operation === 'INSERT') ? t('order.modalHistory.insertSupplement') : t('order.modalHistory.updateSupplement')} {(value.record.volumetric) ? `${t('order.modalHistory.volumetric')} ${t('order.modalHistory.withValues')} ${value.record.supplement}` : `${t(`supplement.descriptions.${value.record.description}`)} ${t('order.modalHistory.withValues')} ${value.record.supplement}`}</>
      }
    } else if (value.table === 'refunds') {
      if (value.type === 'remove') {
        return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {t('order.modalHistory.removeRefund')} {value.record.description} {t('order.modalHistory.withValues')} {value.record.amount}</>
      } else {
        return <>{formatDateTime(value.created_at)} (<b>{printUser(value)}</b>) - {(value.operation === 'INSERT') ? t('order.modalHistory.insertRefund') : t('order.modalHistory.updateRefund')} {value.record.description} {t('order.modalHistory.withValues')} {value.record.amount}</>
      }
    }
  }

  const onChange = (checked: boolean) => {
    if (checked) {
      const onlyStateArray = allItems.filter(x => x.table === 'orders')
      setItems(onlyStateArray)
      setOnlyStates(true)
    } else {
      setItems(allItems)
      setOnlyStates(false)
    }
  }

  return (
    <Modal
        {...modalProps}
        title={t('order.actions.showHistory')}
        centered
        onOk={handleOk}
        onCancel={() => close()}
        footer={[]}
        width="60%"
    >
      <div>
        <Typography.Text>
          {t('order.modalHistory.onlyStates')}
          <Switch style={{ marginLeft: '10px' }} defaultChecked checked={onlyStates} onChange={onChange} />
        </Typography.Text>
      </div>
      <Timeline mode='left' style={{ marginTop: '5%', display: 'grid', justifyContent: 'center' }}>
        {
        (items.length > 0)
          ? items.map((i: any, index: any) => {
            return <Timeline.Item key={index}>{printRow(i)}</Timeline.Item>
          })
          : null}
      </Timeline>
    </Modal>
  )
}
