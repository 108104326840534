import { DateField, List, Space, Table, useForm, useModal, useTable } from '@pankod/refine-antd'
import { CrudFilters, IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { FileWithLink } from 'components/File/FileWithLink'
import { definitions } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT } from 'utility'
import { IssuesFilesUploadForm } from './uploadForm'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useContext, useState } from 'react'
import { IssuesContext } from 'pages/issues/show'

type IssuesFilesListProps = IResourceComponentsProps & { issue: definitions['issues'] }

export const IssuesFilesList: React.FC<IssuesFilesListProps> = ({ issue }) => {
  const { setRefetch } = useContext(IssuesContext)
  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const issueFiles: CrudFilters = issue.id
    ? [
        {
          field: 'issue_id',
          operator: 'eq',
          value: issue.id
        },
        {
          field: 'deleted',
          operator: 'eq',
          value: false
        }
      ]
    : []

  const { tableProps, tableQueryResult } = useTable<definitions['issues_files']>({
    resource: 'issues_files',
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [...issueFiles]
  })

  const { formProps } = useForm<definitions['issues_files']>({
    action: 'create',
    resource: 'issues_files',
    redirect: false
  })

  return (
    <List
      title={t('issues_files.titles.list')}
      breadcrumb={false}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="public_url"
          dataIndex="public_url"
          title={t('item.fields.image')}
          align="center"
          render={(value: string) => <FileWithLink href={value} />}
        />
        <Table.Column
          key="file_name"
          dataIndex="file_name"
          title={t('issues_files.fields.file_name')}
          sorter
        />
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title={t('issues_files.fields.created_at')}
          render={(value) => value && <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
          sorter
        />
        <Table.Column<definitions['issues_files']>
          fixed="right"
          title={t('table.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record) => (
            <Space>
              <ButtonLogicDelete
                click={() => {
                  show()
                  setId(record.id)
                }}
              />
              <DeleteModal
                modalProps={modalProps}
                close={close}
                id={id}
                entity="issues_files"
                tableQueryResult={tableQueryResult}
                setRefetch={setRefetch}
              />
            </Space>
          )}
        />
      </Table>

      <IssuesFilesUploadForm
        formProps={formProps}
        refetch={tableQueryResult.refetch}
        issue={issue}
      />
    </List>
  )
}
