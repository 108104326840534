import { Button, Icons, Modal, useModal } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { UnassignedInvoicesTable } from './UnassignedInvoicesTable'
import { PendingMarketOrders } from 'interfaces'
import { OrderInfo } from './OrderInfo'

type Props = {
  record: PendingMarketOrders
  invoicesMatchingsCount: number
}

export const InvoicesMatchingsModal: React.FC<Props> = ({ record, invoicesMatchingsCount }) => {
  const t = useTranslate()
  const { modalProps, show, close } = useModal()

  const { market_order_id: marketOrderId } = record

  return <>
    <Button
      onClick={show}
      icon={<Icons.FileSyncOutlined />}
      disabled={invoicesMatchingsCount === 0}
    />

    <Modal
      {...modalProps}
      title={t('invoices_matchings.fields.matches_count')}
      centered
      footer={null}
      style={{ minWidth: '96svw' }}
    >
      <OrderInfo record={record} />
      <UnassignedInvoicesTable marketOrderId={marketOrderId} closeFn={invoicesMatchingsCount === 1 ? close : undefined} />
    </Modal>
  </>
}
