import {
  useGetLocale,
  useSetLocale,
  useGetIdentity
} from '@pankod/refine-core'

import {
  AntdLayout,
  Menu,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Space,
  Grid,
  Row,
  Col,
  Tag
} from '@pankod/refine-antd'

import { useTranslation } from 'react-i18next'

import './style.less'
import { useEffect, useState } from 'react'
import { getCountPriorityOrders, getCountRequestedOrders } from 'utility'

const { DownOutlined } = Icons
const { Text } = Typography
const { useBreakpoint } = Grid

export const Header: React.FC = () => {
  const { i18n } = useTranslation()
  const locale = useGetLocale()
  const changeLanguage = useSetLocale()
  const { data: user } = useGetIdentity()
  const screens = useBreakpoint()

  const currentLocale = locale()

  const [requestedOrders, setRequestedOrders] = useState(0)
  const [priorityOrders, setPriorityOrders] = useState(0)

  useEffect(() => {
    function updateRequestedOrders () {
      getCountRequestedOrders().then((res: any) => setRequestedOrders(res || 0))
      getCountPriorityOrders().then((res: any) => setPriorityOrders(res || 0))
    }
    updateRequestedOrders()
    const interval = setInterval(() => updateRequestedOrders(), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages ?? [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === 'es' ? 'Español' : 'English'}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <AntdLayout.Header
      style={{
        padding: '0px 24px',
        height: '64px',
        backgroundColor: '#FFF'
      }}
    >
      <Row align="middle" justify={'end'}>
        <Col sm={8}>
          <div style={{ display: 'flex' }}>
            <Tag icon={<Icons.ShoppingCartOutlined />} color={requestedOrders ? 'warning' : 'success'} style={{ position: 'relative', left: 0, padding: 5, fontSize: 20 }} >
              {requestedOrders}
            </Tag>
            <Tag icon={<Icons.ExclamationCircleFilled />} color={priorityOrders ? '#f16868' : 'gray'} style={{ position: 'relative', left: 0, padding: 5, fontSize: 20 }} >
              {priorityOrders}
            </Tag>
          </div>
        </Col>
        <Col>
          <Space size="middle">
            <Dropdown overlay={menu}>
              <a style={{ color: 'inherit' }} onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
                  <div style={{ display: screens.lg ? 'block' : 'none' }}>
                    {currentLocale === 'en' ? 'English' : 'Español'}
                    <DownOutlined style={{ fontSize: '12px', marginLeft: '6px' }} />
                  </div>
                </Space>
              </a>
            </Dropdown>
            <Text ellipsis strong>{user?.name}</Text>
            <Avatar size="large" src={user?.user_metadata?.avatar_url} alt={user?.name} />
          </Space>
        </Col>
      </Row>
    </AntdLayout.Header>
  )
}
