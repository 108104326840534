const { createClient } = require('@supabase/supabase-js')

const supabaseSuper = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_SUPER_KEY,
  {
    cookieOptions: {
      name: 'syniva'
    }
  }
)

// BE AWARE: This supabase client got ADMIN access to the database!
export default supabaseSuper
