import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import {
  List,
  Table,
  Form,
  Space,
  Button,
  SaveButton,
  EditButton,
  Input,
  useEditableTable,
  FilterDropdownProps
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { SelectFilterDropdown } from 'components/filterDropdown'

export const VariablesList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps
  } = useEditableTable<definitions['variables']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'hideInView',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'id, value, description, hideInView'
    }
  })

  const t = useTranslate()

  return (
    <List title={t('menu.variables')}>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title='ID'
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="description"
            title={t('supplement.fields.description')}
            sorter
            />

          <Table.Column
            key="id"
            dataIndex="value"
            title={t('igic_types.fields.value')}
            align='center'
            render={(value, data: definitions['variables']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="value"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return value
            }}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='shipment_methods'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            )}
          />
          <Table.Column<definitions['shipment_methods']>
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                  <Space>
                    <EditButton
                      {...editButtonProps(record.id)}
                      hideText
                      size="small"
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
