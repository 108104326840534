import { EL_HIERRO_ISLAND_ID, FUERTEVENTURA_ISLAND_ID, GRAN_CANARIA_ISLAND_ID, LANZAROTE_ISLAND_ID, LA_GOMERA_ISLAND_ID, LA_PALMA_ISLAND_ID, TENERIFE_ISLAND_ID } from './constants'

export const postalCodeToIsle = (postalCode: number) => {
  switch (postalCode) {
    case 350 | 351 | 352 | 353 | 354:
      return GRAN_CANARIA_ISLAND_ID
    case 380 | 381 | 382 | 383 | 384 | 385 | 386:
      return TENERIFE_ISLAND_ID
    case 356:
      return FUERTEVENTURA_ISLAND_ID
    case 355:
      return LANZAROTE_ISLAND_ID
    case 387:
      return LA_PALMA_ISLAND_ID
    case 388:
      return LA_GOMERA_ISLAND_ID
    case 389:
      return EL_HIERRO_ISLAND_ID
    default:
      return GRAN_CANARIA_ISLAND_ID
  }
}
