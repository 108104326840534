import {
  useTranslate,
  getDefaultFilter
} from '@pankod/refine-core'

import {
  List,
  Table,
  DateField,
  BooleanField,
  Avatar,
  FilterDropdownProps,
  FilterDropdown,
  Radio,
  Button,
  CreateButton,
  Tag,
  useModal,
  Space,
  useDrawerForm,
  EditButton
} from '@pankod/refine-antd'

import { definitions, Marketplace } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT } from 'utility/constants'
import { currency } from 'utility/currency'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { SelectFilterDropdown, QuantityFilterDropdown, DateFilterDropdown } from 'components/filterDropdown'
import { MarketplaceEditDrawer } from './edit'
import { MarketplaceCreateDrawer } from './create'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { MarketplaceUpdateWeightsDrawer } from './UpdateWeigthsDrawer'
import { getAllMarketplaces } from 'utility/supabaseApi'

type TableMarketplaceProps = {
  isCustom?: boolean
  tableProps: any
  filters: any
  setFilters: Function
  tableQueryResult: any
}

export const MarketTable: React.FC<TableMarketplaceProps> = ({ isCustom = false, tableProps, filters, setFilters, tableQueryResult }) => {
  const t = useTranslate()

  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const {
    formProps: createFormProps,
    drawerProps: createDrawerProps,
    show: createShow,
    saveButtonProps: createSaveButtonProps
  } = useDrawerForm<definitions['marketplaces']>({
    action: 'create',
    resource: 'marketplaces',
    redirect: false
  })

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editShow,
    saveButtonProps: editSaveButtonProps
  } = useDrawerForm<definitions['marketplaces']>({
    action: 'edit',
    resource: 'marketplaces',
    redirect: false,
    metaData: {
      select: '*, marketplace_category_relations(marketplace_category_id)'
    }
  })

  const {
    drawerProps: showUpdateWeightsDrawerProps,
    show: showUpdateWeights
  } = useDrawerForm<definitions['marketplaces']>({
    action: 'edit',
    resource: 'marketplaces',
    redirect: false
  })

  const dataToExport = (marketplaces: any[]) => {
    return marketplaces?.map((marketplace: any) => {
      const igicTypes = marketplace?.igic_types
      const relations = marketplace?.marketplace_category_relations?.map((row: any) => row?.marketplace_categories?.name)?.join(' | ')
      return {
        [t('marketplace.fields.id')]: marketplace?.id || '',
        [t('marketplace.fields.name')]: marketplace?.name || '',
        [t('marketplace.fields.status')]: marketplace?.status ? t('status.active') : t('status.inactive'),
        [t('marketplace.fields.url')]: marketplace?.url || '',
        [t('marketplace.fields.isInHome')]: marketplace?.is_in_home ? t('status.yes') : t('status.no'),
        [t('marketplace.fields.isCustomShop')]: marketplace?.is_custom_shop ? t('status.yes') : t('status.no'),
        [t('marketplace.fields.shipmentPrice')]: marketplace?.shipment_price || '',
        [t('marketplace.fields.freeShipmentPrice')]: marketplace?.free_shipment_price || '',
        [t('marketplace.fields.igicType')]: igicTypes?.name || '',
        [t('marketplace.fields.categories')]: relations || '',
        [t('cmsCategories.fields.weigth')]: marketplace?.weight || '',
        [t('marketplace.fields.createdAt')]: marketplace?.created_at ? new Date(marketplace?.created_at) : '',
        [t('marketplace.fields.updatedAt')]: marketplace?.updated_at ? new Date(marketplace?.updated_at) : ''
      }
    })
  }

  const exportMarketplaces = async () => {
    const data = await getAllMarketplaces()
    return dataToExport(data as any[])
  }

  return (
    <List
        title={(!isCustom) ? t('marketplace.marketplaces') : t('marketplace.titles.custom')}
        headerProps={{
          extra: (
            <>
              {' '}
              <ExportToExcelButton filename='Tiendas' asyncFunction={() => exportMarketplaces()} />
              <Button onClick={() => showUpdateWeights()}>{t('marketplace.actions.updateWeights')}</Button>
              {!isCustom && <CreateButton onClick={() => createShow()} />}
            </>
          )
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column<Marketplace>
            fixed="left"
            dataIndex="logo_url"
            key="image"
            align="center"
            render={(value, record) =>
              value
                ? (
                <a
                  href={record.original_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Avatar src={value} size={64} shape="square" />
                </a>
                  )
                : null
            }
          />
          <Table.Column
            dataIndex="id"
            align="center"
            title={t('marketplace.fields.id')}
            sorter
          />
          <Table.Column
            dataIndex={'name'}
            align="center"
            title={t('marketplace.fields.name')}
            key="id"
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='marketplaces'
                optionLabel='name'
                optionValue='id'
              />
            )}
          />
          <Table.Column
            dataIndex="description"
            title={t('marketplace.fields.description')}
            sorter
          />
          <Table.Column
            key='status'
            dataIndex="status"
            title={t('marketplace.fields.status')}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Radio.Group>
                  <Radio value='true'>{t('status.active')}</Radio>
                  <Radio value='false'>{t('status.inactive')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
            defaultFilteredValue={getDefaultFilter('id', filters)}
            align="center"
            render={(value) => (
              <BooleanField
                value={value}
                valueLabelTrue={t('status.active')}
                valueLabelFalse={t('status.inactive')}
              />
            )}
          />
          <Table.Column
            dataIndex="url"
            align="center"
            title={t('marketplace.fields.url')}
            render={(value) => <a href={value} target='_blank' rel='noreferrer'>{value}</a>}
          />
          <Table.Column
            dataIndex="is_in_home"
            title={t('marketplace.fields.isInHome')}
            align="center"
            render={(value) => (
              <BooleanField
                value={value}
                valueLabelTrue={t('status.yes')}
                valueLabelFalse={t('status.no')}
              />
            )}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Radio.Group>
                  <Radio value='true'>{t('status.yes')}</Radio>
                  <Radio value='false'>{t('status.no')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
            defaultFilteredValue={getDefaultFilter('id', filters)}
          />
          <Table.Column
            dataIndex="is_in_app"
            title={t('marketplace.fields.isInApp')}
            align="center"
            render={(value) => (
              <BooleanField
                value={value}
                valueLabelTrue={t('status.yes')}
                valueLabelFalse={t('status.no')}
              />
            )}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Radio.Group>
                  <Radio value='true'>{t('status.yes')}</Radio>
                  <Radio value='false'>{t('status.no')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
            defaultFilteredValue={getDefaultFilter('id', filters)}
          />
          <Table.Column
            key="shipment_price"
            dataIndex="shipment_price"
            title={t('marketplace.fields.shipmentPrice')}
            align="center"
            render={(value) => currency(value)}
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='shipment_price'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            dataIndex="free_shipment_price"
            title={t('marketplace.fields.freeShipmentPrice')}
            align="center"
            render={(value) => currency(value)}
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='free_shipment_price'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key="iva_percent"
            dataIndex="iva_percent"
            title={t('marketplace.fields.ivaPercent')}
            align="center"
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='igic_types'
                optionLabel='name'
                optionValue='id'
              />
            )}
          />
          <Table.Column
            key="igic_type_id"
            dataIndex="igic_types"
            title={t('marketplace.fields.igicType')}
            align="center"
            render={(igicType) => igicType?.name}
            filterDropdown={(props: FilterDropdownProps) => (
              <SelectFilterDropdown
                props={props}
                entity='igic_types'
                optionLabel='name'
                optionValue='id'
              />
            )}
          />
          <Table.Column
            dataIndex="marketplace_category_relations"
            title={t('marketplace.fields.categories')}
            align="center"
            render={(categories) =>
              categories?.map((category: any) => {
                return (
                  <Tag key={category.market_place_category_id}>
                    {category?.marketplace_categories?.name}
                  </Tag>
                )
              })
            }
          />
          <Table.Column
            key="return_days"
            dataIndex="return_days"
            title={t('marketplace.fields.returnDays')}
            align="center"
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='return_days'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            key="weight"
            dataIndex="weight"
            title={t('cmsCategories.fields.weigth')}
            align="center"
            filterDropdown={() => (
              <QuantityFilterDropdown
                field='weight'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            dataIndex="created_at"
            title={t('marketplace.fields.createdAt')}
            align="center"
            render={(value) => (
              <DateField value={value} format={DEFAULT_DATE_TIME_FORMAT} />
            )}
            sorter
            filterDropdown={() => (
              <DateFilterDropdown
                field='created_at'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column
            dataIndex="updated_at"
            title={t('marketplace.fields.updatedAt')}
            align="center"
            render={(value) => (
              <DateField value={value} format={DEFAULT_DATE_TIME_FORMAT} />
            )}
            sorter
            filterDropdown={() => (
              <DateFilterDropdown
                field='updated_at'
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
          <Table.Column<definitions['marketplaces']>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            render={(_, record) => (
              <Space>
                <EditButton
                  onClick={() => editShow(record.id)}
                  hideText
                />
                <ButtonLogicDelete
                  click={() => {
                    show()
                    setId(record.id)
                  }}
                />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  entity="marketplaces"
                  tableQueryResult={tableQueryResult}
                />
              </Space>
            )}
          />
        </Table>
        <MarketplaceEditDrawer
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
        />
        <MarketplaceCreateDrawer
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        />
        <MarketplaceUpdateWeightsDrawer
          drawerProps={showUpdateWeightsDrawerProps}
        />
      </List>
  )
}
