import { createClient } from '@pankod/refine-supabase'

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || ''
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_API_KEY || ''
const COOKIE_HOST = process.env.REACT_APP_CUSTOM_AUTH_COOKIE_HOST || '.dev-syniva.es'

export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY, {
  // Controlled mode on localStorage to allow auth on subdomains:
  localStorage:
  {
    setItem: (key, value) => {
      localStorage.setItem(key, value)
      const valueObject = JSON.parse(value)
      if (valueObject &&
        valueObject.currentSession &&
        valueObject.currentSession.refresh_token &&
        valueObject.currentSession.expires_at &&
        valueObject.currentSession.expires_in) {
        // TODO: CHECK BEFORE DEPLOY TO PROD.
        // Cookie not secured for dev purposes.
        document.cookie =
          `syniva-refresh-token=${valueObject.currentSession.refresh_token}; Domain=${COOKIE_HOST}; Path=/; Expires=${valueObject.currentSession.expires_at}; Max-Age=${valueObject.currentSession.expires_in}`// ; HttpOnly; Secure`
      }
    },
    getItem: (key) => {
      return localStorage.getItem(key)
    },
    removeItem: (key) => {
      localStorage.removeItem(key)
      // TODO: CHECK BEFORE DEPLOY TO PROD.
      // Cookie not secured for dev purposes.
      // Delete cookie:
      document.cookie =
          `syniva-refresh-token=dead; Domain=${COOKIE_HOST}; Path=/; Expires=1; Max-Age=1` // ; HttpOnly; Secure`
    }
  }
})
