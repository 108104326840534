import { Icons } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { supabaseClient } from 'utility'
import { read, utils } from 'xlsx'

type UsersImportProps = {
  updateUsersImported: Function
}

export const UsersImport: React.FC<UsersImportProps> = ({ updateUsersImported }) => {
  const t = useTranslate()
  const [loading, setLoading] = useState<boolean>(false)

  const importUsers = (users: any) => {
    updateUsersImported(users)
  }

  const uploadChanged = (event: any) => {
    event.preventDefault()
    if (event.target.files) {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })

        workbook.SheetNames.forEach(async (sheet) => {
          const worksheet = workbook.Sheets[sheet]
          // format object
          const data: any = utils.sheet_to_json(worksheet, { header: 'A' })
          const emailList = data.map((i: any) => i.A)
          await findUsersInList([...emailList])
        })
      }
      reader.readAsArrayBuffer(event.target.files[0])
    }
  }

  const findUsersInList = async (list: string[]) => {
    setLoading(true)
    const { count } = await supabaseClient
      .from('users')
      .select('*', { count: 'exact', head: true })

    const maxRows = 1999
    let totalUsersRetrieved: any[] = []
    if (count) {
      const numberOfQueries = new Array(Math.floor(count / maxRows) + 1)

      let counter = 0
      let maxIteration = maxRows
      for (const iteration of numberOfQueries) {
        let it = iteration || 0
        const { data: usersBlock, error: errorUsersBlock } = await supabaseClient
          .from('users')
          .select('id, email, name, default_billing_address_id(id, nif), default_shipping_address_id(id, nif)')
          .eq('deleted', false)
          .range(counter, maxIteration)

        if (errorUsersBlock) {
          console.log(errorUsersBlock)
        } else {
          totalUsersRetrieved = [...totalUsersRetrieved, ...usersBlock]
        }

        it += 1
        counter = it * maxIteration + 1
        maxIteration += maxRows
      }

      // Filter only with users in list
      const users = totalUsersRetrieved.filter((x: any) => list.includes(x.email))

      if (users && users.length > 0) {
        importUsers([...users])
      } else {
        importUsers([])
      }
    }
    setLoading(false)
  }

  return (
    <>
      <input
        style={{ marginBottom: 20 }}
        name='users-list'
        accept=".xls, .xlsx"
        multiple={false}
        type='file'
        onChange={uploadChanged}
      />
      {loading && <div>
          <Icons.LoadingOutlined />
          <b style={{ marginLeft: 10 }}>{t('codes.fields.waitForUpload')}</b>
        </div>}
    </>
  )
}
