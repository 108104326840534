import { useTranslate, useSelect } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Select,
  ButtonProps,
  Grid,
  Edit,
  Space,
  Typography,
  Button,
  InputNumber
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { ADMIN_ROLE_ID, ADMITTED_STATUS, API_URL, CANCELLED_STATUS, DASHBOARD_USER_MIN_ROLE_ID, ECONOMY_SHIPMENT_METHOD_ID, ESTANDAR_SHIPMENT_METHOD_ID, EXPRESS_SHIPMENT_METHOD_ID, NO_STOCK_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, PENALIZATION_STATUS, PENALIZATION_STATUS_TEXT, PROMO_ORDER_PROCESSED_DAYS, PURCHASE_USER_ROLE_ID, REFERRAL_BENEFIT_STATUS_ACCEPTED, REFUND_TYPE, SEQURA_PAYMENT_METHOD_ID } from 'utility/constants'
import { useState, useEffect, useRef } from 'react'
import { calculateOrderPrices, getOrderItems, handleDisableItem, supabaseClient } from 'utility'
import moment from 'moment'
import { EditOrderDetailsCollapse } from './editOptionalOperations'
import { calculateItemSups, calculateOrderSups } from 'utility/orderCalculator'

type EditOrderDetailsProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  closeDrawer: Function
  order: definitions['Order'] | undefined
};

export const EditOrderDetails: React.FC<EditOrderDetailsProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  closeDrawer,
  order
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  const [loading, setLoading] = useState<boolean>(false)
  const [statusId, setStatusId] = useState(0)
  const [administratorId, setAdministratorId] = useState<number | null>(null)
  const [shippingMethodId, setShippingMethodId] = useState(0)
  const [isPenalization, setIsPenalization] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [isReturn, setIsReturn] = useState(false)
  const [supplement, setSupplement] = useState(0)
  const [supplementSuccess, setSupplementSuccess] = useState(false)
  const suppButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setStatusId(order!.status_id || 0)
    setShippingMethodId(order!.shipping_method_id || 0)
    setAdministratorId(order?.administrator_id || null)

    if (order!.status_id) {
      setIsPenalization(order!.status_id === PENALIZATION_STATUS)
      setIsCanceled(order!.cancelation_date !== null)
      setIsReturn(order!.return_date !== null)
    }
  }, [])

  const handleSubmitSupplement = async () => {
    const { error } = await supabaseClient
      .from('supplements')
      .insert([{
        volumetric: false,
        order_id: order!.id,
        status_id: ADMITTED_STATUS,
        description: PENALIZATION_STATUS_TEXT,
        supplement: supplement,
        updated_at: moment()
      }])

    if (!error) {
      setSupplementSuccess(true)
      suppButtonRef.current!.disabled = true
    }
  }

  // set here
  const handleEditOrderDetails = async () => {
    const idShipmentArray = shipmentMethodsOptions.map((s) => s.value)

    const { data: shipmentMethodsPrices } = await supabaseClient.from('shipment_methods')
      .select('id, price')
      .in('id', idShipmentArray)

    setLoading(true)
    try {
      let discount = order?.discount || 0
      let wallet = order?.wallet_amount_used || 0
      if (statusId === PENALIZATION_STATUS && order && order.discount > 0) {
        discount = 0
      }
      if (statusId === PENALIZATION_STATUS && order && order.wallet_amount_used > 0) {
        wallet = 0
      }

      const { data: dataStatusId } = await supabaseClient.from('orders').select('id, status_id, prev_status_id').eq('id', order!.id).single()

      const { error } = await supabaseClient
        .from<definitions['Order']>('orders')
        .update({
          prev_status_id: dataStatusId.status_id,
          status_id: statusId,
          administrator_id: administratorId || undefined,
          shipping_method_id: shippingMethodId,
          updated_by: supabaseClient.auth.user()?.email,
          discount: discount,
          wallet_amount_used: wallet,
          custom_order_price: (statusId === PENALIZATION_STATUS) ? 0 : order?.custom_order_price
        })
        .eq('id', order!.id)

      if (error) {
        setLoading(false)
        return error.message
      }

      if (statusId === PENALIZATION_STATUS) {
        const items = await getOrderItems(order!.id)
        if (items) {
          const itemIds = items.filter((item: any) => !item.disabled).map((item: any) => item.id)
          await handleDisableItem(itemIds, true, order?.shipping_price, true, false, PENALIZATION_STATUS)
        }

        const { data: supplements, error: errorSup } = await supabaseClient.from('supplements').select('*').eq('order_id', order!.id).eq('deleted', false)

        if (order?.custom_order_price && order?.custom_order_price > 0) {
          await supabaseClient.from('refunds')
            .insert({
              order_id: order!.id,
              description: 'Gestión personalizado',
              amount: order?.custom_order_price,
              type: REFUND_TYPE.client
            })
        }

        if (errorSup) {
          setLoading(false)
          return errorSup.message
        }
        const sups = supplements.filter((row) => row.description === PENALIZATION_STATUS_TEXT) || []
        if (sups.length > 0) {
          // WARNING, this call only take efect in dev and production environment, CORS policies
          await fetch(`${API_URL}/billings/generate-invoice/${order!.id}`, {
            method: 'GET',
            credentials: 'include'
          })
        }

        if (order?.payment_method_id && order.payment_method_id === SEQURA_PAYMENT_METHOD_ID) {
          try {
            const res = await fetch(`${API_URL}/sequra/penalization/${order.id}/${statusId}`, { method: 'POST' })
            if ([200, 202, 204].includes(res.status)) {
              console.log('Done')
            }
          } catch (err) {
            console.error(err)
            console.error('Error updating values on Sequra, check body')
          }
        }
      }

      if ((statusId === NO_STOCK_STATUS || statusId === CANCELLED_STATUS) && order!.wallet_amount_used > 0) {
        const { error: errorUpdateOrder } = await supabaseClient.from<definitions['Order']>('orders')
          .update({
            prev_status_id: dataStatusId.status_id,
            status_id: statusId,
            updated_by: supabaseClient.auth.user()?.email
          })
          .eq('id', order!.id)

        if (errorUpdateOrder) {
          setLoading(false)
          return errorUpdateOrder.message
        } else {
          const { data: variablePromoOrderProcessedDays } = await supabaseClient.from('variables').select('name, value').eq('name', PROMO_ORDER_PROCESSED_DAYS).single()

          const expiredDate = new Date(Date.now())
          expiredDate.setDate(expiredDate.getDate() + parseInt(variablePromoOrderProcessedDays.value))

          if (order) {
            const { error: errorReferral } = await supabaseClient.from('referral_benefits')
              .insert({
                referrer_user_id: 0,
                referred_user_id: order?.user_id,
                benefit_amount: order?.wallet_amount_used,
                status: REFERRAL_BENEFIT_STATUS_ACCEPTED,
                referred_expired_at: expiredDate.toISOString(),
                referrer_expired_at: expiredDate.toISOString(),
                increased_by_syniva: false,
                processed_promo: order?.id,
                no_stock: (statusId === NO_STOCK_STATUS)
              })

            if (errorReferral) {
              console.log('Not Updated->', order.user_id)
            } else {
              console.log('Updated->', order.user_id)
            }

            await supabaseClient.from('users')
              .update({ wallet_balance: order.user.wallet_balance + order.wallet_amount_used })
              .eq('id', order.user_id)
          }
        }
      }

      // Check the shipment change and apply changes
      if (shippingMethodId !== order?.shipping_method_id) {
        // Create transportSupplement
        const pConditions = order?.purchase_conditions as any
        let shipmentDiff = Math.round(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price - shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price)
        // From ECONOMY
        if (pConditions.free_vol === 'true' && pConditions.free_vol_price_min < order!.syniva_price) {
          if (order?.shipping_method_id === ECONOMY_SHIPMENT_METHOD_ID) {
            shipmentDiff = shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price
          } else if (order?.shipping_method_id === ESTANDAR_SHIPMENT_METHOD_ID) {
            if (shippingMethodId === ECONOMY_SHIPMENT_METHOD_ID && order.syniva_price > pConditions.free_vol_price_min) {
              shipmentDiff = -(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price)
            } else if (shippingMethodId === ECONOMY_SHIPMENT_METHOD_ID && order.syniva_price < pConditions.free_vol_price_min) {
              shipmentDiff = -(Math.round(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price - shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price))
            }
            if (shippingMethodId === EXPRESS_SHIPMENT_METHOD_ID) {
              shipmentDiff = Math.round(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price - shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price)
            }
          } else if (order?.shipping_method_id === EXPRESS_SHIPMENT_METHOD_ID) {
            if (shippingMethodId === ECONOMY_SHIPMENT_METHOD_ID && order.syniva_price > pConditions.free_vol_price_min) {
              shipmentDiff = -(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price)
            } else if (shippingMethodId === ECONOMY_SHIPMENT_METHOD_ID && order.syniva_price < pConditions.free_vol_price_min) {
              shipmentDiff = -Math.abs((Math.round(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price - shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price)))
            }
            if (shippingMethodId === ESTANDAR_SHIPMENT_METHOD_ID) {
              shipmentDiff = -Math.abs(Math.round(shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === shippingMethodId).price - shipmentMethodsPrices?.find((sm: { id: Number }) => sm.id === order?.shipping_method_id).price))
            }
          }
        }

        // console.log(pConditions, order?.shipping_method_id, shippingMethodId, shipmentDiff)

        await supabaseClient.from('supplements')
          .insert({
            order_id: order?.id,
            description: (shipmentDiff > 0) ? 'transportSupplement' : 'refundTransport',
            supplement: shipmentDiff.toFixed(2),
            status_id: (shipmentDiff > 0) ? ORDER_STATUS_ID_PENDING_PAYMENT : ADMITTED_STATUS,
            volumetric: false,
            deleted: (shipmentDiff === 0)
          })

        if (shipmentDiff < 0) {
          await supabaseClient.from('refunds')
            .insert({
              order_id: order?.id,
              description: 'refundTransport',
              amount: Math.abs(shipmentDiff).toFixed(2),
              status_id: ORDER_STATUS_ID_PENDING_PAYMENT,
              deleted: false,
              type: 'client'
            })
        }

        const orderDetailData = Object.assign({}, order!) as any
        orderDetailData.shipping_method_id = shippingMethodId
        // Prepare other supplements if is required
        const comeFromShipmentChange = true
        await calculateOrderPrices(orderDetailData.id, comeFromShipmentChange)
        await calculateOrderSups(orderDetailData)

        const { data: items } = await supabaseClient.from('items').select('*').eq('order_id', order!.id).eq('disabled', false)
        if (items && items.length) {
          const itemProps: any = {
            initialValues: items[0]
          }
          await calculateItemSups(itemProps, orderDetailData, orderDetailData.id, items[0].price, items[0].iva || 21, items[0].quantity, null, () => null, false, false)
        }
      }

      setLoading(false)
      closeDrawer()
      window.location.reload()
    } catch (err) {
      setLoading(false)
      closeDrawer()
      window.location.reload()
    }
  }

  const handleUndoCancel = async () => {
    const { error } = await supabaseClient
      .from('orders')
      .update({ cancelation_date: null, cancelation_reason: null })
      .eq('id', order!.id)

    if (error) {
      return error.message
    }

    closeDrawer()
    window.location.reload()
  }

  const handleUndoReturn = async () => {
    const { error } = await supabaseClient
      .from('orders')
      .update({ return_date: null, return_reason: null })
      .eq('id', order!.id)

    if (error) {
      return error.message
    }

    closeDrawer()
    window.location.reload()
  }

  const handleStatusChange = (value: number) => {
    setStatusId(value)
    setIsPenalization(value === PENALIZATION_STATUS)
  }

  const { options: userOptions } = useSelect<definitions['users']>({
    resource: 'users',
    filters: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'role_id',
        operator: 'in',
        value: [DASHBOARD_USER_MIN_ROLE_ID, PURCHASE_USER_ROLE_ID, ADMIN_ROLE_ID]
      }
    ],
    fetchSize: 1000,
    optionLabel: 'name',
    optionValue: 'id'
  })

  const { options: statusOptions } = useSelect<definitions['status']>({
    resource: 'status',
    fetchSize: 30,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const { options: shipmentMethodsOptions } = useSelect<definitions['shipment_methods']>({
    resource: 'shipment_methods',
    fetchSize: 30,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'is_in_home',
        operator: 'eq',
        value: true
      }
    ],
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        resource="orders"
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        goBack={false}
        isLoading={loading}
        title={t('order.actions.editDetails')}
      >
        {Number.isInteger(formProps.initialValues?.id) && (
          <Form {...formProps} layout="vertical" onFinish={handleEditOrderDetails}>
            <Form.Item
              hidden={true}
              name="id"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('order.fields.status')}
              name="status_id"
            >
              <Select
                options={statusOptions}
                getPopupContainer={trigger => trigger.parentElement}
                onChange={(value: number) => handleStatusChange(value)}
              />
            </Form.Item>
            <Form.Item
              label={t('order.fields.administrator')}
              name="administrator_id"
            >
              <Select
                showSearch
                options={userOptions}
                getPopupContainer={trigger => trigger.parentElement}
                filterOption={(input, option) => !!option?.label?.toLocaleLowerCase().includes(input.toLowerCase())}
                onChange={(value: number) => setAdministratorId(value)}
              />
            </Form.Item>
            <Form.Item
              label={t('order.fields.shipmentMethod')}
              name="shipping_method_id"
            >
              <Select
                options={shipmentMethodsOptions}
                getPopupContainer={trigger => trigger.parentElement}
                onChange={(value: number) => setShippingMethodId(value)}
              />
            </Form.Item>
          </Form>
        )}
      </Edit>
      {isPenalization && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction='vertical' align='center'>
            <Typography.Text>{t('supplement.fields.supplement')}</Typography.Text>
            <InputNumber value={supplement} onChange={(value) => setSupplement(value)} min={0} decimalSeparator=',' />
            {supplementSuccess && (
              <Typography.Text type="success">{t('notifications.successMsg')}</Typography.Text>
            )}
            <Button ref={suppButtonRef} onClick={handleSubmitSupplement}>{t('order.actions.addSupplement')}</Button>
          </Space>
        </div>
      )}
      {isCanceled && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction='vertical' align='center'>
            <Typography.Text>{t('order.fields.isCanceled')}</Typography.Text>
            <Button ref={suppButtonRef} onClick={handleUndoCancel}>{t('order.actions.undoCancel')}</Button>
          </Space>
        </div>
      )}
      {isReturn && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Space direction='vertical' align='center'>
            <Typography.Text>{t('order.fields.isReturn')}</Typography.Text>
            <Button ref={suppButtonRef} onClick={handleUndoReturn}>{t('order.actions.undoReturn')}</Button>
          </Space>
        </div>
      )}

      <EditOrderDetailsCollapse order={order} />

    </Drawer>
  )
}
