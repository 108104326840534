import { Button, Drawer, Grid, Icons, Input, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { CURRENT_DATE_TIME, PURCHASE_REVIEWED_TEMPLATE_PATH, supabaseClient } from 'utility'
import { read, utils } from 'xlsx'
import { definitions } from 'interfaces'

type ReviewedPurchaseButtonProps = {
  filename: string
}

export const ReviewedPurchaseButton = ({ filename }: ReviewedPurchaseButtonProps) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  filename = `${filename} ${CURRENT_DATE_TIME}.xlsx`

  const {
    drawerProps: editDrawerProps,
    show: editShow
  } = useDrawerForm<definitions['market_orders']>({
    action: 'edit',
    redirect: false
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [updateFile, setUpdateFile] = useState(null)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  // const [updated, setUpdated] = useState<{ orderId: Number, invoiceNumber: string }[]>([])
  const [failed, setFailed] = useState<{ orderId: Number, invoiceNumber: string }[]>([])

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updatePurchaseToReviewed = async (orderId: Number, invoiceNumber: String) => {
    // console.log(orderId, invoiceNumber)
    const { error } = await supabaseClient.from('market_orders')
      .update({ reviewed: true })
      .eq('order_id', orderId)
      .eq('invoice_number', invoiceNumber)

    if (error) return false
    return true
  }

  const readUploadedFile = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setUpdateFinished(null)

    // const updatedMO: { orderId: Number, invoiceNumber: string }[] = []
    const failedMO: { orderId: Number, invoiceNumber: string }[] = []

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = utils.sheet_to_json(ws, { header: 1 })

        let index = 0

        for (const m of dataParse) {
          if (index > 0) {
            console.log(typeof m[0], typeof m[1])
            if (typeof m[1] === 'number') {
              const row = { orderId: Number(m[1]), invoiceNumber: String(m[0]) }
              const res = await updatePurchaseToReviewed(row.orderId, row.invoiceNumber)

              if (!res) failedMO.push(row)
              // else updatedMO.push(row)
            } else {
              failedMO.push({ orderId: Number(m[1]), invoiceNumber: String(m[0]) })
            }
          }
          index += 1
        }

        setLoading(false)
        setUpdateFile(null)
        setFailed(failedMO)
        // setUpdated(updatedMO)
        setUpdateFinished('ok')
      } catch (err) {
        setError(true)
        setLoading(false)
        setUpdateFile(null)
        setFailed(failedMO)
        // setUpdated(updatedMO)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  return <>
    <Button type="primary" onClick={() => editShow()}>
      {t('market_order.reviewedPurchase.title')}
    </Button>
    <Drawer
      {...editDrawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('market_order.reviewedPurchase.title')}
        </Typography.Text>

        <a href={PURCHASE_REVIEWED_TEMPLATE_PATH} download="purchases-reviewed-template.xlsx">
          <Button>
            <Icons.DownloadOutlined />
            {t('market_order.reviewedPurchase.labelTemplate')}
          </Button>
        </a>

        <Typography.Text>
          <b>{t('market_order.reviewedPurchase.advise')}</b>
        </Typography.Text>

        <Typography.Text>{t('marketplace.actions.uploadExcel')}</Typography.Text>
        <Input
          accept='.xlsx'
          bordered={false}
          size='middle'
          type='file'
          key='exceluploader'
          onChange={handleFile}
        />
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => readUploadedFile(e)}
        >
          {t('market_order.reviewedPurchase.labelUpdate')}
        </Button>

        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('market_order.reviewedPurchase.labelUpdating')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t(`market_order.reviewedPurchase.labelFinish${updateFinished}`)}
        </Typography>}

      </Space>

      {(error)
        ? <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>{t('market_order.reviewedPurchase.error')}</Typography.Text>
        </Space>
        : (failed.length > 0) && <Space>
          <Typography.Text>{t('market_order.reviewedPurchase.marketOrderFailed')}</Typography.Text>
          {failed.map((fmo: { orderId: Number, invoiceNumber: string }, index: number) => <p key={index}>{Number(fmo.orderId)} -- {fmo.invoiceNumber}</p>)}
        </Space>}
    </Drawer>
  </>
}
