import { currentSession } from './authLocal'
import { REACT_APP_BILLER_API_URL } from './constants'

export async function fetchBiller (endpoint: string, options?: RequestInit) {
  const token = currentSession().access_token
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  const mergedOptions: RequestInit = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options?.headers || {})
    }
  }

  const response = await fetch(
    `${REACT_APP_BILLER_API_URL}${endpoint}`,
    mergedOptions
  )

  return response
}
