import { useTranslate } from '@pankod/refine-core'

import {
  Input,
  InputNumber,
  Row,
  Col,
  Typography,
  Button,
  Icons,
  Divider,
  Radio
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { ORDER_STATUS_ID_PENDING_PAYMENT, SHIPPING_METHOD_ECONOMY_ID, predefinedSizes } from 'utility'

type VolSupplementProps = {
  edit: boolean
  isAdmin: boolean
  // supplementData: any
  formData: any
  packageNumber: number
  orderId: number
  handleVolSuplements: Function
  shippingMethod: any
  maxKgDefault: number
  extraCost: number
  order: any
}

export const VolSuplementsForm: React.FC<VolSupplementProps> = ({
  edit,
  isAdmin,
  // supplementData,
  formData,
  packageNumber,
  orderId,
  // index,
  handleVolSuplements,
  shippingMethod,
  maxKgDefault,
  extraCost,
  order
}) => {
  const t = useTranslate()

  const [weight, setWeight] = useState(0)
  const [length, setLength] = useState(0)
  const [height, setHeight] = useState(0)
  const [depth, setDepth] = useState(0)
  const [volume, setVolume] = useState(0)
  const [billableVolume, setBillableVolume] = useState(0)
  const [weightExcess, setWeightExcess] = useState(0)
  const [supplement, setSupplement] = useState(0)
  const [statusId, setStatusId] = useState(ORDER_STATUS_ID_PENDING_PAYMENT)
  const [packages, setPackages] = useState<any>(Array.from(new Array(packageNumber)))
  const [packagesPredefinedSize, setPackagesPredefinedSize] = useState<any[]>(Array.from(new Array(packageNumber)))

  if (edit) {
    useEffect(() => {
      setWeight(formData?.weigth || 0)
      setLength(formData?.length || 0)
      setHeight(formData?.heigth || 0)
      setDepth(formData?.depth || 0)
      setVolume(formData?.volume || 0)
      setBillableVolume(formData?.billable_volume || 0)
      setWeightExcess(formData?.weigth_excess || 0)
      setSupplement(formData?.supplement || 0)
      setStatusId(formData?.status_id || ORDER_STATUS_ID_PENDING_PAYMENT)
      const vInfo = formData?.volumetricInfo || null
      setPackages(vInfo)
    }, [formData])
  }

  useEffect(() => {
    const supResume = [{
      weigth: weight,
      length: length,
      heigth: height,
      depth: depth,
      volume: volume,
      billable_volume: billableVolume,
      weigth_excess: weightExcess,
      supplement: parseFloat(supplement.toFixed(2)) > 0 ? parseFloat(supplement.toFixed(2)) : 0,
      maxKgDefault: maxKgDefault,
      volumetric: true,
      order_id: orderId,
      id: Number(formData?.id) || undefined,
      status_id: statusId,
      volumetricInfo: packages
    }]

    handleVolSuplements([...supResume])
  }, [weight, length, height, depth, supplement, packagesPredefinedSize])

  const isExpress = () => shippingMethod?.name.toLowerCase().includes('express')

  const splitQrValue = async (qr: string, index: number) => {
    // QR value example: L270Wd190H190mmV0.009mWt1.58
    const regex = /L(\d+)Wd(\d+)H(\d+)mmV(\d*.?\d+)mWt(\d*.?\d+)/gm
    const matches = regex.exec(qr)
    if (matches) {
      const newLength = Number.parseFloat(matches[1]) / 10
      const newHeight = Number.parseFloat(matches[2]) / 10
      const newDepth = Number.parseFloat(matches[3]) / 10
      const newWeight = Number.parseFloat(matches[5])

      const dUpdate = [...packages]
      const item = Object.assign({}, packages[index])
      item.weigth = newWeight
      item.length = newLength
      item.heigth = newHeight
      item.depth = newDepth
      dUpdate[index] = item

      await calculatePackages(dUpdate, index)
    }
  }

  const calculatePackages = async (data: any, index: number, predefinedSize?: string) => {
    // here calculate
    if (predefinedSize !== undefined && predefinedSize !== 'CUSTOM') {
      data[index].volumetricWeight = Math.ceil((Number(data[index].length || 0) * Number(data[index].heigth || 0) * Number(data[index].depth || 0)) / 5000)
      data[index].billVolume = Math.ceil(predefinedSizes[`${predefinedSize}`].volume)

      await setPackages([...data])
    } else {
      const volumetricWeight = Math.ceil((Number(data[index].length || 0) * Number(data[index].heigth || 0) * Number(data[index].depth || 0)) / 5000)
      const bilVolume = (volumetricWeight > data[index].weigth) ? Math.ceil(volumetricWeight) : Math.ceil(data[index].weigth)

      data[index].volumetricWeight = volumetricWeight
      data[index].billVolume = bilVolume

      await setPackages([...data])
    }
  }

  const calculateSupplement = () => {
    let billableVolumeSup = 0
    let supAmount = 0
    let totalWeight = 0
    let maxLength = 0
    let maxDepth = 0
    let maxHeight = 0

    packages.forEach((element: any, indexPackage: number) => {
      // console.log(element)
      if (element) {
        billableVolumeSup += Math.ceil(element.billVolume)
        totalWeight += element.weigth
        if (maxLength < element.length) {
          maxLength = element.length
        }
        if (maxDepth < element.depth) {
          maxDepth = element.depth
        }
        if (maxHeight < element.heigth) {
          maxHeight = element.heigth
        }
      }
    })

    const weightExcessVar = (billableVolumeSup - maxKgDefault) > (totalWeight - maxKgDefault) ? (billableVolumeSup - maxKgDefault) : (totalWeight - maxKgDefault)
    const volumeVar = Number((maxLength * maxHeight * maxDepth * shippingMethod.volumetric_factor / 1000000).toFixed(2))

    // const priceRanges = shippingMethod.shipping_method_price_ranges.sort((a: any, b: any) => a.from_weight - b.from_weight)
    // const shippingPrice = checkPriceRange(priceRanges, weightExcessVar) || 0
    // console.log(priceRanges, weightExcessVar, shippingPrice || 0)

    // TODO: Update calculation with new params in calculator
    if (shippingMethod.name.toLowerCase().includes('express')) {
      // FIXME: For express is 3 the limit too??
      supAmount = ((billableVolumeSup - 3) * shippingMethod.price_additional_kg)
    } else {
      // New calculation 2024/02/06
      if (billableVolumeSup > 20) {
        supAmount = 12 + 24 + (billableVolumeSup - 20)
      } else if (billableVolumeSup > 8) {
        supAmount = 12 + ((billableVolumeSup - 8) * 2)
      } else if (billableVolumeSup > 5) {
        supAmount = (billableVolumeSup - 4) * 3
      } else if (billableVolumeSup === 5) {
        supAmount = 3
      } else {
        supAmount = 0
      }
      // Old Calculation before 2024/02/06
      // if (billableVolumeSup > 15) {
      //   supAmount = (12 * 1.95) + ((billableVolumeSup - 15) * 0.95)
      // } else {
      //   supAmount = ((billableVolumeSup - 5) * 1.95)
      // }

      // ONLY FOR ECONOMY if flag FREE_SHIPMENT_ECONOMY is enabled set to 0 de shipment price
      const purchaseConditions = order?.purchase_conditions

      // check if is a economy order
      const isEconomy = (order?.shipping_method_id === SHIPPING_METHOD_ECONOMY_ID)
      if (purchaseConditions !== null && purchaseConditions.free_sup_economy === 'true' && purchaseConditions.free_sup_economy_price_min >= 0 && parseFloat(purchaseConditions.free_sup_economy_price_min) <= order!.syniva_price && isEconomy) {
        supAmount = 0
      }
    }

    // console.log(weightExcessVar, volumeVar, supAmount)

    setWeight(totalWeight)
    setWeightExcess(weightExcessVar)
    setDepth(maxDepth)
    setLength(maxLength)
    setHeight(maxHeight)
    setVolume(Math.ceil(volumeVar))
    setBillableVolume(billableVolumeSup)
    setSupplement(parseFloat(supAmount.toFixed(2)))

    const supResume = [{
      weigth: totalWeight,
      length: maxLength,
      heigth: maxHeight,
      depth: maxDepth,
      volume: volumeVar,
      billable_volume: billableVolumeSup,
      weigth_excess: weightExcessVar > 0 ? weightExcessVar : 0,
      supplement: parseFloat(supAmount.toFixed(2)) > 0 ? parseFloat(supAmount.toFixed(2)) : 0,
      maxKgDefault: maxKgDefault,
      volumetric: true,
      order_id: orderId,
      id: Number(formData?.id) || undefined,
      volumetricInfo: packages
    }]

    handleVolSuplements([...supResume])
  }

  useEffect(() => {
    calculateSupplement()
  }, [packages])

  const updatePackageValue = async (field: string, index: number, value: any) => {
    const dUpdate = [...packages]
    const item = Object.assign({}, packages[index])
    item[`${field}`] = value
    dUpdate[index] = item

    calculatePackages(dUpdate, index)
  }

  const updatePredefinedSize = async (index: number, value: any) => {
    const dPredefined = [...packagesPredefinedSize]
    dPredefined[index] = value.target.value
    setPackagesPredefinedSize([...dPredefined])

    const dUpdate = [...packages]
    const item = Object.assign({}, packages[index])
    if (value.target.value === 'CUSTOM') {
      item.predefinedSize = value.target.value
      dUpdate[index] = item
    } else {
      const newLength = Number.parseFloat(predefinedSizes[`${value.target.value}`].length)
      const newHeight = Number.parseFloat(predefinedSizes[`${value.target.value}`].height)
      const newDepth = Number.parseFloat(predefinedSizes[`${value.target.value}`].depth)
      const newWeight = Number.parseFloat(predefinedSizes[`${value.target.value}`].weight)

      item.weigth = newWeight
      item.length = newLength
      item.heigth = newHeight
      item.depth = newDepth
      item.predefinedSize = value.target.value
      dUpdate[index] = item
    }
    await calculatePackages(dUpdate, index, value.target.value)
  }

  const checkPredefinedSize = (index: number) => {
    return (packages[index]?.predefinedSize === 'XS' || packages[index]?.predefinedSize === 'M' || packages[index]?.predefinedSize === 'L')
  }

  const addPackage = async () => {
    const dUpdate = [...packages]
    dUpdate.push({
      weigth: 0,
      depth: 0,
      length: 0,
      heigth: 0,
      order_id: orderId,
      billable_volume: 0,
      volume: 0,
      volumetric: true,
      weigth_excess: 0,
      predefinedSizes: 'CUSTOM'
    })
    await setPackages(dUpdate)
  }

  const removePackage = async (index: number) => {
    // eslint-disable-next-line no-unused-vars
    const removedSup = [...packages.splice(index, 1)]
    await setPackages([...packages])
  }

  return (
    <>
      <Typography style={{ fontSize: 16, marginTop: '-1.5rem', marginBottom: '10px', fontWeight: 700 }}>{`${t('supplement.supplement')}`}</Typography>
      <Row justify='center' style={{ marginBottom: '15px' }}>

        <Col span={6}>
          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.weigth')}</Typography>
          <InputNumber value={weight || 0} min={0} onChange={(value) => setWeight(value ?? 0)} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.length')}</Typography>
          <InputNumber value={length || 0} min={0} onChange={(value) => setLength(value ?? 0)} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.height')}</Typography>
          <InputNumber value={height || 0} min={0} onChange={(value) => setHeight(value ?? 0)} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.depth')}</Typography>
          <InputNumber value={depth || 0} min={0} onChange={(value) => setDepth(value ?? 0)} decimalSeparator=',' />
        </Col>

        <Col span={6} style={{ minWidth: 200 }}>
          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.volume')}</Typography>
          <InputNumber value={volume || 0} readOnly style={{ backgroundColor: '#eee' }} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.billableVolume')}</Typography>
          <InputNumber value={billableVolume || 0} readOnly style={{ backgroundColor: '#eee' }} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.weigthExcess')}</Typography>
          <InputNumber value={weightExcess > 0 ? weightExcess : 0} readOnly style={{ backgroundColor: '#eee' }} decimalSeparator=',' />

          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.supplement')}</Typography>
          <InputNumber
            value={supplement > 0 ? supplement : 0}
            style={{ backgroundColor: isAdmin ? 'white' : '#eee' }}
            decimalSeparator=','
            readOnly={!isAdmin}
            onChange={(value) => setSupplement(value)}
          />
          {/* {shippingPrice === 0 &&
            <Alert message={t('supplement.messages.warnNoRange')} type="warning" />
          } */}
        </Col>

      </Row>

      <Divider />

      <div>
        <Typography style={{ fontSize: 16, marginBottom: '10px', fontWeight: 700 }}>{`${t('supplement.packagesManagement')}`}</Typography>
        <div style={{ display: 'flow-root', marginTop: '-2.25rem', marginBottom: 10 }}>
          <Button style={{ float: 'right' }} onClick={addPackage}>
            {t('supplement.addVolumetric')}
          </Button>
        </div>
      </div>

      {packages.map((i: any, index: number) => {
        return <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography style={{ fontSize: 16, marginBottom: '10px', fontWeight: 700 }}>{`${t('supplement.fields.package')} ${index + 1}` }</Typography>
            {(!isExpress()) && <div>
              <Radio.Group onChange={(value: any) => updatePredefinedSize(index, value)} value={packagesPredefinedSize[index] || null}>
                <Radio value={'XS'}>Sobre</Radio>
                <Radio value={'M'}>Cajita</Radio>
                <Radio value={'L'}>Caja</Radio>
                {/* <Radio value={'CUSTOM'}>Manual</Radio> */}
              </Radio.Group>
            </div>}
            <Button onClick={() => removePackage(index)}>
              <Icons.DeleteOutlined style={{ color: 'red' }} />
            </Button>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.qr')}</Typography>
            <Input id={`input${index}`} key={`input${index}`} disabled={checkPredefinedSize(index)} onChange={(event) => splitQrValue(event.target?.value, index)} autoComplete='off' />
          </div>

          <Row justify='start' style={{ marginBottom: '15px', marginLeft: '30px' }}>

            <Col span={3} style={{ maxWidth: '250px', minWidth: '120px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.weigth')}</Typography>
              <InputNumber value={packages[index]?.weigth || 0} min={0} disabled={checkPredefinedSize(index)} onChange={(value) => updatePackageValue('weigth', index, value ?? 0)} decimalSeparator=',' />
            </Col>

            <Col span={3} style={{ maxWidth: '250px', minWidth: '120px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.length')}</Typography>
              <InputNumber value={packages[index]?.length || 0} min={0} disabled={checkPredefinedSize(index)} onChange={(value) => updatePackageValue('length', index, value ?? 0)} decimalSeparator=',' />
            </Col>

            <Col span={3} style={{ maxWidth: '250px', minWidth: '120px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.height')}</Typography>
              <InputNumber value={packages[index]?.heigth || 0} min={0} disabled={checkPredefinedSize(index)} onChange={(value) => updatePackageValue('heigth', index, value ?? 0)} decimalSeparator=',' />
            </Col>

            <Col span={3} style={{ maxWidth: '250px', minWidth: '120px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.depth')}</Typography>
              <InputNumber value={packages[index]?.depth || 0} min={0} disabled={checkPredefinedSize(index)} onChange={(value) => updatePackageValue('depth', index, value ?? 0)} decimalSeparator=',' />
            </Col>

          </Row>
          <Row justify='start' style={{ marginTop: '15px', marginBottom: '15px', marginLeft: '30px' }}>
            <Col span={3} style={{ maxWidth: '250px', minWidth: '150px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.volumetricWeight')}</Typography>
              <InputNumber value={packages[index]?.volumetricWeight || 0} readOnly style={{ backgroundColor: '#eee' }} decimalSeparator=',' />
            </Col>

            <Col span={3} style={{ maxWidth: '250px', minWidth: '150px' }}>
              <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.billableVolume')}</Typography>
              <InputNumber value={packages[index]?.billVolume || 0} readOnly style={{ backgroundColor: '#eee' }} decimalSeparator=',' />
            </Col>
          </Row>
        </>
      })}
    </>
  )
}
