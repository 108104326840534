import { useTranslate } from '@pankod/refine-core'
import {
  Drawer,
  DrawerProps,
  FormProps,
  ButtonProps,
  Grid,
  Create
} from '@pankod/refine-antd'
import { PriceRangeForm } from './form'

type CreatePriceRangeProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreatePriceRange: React.FC<CreatePriceRangeProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource="shipping_method_price_ranges"
        title={t('shippingMethodPriceRanges.titles.create')}
      >
        <PriceRangeForm formProps={formProps} />
      </Create>
    </Drawer>
  )
}
