import { Image } from '@pankod/refine-antd'
import { IconByExt } from './IconByExt'
// import { PdfViewer } from 'components/PdfViewer/PdfViewer'
import { PreviewMask } from './PreviewMask'

type FileWithLinkProps = {
  href: string
  pdfPreview?: boolean
}

export const FileWithLink: React.FC<FileWithLinkProps> = ({ href, pdfPreview = true }) => {
  const parts = href.split('.')
  const ext = parts[parts.length - 1]

  if (href.endsWith('.png') || href.endsWith('.jpg')) {
    return <Image src={href} width={64} />
  } else {
    return (
      <div className="ant-image" style={{ width: 64, height: 64, alignContent: 'center' }}>
        <IconByExt ext={ext} className="ant-image-img" style={{ fontSize: 40 }} />
        {/* {href.endsWith('.pdf') && pdfPreview
          ? <PdfViewer file={href} />
          : (
              <a href={href} target="_blank" rel="noreferrer" aria-label="eye" className="anticon">
                <PreviewMask label="actions.open" />
              </a>
            )
        } */}
        <a href={href} target="_blank" rel="noreferrer" aria-label="eye" className="anticon">
          <PreviewMask label="actions.open" />
        </a>
      </div>
    )
  }
}
