import { useTranslate } from '@pankod/refine-core'
import {
  Form,
  Select,
  FormProps,
  Input
} from '@pankod/refine-antd'
import { DECREASE_TYPES_MAPPER } from 'utility/mapper'

type DecreaseFormProps = {
  formProps: FormProps<{}>
  decreaseTypes?: Array<number>
  decreaseCosts?: number
}

export const DecreaseForm: React.FC<DecreaseFormProps> = ({
  formProps,
  decreaseTypes,
  decreaseCosts
}) => {
  const t = useTranslate()
  return (
    <>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          decrease_costs: decreaseCosts,
          decrease_types_ids: decreaseTypes !== null ? decreaseTypes : undefined,
          ...formProps.initialValues
        }}
      >
        <Form.Item
          label={t('decrease.decrease_costs')}
          name="decrease_costs"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={t('decrease.decrease_types')}
          name='decrease_types_ids'
          // rules={[{ required: true }]}
        >
          <Select
            options={Object.entries(DECREASE_TYPES_MAPPER).map(([key, value]) => ({
              value: parseInt(key),
              label: t(`decrease_types.${value}`)
            }))}
          />
        </Form.Item>
      </Form>
    </>
  )
}
