import { useTranslate } from '@pankod/refine-core'
import {
  List,
  Table,
  Space,
  EditButton,
  CreateButton,
  useTable,
  useModal,
  useDrawerForm
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { currency } from 'utility/currency'
import { EditPriceRange } from 'components/priceRange/edit'
import { CreatePriceRange } from 'components/priceRange/create'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'

export const PriceRangeTable: React.FC<{ record: definitions['shipment_methods'] }> = ({ record }) => {
  const t = useTranslate()

  const { tableProps: postTableProps, tableQueryResult } = useTable<definitions['shipping_method_price_ranges']>({
    resource: 'shipping_method_price_ranges',
    initialSorter: [
      {
        field: 'from_weight',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'shipping_method_id',
        operator: 'eq',
        value: record.id
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    syncWithLocation: false
  })

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<definitions['shipping_method_price_ranges']>({
    action: 'edit',
    resource: 'shipping_method_price_ranges',
    redirect: false
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['shipping_method_price_ranges']>({
    action: 'create',
    resource: 'shipping_method_price_ranges',
    redirect: false
  })

  createFormProps.initialValues = { shipping_method_id: record.id }

  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  return (
        <List title={t('shippingMethodPriceRanges.priceRange')} createButtonProps={undefined}>
            <CreateButton onClick={() => createShow()} style={{ marginTop: -50, float: 'right' }}>
              {t('shippingMethodPriceRanges.addPriceRange')}
            </CreateButton>
            <Table {...postTableProps}>
                <Table.Column
                    dataIndex="from_weight"
                    align='center'
                    title={t('shippingMethodPriceRanges.fields.from')}
                    render={(value) => `${value} Kg`}
                />
                <Table.Column
                    dataIndex="to_weight"
                    align='center'
                    title={t('shippingMethodPriceRanges.fields.to')}
                    render={(value) => `${value} Kg`}
                />
                <Table.Column
                    dataIndex="price"
                    align='center'
                    title={t('shippingMethodPriceRanges.fields.price')}
                    render={(value) => currency(value)}
                />
                <Table.Column<definitions['shipping_method_price_ranges']>
                    dataIndex="shipping_method_price_ranges_actions"
                    title={t('table.actions')}
                    align='center'
                    render={(_, record) => (
                      <Space>
                        <EditButton
                          onClick={() => editShow(record.id)}
                          hideText
                        />
                        <ButtonLogicDelete
                          click={() => {
                            show()
                            setId(record.id)
                          }}
                        />
                        <DeleteModal
                          modalProps={modalProps}
                          close={close}
                          id={id}
                          entity="shipping_method_price_ranges"
                          tableQueryResult={tableQueryResult}
                        />
                      </Space>
                    )}
                />
            </Table>
            <EditPriceRange
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
            <CreatePriceRange
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
            />
        </List>
  )
}
