import { useState } from 'react'
import { IResourceComponentsProps } from '@pankod/refine-core'

import {
  Create,
  Form,
  Input,
  Upload,
  useForm,
  RcFile
} from '@pankod/refine-antd'

import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'

import 'react-mde/lib/styles/css/react-mde-all.css'

import { definitions } from 'interfaces'
import { supabaseClient, normalizeFile } from 'utility'

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<definitions['users']>()

  const [selectedTab, setSelectedTab] =
        useState<'write' | 'preview'>('write')

  return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="categoryId"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                >
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                >
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                          Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>
                          )
                        }
                    />
                </Form.Item>
                <Form.Item label="Images">
                    <Form.Item
                        name="images"
                        valuePropName="fileList"
                        normalize={normalizeFile}
                        noStyle
                    >
                        <Upload.Dragger
                            name="file"
                            listType="picture"
                            multiple
                            customRequest={async ({
                              file,
                              onError,
                              onSuccess
                            }) => {
                              try {
                                const rcFile = file as RcFile
                                await supabaseClient.storage
                                  .from('refine')
                                  .upload(`public/${rcFile.name}`, file, {
                                    cacheControl: '3600',
                                    upsert: true
                                  })

                                const { data } =
                                        await supabaseClient.storage
                                          .from('refine')
                                          .getPublicUrl(
                                                `public/${rcFile.name}`
                                          )

                                const xhr = new XMLHttpRequest()
                                onSuccess &&
                                        onSuccess(
                                          { url: data?.publicURL },
                                          xhr
                                        )
                              } catch (error) {
                                onError &&
                                        onError(new Error('Upload Error'))
                              }
                            }}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
            </Form>
        </Create>
  )
}
