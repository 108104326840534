import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'

import {
  List,
  Table,
  useTable,
  Space,
  useEditableTable,
  Form,
  SaveButton,
  Button,
  Input,
  Dropdown,
  Icons,
  Menu,
  Grid,
  useDrawerForm,
  InputNumber,
  CreateButton,
  Radio,
  BooleanField,
  FilterDropdownProps,
  FilterDropdown,
  useModal,
  Select,
  Typography
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { EditCms } from 'components/cms/edit'
import { CreateCms } from 'components/cms/create'
import { CreateCmsCategory } from './create'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { VOLUMETRIC_CALCULATOR, supabaseClient } from 'utility'

export const CmsCategoryList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    setId: setEditId,
    filters,
    setFilters,
    tableQueryResult
  } = useEditableTable<definitions['cms_categories']>({
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    initialSorter: [
      {
        field: 'weigth',
        order: 'asc'
      }
    ]
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['cms_categories']>({
    action: 'create',
    resource: 'cms_categories',
    redirect: false
  })

  const t = useTranslate()
  const [id, setId] = useState(0)
  const [selectedButtons, setSelectedButtons] = useState({})
  const [name, setName] = useState<string>()
  const [weigth, setWeigth] = useState<any>()
  const [status, setStatus] = useState<boolean>()
  const { modalProps, show, close } = useModal()

  async function saveSelectedButtons (cmsConfigName: string) {
    const { data, error } = await supabaseClient.from('cms_categories')
      .update({
        cms_config: selectedButtons,
        name: name,
        weigth: weigth,
        is_in_home: status
      })
      .eq('name', cmsConfigName)
    if (data) {
      setEditId(undefined)
      tableQueryResult.refetch()
    } else if (error) {
      return null
    }
  }

  const moreMenu = (record: definitions['cms_categories']) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <Menu.Item
        key="accept"
        style={{ fontSize: 15, display: 'flex', alignItems: 'center', fontWeight: 500 }}
        icon={<Icons.FormOutlined style={{ color: '#52c41a', fontSize: 17, fontWeight: 500 }} />}
        onClick={() => {
          setEditId?.(record.id)
          setSelectedButtons(record.cms_config as {buttons: string[]})
          setName(record.name)
          setWeigth(record.weigth)
          setStatus(record.is_in_home)
        }}
      >
        {t('buttons.edit')}
      </Menu.Item>
      <Menu.Item
        key="accept"
        style={{ fontSize: 17, display: 'flex', alignItems: 'center', fontWeight: 500 }}>
        <ButtonLogicDelete click={() => {
          show()
          setId(record.id)
        }} />
        <DeleteModal modalProps={modalProps} close={close} id={id} entity='cms_categories' tableQueryResult={tableQueryResult} />
        {t('buttons.delete')}
      </Menu.Item>
    </Menu>
  )

  const parseButtonsConfig = (config: any) => {
    const buttons = config?.buttons || ''
    if (buttons.length > 0) return buttons.map((buttonName: string) => t(`cmsCategories.buttons.${buttonName}`))
    else return ''
  }

  const breakpoint = Grid.useBreakpoint()

  return (
    <List title={t('cmsCategories.titles.list')}>
      <CreateButton onClick={() => createShow()} style={{ marginTop: -50, float: 'right' }}>
        {t('cmsCategories.addCategory')}
      </CreateButton>
      <Form {...formProps}>
        <Table
          {...tableProps}
          expandable={{ expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined }}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}>
            <Table.Column
              key={id}
              dataIndex="id"
              title={t('cmsCategories.fields.id')}
              align='center'
              render={(value) => value}
              sorter
            />
            <Table.Column
              key={id}
              dataIndex="name"
              title={t('cmsCategories.fields.name')}
              align='center'
              render={(value, data: definitions['cms_categories']) => {
                if (isEditing(data.id)) {
                  return (
                    <Form.Item name="name" style={{ margin: 0 }} >
                      <Input value={data.name} onChange={(e) => setName(e.target.value)} />
                    </Form.Item>
                  )
                }
                return value
              }}
              sorter
              filterDropdown={(props: FilterDropdownProps) => (
                <SelectFilterDropdown
                  props={props}
                  entity='cms_categories'
                  optionLabel='name'
                  optionValue='id'
                  filters={[{
                    field: 'deleted',
                    operator: 'eq',
                    value: false
                  }]}
                />
              )}
            />
            <Table.Column
              key="weigth"
              dataIndex="weigth"
              title={t('cmsCategories.fields.weigth')}
              align='center'
              render={(value, data: definitions['cms_categories']) => {
                if (isEditing(data.id)) {
                  return (
                    <Form.Item name="weigth" style={{ margin: 0 }}>
                      <InputNumber onChange={(e) => setWeigth(e)} />
                    </Form.Item>
                  )
                }
                return value
              }}
              sorter
              filterDropdown={() => (
                <QuantityFilterDropdown
                  field='weigth'
                  filters={filters}
                  setFilters={setFilters}
                />
              )}
            />
            <Table.Column
              key="is_in_home"
              dataIndex="is_in_home"
              title={t('marketplace.fields.isInHome')}
              align='center'
              render={(value, data: definitions['cms_categories']) => {
                if (isEditing(data.id)) {
                  return (
                    <Form.Item
                      name="is_in_home"
                      style={{ margin: 0 }}
                    >
                      <Radio.Group onChange={(e) => setStatus(e.target.value)}>
                        <Radio value={true}>{t('status.yes')}</Radio>
                        <Radio value={false}>{t('status.no')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )
                }
                return (
                  <BooleanField
                    value={value}
                    valueLabelTrue={t('status.yes')}
                    valueLabelFalse={t('status.no')}
                  />
                )
              }}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown {...props}>
                  <Radio.Group>
                    <Radio value='true'>{t('status.yes')}</Radio>
                    <Radio value='false'>{t('status.no')}</Radio>
                  </Radio.Group>
                </FilterDropdown>
              )}
            />
            {}
            <Table.Column
              key="cms_config"
              dataIndex="cms_config"
              title={t('cmsCategories.fields.cmsConfig')}
              align='center'
              width={15}
              render={(value, data: definitions['cms_categories']) => {
                if (isEditing(data.id)) {
                  return (
                    <Form.Item name="cms_config" style={{ margin: 0 }} >
                      <Select
                        mode='multiple'
                        options={[{ label: t('Calculadora'), value: VOLUMETRIC_CALCULATOR }]}
                        onChange={(e) => { setSelectedButtons({ buttons: e }) }}
                      />
                    </Form.Item>
                  )
                }
                return (
                  <Typography>
                    {/* {JSON.stringify(data?.cms_config).replaceAll('"', '').replaceAll('[', '').replaceAll('{', '').replaceAll(']', '').replaceAll('}', '')} */}
                    {parseButtonsConfig(data?.cms_config)}
                  </Typography>
                )
              }}
            />
            <Table.Column<definitions['cms_categories']>
              title={t('table.actions')}
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_text, record) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton
                        {...saveButtonProps}
                        size="small"
                        onClick={async () => { await saveSelectedButtons(record.name) }}
                      />
                      <Button {...cancelButtonProps} size="small" >
                        {t('buttons.cancel')}
                      </Button>
                    </Space>
                  )
                }
                return (
                  <Dropdown overlay={moreMenu(record)} trigger={['click']} >
                    <Icons.MoreOutlined
                      onClick={(e) => e.stopPropagation()}
                      style={{ fontSize: 24 }}
                    />
                  </Dropdown>
                )
              }}
            />
        </Table>
      </Form>
      <CreateCmsCategory drawerProps={createDrawerProps} formProps={createFormProps} saveButtonProps={createSaveButtonProps} />
    </List>
  )
}

const CmsTable: React.FC<{ record: definitions['cms_categories'] }> = ({ record }) => {
  const t = useTranslate()

  const { tableProps: postTableProps, filters, setFilters, tableQueryResult } = useTable<definitions['cms']>({
    resource: 'cms',
    permanentFilter: [
      {
        field: 'category_id',
        operator: 'eq',
        value: record.id
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    initialSorter: [
      {
        field: 'weigth',
        order: 'asc'
      }
    ],
    syncWithLocation: false
  })

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<definitions['cms']>({
    action: 'edit',
    resource: 'cms',
    redirect: false
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['cms']>({
    action: 'create',
    resource: 'cms',
    redirect: false
  })

  createFormProps.initialValues = { category_id: record.id }

  const [id, setId] = useState(0)
  const { modalProps, show, close } = useModal()
  const moreMenu = (record: definitions['cms']) => (
    <Menu mode="vertical" onClick={({ domEvent }) => domEvent.stopPropagation()}>
      <Menu.Item
        key="edit"
        style={{
          fontSize: 15,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500
        }}
        icon={<Icons.FormOutlined
          style={{
            color: '#52c41a',
            fontSize: 17,
            fontWeight: 500
          }}
        />}
        onClick={() => {
          editShow(record.id)
        }}
      >
        {t('buttons.edit')}
      </Menu.Item>
      <Menu.Item
        key="accept"
        style={{
          fontSize: 15,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500
        }}>
        <ButtonLogicDelete click={() => {
          show()
          setId(record.id)
        }} />
        <DeleteModal
          modalProps={modalProps}
          close={close}
          id={id}
          entity='cms'
          tableQueryResult={tableQueryResult}
        />
        {t('buttons.delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <List title={t('cms.cms')} createButtonProps={undefined}>
      <CreateButton onClick={() => createShow()} style={{ marginTop: -50, float: 'right' }}>
        {t('cms.addCms')}
      </CreateButton>
      <Table {...postTableProps} rowKey="id">
        <Table.Column
          key="id"
          dataIndex="id"
          title={t('cms.fields.id')}
          sorter
        />
        <Table.Column
          dataIndex="name"
          title={t('cms.fields.name')}
          sorter
          key="id"
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='cms'
              optionLabel='name'
              optionValue='id'
              filters={[
                {
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                },
                {
                  field: 'category_id',
                  operator: 'eq',
                  value: record.id
                }
              ]}
            />
          )}
        />
        <Table.Column
          key="content"
          dataIndex="content"
          title={t('cms.fields.content')}
        />
        <Table.Column
          key="is_in_home"
          dataIndex="is_in_home"
          title={t('marketplace.fields.isInHome')}
          align='center'
          render={(value) => (
            <BooleanField
              value={value}
              valueLabelTrue={t('status.yes')}
              valueLabelFalse={t('status.no')}
            />
          )}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Radio.Group>
                <Radio value='true'>{t('status.yes')}</Radio>
                <Radio value='false'>{t('status.no')}</Radio>
              </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="weigth"
          dataIndex="weigth"
          title={t('cmsCategories.fields.weigth')}
          align='center'
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='weigth'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column
          key="cms_parent"
          dataIndex="cms_parent"
          title={t('cmsCategories.fields.cms_parent')}
          align='center'
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='cms_parent'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column<definitions['cms']>
          dataIndex="cms_actions"
          title={t('table.actions')}
          render={(_, record) => (
            <Dropdown overlay={moreMenu(record)} trigger={['click']}>
              <Icons.MoreOutlined
                onClick={(e) => e.stopPropagation()}
                style={{ fontSize: 24 }}
              />
            </Dropdown>
          )}
        />
      </Table>
      <EditCms
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateCms
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </List>
  )
}

const expandedRowRender = (record: definitions['cms_categories']) => {
  return <CmsTable record={record} />
}
