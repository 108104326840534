import React, { useState } from 'react'
import { IResourceComponentsProps } from '@pankod/refine-core'
import {
  Alert,
  Button,
  Edit,
  Form,
  ListButton,
  RefreshButton,
  useForm
} from '@pankod/refine-antd'
import { definitions, IInput } from 'interfaces'
import { FormItems } from 'components/FormItems/FormItems'
import { useTranslation } from 'react-i18next'

import 'react-mde/lib/styles/css/react-mde-all.css'

export const IslandEdit: React.FC<IResourceComponentsProps> = () => {
  const [isDeprecated, setIsDeprecated] = useState(false)
  const { t } = useTranslation()
  const { formProps, saveButtonProps, queryResult } = useForm<definitions['islands']>({
    liveMode: 'manual',
    onLiveEvent: () => {
      setIsDeprecated(true)
    }
  })

  const handleRefresh = () => {
    queryResult?.refetch()
    setIsDeprecated(false)
  }

  const inputs: IInput[] = [
    {
      label: t('islands.fields.name'),
      name: 'name',
      required: true
    },
    {
      label: t('islands.fields.province'),
      name: 'province',
      required: true,
      select: [
        { label: 'Las Palmas', value: 'Las Palmas' },
        { label: 'Santa Cruz de Tenerife', value: 'Santa Cruz de Tenerife' }
      ]
    },
    {
      label: t('islands.fields.cpPrefixes'),
      name: 'cp_prefixes',
      required: true,
      help: t('islands.fields.help')
    },
    {
      label: t('islands.fields.planeExtraCost'),
      name: 'plane_extra_cost',
      required: true,
      number: {
        money: true,
        min: 0
      }
    },
    {
      label: t('islands.fields.boatExtraCost'),
      name: 'boat_extra_cost',
      required: true,
      number: {
        money: true,
        min: 0
      }
    }
  ]

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton />
            <RefreshButton onClick={handleRefresh} />
          </>
        )
      }}
    >
      {isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={{
            marginBottom: 20
          }}
          action={
            <Button
              onClick={handleRefresh}
              size="small"
              type="ghost"
            >
              Refresh
            </Button>
          }
        />
      )}

      <Form {...formProps} layout="vertical">
        {inputs.map((input, keyIndex) => {
          return FormItems(input, keyIndex)
        })}
      </Form>
    </Edit>
  )
}
