import React from 'react'
import { useLogin, useTranslate } from '@pankod/refine-core'
import {
  Row,
  Col,
  AntdLayout,
  Card,
  // Typography,
  Form,
  Input,
  Button,
  // notification,
  Checkbox
} from '@pankod/refine-antd'
// import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import './styles.css'
import { Logo } from 'components/logo'

// const { Text } = Typography
export interface ILoginForm {
  username?: string;
  password?: string;
  remember?: boolean;
  provider?: 'google' | 'facebook';
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>()
  const t = useTranslate()

  const { mutate: login } = useLogin<ILoginForm>()

  // const [googleLoading, setGoogleLoading] = React.useState(false)

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh'
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <Logo />
            </div>
            <Card headStyle={{ borderBottom: 0 }} style={{ marginBottom: '12px' }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values)
                  return Promise.resolve()
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  email: '',
                  password: ''
                }}
              >
                <Form.Item
                  name="email"
                  label={t('pages.login.fields.email')}
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input size="large" placeholder={t('pages.login.fields.email')} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t('pages.login.fields.password')}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input size="large" type="password" placeholder="●●●●●●●●" />
                </Form.Item>
                <div style={{ marginBottom: '12px' }}>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox
                      style={{
                        fontSize: '12px'
                      }}
                    >
                      {t('pages.login.buttons.rememberMe')}
                    </Checkbox>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {t('pages.login.signin')}
                </Button>
              </Form>
            </Card>
            {/* DISABLED FOR NOW...
             <Button
              block
              style={{ marginBottom: 8 }}
              type="primary"
              size='large'
              icon={<GoogleOutlined />}
              loading={googleLoading}
              onClick={() => {
                setGoogleLoading(true)
                login({ provider: 'google' })
              }}
            >Login with Google</Button>
            <Button
              block
              style={{ marginBottom: 8 }}
              type="primary"
              size='large'
              icon={<FacebookOutlined />}
              loading={googleLoading}
              onClick={() => {
                setGoogleLoading(true)
                login({ provider: 'facebook' })
              }}
            >Login with Facebook</Button> */}
          </div>
        </Col>
      </Row>
    </AntdLayout>
  )
}
