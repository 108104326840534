import { useTranslate } from '@pankod/refine-core'

import {
  DrawerProps,
  FormProps,
  Create,
  Drawer,
  Form,
  Input,
  Grid,
  Row,
  Col,
  ButtonProps,
  InputNumber,
  Checkbox
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'

type ItemCategoriesProps = {
  drawerProps: DrawerProps
  saveButtonProps: ButtonProps
  formProps: FormProps
  close: Function
  isEdit: boolean
};

export const CreateItemCategories: React.FC<ItemCategoriesProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
  close,
  isEdit
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [applyIVA, setApplyIVA] = useState(false)

  useEffect(() => {
    if (isEdit && formProps && formProps.initialValues !== undefined) {
      setApplyIVA(formProps.initialValues.apply_iva)
    }
  }, [formProps.initialValues])

  useEffect(() => {
    if (formProps && formProps.form) {
      formProps.form.setFields([
        {
          name: ['apply_iva'],
          value: applyIVA
        }
      ])
    }
  }, [applyIVA])

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="item_categories"
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        goBack={false}
        title={(isEdit) ? t('tariff.actions.editTariff') : t('tariff.actions.addTariff')}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={[64, 0]} wrap>
            <Col xs={10} lg={10}>
              <Form.Item
                name="code"
                label={t('tariff.code')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label={t('tariff.description')}
          >
            <Input/>
          </Form.Item>
          <Row gutter={[64, 0]} wrap>
            <Col xs={7} lg={7}>
              <Form.Item
                name="iva_percent"
                label={t('tariff.iva_percent')}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={7} lg={7}>
              <Form.Item
                name="apply_iva"
                label={t('tariff.apply_iva')}
              >
                <Checkbox onChange={(e: any) => setApplyIVA(e.target.checked)} checked={applyIVA} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={7} lg={7}>
              <Form.Item
                name="igic_percent"
                label={t('tariff.igic_percent')}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={7} lg={7}>
              <Form.Item
                name="aiem_percent"
                label={t('tariff.aiem_percent')}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
