import {
  Edit,
  Drawer,
  DrawerProps,
  FormProps,
  ButtonProps,
  Grid
} from '@pankod/refine-antd'
import { ChildCategoryForm } from './form'

type EditChildCategoryProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const EditChildCategory: React.FC<EditChildCategoryProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource='marketplace_categories'
      >
        <ChildCategoryForm formProps={formProps} />
      </Edit>
    </Drawer>
  )
}
