import { useTranslate } from '@pankod/refine-core'

import {
  DrawerProps,
  FormProps,
  Checkbox,
  Create,
  Drawer,
  Form,
  Input,
  Grid,
  Row,
  Col,
  ButtonProps
  // Select
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
// import { definitions } from 'interfaces'

type CreateCodeProps = {
  drawerProps: DrawerProps
  saveButtonProps: ButtonProps
  formProps: FormProps
  close: Function
  isEdit: boolean
};

export const CreateSupplier: React.FC<CreateCodeProps> = ({
  drawerProps,
  saveButtonProps,
  formProps,
  close,
  isEdit
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [verified, setVerified] = useState<boolean>(false)

  useEffect(() => {
    setVerified(formProps?.initialValues?.verified)
  }, [formProps.initialValues])

  // const { options } = useSelect<definitions['supplier_info']>({
  //   resource: 'supplier_info',
  //   fetchSize: 1000,
  //   optionLabel: 'description',
  //   optionValue: 'id',
  //   filters: [{ field: 'deleted', operator: 'eq', value: false }]
  // })

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="suppliers"
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        goBack={false}
        title={(isEdit) ? t('suppliers.actions.editSupplier') : t('suppliers.actions.addSupplier')}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={[64, 0]} wrap>
            <Col xs={8} lg={8}>
              <Form.Item
                name="cif"
                label={t('suppliers.fields.CIF')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={16} lg={16}>
              <Form.Item
                name="legal_name"
                label={t('suppliers.fields.legalName')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="legal_address"
            label={t('suppliers.fields.legalAddress')}
          >
            <Input />
          </Form.Item>
          <Row gutter={[64, 0]} wrap>
            <Col xs={8} lg={8}>
              <Form.Item
                name="country"
                label={t('suppliers.fields.country')}
                >
                  <Input />
              </Form.Item>
            </Col>

            <Col xs={8} lg={8}>
              <Form.Item
                name="province"
                label={t('suppliers.fields.province')}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={8} lg={8}>
              <Form.Item
                name="postal_code"
                label={t('suppliers.fields.postalCode')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[64, 0]} wrap>
            <Col xs={8} lg={8}>
              <Form.Item
                name="verified"
                label={t('suppliers.fields.verified')}
              >
                <Checkbox
                  onChange={(e: any) => {
                    setVerified(e.target.checked)
                    formProps?.form?.setFields([
                      {
                        name: ['verified'],
                        value: e.target.checked
                      }
                    ])
                  }}
                  checked={verified}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={16} lg={16}>
              <Form.Item
                name="supplier_info_id"
                label={t('suppliers.fields.supplier_info_id')}
              >
                <Select options={options} />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
