import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useEffect } from 'react'

export const SupersetDashboard: React.FC<any> = ({ dashboardId, url, granterUrl, granterToken }) => {
  const fetchGuestToken = async (): Promise<string> => {
    try {
      const result = await fetch(`${granterUrl}?dashboardId=${dashboardId}`,
        { headers: { Authorization: `Bearer ${granterToken}` } })

      const data = await result.json()
      return data?.guestToken
    } catch (e) {
      console.error(e)
    }
    return ''
  }

  useEffect(() => {
    const containerDiv = document.getElementById('superset-embedded-container')!

    const embedDashboardAsync = async () => {
      try {
        await embedDashboard({
          id: dashboardId,
          supersetDomain: url,
          mountPoint: containerDiv,
          fetchGuestToken: () => fetchGuestToken(),
          dashboardUiConfig: {
            filters: {
              expanded: true
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    embedDashboardAsync()
  }, [])

  useEffect(() => {
    const containerDiv = document.getElementById('superset-embedded-container')!
    const iframe = containerDiv.querySelector('iframe')!
    iframe.width = '100%'
    iframe.height = '100%'
  })

  return <>
    <style>
      {`
        #superset-embedded-container iframe {
          border: none;
        }
      `}
    </style>
    <div id="superset-embedded-container" style={{ height: 'calc(100vh - 120px)' }} />
  </>
}
