import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import {
  Create,
  Form,
  Input,
  useForm
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'

import 'react-mde/lib/styles/css/react-mde-all.css'

export const PaymentMethodsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<definitions['payment_methods']>()

  const t = useTranslate()

  return (
    <Create saveButtonProps={saveButtonProps} title={t('payment_methods.titles.create')}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('payment_methods.fields.name')}
          name='name'
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
