import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  ButtonProps,
  Grid,
  Create,
  Edit,
  InputNumber,
  Row,
  Col,
  Select
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility/supabaseClient'
import { QueryObserverResult } from '@tanstack/react-query'
import { MAX_IGIC_VALUE, SHIPPING_METHOD_ECONOMY_ID, SUPPLEMENT_PREDEFINED_OPTIONS, calcIgicPrice, calcTariffPrice, calculateOrderPrices, priceWithoutIva } from 'utility'
import { Order } from 'interfaces'
import { calculateItemSups } from 'utility/orderCalculator'

type ItemsActionsProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
  closeDrawer: Function
  tableQueryResult: QueryObserverResult
  orderQuery: QueryObserverResult
  refetchSup?: Function
  orderId: number
  userEmail: string
  marketplaceId: number
  edit?: boolean
  replace?: boolean
  itemCategories?: any
  order: Order
}

export const ItemsActions: React.FC<ItemsActionsProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  closeDrawer,
  tableQueryResult,
  edit = false,
  replace = false,
  orderId,
  userEmail,
  orderQuery,
  refetchSup,
  marketplaceId,
  itemCategories,
  order
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  let options: any = [{ label: '-', value: null }]
  options = [...options, ...SUPPLEMENT_PREDEFINED_OPTIONS.filter((o: string) => o.includes('reduced')).map((o: string) => {
    return { label: t(`supplement.descriptions.${o}`), value: o || null }
  })]

  const [url, setUrl] = useState('')
  const [image, setImage] = useState('')
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(0)
  const [iva, setIva] = useState((typeof order?.marketplace_id?.iva_percent === 'number' && order?.marketplace_id?.iva_percent >= 0 && order?.shipping_method_id !== SHIPPING_METHOD_ECONOMY_ID) ? order.marketplace_id.iva_percent : 21)
  // const [iva, setIva] = useState(21)
  const [tariffPercent, setTariffPercent] = useState(0)
  const [tariffPrice, setTariffPrice] = useState(0)
  const [igicPercent, setIgicPercent] = useState(0)
  const [igicPrice, setIgicPrice] = useState(0)
  const [itemCategoryId, setItemCategoryId] = useState(null)
  const [synivaPrice, setSynivaPrice] = useState(0)
  const [size, setSize] = useState('')
  const [color, setColor] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [supplementType, setSupplementType] = useState<string | null>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const formData = formProps.initialValues
  let prevItem: any

  const itemId = Number.isInteger(formData?.id) && formData?.id

  if (edit || replace) {
    useEffect(() => {
      setUrl(formData?.url || '')
      setImage(formData?.image || '')
      setCode(formData?.code || '')
      setName(formData?.name || '')
      setDescription(formData?.description || '')
      setPrice(formData?.price || 0)
      setIva((replace && order?.shipping_method_id === SHIPPING_METHOD_ECONOMY_ID) ? 0 : (typeof order?.marketplace_id?.iva_percent === 'number' && order?.marketplace_id?.iva_percent >= 0 && order?.shipping_method_id !== SHIPPING_METHOD_ECONOMY_ID) ? order.marketplace_id.iva_percent : (typeof formData?.iva === 'number') ? formData?.iva : 21)
      // setIva((typeof formData?.iva === 'number') ? formData?.iva : 21)
      setTariffPercent(formData?.tariff_percent || 0)
      setTariffPrice(formData?.tariff_price || 0)
      setIgicPercent(formData?.igic_percent || 0)
      setIgicPrice(formData?.igic_price || 0)
      setSynivaPrice(formData?.syniva_price || 0)
      setSize(formData?.size || '')
      setColor(formData?.color || '')
      setQuantity(formData?.quantity || 1)
      setItemCategoryId(formData?.item_category_id)
      setSupplementType(formData?.supplement_type || null)
    }, [formData])

    if (replace) {
      prevItem = formProps.initialValues
    }
  }

  useEffect(() => {
    const synivaPrice = priceWithoutIva(price, iva)
    setSynivaPrice(synivaPrice)
  }, [price, iva])

  useEffect(() => {
    const newTariffPrice = calcTariffPrice(quantity, synivaPrice, tariffPercent)
    setTariffPrice(newTariffPrice)
  }, [quantity, tariffPercent, synivaPrice])

  useEffect(() => {
    const shipMode = order.shipping_method?.shipMode || false
    let currentTotalPrice = Number(order.items?.filter((i) => i.id !== itemId && i.disabled === false).map((i) => (shipMode) ? i.price * i.quantity : (i.syniva_price || 0) * i.quantity || 0).reduce((a, b) => a + b, 0).toFixed(2) || 0)
    currentTotalPrice += (shipMode) ? price * quantity : synivaPrice * quantity
    const newIgicPrice = calcIgicPrice(quantity, (shipMode) ? price : synivaPrice, igicPercent)
    setIgicPrice((currentTotalPrice > MAX_IGIC_VALUE) ? newIgicPrice : 0)
  }, [quantity, igicPercent, synivaPrice])

  const states = {
    order_id: orderId,
    marketplace_id: marketplaceId,
    user_email: userEmail,
    url: url,
    image: image,
    code: code,
    name: name,
    description: description,
    price: price,
    iva: iva,
    tariff_percent: tariffPercent,
    tariff_price: tariffPrice,
    igic_percent: igicPercent,
    igic_price: igicPrice,
    syniva_price: synivaPrice,
    size: size,
    color: color,
    quantity: quantity,
    shipping_price_to_madrid: null,
    item_category_id: itemCategoryId,
    supplement_type: supplementType || null
  }

  const calculateItems = async (replace?: boolean) => {
    await calculateItemSups(formProps, order, orderId, price, iva, quantity, supplementType, refetchSup, replace, false)
  }

  const insertItem = async () => {
    setLoading(true)

    await supabaseClient.from('items').insert([states])

    await calculateOrderPrices(orderId)
    await calculateItems()

    tableQueryResult.refetch()
    orderQuery.refetch()
    setLoading(false)
    closeDrawer()
  }

  const updateItem = async () => {
    setLoading(true)
    if (formProps.initialValues) {
      states.shipping_price_to_madrid = formProps.initialValues.shipping_price_to_madrid || null
    }

    await supabaseClient.from('items').update(states).eq('id', itemId)

    await calculateOrderPrices(orderId)
    await calculateItems()

    tableQueryResult.refetch()
    orderQuery.refetch()
    setLoading(false)
    closeDrawer()
  }

  const replaceItem = async () => {
    setLoading(true)

    states.shipping_price_to_madrid = prevItem.shipping_price_to_madrid || null
    states.item_category_id = itemCategoryId || prevItem.item_category_id || null
    states.supplement_type = prevItem.supplement_type || null

    await supabaseClient.from('items').insert([states])

    prevItem.disabled = true
    await supabaseClient.from('items').update(prevItem).eq('id', prevItem.id)

    await calculateOrderPrices(orderId)
    await calculateItems(true)

    tableQueryResult.refetch()
    orderQuery.refetch()
    setLoading(false)
    closeDrawer()
  }

  const Action: any = (replace) ? Create : (edit) ? Edit : Create

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      {Number.isInteger(formData?.order_id) && (
        <Action
          resource="items"
          saveButtonProps={saveButtonProps}
          isLoading={loading}
          breadcrumb={false}
          goBack={false}
          title={(replace) ? t('item.titles.replace') : (edit) ? t('item.titles.edit') : t('item.titles.create')}
          headerButtons={() => (<></>)}
        >
          <Form
            {...formProps}
            layout="vertical"
            onFinish={(replace) ? replaceItem : (edit) ? updateItem : insertItem}
          >
            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={5}>
                <Form.Item
                  label={t('shipments.fields.order_id')}
                  name="order_id"
                >
                  <InputNumber readOnly style={{ backgroundColor: '#eee' }} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={14}>
                <Form.Item
                  label={t('order.fields.userEmail')}
                  name="user_email"
                >
                  <Input readOnly style={{ backgroundColor: '#eee' }} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={5}>
                <Form.Item
                  label={t('item.fields.marketplaceId')}
                  name="marketplace_id"
                >
                  {edit ? (<InputNumber readOnly style={{ backgroundColor: '#eee' }} />) : (<InputNumber />)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('item.fields.image')}
              name="image"
              getValueProps={(value) => value}
            >
              <Input value={image} onChange={(event) => setImage(event.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('item.fields.url')}
              name="url"
              getValueProps={(value) => value}
            >
              <Input value={url} onChange={(event) => setUrl(event.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('item.fields.code')}
              name="code"
              getValueProps={(value) => value}
            >
              <Input value={code} onChange={(event) => setCode(event.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('item.fields.name')}
              name="name"
              getValueProps={(value) => value}
            >
              <Input value={name} onChange={(event) => setName(event.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('item.fields.description')}
              name="description"
              getValueProps={(value) => value}
            >
              <Input.TextArea value={description} onChange={(event) => setDescription(event.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('item.fields.price')}
              name="price"
              getValueProps={(value) => value}
            >
              <InputNumber value={price} onChange={(value) => setPrice(value ?? 0)} min={0} addonAfter='€' decimalSeparator=',' />
            </Form.Item>
            <Row gutter={[64, 0]} wrap>
              <Col xs={12} lg={8}>
                <Form.Item
                  label={t('item.fields.iva')}
                  name="iva"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={iva} onChange={(value) => {
                    setIva(value ?? 21)
                    setPrice(price ?? 0)
                  }} min={0} addonAfter='%' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                <Form.Item
                  label={t('order.fields.synivaPrice')}
                  name="syniva_price"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={synivaPrice} readOnly min={0} addonAfter='€' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={18} lg={18}>
                {<Form.Item
                  label={t('item.fields.supplementType')}
                  name="supplement_type"
                  getValueProps={(value) => value}
                >
                  <Select
                    options={options}
                    getPopupContainer={trigger => trigger.parentElement}
                    value={supplementType}
                    onChange={(value: string) => setSupplementType(value)}
                  />
                </Form.Item>}
              </Col>
              <Col xs={12} lg={8}>
                <Form.Item
                  label={t('item.fields.tariffPercent')}
                  name="tariffPercent"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={tariffPercent} onChange={(value) => {
                    setTariffPercent(value ?? 21)
                  }} min={0} addonAfter='%' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                <Form.Item
                  label={t('item.fields.tariffPrice')}
                  name="tariff_price"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={tariffPrice} readOnly min={0} addonAfter='€' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={12} lg={8}>
                <Form.Item
                  label={t('item.fields.igicPercent')}
                  name="igicPercent"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={igicPercent} onChange={(value) => {
                    setIgicPercent(value ?? 21)
                  }} min={0} addonAfter='%' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={12} lg={12}>
                <Form.Item
                  label={t('item.fields.igicPrice')}
                  name="igic_price"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={igicPrice} readOnly min={0} addonAfter='€' decimalSeparator=',' />
                </Form.Item>
              </Col>
              <Col xs={24} lg={18}>
                <Form.Item
                  name='item_category_id'
                  label={t('item.fields.item_category')}
                  style={{ marginBottom: 30 }}
                >
                  <Select
                    onChange={async (value: any) => {
                      const { data: itemCatSelected } = await supabaseClient.from('item_categories').select('id, apply_iva, igic_percent, iva_percent, aiem_percent').eq('id', value).single()
                      if (itemCatSelected) {
                        setItemCategoryId(itemCatSelected.id)
                        setTariffPercent(itemCatSelected.aiem_percent)
                        setIgicPercent(itemCatSelected.igic_percent)
                        if (itemCatSelected.apply_iva) setIva(itemCatSelected.iva_percent)
                      }
                    }}
                    showSearch
                    options={itemCategories}
                    filterOption={(input, option: any) => !!option?.label?.toLocaleLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={18}>
                <Form.Item
                  label={t('item.fields.size')}
                  name="size"
                  getValueProps={(value) => value}
                >
                  <Input value={size} onChange={(event) => setSize(event.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item
                  label={t('item.fields.quantity')}
                  name="quantity"
                  getValueProps={(value) => value}
                >
                  <InputNumber value={quantity} onChange={(value) => setQuantity(value ?? 1)} min={1} decimalSeparator=',' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('item.fields.color')}
              name="color"
              getValueProps={(value) => value}
            >
              <Input value={color} onChange={(event) => setColor(event.target.value)} />
            </Form.Item>
          </Form>
        </Action>
      )}
    </Drawer>
  )
}
