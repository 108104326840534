import {
  ButtonProps,
  Create,
  Drawer,
  DrawerProps,
  FormProps,
  Grid
} from '@pankod/refine-antd'
import { BannersForm } from './form'

type BannerCreateProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
}

export const BannerCreateDrawer: React.FC<BannerCreateProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={saveButtonProps}
        headerButtons={() => (<></>)}
      >
        <BannersForm
          formProps={formProps}
        />
      </Create>
    </Drawer>
  )
}
