import { Button, Icons, Input, List, Space, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility'

type ExtraInfoMarketplacesProps = {
  extraInfo?: any,
  id: number,
  showInternalShopId?: number | undefined
  customShopData?: any | null
}

export const ExtraInfoMarkeplaces = ({ extraInfo, id, showInternalShopId, customShopData }: ExtraInfoMarketplacesProps) => {
  const t = useTranslate()

  const [agregationList, setAgregationList] = useState<any>()
  const [inputValue, setInputValue] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [valueRelatedShops, setValueRelatedShops] = useState<any>(null)
  const [internalShopExtra, setinternalShopExtra] = useState<any>(null)

  const retrieveInternalShopExtraInfo = async () => {
    const { data } = await supabaseClient.from('marketplaces')
      .select('id, name, extra_info')
      .eq('deleted', false)
      .eq('id', showInternalShopId)
      .single()

    if (data) {
      const extra = {
        ...data,
        extra_info: (typeof data.extra_info === 'object') ? data.extra_info?.info || [] : JSON.parse(data.extra_info).info
      }
      setinternalShopExtra(extra)
    }
  }

  useEffect(() => {
    if (extraInfo !== '' && extraInfo !== null && extraInfo !== undefined) {
      if (typeof extraInfo === 'object') {
        setAgregationList(extraInfo.info || [])
      } else {
        const infoList = JSON.parse(extraInfo)
        setAgregationList(infoList?.info || [])
      }
    } else {
      setAgregationList([])
    }
    if (customShopData) {
      if (customShopData.internal) {
        if (customShopData.internal[0] && customShopData.internal[0].extra_info !== '' && customShopData.internal[0].extra_info !== null) {
          if (typeof customShopData.internal[0].extra_info === 'object') {
            setAgregationList(customShopData.internal[0].extra_info.info || [])
          } else {
            const infoList = JSON.parse(customShopData.internal[0].extra_info)
            setAgregationList(infoList?.info || [])
          }
        } else if (typeof customShopData === 'object') {
          if (customShopData.internal && customShopData.internal.extra_info) {
            const infoList = JSON.parse(customShopData.internal.extra_info)
            setAgregationList(infoList?.info || [])
          } else {
            setAgregationList([])
          }
        } else {
          setAgregationList([])
        }
      }
      if (customShopData.related && customShopData.related[0]) {
        customShopData.related = customShopData.related.map((r: any) => {
          if (Array.isArray(r.extra_info)) return r
          return {
            ...r,
            extra_info: (typeof r.extra_info === 'object') ? r.extra_info?.info || [] : JSON.parse(r.extra_info).info
          }
        })

        setValueRelatedShops(customShopData.related || [])
      }
    } else if (showInternalShopId) {
      retrieveInternalShopExtraInfo()
    }
  }, [id])

  const handleChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleInsertExtraInfo = async () => {
    if (inputValue !== '') {
      await setAgregationList([...agregationList, inputValue])
      setInputValue('')
      updateMarketplaceExtraInfo([...agregationList, inputValue])
    }
  }

  const removeHandleExtraInfo = async (index: number) => {
    const elements = [].concat(agregationList)
    elements.splice(index, 1)
    await setAgregationList([].concat(elements))
    updateMarketplaceExtraInfo(elements)
  }

  const updateMarketplaceExtraInfo = async (data: any) => {
    const extraInfoData = { ...extraInfo, info: data }
    await supabaseClient.from('marketplaces')
      .update({
        extra_info: JSON.stringify(extraInfoData)
      })
      .eq('id', id)
  }

  return (agregationList && <List key={agregationList.length} breadcrumb={false}
    headerProps={{ subTitle: (agregationList && agregationList.length > 0) ? '' : t('marketplace.fields.noExtraInput'), style: { backgroundColor: 'transparent' } }}
    title={<Space style={{ width: '100%' }}>
      <Input disabled={customShopData} placeholder={t('marketplace.fields.extraInput')} onChange={handleChange} />
      <Button disabled={customShopData} type='primary' icon={<Icons.PlusOutlined />} onClick={() => handleInsertExtraInfo()} />
    </Space>}>
    {(customShopData?.internal) && <Typography.Text style={{ marginLeft: '10px', fontWeight: 700, fontSize: 18 }} key='internalShopRelated'>{`${t('marketplace.fields.internalShopRelated')} - ${(customShopData.internal[0]) ? customShopData.internal[0].name : customShopData.internal.name}`}</Typography.Text>}
    {(agregationList && agregationList.length > 0)
      ? [...agregationList].map((element: String, index: number) => {
          return <div style={{ backgroundColor: 'transparent', marginBottom: '5px', fontSize: '16px', display: 'flex', alignItems: 'center' }} key={index}>
          {(!customShopData) ? <Button style={{ borderColor: '#fa541c', color: '#fa541c' }} icon={<Icons.DeleteOutlined />} onClick={() => removeHandleExtraInfo(index)} /> : null}
          <Typography.Text style={{ marginLeft: '10px' }} key={index}>{element}</Typography.Text>
        </div>
        })
      : null}

    {(showInternalShopId && internalShopExtra)
      ? <div style={{ backgroundColor: 'transparent', marginTop: '2rem', marginBottom: '5px', fontSize: '16px', alignItems: 'center' }} key={internalShopExtra.id}>
          <Typography.Text style={{ fontWeight: 600 }} key={internalShopExtra.id}>{internalShopExtra.name}</Typography.Text>
          <div style={{ marginLeft: '2rem' }}>
            {internalShopExtra.extra_info && internalShopExtra.extra_info.map((i: string, indexI: number) => {
              return <div style={{ backgroundColor: 'transparent', marginBottom: '5px', fontSize: '16px', display: 'flex', alignItems: 'center' }} key={indexI}>
                <Typography.Text key={indexI}> - {i}</Typography.Text>
              </div>
            })}
          </div>
        </div>
      : null}

    {(customShopData?.internal || showInternalShopId)
      ? null
      : <>
        {(!showInternalShopId && customShopData?.related && customShopData?.related[0]) && <Typography.Text style={{ marginLeft: 10, fontWeight: 700, fontSize: 18 }} key='shopsRelated'>{t('marketplace.fields.shopsRelated')}</Typography.Text>}
        {(!showInternalShopId && valueRelatedShops && valueRelatedShops.length > 0)
          ? [...valueRelatedShops].map((element: any, index: number) => {
              return <div style={{ backgroundColor: 'transparent', marginBottom: '5px', fontSize: '16px', alignItems: 'center' }} key={index}>
                <Typography.Text style={{ marginLeft: '2rem', fontWeight: 600 }} key={index}> - {element.name}</Typography.Text>
                <div style={{ marginLeft: '4rem' }}>
                  {element.extra_info && element.extra_info.map((i: any, indexI: number) => {
                    return <div key={indexI} style={{ backgroundColor: 'transparent', marginBottom: '5px', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                      <Typography.Text style={{ marginLeft: '10px' }} key={indexI}>{i}</Typography.Text>
                    </div>
                  })}
                </div>
              </div>
            })
          : null}
      </>
    }
  </List>)
}
