
import { useTranslate, useSelect } from '@pankod/refine-core'

import {
  Form,
  Select,
  useForm
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'

type CategoriesRelationsFormProps = {
  marketplaceId: number
  relations: []
}

export const CategoriesRelationsForm: React.FC<CategoriesRelationsFormProps> = ({ marketplaceId, relations }) => {
  const { formProps: categoriesRelationsFormProps } = useForm<definitions['marketplace_category_relations']>()
  const t = useTranslate()

  const { options } = useSelect<definitions['marketplace_categories']>({
    resource: 'marketplace_categories',
    fetchSize: 100000,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const deleteAllRelations = async () => {
    await supabaseClient
      .from('marketplace_category_relations')
      .delete()
      .eq('marketplace_id', marketplaceId)
  }

  const insertMarketplacesCategories = async (relatedCategories: []) => {
    await deleteAllRelations()

    await supabaseClient
      .from('marketplace_category_relations')
      .insert(
        relatedCategories?.map((category: number) => (
          { marketplace_id: marketplaceId, marketplace_category_id: category }
        )), { upsert: true })
  }

  // eslint-disable-next-line camelcase
  const categoryIds = relations?.map(({ marketplace_category_id }) => marketplace_category_id)

  return (
    <>
    {Number.isInteger(marketplaceId) && (
      <Form
        {...categoriesRelationsFormProps}
        layout='vertical'
        initialValues={{
          ...categoriesRelationsFormProps.initialValues,
          marketplace_id: marketplaceId,
          marketplace_category_id: categoryIds
        }}
      >
        <Form.Item
          label={t('marketplace.fields.categories')}
          name="marketplace_category_id"
        >
          <Select
            mode='multiple'
            options={options}
            getPopupContainer={trigger => trigger.parentElement}
            filterOption={(input, option) => (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
            onChange={(value) => insertMarketplacesCategories(value)}
            />
        </Form.Item>
      </Form>
    )}
    </>
  )
}
