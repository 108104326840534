import { Button, Spin } from '@pankod/refine-antd'
import { EventSourceSyncHookProps, useEventSourceSync } from 'hooks/useEventSourceSync'
import { REACT_APP_BILLER_API_URL } from 'utility'
import { currentSession } from 'utility/authLocal'

type Props = {
  endpoint: string
  buttonText: string
  icon: JSX.Element
  disabledCondition?: boolean
  queryParams?: { [key: string]: any }
} & EventSourceSyncHookProps

export const EventSourceButton: React.FC<Props> = ({ endpoint, buttonText, headerText, completedText, errorDescText, refetchFn, icon, disabledCondition, queryParams = {} }) => {
  const { loading, startEventSource } = useEventSourceSync({ headerText, completedText, errorDescText, refetchFn })

  const handleClick = () => {
    const token = currentSession().access_token
    const params = new URLSearchParams({
      ...queryParams,
      token
    }).toString()
    const url = `${REACT_APP_BILLER_API_URL}${endpoint}?${params}`
    startEventSource(url)
  }

  return (
    <Button
      onClick={handleClick}
      icon={loading ? <Spin size="small" style={{ marginRight: 10 }} /> : icon}
      disabled={loading || disabledCondition}
    >
      {buttonText}
    </Button>
  )
}
