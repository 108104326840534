/* eslint-disable eqeqeq */
import { useTranslate } from '@pankod/refine-core'

import {
  List,
  Table,
  Tag,
  DateField,
  ShowButton,
  FilterDropdownProps,
  FilterDropdown,
  Typography,
  Input,
  Icons,
  useDrawerForm,
  Space,
  CreateButton,
  Checkbox,
  Radio,
  Select,
  useModal
} from '@pankod/refine-antd'

import { Order } from 'interfaces'
import { ACEPTED_STATUS_ID, ADMITTED_STATUS, ADMITTED_STATUS_TEXT, ADVERTISEMENT_STATUS_ID, CANCELLED_STATUS_ID, DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED, PENDING_PAYMENT_STATUS_TEXT, PREPARED_STATUS, PROCESSED_STATUS, SENT_BUDGE_STATUS_ID, SEQURA_PAYMENT_METHOD_ID, SHIPPED_STATUS, SHIPPING_METHOD_ECONOMY_ID, SUPPLEMENT_STATUS } from 'utility/constants'
import { Key, useEffect, useState } from 'react'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { getOrderExport, getOrders, supabaseClient, capitalizeFirstLetter, currency, getColorLabel, filter, sorter, getColorLabelEstimatedCost, MapEstimatedCostsStatusIDToText, itemsCompletedCounter } from 'utility'
import '../../custom.css'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { currentUserEmail } from 'utility/authLocal'
import { OrderLiveListProps } from './list'
import { EditableRowField } from 'components/EditableRowField/EditableRowField'
import { CreateOrder } from 'pages/orders/create'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'

type CheckboxWithAdviseProps = {
  record: any
}

export const CheckboxWithAdvise: React.FC<CheckboxWithAdviseProps> = ({ record }) => {
  const t = useTranslate()
  const { modalProps, show, close } = useModal()

  const [recordCompleted, setRecordCompleted] = useState(record.completed)

  const handleCompletedChange = async (value: any, record: any) => {
    if (record.id) {
      await supabaseClient.from('orders')
        .update({
          completed: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)

      setRecordCompleted(value)
    }
  }
  return (
    <>
      <Checkbox checked={recordCompleted} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        show()
      }} onChange={() => null} />
      <AdviceModal
        infoText={(!recordCompleted) ? t('order.actions.adviseToMarkAsCompleted', { order_id: record.id }) : t('order.actions.adviseToMarkAsIncompleted', { order_id: record.id })}
        okFunction={() => handleCompletedChange(!recordCompleted, record)}
        close={close}
        show={show}
        modalProps={modalProps} />
    </>
  )
}
export type tablePropsProps = {
  dataSource: any[]
}

export const OrdersLiveList: React.FC<OrderLiveListProps> = ({ select, dataToExport, getConditionalClassName, onlyPaidOrders, statusOrder, specialFilter }) => {
  let callingServer = false
  const prepareInitialFilter = () => {
    let filt = [{ field: 'supplements.deleted', operator: 'eq', value: false }]

    if (statusOrder === null) {
      filt.push({ field: 'status_id', operator: 'in', value: [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS] } as filter)
    } else {
      if (statusOrder !== -1) {
        filt.push({ field: 'status_id', operator: Array.isArray(statusOrder) ? 'in' : 'eq', value: statusOrder } as filter)
      }
    }

    if (specialFilter === 'cancel') {
      filt.push({ field: 'cancelation_date', operator: 'not', value: null } as filter)
    } else if (specialFilter === 'return') {
      filt.push({ field: 'return_date', operator: 'not', value: null } as filter)
    } else if (specialFilter === 'sup_pending_pay') {
      filt.push({ field: 'supplements.volumetric', operator: 'filter', secondOp: 'is', value: false } as filter)
      filt.push({ field: 'supplements.status_id', operator: 'filter', secondOp: 'neq', value: ADMITTED_STATUS } as filter)
      filt.push({ field: 'estimated_costs_status', operator: 'is', value: null } as filter)
    } else if (specialFilter === 'sup_pending_pay_no_volumetric') {
      filt.push({ field: 'supplements.volumetric', operator: 'filter', secondOp: 'is', value: false } as filter)
      filt.push({ field: 'supplements.status_id', operator: 'filter', secondOp: 'eq', value: ORDER_STATUS_ID_PENDING_PAYMENT } as filter)
    } else if (specialFilter === 'sup_pending_pay_volumetric') {
      filt.push({ field: 'supplements.volumetric', operator: 'filter', secondOp: 'is', value: true } as filter)
      filt.push({ field: 'supplements.status_id', operator: 'filter', secondOp: 'eq', value: ORDER_STATUS_ID_PENDING_PAYMENT } as filter)
    } else if (specialFilter === 'despared') {
      filt.push({ field: 'location', operator: 'not', value: null } as filter)
    } else if (specialFilter === 'refunds' || specialFilter === 'refunds_provider') {
      filt = [...filt.filter((x: filter) => x.field !== 'supplements.deleted')]
      filt.push({ field: 'status_id', operator: 'gte', value: PROCESSED_STATUS } as filter)
    } else if (specialFilter === 'free_vol') {
      filt.push({ field: 'purchase_conditions->>free_vol', operator: 'eq', value: 'true' } as filter)
      filt.push({ field: 'shipping_method_id', operator: 'eq', value: SHIPPING_METHOD_ECONOMY_ID } as filter)
    } else if (specialFilter === 'budgeted') {
      filt.push({ field: 'estimated_costs_status', operator: 'in', value: [ADVERTISEMENT_STATUS_ID, SENT_BUDGE_STATUS_ID, ACEPTED_STATUS_ID] } as filter)
      filt.push({ field: 'cancelation_date', operator: 'is', value: null } as filter)
      filt.push({ field: 'status_id', operator: 'in', value: [ORDER_STATUS_ID_REQUESTED, SUPPLEMENT_STATUS] } as filter)
    } else if (specialFilter === 'requested') {
      filt.push({ field: 'estimated_costs_status', operator: 'is', value: null } as filter)
    } else if (specialFilter === 'estimated') {
      filt.push({ field: 'estimated_delivered_date', operator: 'not', value: null } as filter)
      filt.push({ field: 'estimated_delivered_date', operator: 'lte', value: new Date(Date.now()).toISOString() } as filter)
    } else if (specialFilter === 'sequra') {
      filt.push({ field: 'payment_method_id', operator: 'eq', value: SEQURA_PAYMENT_METHOD_ID } as filter)
    }

    return filt
  }

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [filters, setFilters] = useState<filter[]>(prepareInitialFilter())
  const [sorter, setSorter] = useState<sorter>({ field: 'id', ascending: false })

  const getOrdersList = async (resetPages: boolean = false) => {
    if (!callingServer) {
      setLoading(true)
      let cPage = (resetPages) ? 1 : currentPage
      let cPageSize = pageSize
      callingServer = true
      let specialFilt = null
      // Special case, we need force to query more element and filter manually
      if (specialFilter === 'sup_pending_pay') {
        select = select.replace('supplements(', 'sup_count:supplements(count), supplements:supplements(deleted,')
        cPage = 1
        cPageSize = 1000
      } else if (specialFilter === 'sup_pending_pay_volumetric') {
        select = select.replace('supplements(', 'supplements!inner(')
      } else if (specialFilter === 'sup_pending_pay_no_volumetric') {
        select = select.replace('supplements(', 'supplements!inner(')
      } else if (specialFilter === 'refunds' || specialFilter === 'refunds_provider') {
        cPage = 1
        cPageSize = 10000
        select = select.replace(', supplements(supplement, volumetric, description, status(id, name))', ', refunds!inner(type, status_id, deleted, amount))')
        specialFilt = [...filters]
        specialFilt.push({ field: 'refunds.type', operator: 'eq', value: (specialFilter === 'refunds_provider') ? 'supplier' : 'client' } as filter)
        specialFilt.push({ field: 'refunds.deleted', operator: 'eq', value: false } as filter)
        specialFilt.push({ field: 'refunds.status_id', operator: 'eq', value: ORDER_STATUS_ID_PENDING_PAYMENT } as filter)
      } else if (specialFilter === 'budgeted') {
        cPage = 1
        cPageSize = 10000
      } else if (specialFilter === 'sequra') {
        cPage = 1
        cPageSize = 10000
        select = select.replace(', sequra_disbursement_ref', ', sequra_disbursement_ref,  refunds(type, status_id, deleted, amount)')
      } else if (specialFilter === 'despared') {
        select = select.replace(', sequra_disbursement_ref', ', sequra_disbursement_ref,  items!inner(id, delivered_warehouse, disabled)')
      }

      // console.log(specialFilt, filters)

      let { data: dataSource, count } = await getOrders(select, cPage, cPageSize, [...prepareInitialFilter(), ...specialFilt || filters], sorter)

      // Special cases
      if (specialFilter === 'sup_pending_pay') {
        dataSource = dataSource.filter((x: any) => x.sup_count[0].count === 0)
        count = dataSource.length
      } else if (specialFilter === 'sup_pending_pay_no_volumetric') {
        dataSource = dataSource.filter((x: any) => x.estimated_costs_status === null || x.estimated_costs_status === ACEPTED_STATUS_ID)
      } else if (specialFilter === 'budgeted') {
        dataSource = dataSource.filter((x: any) => {
          const haveVolSuplement = x.supplements.find((s: any) => s.volumetric && s.description === null)
          return !haveVolSuplement
        }).filter((x: any) => {
          const havePendingPaySupplement = x.supplements.find((s: any) => !s.volumetric && s.description !== null && s.status.id === ORDER_STATUS_ID_PENDING_PAYMENT)
          // console.log(x.id, x.estimated_costs_status, x.supplements, havePendingPaySupplement)
          if (havePendingPaySupplement) return x.estimated_costs_status !== ACEPTED_STATUS_ID
          else return ([ADVERTISEMENT_STATUS_ID, SENT_BUDGE_STATUS_ID, ACEPTED_STATUS_ID].includes(x.estimated_costs_status))
        })
        count = dataSource.length
      } else if (specialFilter === 'refunds_provider' || specialFilter === 'refunds') {
        const filterRefund = filters.find((x: { field: string }) => x.field === '')
        if (filterRefund) {
          dataSource = dataSource.filter((x: any) => {
            const totalRefundAmount = x.refunds.filter((r: any) => (specialFilter === 'refunds_provider') ? !r.deleted && r.type === 'supplier' && r.status_id === 0 : !r.deleted && r.type === 'client' && r.status_id === 0).map((r: any) => r.amount).reduce((a: number, b: number) => a + b, 0)
            if (filterRefund.operator === 'eq') {
              return (totalRefundAmount === Number(filterRefund.value))
            } else if (filterRefund.operator === 'gt') {
              return (totalRefundAmount > Number(filterRefund.value))
            } else if (filterRefund.operator === 'lt') {
              return (totalRefundAmount < Number(filterRefund.value))
            } else return true
          })
        }
      }

      const tableProps = {
        dataSource: dataSource
      }
      tableProps.dataSource = (tableProps.dataSource as any)?.map((row: any) => {
        row.action_dates = { cancelation_date: row.cancelation_date, return_date: row.return_date }
        return row
      })

      if (onlyPaidOrders) {
        tableProps.dataSource = tableProps.dataSource?.filter((x: any) => x.status_id && x.status_id > 0)
      }
      setTableProps(tableProps)
      setTotalData(count || 0)
      setLoading(false)
    }
    callingServer = false
  }

  const [totalData, setTotalData] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(localStorage.getItem('ordersPage') ? parseInt(localStorage.getItem('ordersPage') || '1') : 1)
  const [pageSize, setPageSize] = useState(localStorage.getItem('ordersPageSize') ? parseInt(localStorage.getItem('ordersPageSize') || '10') : 10)
  const [loading, setLoading] = useState(false)
  const [additionalFilter, setAdditionalFilter] = useState<boolean>(false)

  // filter values
  const [filterId, setFilterId] = useState<number|undefined>(undefined)
  const [filteredIdState, setFilteredIdState] = useState<boolean>(false)
  const [filterAlias, setFilterAlias] = useState<string|undefined>(undefined)
  const [filteredAliasState, setFilteredAliasState] = useState<boolean>(false)
  const [filterLocation, setFilterLocation] = useState<string|undefined>(undefined)
  const [filteredLocationState, setFilteredLocationState] = useState<boolean>(false)
  const [filterMarketplaceId, setFilterMarketplaceId] = useState<Key[]|undefined>(undefined)
  const [filteredMarketplaceIdState, setFilteredMarketplaceIdState] = useState<boolean>(false)
  const [filterStatusId, setFilterStatusId] = useState<Key[]|undefined>(undefined)
  const [filterEstimatedStatusId, setFilterEstimatedStatusId] = useState<Key[]|undefined>(undefined)
  const [filteredStatusIdState, setFilteredStatusIdState] = useState<boolean>(false)
  const [filteredEstimatedStatusIdState, setFilteredEstimatedStatusIdState] = useState<boolean>(false)
  const [filterCompleted, setFilterCompleted] = useState<boolean|undefined>(undefined)
  const [filterPriority, setFilterPriority] = useState<boolean|undefined>(undefined)
  const [filterDisbursement, setFilterDisbursement] = useState<string|undefined>(undefined)

  const [filteredDisbursementState, setFilteredDisbursementState] = useState<boolean>(false)
  const [filteredCreatedAtState, setFilteredCreatedAtState] = useState<boolean>(false)
  const [filteredTotalState, setFilteredTotalState] = useState<boolean>(false)
  const [filteredCancelDateState, setFilteredCancelDateState] = useState<boolean>(false)
  const [filteredCompletedState, setFilteredCompletedState] = useState<boolean>(false)
  const [filteredPriorityState, setFilteredPriorityState] = useState<boolean>(false)
  const [filteredEstimatedDateState, setFilteredEstimatedDateState] = useState<boolean>(false)

  const t = useTranslate()

  useEffect(() => {
    // console.log('filters -->', filters, additionalFilter)
    if (!callingServer) {
      getOrdersList(additionalFilter)
    }
  }, [filters])

  useEffect(() => {
    // console.log('sorter -->', sorter)
    if (!callingServer) {
      if (tableProps.dataSource.length > 0) {
        getOrdersList()
      }
    }
  }, [sorter, currentPage, pageSize])

  const { Text } = Typography

  const excludedFields = (onlyPaidOrders) ? [PENDING_PAYMENT_STATUS_TEXT] : ['']

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<Order>({
    action: 'create',
    resource: 'orders',
    redirect: false
  })

  let controlWidth = window.innerWidth
  const fixedMobile = controlWidth > 700 ? 'left' : false
  // previous controlWidth
  // controlWidth = controlWidth < 728 ? 750 : 200
  controlWidth = controlWidth < 728 ? 400 : 105

  const handleLocationChange = async (value: any, record: any, func: Function) => {
    if (record.id) {
      await supabaseClient
        .from('orders')
        .update({
          location: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)
      const val = tableProps?.dataSource?.find(x => x.id === record.id)
      if (val) {
        val.location = value
      }
      func(true)
    }
  }

  async function exportOrders () {
    let selection = select
    let specialFilt = null

    if (specialFilter === 'sup_pending_pay') {
      selection = selection.replace('supplements(', 'sup_count:supplements(count), supplements:supplements(deleted,')
    } else if (specialFilter === 'sup_pending_pay_volumetric') {
      selection = selection.replace('supplements(', 'supplements!inner(')
    } else if (specialFilter === 'sup_pending_pay_no_volumetric') {
      selection = selection.replace('supplements(', 'supplements!inner(')
    } else if (specialFilter === 'refunds' || specialFilter === 'refunds_provider') {
      selection = selection.replace(', supplements(supplement, volumetric, description, status(id, name))', ', refunds!inner(type, status_id, deleted, amount))')
      specialFilt = [...filters]
      specialFilt.push({ field: 'refunds.type', operator: 'eq', value: (specialFilter === 'refunds_provider') ? 'supplier' : 'client' } as filter)
      specialFilt.push({ field: 'refunds.deleted', operator: 'eq', value: false } as filter)
      specialFilt.push({ field: 'refunds.status_id', operator: 'eq', value: ORDER_STATUS_ID_PENDING_PAYMENT } as filter)
    } else if (specialFilter === 'sequra') {
      selection = selection.replace(', sequra_disbursement_ref', ', sequra_disbursement_ref,  refunds(type, status_id, deleted, amount)')
    }

    let data = await getOrderExport(selection, specialFilt || filters, sorter)

    if (onlyPaidOrders) {
      data = data?.filter((x: any) => x.status_id && x.status_id > 0)
    }

    return dataToExport(data as any[])
  }

  return (
    <>
      <List
        resource='orders'
        title={
          <>
            {t('order.orders')}
            <Text type='secondary' style={{ fontSize: 10, display: 'block', lineHeight: 2 }}>{t('order.cancelHelper')}</Text>
            <Text type='secondary' style={{ fontSize: 10, display: 'block', lineHeight: 2 }}>{t('order.returnHelper')}</Text>
          </>
        }
        headerProps={{
          extra: <>
            <CreateButton onClick={() => createShow()}>{t('order.actions.addOrder')}</CreateButton>
            <ExportToExcelButton filename='Pedidos' asyncFunction={() => exportOrders()} />
          </>
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          rowClassName={(record) => getConditionalClassName(record)}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            onChange (page, pageSize) {
              setCurrentPage(page)
              setPageSize(pageSize)
              localStorage.setItem('ordersPage', page.toString() || '')
              localStorage.setItem('ordersPageSize', pageSize.toString() || '')
            }
          }}
          onChange={(_pagination: any, _filters: any, sorter: any, _extra: any) => {
            if (sorter && sorter.order) {
              setSorter({ field: sorter.field, ascending: (sorter.order === 'ascend') })
            } else {
              setSorter({ field: 'id', ascending: false })
            }
          }}
          scroll={{ x: `${controlWidth}%`, y: '60vh' }}
          >
          <Table.Column
            key='id'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="id"
            align="center"
            title={t('order.fields.id')}
            filtered={filteredIdState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterId(Number(selectedKeys))
                  return selectedKeys
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'id')])
                  setFilterId(undefined)
                  setFilteredIdState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterId) {
                    if (filters.find((x: filter) => x.field === 'id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'id')
                      updatedFilters[index].value = filterId

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'id', operator: 'eq', value: filterId }]])
                    }
                    setFilteredIdState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
            sorter
            width= {fixedMobile ? 7 : 1}
          />
          <Table.Column
            key='alias'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="alias"
            align="center"
            title={t('order.fields.alias')}
            sorter
            width= {fixedMobile ? 7 : 1}
            filtered={filteredAliasState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterAlias(selectedKeys.toString().toUpperCase())
                  return selectedKeys.toString().toUpperCase()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'alias')])
                  setFilterId(undefined)
                  setFilteredAliasState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterAlias) {
                    if (filters.find((x: filter) => x.field === 'alias')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'alias')
                      updatedFilters[index].value = filterAlias

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'alias', operator: 'eq', value: filterAlias }]])
                    }
                    setFilteredAliasState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key="is_priority"
            dataIndex="is_priority"
            align="center"
            title={t('order.fields.is_priority')}
            sorter
            width= {fixedMobile ? 10 : 2}
            render={(value) => (
              <Space style={{ justifyContent: 'center' }}>
                {(value) ? <Icons.ExclamationCircleFilled style={{ fontSize: 30, color: '#f16868' }} /> : null}
              </Space>
            )}
            filtered={filteredPriorityState}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterPriority(selectedKeys.toString() === 'true')
                  return selectedKeys.toString() === 'true'
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'is_priority')])
                  setFilterPriority(undefined)
                  setFilteredPriorityState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  console.log(filterPriority)
                  if (typeof filterPriority === 'boolean') {
                    if (filters.find((x: filter) => x.field === 'is_priority')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'is_priority')
                      updatedFilters[index].value = filterPriority

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'is_priority', operator: 'eq', value: filterPriority }]])
                    }
                    setFilteredPriorityState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Radio.Group>
                  <Radio value={true}>{t('order.fields.priority')}</Radio>
                  <Radio value={false}>{t('order.fields.noPriority')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
          />

          <Table.Column
            key='package_number'
            dataIndex="package_number"
            align="center"
            title={t('order.fields.package_number')}
            width= {fixedMobile ? 7 : 1}
          />
          <Table.Column
            key='location'
            dataIndex="location"
            align="center"
            title={t('order.fields.location')}
            width= {fixedMobile ? 12 : 2}
            render={(value, record: Order) => (
              <Space style={{ justifyContent: 'center' }}>
                <EditableRowField key={value} record={record} handleSave={handleLocationChange} />
              </Space>
            )}
            sorter
            filtered={filteredLocationState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterLocation(selectedKeys.toString() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'location')])
                  setFilterLocation(undefined)
                  setFilteredLocationState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterLocation) {
                    if (filters.find((x: filter) => x.field === 'location')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'location')
                      updatedFilters[index].value = filterLocation

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'location', operator: 'eq', value: filterLocation }]])
                    }
                    setFilteredLocationState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          {(specialFilter === 'despared') && <Table.Column
            key='warehouse_completed'
            dataIndex="warehouse_completed"
            align="center"
            title={t('order.fields.itemsCompleted')}
            render={(value, record: any) => itemsCompletedCounter(record?.items)}
            sorter
            width= {fixedMobile ? 10 : 1}
          />}
          {(specialFilter === 'despared') && <Table.Column
            key='completed'
            dataIndex="completed"
            align="center"
            title={t('order.fields.completed')}
            width= {fixedMobile ? 10 : 1}
            render={(value, record: Order) => (
              <Space style={{ justifyContent: 'center' }}>
                <CheckboxWithAdvise record={record} />
              </Space>
            )}
            filtered={filteredCompletedState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterCompleted(selectedKeys.toString() === 'true')
                  return selectedKeys.toString() === 'true'
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'completed')])
                  setFilterCompleted(undefined)
                  setFilteredCompletedState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (typeof filterCompleted === 'boolean') {
                    if (filters.find((x: filter) => x.field === 'completed')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'completed')
                      updatedFilters[index].value = filterCompleted

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'completed', operator: 'eq', value: filterCompleted }]])
                    }
                    setFilteredCompletedState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Radio.Group>
                  <Radio value={true}>{t('order.fields.completed')}</Radio>
                  <Radio value={false}>{t('order.fields.noCompleted')}</Radio>
                </Radio.Group>
              </FilterDropdown>
            )}
          />}
          <Table.Column
            key='created_at'
            dataIndex="created_at"
            align="center"
            title={t('order.fields.createdAt')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
            sorter
            width= {fixedMobile ? 10 : 1}
            filtered={filteredCreatedAtState}
            filterDropdown={() => (
              <DateFilterDropdown
                field='created_at'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    let filtersToAdd = [...filters]

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'gte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'gte')
                      updatedFilters[index].value = newFilter[0].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                    }

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'lte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'lte')
                      updatedFilters[index].value = newFilter[1].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                    }

                    setFilters([...filtersToAdd])
                    setFilteredCreatedAtState(true)
                    setAdditionalFilter(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== 'created_at')])
                    setFilteredCreatedAtState(false)
                    setAdditionalFilter(false)
                  }
                }}
              />
            )}
          />
          {(!specialFilter || specialFilter === 'despared' || specialFilter === 'estimated') && <Table.Column
            key='estimated_delivered_date'
            dataIndex="estimated_delivered_date"
            align="center"
            title={t('order.fields.estimated_delivered_date')}
            render={(value) => (value) ? <DateField format={DEFAULT_DATE_FORMAT} value={value} /> : ''}
            sorter
            width= {fixedMobile ? 10 : 1}
            filtered={filteredEstimatedDateState}
            filterDropdown={() => (
              <DateFilterDropdown
                field='estimated_delivered_date'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    let filtersToAdd = [...filters]

                    if (filters.find((x: filter) => x.field === 'estimated_delivered_date' && x.operator === 'gte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'estimated_delivered_date' && x.operator === 'gte')
                      updatedFilters[index].value = newFilter[0].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                    }

                    if (filters.find((x: filter) => x.field === 'estimated_delivered_date' && x.operator === 'lte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'estimated_delivered_date' && x.operator === 'lte')
                      updatedFilters[index].value = newFilter[1].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                    }

                    setFilters([...filtersToAdd])
                    setFilteredEstimatedDateState(true)
                    setAdditionalFilter(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== 'estimated_delivered_date')])
                    setFilteredEstimatedDateState(false)
                    setAdditionalFilter(false)
                  }
                }}
              />
            )}
          />}
          <Table.Column
            dataIndex={['marketplace_id', 'name']}
            align="center"
            title={t('order.fields.marketplace')}
            sorter
            width= {fixedMobile ? 7 : 1}
            key="marketplace_id"
            filtered={filteredMarketplaceIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterMarketplaceId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'marketplace_id')])
                setFilterMarketplaceId(undefined)
                setFilteredMarketplaceIdState(false)
                setAdditionalFilter(false)
              }
              props.confirm = () => {
                if (filterMarketplaceId && filterMarketplaceId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'marketplace_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_id')
                    updatedFilters[index].value = filterMarketplaceId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'marketplace_id', operator: 'in', value: filterMarketplaceId }]])
                  }
                  setFilteredMarketplaceIdState(true)
                  setAdditionalFilter(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='marketplaces'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='total'
            dataIndex="total"
            align="center"
            title={t('order.fields.billingAmount')}
            sorter
            width= {fixedMobile ? 10 : 1}
            render={(value: number, record: any) => {
              if (specialFilter === 'refunds_provider') {
                const totalAmount = record.refunds.filter((x: any) => !x.deleted && x.type === 'supplier' && x.status_id === 0).map((x: any) => x.amount || 0).reduce((prev: number, next: number) => prev + next, 0)
                return currency(totalAmount)
              } else if (specialFilter === 'refunds') {
                const totalAmount = record.refunds.filter((x: any) => !x.deleted && x.type === 'client' && x.status_id === 0).map((x: any) => x.amount || 0).reduce((prev: number, next: number) => prev + next, 0)
                return currency(totalAmount)
              } else {
                return currency(value)
              }
            }}
            filtered={filteredTotalState}
            filterDropdown={() => (
              <QuantityFilterDropdown
                field={`${(specialFilter && ['refunds_provider', 'refunds'].includes(specialFilter)) ? '' : 'total'}`}
                filters={filters}
                setFilters={(newFilter: any) => {
                  if (Array.isArray(newFilter)) {
                    setFilters([...newFilter])
                    setFilteredTotalState(true)
                    setAdditionalFilter(true)
                  } else {
                    if (specialFilter && ['refunds_provider', 'refunds'].includes(specialFilter)) {
                      setFilters([...filters.filter((x: filter) => x.field !== '')])
                    } else {
                      setFilters([...filters.filter((x: filter) => x.field !== 'total')])
                    }
                    setFilteredTotalState(false)
                    setAdditionalFilter(false)
                  }
                }}
              />
            )}
          />
          <Table.Column
            key="status_id"
            dataIndex={['status', 'name']}
            align="center"
            title={t('order.fields.status')}
            width= {fixedMobile ? 10 : 1}
            render={(value, record: Order) => (
              <Tag color={getColorLabel(value, record?.prev_status_id)}>
                {capitalizeFirstLetter(value)}
              </Tag>
            )}
            sorter
            filtered={filteredStatusIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterStatusId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'status_id' && x.operator === 'in')])
                setFilterStatusId(undefined)
                setFilteredStatusIdState(false)
                setAdditionalFilter(false)
              }
              props.confirm = () => {
                if (filterStatusId && filterStatusId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'status_id' && x.operator === 'in')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'status_id' && x.operator === 'in')
                    updatedFilters[index].value = filterStatusId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'status_id', operator: 'in', value: filterStatusId }]])
                  }
                  setFilteredStatusIdState(true)
                  setAdditionalFilter(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='status'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
                excludeFieldsFilter={excludedFields}
              />
            }}
          />
          {(specialFilter === 'sequra') && <Table.Column
            key="sequra_disbursement_ref"
            dataIndex="sequra_disbursement_ref"
            align="center"
            title={t('order.fields.disbursement')}
            width= {fixedMobile ? 12 : 1}
            sorter
            filtered={filteredDisbursementState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterDisbursement(selectedKeys.toString())
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'sequra_disbursement_ref')])
                  setFilterId(undefined)
                  setFilteredDisbursementState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterDisbursement) {
                    if (filters.find((x: filter) => x.field === 'alias')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'sequra_disbursement_ref')
                      updatedFilters[index].value = filterDisbursement

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'sequra_disbursement_ref', operator: 'eq', value: filterDisbursement }]])
                    }
                    setFilteredDisbursementState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />}
          {(specialFilter === 'cancel' || specialFilter === 'return') && <Table.Column
            key="action_dates"
            dataIndex="action_dates"
            align="center"
            title={(specialFilter === 'cancel' || specialFilter === 'return') ? t(`order.fields.${specialFilter}Date`) : t('order.fields.cancellationReturnDate')}
            width= {fixedMobile ? 10 : 2}
            render={({ cancelation_date: cancelationDate, return_date: returnDate }) => {
              if (cancelationDate && !returnDate) {
                return <DateField format={DEFAULT_DATE_FORMAT} value={cancelationDate} />
              }
              if (returnDate && !cancelationDate) {
                return <DateField format={DEFAULT_DATE_FORMAT} value={returnDate} />
              }
              return ''
            }}
            filtered={filteredCancelDateState}
            filterDropdown={() => (
              <>
                {(specialFilter === 'cancel') && <DateFilterDropdown
                  field='cancelation_date'
                  filters={filters}
                  setFilters={(newFilter: any, method: string) => {
                    if (newFilter.length > 0 && method === 'merge') {
                      let filtersToAdd = [...filters]

                      if (filters.find((x: filter) => x.field === 'cancelation_date' && x.operator === 'gte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'cancelation_date' && x.operator === 'gte')
                        updatedFilters[index].value = newFilter[0].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                      }

                      if (filters.find((x: filter) => x.field === 'cancelation_date' && x.operator === 'lte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'cancelation_date' && x.operator === 'lte')
                        updatedFilters[index].value = newFilter[1].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                      }

                      setFilters([...filtersToAdd])
                      setFilteredCancelDateState(true)
                      setAdditionalFilter(true)
                    } else {
                      setFilters([...filters.filter((x: filter) => x.field !== 'cancelation_date')])
                      setFilteredCancelDateState(false)
                      setAdditionalFilter(false)
                    }
                  }}
                  label={t('order.fields.cancelationDate')}
                />}
                {(specialFilter === 'return') && <DateFilterDropdown
                  field='return_date'
                  filters={filters}
                  setFilters={(newFilter: any, method: string) => {
                    if (newFilter.length > 0 && method === 'merge') {
                      let filtersToAdd = [...filters]

                      if (filters.find((x: filter) => x.field === 'return_date' && x.operator === 'gte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'return_date' && x.operator === 'gte')
                        updatedFilters[index].value = newFilter[0].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                      }

                      if (filters.find((x: filter) => x.field === 'return_date' && x.operator === 'lte')) {
                        const updatedFilters = [...filtersToAdd]
                        const index = updatedFilters.findIndex((x: filter) => x.field === 'return_date' && x.operator === 'lte')
                        updatedFilters[index].value = newFilter[1].value

                        filtersToAdd = [...updatedFilters]
                      } else {
                        filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                      }

                      setFilters([...filtersToAdd])
                      setFilteredCancelDateState(true)
                      setAdditionalFilter(true)
                    } else {
                      setFilters([...filters.filter((x: filter) => x.field !== 'return_date')])
                      setFilteredCancelDateState(false)
                      setAdditionalFilter(false)
                    }
                  }}
                  label={t('order.fields.returnDate')}
                />}
              </>
            )}
          />}
          {(specialFilter === 'sup_pending_pay' || specialFilter === 'sup_pending_pay_no_volumetric') && <Table.Column
            key="supplements.status_id"
            dataIndex='supplements'
            align="center"
            title={t('order.fields.supplementStatus')}
            width= {fixedMobile ? 7 : 2}
            render={(supplements) => {
              if (supplements.length > 0) {
                const pendingPaymentSupplements = supplements?.filter((supplement: any) => supplement.status.id === ORDER_STATUS_ID_PENDING_PAYMENT && !supplement.volumetric && !supplement.deleted)
                if (pendingPaymentSupplements.length > 0) {
                  return (
                    <Tag color={getColorLabel(PENDING_PAYMENT_STATUS_TEXT)}>
                      {capitalizeFirstLetter(PENDING_PAYMENT_STATUS_TEXT)}
                    </Tag>
                  )
                } else {
                  return (
                    <Tag color={getColorLabel(ADMITTED_STATUS_TEXT)}>
                      {capitalizeFirstLetter(ADMITTED_STATUS_TEXT)}
                    </Tag>
                  )
                }
              }
            }}
          />}
          {(specialFilter === 'sup_pending_pay_volumetric' || specialFilter === 'sup_pending_pay') && <Table.Column
            key="supplements.status_id"
            dataIndex='supplements'
            align="center"
            title={t('order.fields.supplementStatusVolumetric')}
            width= {fixedMobile ? 10 : 2}
            render={(supplements) => {
              if (supplements.length > 0) {
                const pendingPaymentSupplements = supplements?.filter((supplement: any) => supplement.status.id === ORDER_STATUS_ID_PENDING_PAYMENT && supplement.volumetric && !supplement.deleted)
                if (pendingPaymentSupplements.length > 0) {
                  return (
                    <Tag color={getColorLabel(PENDING_PAYMENT_STATUS_TEXT)}>
                      {capitalizeFirstLetter(PENDING_PAYMENT_STATUS_TEXT)}
                    </Tag>
                  )
                } else {
                  return (
                    <Tag color={getColorLabel(ADMITTED_STATUS_TEXT)}>
                      {capitalizeFirstLetter(ADMITTED_STATUS_TEXT)}
                    </Tag>
                  )
                }
              }
            }}
          />}
          {(specialFilter === 'budgeted') && <Table.Column
            key="estimated_costs_status"
            dataIndex="estimated_costs_status"
            align="center"
            title={t('order.fields.estimatedStatus')}
            width= {fixedMobile ? 10 : 2}
            render={(value: any) => {
              if (value) {
                return (<Tag color={getColorLabelEstimatedCost(value || null)}>
                  {MapEstimatedCostsStatusIDToText(value || null)}
                </Tag>)
              } else {
                return null
              }
            }}
            filtered={filteredEstimatedStatusIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.clearFilters = () => {
                const updatedFilters = [...filters]
                const index = updatedFilters.findIndex((x: filter) => x.field === 'estimated_costs_status' && x.operator === 'in')
                updatedFilters[index].value = [ADVERTISEMENT_STATUS_ID, SENT_BUDGE_STATUS_ID, ACEPTED_STATUS_ID]

                setFilters([...updatedFilters])
                setFilterEstimatedStatusId(undefined)
                setFilteredEstimatedStatusIdState(false)
                setAdditionalFilter(false)
              }
              props.confirm = () => {
                console.log(filterEstimatedStatusId, filters)
                if (filterEstimatedStatusId && filterEstimatedStatusId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'estimated_costs_status' && x.operator === 'in')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'estimated_costs_status' && x.operator === 'in')
                    updatedFilters[index].value = [...filterEstimatedStatusId || []]

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'estimated_costs_status', operator: 'in', value: [...filterEstimatedStatusId || []] }]])
                  }
                  setFilteredEstimatedStatusIdState(true)
                  setAdditionalFilter(true)
                }
              }
              return <FilterDropdown
                {...props}
                clearFilters={() => {
                  props.clearFilters?.()
                  props.confirm()
                }}
              >
                <Select
                  mode='multiple'
                  onSelect={(value: any) => {
                    const values = [...filterEstimatedStatusId || [], value]
                    console.log(value, filterEstimatedStatusId, values)
                    setFilterEstimatedStatusId(values)
                  }}
                  options={[
                    { label: t('estimated_costs.select.advise'), value: ADVERTISEMENT_STATUS_ID },
                    { label: t('estimated_costs.select.sent'), value: SENT_BUDGE_STATUS_ID },
                    { label: t('estimated_costs.select.accept'), value: ACEPTED_STATUS_ID },
                    { label: t('estimated_costs.select.cancel'), value: CANCELLED_STATUS_ID }
                  ]}
                  style={{ minWidth: 150 }}
                />
              </FilterDropdown>
            }}
          />}
          <Table.Column<Order>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            width= {fixedMobile ? 7 : 1}
            // width= {20}
            render={(_, record) => {
              if (record.locked) {
                return (
                  <>
                    <Icons.LockOutlined style={{ color: '#f5222d', fontSize: 20 }} />
                    {record.locked_by === currentUserEmail() && (
                      <ShowButton
                        resourceNameOrRouteName='orders'
                        hideText
                        style={{ color: 'green', fontSize: '15px', marginLeft: 5 }}
                        recordItemId={record.id}
                      />
                    )}
                    <p>{record?.locked_by}</p>
                  </>
                )
              }
              return (
                <ShowButton
                  resourceNameOrRouteName='orders'
                  hideText
                  style={{ color: 'green', fontSize: '15px' }}
                  recordItemId={record.id}
                />
              )
            }}
          />
        </Table>
        <CreateOrder
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
          closeDrawer={createClose}
        />
      </List>
    </>
  )
}
