import { Button, Col, Form, Input, Radio, Row, Space, Icons } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useRef, useState } from 'react'

type QuantityFilterDropdownProps = {
  field: string
  filters: any
  setFilters: Function
}

export const QuantityFilterDropdown: React.FC<QuantityFilterDropdownProps> = ({ field, filters, setFilters }) => {
  const [operator, setOperator] = useState('')
  const valueRef = useRef<any>(null)

  const t = useTranslate()

  const handleSubmit = () => {
    const newFilter = {
      field: field,
      operator: operator,
      value: valueRef.current.input.value
    }

    let index = filters.findIndex((el: any) => el.field === newFilter.field)
    index = index === -1 ? filters.length : index
    filters[index] = newFilter

    setFilters(filters)
  }

  const handleClear = () => {
    setFilters((prev: any) => prev.filter((filter: any) => filter.field !== field))
  }

  return (
      <Row justify='space-around' style={{ paddingTop: '12px' }}>
        <Col span={2}>
          <Form.Item>
            <Radio.Group onChange={(event) => setOperator(event.target.value)}>
              <Space direction="vertical">
                <Radio value='lt'>{'<'}</Radio>
                <Radio value='eq'>{'='}</Radio>
                <Radio value='gt'>{'>'}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Space align='end'>
            <Form.Item name={field}>
              <Input ref={valueRef} />
            </Form.Item>
          </Space>
          <Space align='end'>
            <Button
              icon={<Icons.FilterOutlined />}
              type='primary'
              size='small'
              onClick={() => handleSubmit()}
            >
              {t('buttons.filter')}
            </Button>
            <Button
              type='primary'
              size='small'
              danger
              onClick={() => handleClear()}
            >
              {t('buttons.clear')}
            </Button>
          </Space>
        </Col>
      </Row>
  )
}
