import { IResourceComponentsProps } from '@pankod/refine-core'
import {
  Create,
  Form,
  useForm
} from '@pankod/refine-antd'
import { definitions, IInput } from 'interfaces'
import { FormItems } from 'components/FormItems/FormItems'
import { useTranslation } from 'react-i18next'

import 'react-mde/lib/styles/css/react-mde-all.css'

export const IslandCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<definitions['islands']>()
  const { t } = useTranslation()

  const inputs: IInput[] = [
    {
      label: t('islands.fields.name'),
      name: 'name',
      required: true
    },
    {
      label: t('islands.fields.province'),
      name: 'province',
      required: true,
      select: [
        { label: 'Las Palmas', value: 'Las Palmas' },
        { label: 'Santa Cruz de Tenerife', value: 'Santa Cruz de Tenerife' }
      ]
    },
    {
      label: t('islands.fields.cpPrefixes'),
      name: 'cp_prefixes',
      required: true,
      help: t('islands.fields.help')
    },
    {
      label: t('islands.fields.planeExtraCost'),
      name: 'plane_extra_cost',
      required: true,
      number: {
        money: true,
        min: 0
      }
    },
    {
      label: t('islands.fields.boatExtraCost'),
      name: 'boat_extra_cost',
      required: true,
      number: {
        money: true,
        min: 0
      }
    }
  ]

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" >
        {inputs.map((input, keyIndex) => {
          return FormItems(input, keyIndex)
        })}
      </Form>
    </Create>
  )
}
