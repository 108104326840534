import { Button, DatePicker, Drawer, Grid, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { CURRENT_DATE_TIME, formatDateTime, getUsersWithoutOrdersFunction } from 'utility'
import { utils, writeFile } from 'xlsx'
import { definitions } from 'interfaces'

type usersWithoutOrdersButtonProps = {
  filename: string
}

export const ExportUsersWithoutOrdersButton = ({ filename }: usersWithoutOrdersButtonProps) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  filename = `${filename} ${CURRENT_DATE_TIME}.xlsx`

  const {
    drawerProps: createDrawerProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['users']>({
    action: 'create',
    redirect: false
  })

  const { RangePicker } = DatePicker

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [dates, setDates] = useState<any[]>([])

  const getUsersWithoutOrders = async () => {
    const headerNames = {
      id: t('users.id'),
      email: t('users.email'),
      name: t('users.name'),
      created_at: t('users.created_at')
    }

    const data = await getUsersWithoutOrdersFunction(dates)

    if (!data || data.length === 0) {
      return []
    }

    for (const u of data) {
      if (u.created_at) u.created_at = formatDateTime(u.created_at)
    }
    data.unshift(headerNames)
    return data || []
  }

  const autofitColumns = (json: any[]) => {
    const jsonKeys = Object.keys(json[0])
    const objectMaxLength: any = []

    for (let i = 0; i < json.length; i++) {
      const value = json[i]
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10
        } else if (value[jsonKeys[j]] instanceof Date) {
          objectMaxLength[j] = 20
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0
          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
        }
      }

      const key = jsonKeys
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length
      }
    }

    return objectMaxLength.map((w: number) => {
      return { width: w }
    })
  }

  const exportToExcel = async () => {
    setLoading(true)
    const jsonData = await getUsersWithoutOrders()
    if (jsonData === null) {
      setError(true)
    } else {
      const worksheet = utils.json_to_sheet(jsonData, { skipHeader: true, cellDates: true, dateNF: 'dd/mm/yyyy' })
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      worksheet['!cols'] = autofitColumns(jsonData)
      setLoading(false)
      writeFile(workbook, filename, { cellDates: true })

      createClose()
    }
  }

  const changeDates = (datesInput: any) => {
    const arrayDates = [datesInput[0], datesInput[1]]
    const dateStart = `${arrayDates[0].get('year')}-${(arrayDates[0].get('month') + 1 > 9) ? arrayDates[0].get('month') + 1 : '0' + (arrayDates[0].get('month') + 1)}-${(arrayDates[0].get('date') > 9) ? arrayDates[0].get('date') : '0' + arrayDates[0].get('date')}`
    const dateEnd = `${arrayDates[1].get('year')}-${(arrayDates[1].get('month') + 1 > 9) ? arrayDates[1].get('month') + 1 : '0' + (arrayDates[1].get('month') + 1)}-${(arrayDates[1].get('date') > 9) ? arrayDates[1].get('date') : '0' + arrayDates[1].get('date')}`
    setDates([dateStart, dateEnd])
  }

  return <>
    <Button type="primary" onClick={() => createShow()}>
      {t('buttons.getUsersWithoutOrders')}
    </Button>
    <Drawer
      {...createDrawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('users.exportWithoutOrdersButton.labelDate')}
        </Typography.Text>
        <RangePicker showTime={false} onChange={changeDates} />

        <Button style={{ marginTop: '1%' }} type="primary" disabled={(dates.length < 2)} loading={loading} onClick={() => exportToExcel()}>
          {t('users.exportWithoutOrdersButton.generateReport')}
        </Button>
      </Space>

      {(error)
        ? <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>
            {t('users.exportWithoutOrdersButton.error')}
          </Typography.Text>
        </Space>
        : null}
    </Drawer>
  </>
}
