import { useTranslate } from '@pankod/refine-core'

import {
  Form,
  Input,
  FormProps,
  Select,
  InputNumber
} from '@pankod/refine-antd'
import { SHIPMENT_TYPE_CONSOLIDATED, SHIPMENT_TYPE_DIRECT } from 'utility/constants'
// import dayjs from 'dayjs'

type ShipmentsFormProps = {
  formProps: FormProps<{}>
};

export const ShipmentsForm: React.FC<ShipmentsFormProps> = (data) => {
  const t = useTranslate()

  return (
    <>
      <Form
        {...data.formProps}
        layout="vertical"
      >
        <Form.Item
          label={t('shipments.fields.order_id')}
          name="order_id"
        >
          <InputNumber readOnly />
        </Form.Item>
        {/* HIDDEN FOR NOW */}
        {/* <Form.Item
          label={t('shipments.fields.estimatedArrivalDate')}
          name="estimated_arrival_date"
          getValueProps={(value) => ({
            value: value ? dayjs(value) : ''
          })}
        >
          <DatePicker />
        </Form.Item> */}
        <Form.Item
          label={t('shipments.fields.trackingId')}
          name="tracking_id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('shipments.fields.type')}
          name="type"
        >
          <Select options={[
            { value: SHIPMENT_TYPE_DIRECT, label: SHIPMENT_TYPE_DIRECT },
            { value: SHIPMENT_TYPE_CONSOLIDATED, label: SHIPMENT_TYPE_CONSOLIDATED }
          ]} />
        </Form.Item>
      </Form>
    </>
  )
}
