import { Button, DatePicker, Drawer, Grid, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { CURRENT_DATE_TIME, excelDateFormat, prepareBankReport } from 'utility'
import { utils, writeFile } from 'xlsx'
import { definitions } from 'interfaces'

type bankReportProps = {
  filename: string
}

export const BankReportButton = ({ filename }: bankReportProps) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  filename = `${filename} ${CURRENT_DATE_TIME}.xlsx`

  const {
    drawerProps: createDrawerProps,
    close: createClose,
    show: createShow
  } = useDrawerForm<definitions['orders']>({
    action: 'create',
    redirect: false
  })

  const { RangePicker } = DatePicker

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [dates, setDates] = useState<any[]>([])

  const getDataOfBankReport = async (datesSelected: any) => {
    const arrayPromise = []

    // group here in promises
    const d1 = new Date(datesSelected[0])
    const d2 = new Date(datesSelected[1])
    // To calculate the time difference of two dates
    const diff = d2.getTime() - d1.getTime()
    // To calculate the no. of days between two dates
    const days = (diff / (1000 * 3600 * 24))

    let data: any[] = []
    const datesRanges: any = []

    const partialNumber = 5
    if (days > partialNumber) {
      // console.log('a lot of days to manage... Fragmenting queries', datesSelected)
      const accDays = Math.round(days / partialNumber)

      const accDate = new Date(datesSelected[0])

      for (let i = 0; i < partialNumber; i++) {
        const fromDate = new Date(accDate)
        accDate.setDate(accDate.getDate() + accDays)
        const dateStart = `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1 > 9) ? fromDate.getMonth() + 1 : '0' + (fromDate.getMonth() + 1)}-${(fromDate.getDate() > 9) ? fromDate.getDate() : '0' + fromDate.getDate()}`
        const dateEnd = `${accDate.getFullYear()}-${(accDate.getMonth() + 1 > 9) ? accDate.getMonth() + 1 : '0' + (accDate.getMonth() + 1)}-${(accDate.getDate() > 9) ? accDate.getDate() : '0' + accDate.getDate()}`

        datesRanges.push([dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd])
        // console.log('calling date range -->', [dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd])
        arrayPromise.push(prepareBankReport([dateStart, (i === (partialNumber - 1)) ? datesSelected[1] : dateEnd]))
      }

      const results = await Promise.all(arrayPromise)

      let querySucess: any[] = []
      let index = 0
      for await (const r of results) {
        if (r.error) {
          // console.log(index, datesRanges[index])
          const dataByError = await getDataOfBankReport(datesRanges[index])
          querySucess = [...querySucess, ...dataByError]
        }
        if (r.data) querySucess = [...querySucess, ...r.data]
        index += 1
      }

      data = querySucess
    } else {
      const { data: res, error } = await prepareBankReport(datesSelected)
      if (error) {
        console.error(error)
        data = await getDataOfBankReport(datesSelected)
      }
      data = res
    }
    return data
  }

  const generateBankReport = async () => {
    const headerNames = {
      created_at: t('order.fields.createdAt'),
      order_id: t('order.fields.id'),
      alias: t('order.fields.alias'),
      // invoice: t('order.fields.invoiceNumber'),
      // invoice_date: t('order.fields.invoiceDate'),
      status: t('order.fields.status'),
      marketplace: t('order.fields.marketplace'),
      // refunds_client_sum: t('order.biReport.refundsClient'),
      // refunds_client_status: t('order.biReport.refundsClientStatus'),
      process_date: t('order.banksReport.processDate'),
      purchase_amount: t('order.banksReport.buy'),
      purchase_iva_amount: t('order.banksReport.buyIva'),
      purchases_completed: t('order.banksReport.purchaseStatus'),
      refunds_supplier_sum: t('order.banksReport.refundsSupplier'),
      refunds_supplier_status: t('order.banksReport.refundsSupplierStatus')
      // total: t('order.fields.total')
    }

    // console.log(dates)
    const data = await getDataOfBankReport(dates)

    if (!data || data.length === 0) {
      return []
    }
    const filteredData: any[] = []

    data.forEach((order: any) => {
      if (!filteredData.find((x: any) => x.order_id === order.order_id)) filteredData.push(order)
    })

    // Sort by Date
    const sortedData = filteredData.sort((a: any, b: any) => {
      return Date.parse(a.created_at) - Date.parse(b.created_at)
    })

    for (const o of sortedData) {
      // if (o.refunds_client_status || o.refunds_client_status === 0) {
      //   o.refunds_client_status = t(`order.status.${o.refunds_client_status}`)
      // }
      if (o.refunds_supplier_status || o.refunds_supplier_status === 0) {
        o.refunds_supplier_status = t(`order.status.${o.refunds_supplier_status}`)
      }

      if (o.purchases_completed && o.purchases_total && o.purchases_total > 0) {
        if (o.purchases_completed === o.purchases_total) o.purchases_completed = t('order.banksReport.purchasesCompleted')
        else if (o.purchases_completed < o.purchases_total && o.purchases_completed > 0) o.purchases_completed = t('order.banksReport.purchasesPartial')
        else o.purchases_completed = t('order.banksReport.purchasesPending')
      } else {
        o.purchases_completed = t('order.banksReport.noPurchases')
      }
      o.purchase_total = ''

      if (o.created_at) {
        o.created_at = excelDateFormat(o.created_at)
      }
      if (o.process_date) {
        o.process_date = excelDateFormat(o.process_date)
      }

      if (o.registered_at) {
        o.registered_at = excelDateFormat(o.registered_at)
      }
    }

    sortedData.unshift(headerNames)
    return sortedData
  }

  const autofitColumns = (json: any[]) => {
    const jsonKeys = Object.keys(json[0])
    const objectMaxLength: any = []

    for (let i = 0; i < json.length; i++) {
      const value = json[i]
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10
        } else if (value[jsonKeys[j]] instanceof Date) {
          objectMaxLength[j] = 20
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0
          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
        }
      }

      const key = jsonKeys
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] = objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length
      }
    }

    return objectMaxLength.map((w: number) => {
      return { width: w }
    })
  }

  const exportToExcel = async () => {
    setLoading(true)
    const jsonData = await generateBankReport()
    if (jsonData === null) {
      setError(true)
    } else {
      const worksheet = utils.json_to_sheet(jsonData, { skipHeader: true, cellDates: true, dateNF: 'dd/mm/yyyy' })
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      worksheet['!cols'] = autofitColumns(jsonData)
      setLoading(false)
      writeFile(workbook, filename, { cellDates: true })

      createClose()
    }
  }

  const changeDates = (datesInput: any) => {
    const arrayDates = [datesInput[0], datesInput[1]]
    const dateStart = `${arrayDates[0].get('year')}-${(arrayDates[0].get('month') + 1 > 9) ? arrayDates[0].get('month') + 1 : '0' + (arrayDates[0].get('month') + 1)}-${(arrayDates[0].get('date') > 9) ? arrayDates[0].get('date') : '0' + arrayDates[0].get('date')}`
    const dateEnd = `${arrayDates[1].get('year')}-${(arrayDates[1].get('month') + 1 > 9) ? arrayDates[1].get('month') + 1 : '0' + (arrayDates[1].get('month') + 1)}-${(arrayDates[1].get('date') > 9) ? arrayDates[1].get('date') : '0' + arrayDates[1].get('date')}`
    setDates([dateStart, dateEnd])
  }

  return <>
    <Button type="primary" onClick={() => createShow()}>
      {t('buttons.generateBanks')}
    </Button>
    <Drawer
      {...createDrawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('order.banksReport.labelDateBanks')}
        </Typography.Text>
        <RangePicker showTime={false} onChange={changeDates} />

        <Button style={{ marginTop: '1%' }} type="primary" disabled={(dates.length < 2)} loading={loading} onClick={() => exportToExcel()}>
          {t('buttons.generateBanks')}
        </Button>
      </Space>

      {(error)
        ? <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>
            {t('order.banksReport.error')}
          </Typography.Text>
        </Space>
        : null}
    </Drawer>
  </>
}
