import { useTranslate } from '@pankod/refine-core'

import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  ButtonProps,
  Grid,
  InputNumber,
  Radio
} from '@pankod/refine-antd'

type CreateCmsCategoryProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateCmsCategory: React.FC<CreateCmsCategoryProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{ extra: null }}
        resource="cms_categories"
        title={t('cmsCategories.titles.create')}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={t('cmsCategories.fields.name')}
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('cmsCategories.fields.weigth')}
            name="weigth"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="is_in_home"
            label={t('marketplace.fields.isInHome')}
            style={{ margin: 0 }}
          >
            <Radio.Group>
              <Radio value={true}>{t('status.yes')}</Radio>
              <Radio value={false}>{t('status.no')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  )
}
