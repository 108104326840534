import { Modal } from '@pankod/refine-antd'

type Props = {
  infoText: string
  okFunction: () => void
  close: () => void
  show: () => void
  modalProps: any
}

export const AdviceModal: React.FC<Props> = ({ infoText, okFunction, modalProps, show, close }) => {
  async function handleSubmit () {
    await okFunction?.()
    close()
  }

  return (
    <Modal
      style={{ zIndex: 800 }}
      {...modalProps}
      children={<b>{infoText}</b>}
      centered
      onOk={handleSubmit}
    />
  )
}
