import { Button, Drawer, ExportButton, Grid, Input, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { supabaseClient } from 'utility'
import { read, utils, writeFile } from 'xlsx'
import { definitions } from 'interfaces'

export const ApplyCostsButton = ({ refetch }: { refetch: () => void }) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const {
    drawerProps,
    show
  } = useDrawerForm<definitions['company_order']>({
    action: 'create',
    redirect: false
  })

  const [loading, setLoading] = useState(false)

  const [updateFile, setUpdateFile] = useState(null)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updateOrderCost = async (orderId: Number, volWeight: Number, volPrice: Number) => {
    await supabaseClient.from('company_order')
      .update({ vol_weight: volWeight, vol_price: volPrice })
      .eq('id', orderId)
  }

  const updateOrdersCosts = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setUpdateFinished(null)

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = utils.sheet_to_json(ws, { header: 1 })

        for (const m of dataParse) {
          if (typeof m[1] !== 'string') {
            const row = { orderId: Number(m[0]), volWeight: Number(m[1]), volPrice: Number(m[2]) }
            await updateOrderCost(row.orderId, row.volWeight, row.volPrice)
          }
        }

        refetch()
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('ok')
      } catch (err) {
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  const downloadTemplate = () => {
    const templateData = [['1234', 1.21, 7.00], ['5678', 2.13, 9.00]]
    const rowList = templateData.map((x: any) => {
      return { 'ID pedido': x[0], 'Peso volumétrico': x[1], Importe: x[2] }
    })
    const worksheet = utils.json_to_sheet(rowList, { skipHeader: false })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    writeFile(workbook, 'apply_orders_costs_template.xlsx')
  }

  return <>
    <Button type="primary" onClick={() => show()}>
      {t('buttons.applyCosts')}
    </Button>
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
        <Typography.Text>
          {t('company_order.applyCosts.labelApplyCosts')}
        </Typography.Text>

        <ExportButton
          style={{ marginBottom: '1rem' }}
          onClick={() => downloadTemplate()}
          size='small'
        >
          {t('company_order.applyCosts.labelTemplate')}
        </ExportButton>

        <Typography.Text>{t('marketplace.actions.uploadExcel')}</Typography.Text>
        <Input
          accept='.xlsx'
          bordered={false}
          size='middle'
          type='file'
          key='exceluploader'
          onChange={handleFile}
        />
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => updateOrdersCosts(e)}
        >
          {t('company_order.applyCosts.labelUpdate')}
        </Button>

        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('company_order.applyCosts.labelUpdating')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t(`company_order.applyCosts.labelFinish${updateFinished}`)}
        </Typography>}

      </Space>
    </Drawer>
  </>
}
