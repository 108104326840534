import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Grid,
  Create,
  Radio,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Checkbox,
  Collapse,
  Icons
} from '@pankod/refine-antd'
import { UsersFinder } from './UsersFinder'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { QueryObserverResult } from '@tanstack/react-query'
import { supabaseClient } from 'utility'
import { UsersImport } from './UsersImport'
import { MarketplacesFinder } from './MarketFinder'

const { Panel } = Collapse

type CreateCodeProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  tableQueryResult: QueryObserverResult
  close: Function
  isEdit: boolean
};

export const CreateCode: React.FC<CreateCodeProps> = ({
  drawerProps,
  formProps,
  tableQueryResult,
  close,
  isEdit
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [showFinder, setShowFinder] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [usersImported, setUsersImported] = useState<any[]>([])
  const [userSelected, setUserSelected] = useState<any>(undefined)
  const [marketSelected, setMarketSelected] = useState<any>(undefined)
  const [codeType, setCodeType] = useState(undefined)
  const [codeConfig, setCodeConfig] = useState<any>(undefined)
  const [validFrom, setValidFrom] = useState<any>(dayjs(new Date(Date.now())))
  const [validUntil, setValidUntil] = useState<any>(dayjs(new Date(Date.now())))
  const [currentValueAttribute, setCurrentValueAttribute] = useState<string | null>(null)
  const [currentDevice, setCurrentDevice] = useState<string>('all')
  const [currentDiscountType, setCurrentDiscountType] = useState<string | null>(null)
  const [currentValue, setCurrentValue] = useState<number>(0)
  const [firstOrder, setFirstOrder] = useState(false)
  const [codeUses, setCodeUses] = useState(1)
  const [code, setCode] = useState('')
  const [minimalPrice, setMinimalPrice] = useState(0)

  const [userGroup, setUserGroup] = useState<any[]>([])

  useEffect(() => {
    if (isEdit && formProps && formProps.initialValues !== undefined) {
      setCode(formProps.initialValues.code)
      updateFormField('code', formProps.initialValues.code)
      setCodeType(formProps.initialValues.type)
      updateFormField('type', formProps.initialValues.type)
      formProps.initialValues.value.conditions.forEach((item: any) => {
        if (item.firstOrder) {
          setFirstOrder(item.firstOrder)
        } else if (item.minimalPrice && item.minimalPrice > 0) {
          setMinimalPrice(item.minimalPrice)
        } else if (item.devices) {
          setCurrentDevice(item.devices)
        } else if (item.numberOfUses) {
          setCodeUses(item.numberOfUses)
        } else if (item.type === 'temporal') {
          setValidFrom(item.validFrom)
          setValidUntil(item.validUntil)
        } else if (item.user && formProps.initialValues?.email !== null) {
          setShowFinder(true)
          setUserSelected(item.user)
        } else if (item.group && formProps.initialValues?.scope === 'group') {
          setShowImport(true)
          setUserGroup(item.group)
        } else if (item.market && formProps.initialValues?.market !== null) {
          setMarketSelected(item.market)
        }
      })

      const values = formProps.initialValues.value.value.split('|')
      setCurrentValueAttribute(values[0])
      setCurrentDiscountType(values[1])
      setCurrentValue(values[2])
      updateFormField('value', formProps.initialValues.value)
    }
  }, [formProps.initialValues])

  useEffect(() => {
    updateCodeConfig(formProps)
  }, [codeType, userSelected, marketSelected, usersImported, validUntil, validFrom, currentValueAttribute, currentDiscountType, currentValue, firstOrder, minimalPrice, currentDevice, codeUses, code])

  const updateFormField = (field: string, value: string | number) => {
    if (formProps && formProps.form) {
      formProps.form.setFields([
        {
          name: [`${field}`],
          value: value
        }
      ])
    }
  }

  const updateUserData = (item: any) => {
    setUserSelected(item)
    updateCodeConfig(formProps)
  }

  const updateMarketData = (item: any) => {
    setMarketSelected(item)
    updateCodeConfig(formProps)
  }

  const updateUsersImported = (users: any) => {
    setUsersImported([...users])
    setUserGroup([...users])
  }

  const validateForm = () => {
    return true
  }

  const updateCodeConfig = (formData?: any) => {
    const finalConfig: any = {}

    if (formData && formData.form) {
      const val = `${currentValueAttribute}|${currentDiscountType}|${currentValue}`
      const conditions: any[] = []
      conditions.push({ firstOrder: firstOrder })
      conditions.push({ numberOfUses: codeUses })
      conditions.push({ minimalPrice: minimalPrice })
      conditions.push({ devices: currentDevice })
      if (codeType === 'temporal') {
        let valueEndDate = dayjs(validUntil)
        const offset = new Date().getTimezoneOffset()
        valueEndDate = valueEndDate.set('hour', 23).set('minute', 59).set('second', 59)
        valueEndDate = valueEndDate.add((-offset), 'minute')
        conditions.push({ type: formProps.form?.getFieldValue('type'), validUntil: valueEndDate, validFrom: validFrom })
      }
      if (formProps.form?.getFieldValue('scope') === 'personal' && userSelected) {
        conditions.push({ personal: formProps.form?.getFieldValue('scope'), user: userSelected })
      }
      if (marketSelected) {
        conditions.push({ market: marketSelected })
      }

      finalConfig.value = val
      finalConfig.conditions = conditions

      setCodeConfig({ ...finalConfig })
    }
  }

  const generateCode = (e: any) => {
    e.preventDefault()
    if (formProps && formProps.form) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < 12) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }

      formProps.form.setFields([
        {
          name: ['code'],
          value: result
        }
      ])
      setCode(result)
    }
    updateCodeConfig(formProps)
  }

  const saveCode = async () => {
    if (validateForm()) {
      const code = {
        code: formProps.form?.getFieldValue('code'),
        description: formProps.form?.getFieldValue('description'),
        enabled: formProps.form?.getFieldValue('enabled'),
        scope: formProps.form?.getFieldValue('scope'),
        type: formProps.form?.getFieldValue('type'),
        email: (formProps.form?.getFieldValue('scope') === 'personal' && userSelected) ? userSelected?.email : null,
        value: codeConfig
      }

      if (showImport && formProps.form?.getFieldValue('scope') === 'group') {
        const val = { ...codeConfig }
        if (usersImported.length <= 0) {
          val.conditions = [...val.conditions, { group: userGroup }]
        } else {
          val.conditions = [...val.conditions, { group: usersImported }]
        }
        code.value = { ...val }
      }

      if (!isEdit) {
        await supabaseClient
          .from('codes')
          .insert(code)
      } else {
        await supabaseClient
          .from('codes')
          .update(code)
          .eq('id', formProps.initialValues?.id)
      }
    } else {
      // show errors
    }

    setUserSelected(undefined)
    setUsersImported([])
    setShowFinder(false)
    setShowImport(false)
    tableQueryResult.refetch()
    close()
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="codes"
        saveButtonProps={{
          onClick: () => saveCode()
        }}
        breadcrumb={false}
        goBack={false}
        title={t('codes.actions.addCode')}
      >
        <Form {...formProps} layout="vertical">
          <div>
            <Form.Item
              name="code"
              label={t('codes.fields.code')}
              style={{ display: 'flex' }}
            >
              <Input disabled={isEdit} onChange={(e) => {
                e.preventDefault()
                setCode(e.target.value)
                if (formProps && formProps.form) {
                  formProps.form.setFields([
                    {
                      name: ['code'],
                      value: e.target.value
                    }
                  ])
                }
              }} style={{ width: '77%', marginRight: '3%' }} value={code} />
              <Button disabled={isEdit} style={{ width: '20%' }} onClick={(e: any) => generateCode(e)}>{t('codes.fields.generate')}</Button>
            </Form.Item>
          </div>

          <Form.Item
            name="description"
            label={t('codes.fields.description')}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="enabled"
            label={t('codes.fields.enabled')}
            initialValue={formProps.initialValues?.enabled || true}
          >
            <Radio.Group onChange={(e: any) => {
              if (formProps && formProps.form) {
                formProps.form.setFields([
                  {
                    name: ['enabled'],
                    value: e.target.value
                  }
                ])
              }
            }}>
              <Radio value={true}>{t('codes.fields.enabled')}</Radio>
              <Radio value={false}>{t('codes.fields.disabled')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="scope"
            label={t('codes.fields.isPersonal')}
            initialValue={'general'}
          >
            <Radio.Group onChange={(e: any) => {
              setShowFinder((e.target.value === 'personal'))
              setShowImport((e.target.value === 'group'))
            }}>
              <Radio disabled={isEdit} value={'personal'}>{t('codes.fields.personal')}</Radio>
              <Radio disabled={isEdit} value={'general'}>{t('codes.fields.general')}</Radio>
              <Radio disabled={isEdit} value={'group'}>{t('codes.fields.list')}</Radio>
            </Radio.Group>
          </Form.Item>

          {showFinder && (
            <>
              {userSelected !== undefined && (<Typography>{`${t('codes.fields.userSelectedText')} ${userSelected?.name}`}</Typography>)}
              <UsersFinder updateUserData={updateUserData} />
            </>
          )}

          {showImport && (
            <>
              <UsersImport updateUsersImported={updateUsersImported} />
              {userGroup.length > 0 && (
                <Collapse style={{ marginBottom: 20 }}>
                  <Panel header={t('codes.fields.list')} key="1">
                    {userGroup.map((u: any) => <p key={u.email}>{u.email}</p>)}
                  </Panel>
                </Collapse>
              )}
            </>
          )}

          <Form.Item
            name="type"
            label={t('codes.fields.type')}
            initialValue={formProps?.initialValues?.type || 'standard'}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                { label: t('codes.fields.typeNormal'), value: 'standard' },
                { label: t('codes.fields.typeTemporal'), value: 'temporal' }
                // { label: t('codes.fields.typeInvitation'), value: 'invitation' }
              ]}
              onChange={(value) => {
                setCodeType(value)
              }}
            />
          </Form.Item>

          {/** Conditions */}
          <Divider style={{ marginTop: -5 }} />
          <Typography style={{ fontWeight: 700, marginBottom: 10 }}>{t('codes.fields.config')}</Typography>

          {codeType === 'temporal' && (
            <>
              <Form.Item
              label={t('codes.fields.validFrom')}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : ''
              })}
              >
                <DatePicker value={dayjs(validFrom || Date.now())} onChange={(value: any) => {
                  setValidFrom(value)
                }} />
              </Form.Item>
              <Form.Item
                label={t('codes.fields.validUntil')}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : ''
                })}
              >
                <DatePicker value={dayjs(validUntil || Date.now())} onChange={(value: any) => {
                  setValidUntil(value)
                }} />
              </Form.Item>
            </>
          )}

          <div style={{ display: 'flex', marginBottom: 10 }}>
            <Checkbox
              style={{ width: '60%', fontSize: '14px' }}
              onChange={(e: any) => {
                setFirstOrder(e.target.checked)
              }}
              checked={firstOrder}
            >
              <Typography>{t('codes.fields.firstOrder')}</Typography>
            </Checkbox>

            {/* Add InputNumber for number of uses */}
            <div style={{ width: '40%', display: 'flex' }}>
              <Typography style={{ width: '50%', fontWeight: 700 }}>
                {t('codes.fields.numberOfUses')}
              </Typography>
              <InputNumber
                min={1}
                style={{ width: '50%', marginTop: -5 }}
                value={codeUses}
                onChange={(e: number) => {
                  setCodeUses(e)
                }}
              />
            </div>
          </div>

          <div style={{ width: '50%', display: 'flex', margin: '20px 0px 10px 0px' }}>
            <Typography style={{ width: '50%', fontWeight: 700 }}>
              {t('codes.fields.minimalPrice')}
            </Typography>
            <InputNumber
              min={0}
              style={{ width: '40%', marginTop: -5 }}
              value={minimalPrice}
              onChange={(e: number) => {
                setMinimalPrice(e)
              }}
            />
          </div>

          <div style={{ width: '50%', display: 'flex', margin: '20px 0px 10px 0px' }}>
            <Typography style={{ width: '50%', fontWeight: 700 }}>
              {t('codes.fields.devices')}
            </Typography>
            <Select style={{ width: '40%' }}
              value={currentDevice}
              onChange={(e: any) => {
                setCurrentDevice(e)
              }}
              options={[
                { label: t('codes.fields.all'), value: 'all' },
                { label: t('codes.fields.onlyWeb'), value: 'onlyWeb' },
                { label: t('codes.fields.onlyMobile'), value: 'onlyMobile' },
                { label: t('codes.fields.onlyAndroid'), value: 'onlyAndroid' },
                { label: t('codes.fields.onlyIOS'), value: 'onlyIOS' }
              ]}
            />
          </div>

          <div style={{ width: '50%', display: 'flex', margin: '20px 0px 0px 0px' }}>
            <Typography style={{ width: '50%', fontWeight: 700 }}>{t('codes.fields.market')}</Typography>
            <div style={{ width: '50%' }}>
              <MarketplacesFinder updateData={updateMarketData} />
            </div>
          </div>
          {marketSelected !== undefined && (<Typography>
            {`${t('codes.fields.marketSelectedText')} ${marketSelected?.name}`}
            <Button icon={<Icons.DeleteOutlined />} style={{ marginLeft: '1rem', width: '2rem' }} onClick={(e: any) => updateMarketData(undefined)}></Button>
          </Typography>)}

          <Typography style={{ marginBottom: 10, fontWeight: 700, marginTop: 10 }}>
            {t('codes.fields.value')}
          </Typography>

          <Row gutter={[64, 0]} wrap>
            {/* Type standard */}
            <Col xs={14} lg={10}>
              <Select style={{ width: '100%' }}
                value={currentValueAttribute}
                onChange={(e: any) => {
                  setCurrentValueAttribute(e)
                }}
                options={[
                  { label: t('codes.fields.price'), value: 'price' },
                  { label: t('codes.fields.allShipment'), value: 'allshipment' },
                  { label: t('codes.fields.standardShipment'), value: 'standardShipment' },
                  { label: t('codes.fields.onlyStandardShipment'), value: 'onlyStandardShipment' },
                  { label: t('codes.fields.onlyEconomyShipment'), value: 'onlyEconomyShipment' }
                ]}
              />
            </Col>
            <Col xs={12} lg={8}>
              <Select style={{ width: '100%' }}
                value={currentDiscountType}
                onChange={(e: any) => {
                  setCurrentDiscountType(e)
                }}
                options={[
                  { label: t('codes.fields.percentage'), value: 'percentage' },
                  { label: t('codes.fields.money'), value: 'money' }
                ]}
              />
            </Col>
            <Col xs={8} lg={6}>
              <InputNumber
                disabled={currentDiscountType === null && currentValueAttribute === null}
                min={0}
                max={100}
                style={{ width: '100%' }}
                value={currentValue}
                onChange={(value: number) => {
                  setCurrentValue(value)
                }}
              />
            </Col>
          </Row>

        </Form>
      </Create>
    </Drawer>
  )
}
