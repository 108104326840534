import { useSelect, useTranslate } from '@pankod/refine-core'

import {
  DrawerProps,
  FormProps,
  Checkbox,
  Create,
  Drawer,
  Form,
  Input,
  Grid,
  Row,
  Col,
  Select
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'

type CreateCodeProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  close: Function
  isEdit: boolean
  supplierId?: number
};

export const CreateSeller: React.FC<CreateCodeProps> = ({
  drawerProps,
  formProps,
  close,
  isEdit,
  supplierId
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [verified, setVerified] = useState<boolean>(false)

  useEffect(() => {
    setVerified(formProps?.initialValues?.verified)
  }, [formProps.initialValues])

  const { options } = useSelect<definitions['supplier_info']>({
    resource: 'supplier_info',
    fetchSize: 1000,
    optionLabel: 'description',
    optionValue: 'id',
    filters: [{ field: 'deleted', operator: 'eq', value: false }]
  })

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="suppliers"
        saveButtonProps={
          {
            onClick: async (e: any) => {
              const supplier = {
                id: supplierId || undefined,
                legal_name: formProps.form?.getFieldValue('legal_name'),
                verified: verified,
                supplier_info_id: formProps.form?.getFieldValue('supplier_info_id'),
                seller: true
              }

              await supabaseClient.from('suppliers')
                .upsert(supplier)

              window.location.reload()
              close()
            }
          }
        }
        breadcrumb={false}
        goBack={false}
        title={(isEdit) ? t('sellers.actions.editSeller') : t('sellers.actions.addSeller')}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={24}>
              <Form.Item
                name="legal_name"
                label={t('sellers.fields.legalName')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[64, 0]} wrap>
            <Col xs={8} lg={8}>
              <Form.Item
                name="verified"
                label={t('sellers.fields.verified')}
              >
                <Checkbox
                  onChange={(e: any) => {
                    setVerified(e.target.checked)
                    formProps?.form?.setFields([
                      {
                        name: ['verified'],
                        value: e.target.checked
                      }
                    ])
                  }}
                  checked={verified}
                />
              </Form.Item>
            </Col>
            <Col xs={16} lg={16}>
              <Form.Item
                name="supplier_info_id"
                label={t('sellers.fields.supplier_info_id')}
              >
                <Select options={options} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </Drawer>
  )
}
