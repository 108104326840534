import { useTranslate, useSelect } from '@pankod/refine-core'
import {
  Form,
  Input,
  FormProps,
  Select,
  Radio,
  InputNumber
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'

import 'react-mde/lib/styles/css/react-mde-all.css'

type MarketplaceCategoriesFormProps = {
  formProps: FormProps<{}>
};

export const MarketplaceCategoriesForm: React.FC<MarketplaceCategoriesFormProps> = (data) => {
  const { options } = useSelect<definitions['marketplace_categories']>({
    resource: 'marketplace_categories',
    fetchSize: 500000,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'parent_id',
        operator: 'null',
        value: ''
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const t = useTranslate()

  return (
    <Form
      {...data?.formProps}
      layout="vertical"
      initialValues={{
        ...data?.formProps?.initialValues,
        parent_id: null,
        weight: data?.formProps?.initialValues?.weight ? data?.formProps?.initialValues.weight : 1
      }}
    >
      <Form.Item
        label={t('marketplace_categories.fields.name')}
        name='name'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.description')}
        name='description'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.weight')}
        name='weight'
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={t('marketplace_categories.fields.parentCategory')}
        name="parent_id"
        rules={[
          {
            required: false
          }
        ]}
      >
          <Select options={[
            { value: null, label: '------' },
            ...options
          ]} />
      </Form.Item>
      <Form.Item
        label={t('marketplace.fields.isInHome')}
        name="is_in_home"
      >
        <Radio.Group>
          <Radio value={true}>{t('status.yes')}</Radio>
          <Radio value={false}>{t('status.no')}</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}
