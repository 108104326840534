import { Checkbox, ExportButton, Form, List, Space, Table, useEditableTable } from '@pankod/refine-antd'
import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { excelDateFormat, formatDateTime, supabaseClient } from 'utility'
import { utils, writeFile } from 'xlsx'

export const PromoList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    setId: setEditId
  } = useEditableTable<definitions['promo']>({
    initialSorter: [
      {
        field: 'created_at',
        order: 'asc'
      }
    ],
    metaData: {
      select: 'id, created_at, name, promo_id, enabled, description, amount, valid_date'
    },
    hasPagination: true
  })

  const t = useTranslate()

  const downloadUsers = async (id: number) => {
    const { data: usersData } = await supabaseClient.from('referral_benefits')
      .select('referred_user_id(id, email, created_at), created_at')
      .eq('promo_id', id)

    if (usersData) {
      const usersList = usersData.map((x: any) => {
        return { users: x.referred_user_id.email, created_at: excelDateFormat(x.created_at), register_date: excelDateFormat(x.referred_user_id.created_at) }
      })
      const worksheet = utils.json_to_sheet(usersList, { skipHeader: true })
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      writeFile(workbook, `users_promo_${id}.xlsx`)
    }
  }

  return (
    <List title={t('promos.title')}>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title='ID'
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="promo_id"
            title={t('promos.fields.promo_id')}
            sorter
          />
          <Table.Column
            dataIndex="name"
            title={t('promos.fields.name')}
            sorter
          />
          <Table.Column
            dataIndex="description"
            width={400}
            title={t('promos.fields.description')}
            sorter
          />
          <Table.Column
            dataIndex="created_at"
            title={t('promos.fields.created_at')}
            render={(val: string) => `${formatDateTime(val, true)}`}
            sorter
          />
          <Table.Column
            dataIndex="amount"
            title={t('promos.fields.wallet')}
            render={(val: number) => `${val}€`}
            sorter
          />
          <Table.Column
            dataIndex="valid_date"
            title={t('promos.fields.valid_date')}
            render={(val: string) => `${formatDateTime(val, true)}`}
            sorter
          />
          <Table.Column
            dataIndex="enabled"
            title={t('promos.fields.enabled')}
            render={(val: boolean) => <Checkbox checked={val} disabled={true} />}
            sorter
          />

          <Table.Column
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record: any) => {
              return (
                <>
                  <Space>
                    <ExportButton
                      onClick={() => downloadUsers(record.id)}
                      hideText
                      size='small'
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
    </List>
  )
}
