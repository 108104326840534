import { AutoComplete, Icons, Input } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'

import { supabaseClient } from 'utility'

const { SearchOutlined } = Icons

type SupplierFinderProps = {
  updateMarketplaceData: Function
}

export const SupplierFinder: React.FC<SupplierFinderProps> = ({ updateMarketplaceData }) => {
  const [value, setValue] = useState<string>('')
  const [options, setOptions] = useState<any[]>([])

  const updateSelected = (item: any) => {
    updateMarketplaceData(item)
  }

  const renderItem = (label: string, item: any) => {
    return (
      <div onClick={(e) => {
        e.preventDefault()
        updateSelected(item)
      }}>
        <p style={{ height: '15px', textAlign: 'start' }}>{label}</p>
      </div>
    )
  }

  const refetchPosts = async () => {
    const { data: marketplaces, error: errorMarketplaces } = await supabaseClient
      .from('suppliers')
      .select('id, cif, legal_name, legal_address, province, postal_code')
      .or(`cif.ilike.%${value}%, legal_name.ilike.%${value}%`)
      .eq('deleted', false)
      .neq('legal_name', '')

    if (errorMarketplaces) {
      return
    }

    if (marketplaces) {
      const data = [...marketplaces]
      data.forEach(item => {
        item.label = renderItem(item.legal_name, item)
      })

      if (data.length > 0) {
        setOptions([
          {
            label: (<p>{'Providers'}</p>),
            options: data
          }
        ])
      }
    }
  }

  useEffect(() => {
    setOptions([])
    refetchPosts()
  }, [value])

  return (
    <>
      <AutoComplete
        style={{ width: '100%', marginBottom: '1rem' }}
        filterOption={false}
        options={options}
        onSearch={(value: string) => setValue(value)}
      >
        <Input
          size="large"
          placeholder="Search posts or categories"
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
    </>
  )
}
