import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { OrdersList } from './ordersList'
import '../../custom.css'
import { CANCELLED_STATUS, DECREASE_RETURN_STATUS, INTERNAL_SPENDING_RETURN_STATUS, NO_STOCK_STATUS, ORDER_STATUS_ID_PENDING_PAYMENT, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, RETURN_STATUS, formatDateTime } from 'utility'
import { Tabs } from '@pankod/refine-antd'
import { useState } from 'react'
import { ESTIMATED_COSTS_STATUS_MAPPER } from 'utility/mapper'
import { EstimatedCostsStatusIdTypes } from 'types/types'

export type OrdersListProps = {
  select: string
  dataToExport: (orders: any[]) => any
  getConditionalClassName: (record: definitions['orders']) => string
  onlyPaidOrders: boolean
  statusOrder: Number[] | Number | null
}

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const select = 'id, alias, device, total, type, cancelation_date, return_date, created_at, locked, locked_by, prev_status_id, package_number, location, estimated_costs_status,' +
  'user(id, email), administrator(name), status_id, status(id, name),' +
  'payment_method(name), marketplace_id(id, name, original_url), shipping_method(name),' +
  'shipments(type, shipper_type, delivery_note, extra_cost, method_id(name)),' +
  'market_orders(purchase_id, transaction_id, purchase_amount, invoice_number),' +
  'supplements(supplement, status(id, name)),' +
  'items!inner(custom_order_site)'

  const t = useTranslate()

  const [tab, setTab] = useState(localStorage.getItem('lastOrderListTab') || '1')

  const getConditionalClassName = (record: definitions['orders']) => {
    if (record.cancelation_date && (record.status_id !== CANCELLED_STATUS && record.status_id !== PENALIZATION_STATUS && record.status_id !== NO_STOCK_STATUS)) {
      return 'cancelled-order'
    }
    if (record.return_date && record.status_id !== RETURN_STATUS && record.status_id !== PARCIAL_RETURN_STATUS && record.status_id !== DECREASE_RETURN_STATUS && record.status_id !== INTERNAL_SPENDING_RETURN_STATUS) {
      return 'return-order'
    }
    return ''
  }

  const handleClick = (key: string, e: any) => {
    e.preventDefault()
    setTab(key)
    localStorage.setItem('lastOrderListTab', key)
  }

  const dataToExport = (orders: any[]) => {
    return orders?.map((order: any) => {
      const shipment = order?.shipments[0]
      const marketOrders = order?.market_orders
      const marketplace = order?.marketplace_id?.id ? order?.marketplace_id?.name : (order?.items[0]?.custom_order_site || order?.marketplace_id?.name)

      const invoiceNumbers = marketOrders?.map((m: definitions['market_orders']) => m?.invoice_number || '').join(', ')
      const transactionIds = marketOrders?.map((m: definitions['market_orders']) => m?.transaction_id || '').join(', ')
      const purchaseIds = marketOrders?.map((m: definitions['market_orders']) => m?.purchase_id || '').join(', ')

      const supplementAmount = order?.supplements
        .map((s: definitions['supplements']) => s.supplement || 0)
        .reduce((a: number, b: number) => a + b, 0)

      const marketOrdersAmount = order?.market_orders
        .map((s: definitions['market_orders']) => s.purchase_amount || 0)
        .reduce((a: number, b: number) => a + b, 0)

      return {
        [t('order.fields.id')]: order?.id || '',
        [t('order.fields.createdAt')]: formatDateTime(order?.created_at, true) || '',
        [t('order.fields.alias')]: order?.alias || '',
        [t('order.fields.package_number')]: order?.package_number || '',
        [t('order.fields.location')]: order?.location || '',
        [t('order.fields.userEmail')]: order?.user?.email || '',
        [t('order.fields.marketplace')]: marketplace || '',
        [t('order.fields.device')]: order?.device || '',
        [t('order.fields.billingAmount')]: order?.total || 0,
        [t('order.fields.paymentMethod')]: order?.payment_method?.name || '',
        [t('order.fields.status')]: capitalizeFirstLetter(order?.status?.name) || '',
        [t('order.fields.type')]: order?.type || '',
        [t('order.fields.estimatedStatus')]: ESTIMATED_COSTS_STATUS_MAPPER[order.estimated_costs_status as EstimatedCostsStatusIdTypes],
        [t('order.fields.shipmentMethod')]: order?.shipping_method?.name || '',
        [t('order.fields.shipmentType')]: capitalizeFirstLetter(shipment?.type) || '',
        [t('order.fields.shipperType')]: shipment?.shipper_type || '',
        [t('order.fields.deliveryNote')]: shipment?.delivery_note || '',
        [t('order.fields.supplementShipment')]: supplementAmount || 0,
        [t('order.fields.administrator')]: order?.administrator?.name || '',
        [t('market_order.fields.purchaseId')]: purchaseIds || '',
        [t('market_order.fields.transactionId')]: transactionIds || '',
        [t('market_order.fields.purchaseAmount')]: marketOrdersAmount || 0,
        [t('market_order.fields.invoiceNumber')]: invoiceNumbers || ''
      }
    })
  }

  return (

    <Tabs defaultActiveKey='1' activeKey={tab} size='middle' onTabClick={handleClick}>
      <Tabs.TabPane tab={t('order.orders')} key='1'>
        <OrdersList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={true}
          statusOrder={null}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.pendingPaymentOrders')} key='2'>
        <OrdersList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={ORDER_STATUS_ID_PENDING_PAYMENT}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.cancelledOrders')} key='3'>
        <OrdersList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={CANCELLED_STATUS}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.returnedOrders')} key='4'>
        <OrdersList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={[RETURN_STATUS, PARCIAL_RETURN_STATUS, DECREASE_RETURN_STATUS]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.noStockOrders')} key='5'>
        <OrdersList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={NO_STOCK_STATUS}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}
