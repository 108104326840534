import { useTranslate } from '@pankod/refine-core'
import { Alert, Form, Modal, ModalProps, Select } from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'
import { useState } from 'react'
import { BUSINESS_STATUS_MAPPER } from 'utility/mapper'

type ModalChangeBusinessOrdersStatusProps = {
  modalProps: ModalProps,
  close: any,
  selectedRows: definitions['company_order'][]
  getCompanyOrderList: Function
}

export const ModalChangeBusinessOrdersStatus: React.FC<ModalChangeBusinessOrdersStatusProps> = ({ modalProps, close, selectedRows, getCompanyOrderList }) => {
  const t = useTranslate()
  const [statusId, setStatusId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showUpdateError, setShowUpdateError] = useState<boolean>(false)
  const [showUpdateAlert, setShowUpdateAlert] = useState<boolean>(false)

  const updateOrdersStatus = async () => {
    setLoading(true)
    const data = selectedRows?.map((row: any) => { return { orderId: row.id } })
    const userEmail = supabaseClient.auth.user()?.email

    if (data.length === 0) setShowUpdateAlert(true)

    try {
      for (const order of data) {
        const { data, error } = await supabaseClient.from('company_order')
          .update({
            status_id: statusId,
            updated_by: userEmail
          })
          .eq('id', order.orderId)
        if (data && !error) {
          getCompanyOrderList()
          close()
        }
      }
    } catch (error) {
      setShowUpdateError(true)
    }
    setLoading(false)
  }

  return (
    <Modal
      {...modalProps}
      title={t('actions.changeOrdersStatus')}
      centered
      onOk={updateOrdersStatus}
      onCancel={() => {
        close()
        setShowUpdateAlert(false)
        setShowUpdateError(false)
      }}
      confirmLoading={loading}
    >
      {`${t('menu.orders')}: ${selectedRows?.map((row) => row.id).join(', ')}`}
      <Form layout='vertical'>
        <Form.Item
          label={t('order.fields.status')}
        >
          <Select options={
            Object.entries(BUSINESS_STATUS_MAPPER)
              .map(([key, value]) => ({
                value: parseInt(key),
                label: t(`company_order.status.${value}`)
              }))
          } onChange={(value) => setStatusId(value)} />
        </Form.Item>
        {showUpdateError && <Alert message={t('company_order.error.updateStatusError')} type="error" />}
        {showUpdateAlert && <Alert message={t('company_order.error.updatesStatusAlert')} type="warning" />}
      </Form>
    </Modal>
  )
}
