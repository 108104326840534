import { Tabs } from '@pankod/refine-antd'
import { IResourceComponentsProps, useInvalidate, useTranslate } from '@pankod/refine-core'
import { InvoicesMatchingsTable } from 'components/InvoicesMatchings/InvoicesMatchingsTable'
import { UnassignedInvoicesTable } from 'components/InvoicesMatchings/UnassignedInvoicesTable'
import { PendingMarketOrders } from 'interfaces'
import { useState } from 'react'
import { InvoicesMatchingsContext } from 'utility/contexts'

export const InvoicesMatchingsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const invalidate = useInvalidate()
  const [selectedRows, setSelectedRows] = useState<PendingMarketOrders[]>([])

  function invalidateAll () {
    setSelectedRows([])
    invalidate({ invalidates: ['all'] })
  }

  return (
    <InvoicesMatchingsContext.Provider value={invalidateAll}>
      <Tabs defaultActiveKey="0" size='middle' onChange={invalidateAll}>
        <Tabs.TabPane tab={t('invoices_matchings.titles.orders')} key={'orders'}>
          <InvoicesMatchingsTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('invoices_matchings.titles.unassigned_invoices')} key={'unassigned_invoices'}>
          <UnassignedInvoicesTable />
        </Tabs.TabPane>
      </Tabs>
    </InvoicesMatchingsContext.Provider>
  )
}
