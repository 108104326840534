
import { useTranslate, useSelect } from '@pankod/refine-core'

import {
  Form,
  Select,
  useForm
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'
import { useState, useEffect } from 'react'

type MarketplaceRelationsFormProps = {
  marketplaceId: number
}

export const MarketplaceRelationsForm: React.FC<MarketplaceRelationsFormProps> = ({ marketplaceId }) => {
  const { formProps: marketplacesRelationsFormProps } = useForm<definitions['marketplace_relations']>()
  const [relations, setRelations] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  const t = useTranslate()

  const getMarketplaceRelations = async () => {
    const { data } = await supabaseClient
      .from('marketplace_relations')
      .select('related_marketplace_id')
      .eq('marketplace_id', marketplaceId)

    return data
  }

  useEffect(() => {
    if (marketplaceId) {
      getMarketplaceRelations().then((data) => {
        setRelations(data?.map((relation) => relation.related_marketplace_id))
        setIsLoading(false)
      })
    }
  }, [marketplaceId])

  const { options } = useSelect<definitions['marketplaces']>({
    resource: 'marketplaces',
    fetchSize: 100000,
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const deleteAllRelations = async () => {
    await supabaseClient
      .from('marketplace_relations')
      .delete()
      .eq('marketplace_id', marketplaceId)
  }

  const insertRelatedMarketplaces = async (relatedMarketplaces: []) => {
    await deleteAllRelations()

    await supabaseClient
      .from('marketplace_relations')
      .insert(
        relatedMarketplaces?.map((marketplace: number) => (
          { marketplace_id: marketplaceId, related_marketplace_id: marketplace }
        )), { upsert: true })
  }

  if (isLoading) {
    return <></>
  }

  return (
    <>
    {Number.isInteger(marketplaceId) && (
      <Form
        {...marketplacesRelationsFormProps}
        layout='vertical'
      >
        <Form.Item
          label={t('marketplace.fields.relatedMarketplaces')}
          name="related_marketplace_id"
          initialValue={relations}
        >
          <Select
            mode='multiple'
            options={options}
            getPopupContainer={trigger => trigger.parentElement}
            filterOption={(input, option) => (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())}
            onChange={(value) => insertRelatedMarketplaces(value)}
            />
        </Form.Item>
      </Form>
    )}
    </>
  )
}
