import { useTranslate, IResourceComponentsProps, getDefaultFilter } from '@pankod/refine-core'
import {
  List,
  Table,
  Form,
  Space,
  Button,
  SaveButton,
  EditButton,
  Input,
  useEditableTable,
  useSelect,
  FilterDropdownProps,
  FilterDropdown,
  Select,
  useModal,
  Upload,
  RcFile,
  Image
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { normalizeFile, supabaseClient } from 'utility'

export const PaymentMethodsList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    setId: setEditId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    filters,
    tableQueryResult
  } = useEditableTable<definitions['payment_methods']>({
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ]
  })

  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  const { selectProps: paymentProps } = useSelect<definitions['payment_methods']>({
    resource: 'payment_methods',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: getDefaultFilter('id', filters, 'in')
  })

  return (
    <List title={t('payment_methods.titles.list')}>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column dataIndex="id" title={t('payment_methods.fields.id')} align='center' sorter />
          <Table.Column
            dataIndex="logo_url"
            title={t('item.fields.image')}
            key="image"
            align="center"
            render={(value, data: definitions['payment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="logo"
                    valuePropName="fileList"
                    normalize={normalizeFile}
                    noStyle
                  >
                    <Upload.Dragger
                      name="logo"
                      listType="picture"
                      customRequest={async ({
                        file,
                        onError,
                        onSuccess
                      }) => {
                        const rcFile = file as RcFile
                        const fileUrl = `${rcFile.name}`

                        const { error } = await supabaseClient.storage
                          .from('logos')
                          .upload(fileUrl, file, {
                            cacheControl: '3600',
                            upsert: true
                          })

                        if (error) {
                          return onError?.(error)
                        }
                        const { data, error: urlError } =
                          await supabaseClient.storage
                            .from('logos')
                            .getPublicUrl(fileUrl)

                        if (urlError) {
                          return onError?.(urlError)
                        }

                        onSuccess?.(
                          { url: data?.publicURL },
                          new XMLHttpRequest()
                        )
                      }}
                    >
                      <p className="ant-upload-text">
                        {t('actions.dragFile')}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                )
              }
              return value
                ? <Image
                    src={value}
                    width={64}
                  />
                : null
            }}
          />
          <Table.Column
            dataIndex="name"
            title={t('payment_methods.fields.name')}
            key="id"
            align='center'
            render={(value, data: definitions['payment_methods']) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item
                    name="name"
                    style={{ margin: 0 }}
                  >
                    <Input />
                  </Form.Item>
                )
              }
              return value
            }
           }
           sorter
           filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
                {...props}
                mapValue={(selectedKeys) =>
                  selectedKeys.map((i) => parseInt(i.toString()))
                }
            >
                <Select
                    style={{ minWidth: 200 }}
                    mode="multiple"
                    {...paymentProps}
                />
            </FilterDropdown>
           )}
          />
          <Table.Column<definitions['payment_methods']>
            title={t('table.actions')}
            dataIndex="actions"
            align='center'
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      hideText
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                    >
                      {t('buttons.cancel')}
                    </Button>
                  </Space>
                )
              }
              return (
                <>
                  <Space>
                    <EditButton
                      {...editButtonProps(record.id)}
                      hideText
                      size="small" />

                      <ButtonLogicDelete click={() => {
                        show()
                        setId(record.id)
                      }} />
                      <DeleteModal
                        modalProps={modalProps}
                        close={close}
                        id={id}
                        entity='payment_methods'
                        tableQueryResult={tableQueryResult}
                      />
                  </Space>
                </>

              )
            }
          }
          />
        </Table>
      </Form>
    </List>
  )
}
