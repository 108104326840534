import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Edit,
  FormProps,
  Grid
} from '@pankod/refine-antd'
import { useEffect } from 'react'
import { useForceUpdate } from 'utility/useForceUpdate'
import { MarketplaceForm } from './form'
import { updateIssueDeadlines } from 'utility'

type MarketplaceEditProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  saveButtonProps: ButtonProps
}

export const MarketplaceEditDrawer: React.FC<MarketplaceEditProps> = ({
  drawerProps,
  formProps,
  saveButtonProps
}) => {
  const breakpoint = Grid.useBreakpoint()
  const forceUpdate = useForceUpdate()

  useEffect(() => forceUpdate(), [formProps.initialValues?.id])

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '70vw' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={{
          onClick: async (e: any) => {
            if (saveButtonProps && saveButtonProps.onClick) await saveButtonProps?.onClick(e)
            updateIssueDeadlines(formProps.initialValues?.id)
          }
        }}
      >
        <MarketplaceForm
          edit={true}
          formProps={formProps}
        />
      </Edit>
    </Drawer>
  )
}
