import { Form, Input, Button, Icons, Modal, useModal } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { supabaseClient } from 'utility'

const SellerFilter: React.FC = () => {
  const t = useTranslate()
  const [inputVal, setInputVal] = useState('')
  const [results, setResults] = useState<any|null>(null)

  const { modalProps, show, close } = useModal()

  const handleSearch = async () => {
    const { data } = await supabaseClient.from('suppliers')
      .select('id, created_at, legal_name, verified, supplier_info_id(id, description)')
      .eq('deleted', false)
      .eq('seller', true)
      .ilike('legal_name', `%${inputVal}%`)

    setResults(data)
    show()
  }

  return <>
    <Form layout="vertical">
      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}>
        <Form.Item>
          <Input value={inputVal} onChange={(e: any) => setInputVal(e.target.value)} />
        </Form.Item>
        <Button style={{ marginBottom: '15px' }} onClick={() => handleSearch()} type="primary">
          <Icons.SearchOutlined />
        </Button>
      </div>
    </Form>
    <Modal
      {...modalProps}
      title={t('sellers.actions.title')}
      onCancel={() => close()}
      onOk={() => close()}
    >
      {(results && results.length > 0)
        ? results?.map((r: any) => {
          return <>
            <p key={r.id}>{r.legal_name}</p>
            {(r.supplier_info_id) && <ul>
              <li>{r.supplier_info_id.description}</li>
            </ul>}
          </>
        })
        : <>
          <b>{t('sellers.actions.noResults')}</b>
          <br/><br/>
          <b>{t('sellers.actions.adviseInfo')}</b>
        </>
      }
    </Modal>
  </>
}

export default SellerFilter
