import { Button, Checkbox, CreateButton, EditButton, Form, FormProps, Icons, Input, List, Modal, Space, Table, Typography, useDrawerForm, useEditableTable } from '@pankod/refine-antd'
import { useTranslate, IResourceComponentsProps, CrudFilters } from '@pankod/refine-core'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { CreateSeller } from 'components/sellers/create'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { filter, getSupplierExport, supabaseClient } from 'utility'

export const SellerList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    searchFormProps,
    setId: setEditId
  } = useEditableTable<definitions['suppliers']>({
    resource: 'suppliers',
    initialSorter: [
      {
        field: 'id',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      },
      {
        field: 'seller',
        operator: 'eq',
        value: true
      }
    ],
    metaData: {
      select: 'id, created_at, legal_name, verified, supplier_info_id(id, description)'
    },
    hasPagination: true,
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { q } = params

      filters.push({ field: 'legal_name', operator: 'contains', value: q })
      setAdditionalFilters([{ field: 'legal_name', operator: 'contains', value: q }])

      return filters
    }
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['suppliers']>({
    action: 'create',
    resource: 'suppliers',
    redirect: false
  })

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['suppliers']>({
    action: 'edit',
    resource: 'suppliers',
    redirect: false
  })

  const [additionalFilters, setAdditionalFilters] = useState<filter[] | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<number>(-1)

  const deleteSeller = async () => {
    if (currentId > -1) {
      await supabaseClient
        .from('suppliers')
        .update({
          deleted: true
        })
        .eq('id', currentId)
    }
    setShowModal(false)
    setCurrentId(-1)
  }

  const t = useTranslate()

  async function exportSellers () {
    const data = await getSupplierExport(
      'id, created_at, legal_name, verified, supplier_info_id(id, description)',
      additionalFilters,
      true
    )

    return dataToExport(data as any[])
  }

  const dataToExport = (providers: any[]) => {
    return providers?.map((provider: any) => {
      return {
        [t('suppliers.fields.id')]: provider?.id,
        [t('suppliers.fields.legalName')]: provider?.legal_name,
        [t('suppliers.fields.supplier_info_id')]: provider?.supplier_info_id?.description,
        [t('suppliers.fields.verified')]: (provider?.verified) ? 1 : 0
      }
    })
  }

  const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
      <Form layout="vertical" {...formProps}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem' }}>
          <Form.Item name="q">
            <Input
                placeholder={t('sellers.filterBy')}
                prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
          <Button style={{ marginBottom: '15px' }} htmlType="submit" type="primary">
            {t('sellers.actions.filter')}
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <List title={t('sellers.title')} headerButtons={
      <>
        <CreateButton onClick={() => createShow()}>
          {t('sellers.actions.addSeller')}
        </CreateButton>
        <ExportToExcelButton filename='Sellers' asyncFunction={() => exportSellers()} />
      </>
    }>
      <Filter formProps={searchFormProps} />
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === 'TD') {
                setEditId && setEditId(record.id)
              }
            }
          })}
        >
          <Table.Column
            dataIndex="id"
            title='ID'
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="legal_name"
            title={t('sellers.fields.legalName')}
          />
          <Table.Column
            dataIndex="verified"
            title={t('sellers.fields.verified')}
            render={(_, record: any) => {
              return (
                <>
                  <Checkbox style={{
                    alignItems: 'center'
                  }}
                  checked={record.verified} />
                </>
              )
            }}
          />
          <Table.Column
            dataIndex="supplier_info_id"
            title={t('sellers.fields.supplier_info_id')}
            render={(_, record: definitions['suppliers']) => (record?.supplier_info_id as any)?.description || ''}
          />
          <Table.Column
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record: any) => {
              return (
                <>
                  <Space>
                    <EditButton
                      onClick={() => {
                        editShow(record.id)
                        setCurrentId(record.id)
                      }}
                      hideText
                      size='small'
                    />
                    <Button
                      onClick={() => {
                        setShowModal(true)
                        setCurrentId(record.id)
                      }}
                      icon={<Icons.DeleteOutlined style={{ color: 'red' }} />}
                      size='small'
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
      <CreateSeller
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        close={createClose}
        isEdit={false}
      />
      <CreateSeller
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        close={editClose}
        isEdit={true}
        supplierId={currentId}
      />
      <Modal visible={showModal} onCancel={() => setShowModal(false)} onOk={() => deleteSeller()}>
        <Space>
          <Typography>{t('sellers.modalTitle')}</Typography>
        </Space>
      </Modal>
    </List>
  )
}
