import { useTranslate } from '@pankod/refine-core'
import {
  Button,
  Drawer,
  DrawerProps,
  ExportButton,
  Form,
  Grid,
  Input,
  Typography
} from '@pankod/refine-antd'
import { useState } from 'react'
import * as XLSX from 'xlsx'
import { supabaseClient } from 'utility'

type MarketplacesUpdateWeightsProps = {
  drawerProps: DrawerProps
}

export const MarketplaceUpdateWeightsDrawer: React.FC<MarketplacesUpdateWeightsProps> = ({
  drawerProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [updateFile, setUpdateFile] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [updateFinished, setUpdateFinished] = useState<string | null>(null)

  const handleFile = (e: any) => {
    setUpdateFile(e.target.files[0])
  }

  const updateMarketplaceWeigth = async (name: string, weight: number) => {
    await supabaseClient.from('marketplaces')
      .update({
        weight: weight
      })
      .ilike('name', `%${name.toLowerCase()}%`)
  }

  const updateWeights = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setUpdateFinished(null)

    const reader = new FileReader()
    reader.onload = async function (e) {
      try {
        const data = e?.target?.result
        const readedData = XLSX.read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        const dataParse: any = XLSX.utils.sheet_to_json(ws, { header: 1 })
        console.log(dataParse)

        for (const m of dataParse) {
          if (typeof m[1] !== 'string') {
            const row = { name: String(m[0]), weight: Number(m[1]) }
            await updateMarketplaceWeigth(row.name, row.weight)
          }
        }

        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('ok')
      } catch (err) {
        setLoading(false)
        setUpdateFile(null)
        setUpdateFinished('error')
      }
    }

    if (updateFile) {
      reader.readAsBinaryString(updateFile)
    }
  }

  const downloadTemplate = () => {
    const templateData = [['Amazon', 1], ['Zalando', 2]]
    const rowList = templateData.map((x: any) => {
      return { Marca: x[0], Peso: x[1] }
    })
    const worksheet = XLSX.utils.json_to_sheet(rowList, { skipHeader: false })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'weights_template.xlsx')
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '70vw' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <div style={{ margin: '2rem' }}>
        <Form layout="vertical" onFinish={updateWeights}>
          <ExportButton
            style={{ marginBottom: '1rem' }}
            onClick={() => downloadTemplate()}
            size='small'
          >
            {t('marketplace.actions.template')}
          </ExportButton>
          <Typography style={{ marginBottom: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
            {t('marketplace.actions.importantInfo')}
          </Typography>
          <Form.Item
            name="Upload Image"
            label={t('marketplace.actions.uploadExcel')}
          >
            <Input
              accept='.xlsx'
              bordered={false}
              size='middle'
              type='file'
              key='exceluploader'
              onChange={handleFile}
            />
          </Form.Item>
        </Form>
        <Button disabled={updateFile === null} loading={loading} type='primary'
          onClick={(e: any) => updateWeights(e)}
        >
          {t('marketplace.actions.update')}
        </Button>
        {loading && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t('marketplace.actions.updating')}
        </Typography>}
        {updateFinished && <Typography style={{ marginTop: '2rem', fontWeight: 600, fontStyle: 'italic' }}>
          {t(`marketplace.actions.finish${updateFinished}`)}
        </Typography>}
      </div>
    </Drawer>
  )
}
