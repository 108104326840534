import { IInput } from 'interfaces'
import { Form, Select, InputNumber, Input } from '@pankod/refine-antd'

export const FormItems = (input: IInput, keyIndex: number) => {
  const FormItem = (input: IInput, keyIndex: number, slot: JSX.Element) => {
    return <Form.Item
      key={keyIndex}
      label={input.label}
      name={input.name}
      rules={[
        {
          required: input.required
        }
      ]}
      help={input.help || undefined}
    >
      {slot}
    </Form.Item>
  }

  if (input.select) {
    return FormItem(input, keyIndex, <Select options={input.select} />)
  } else if (input.number) {
    return FormItem(input, keyIndex, <InputNumber addonAfter={input.number.money ? '€' : undefined} defaultValue={0} min={input.number.min} max={input.number.max} decimalSeparator=',' />)
  }
  return FormItem(input, keyIndex, <Input name={input.name} />)
}
