import { Button, Checkbox, CreateButton, EditButton, Form, Icons, List, Modal, Space, Table, Typography, useDrawerForm, useEditableTable } from '@pankod/refine-antd'
import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import { CreateItemCategories } from 'components/item_categories/create'
import { definitions } from 'interfaces'
import { useState } from 'react'
import { supabaseClient } from 'utility'

export const itemCategories: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    tableQueryResult
  } = useEditableTable<definitions['item_categories']>({
    initialSorter: [
      {
        field: 'code',
        order: 'asc'
      }
    ],
    permanentFilter: [
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'id, code, description, apply_iva, iva_percent, igic_percent, aiem_percent'
    },
    hasPagination: true
  })

  const {
    drawerProps: createDrawerProps,
    saveButtonProps: createButtonProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose
  } = useDrawerForm<definitions['item_categories']>({
    action: 'create',
    resource: 'item_categories',
    redirect: false
  })

  const {
    drawerProps: editDrawerProps,
    saveButtonProps: editButtonProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose
  } = useDrawerForm<definitions['item_categories']>({
    action: 'edit',
    resource: 'item_categories',
    redirect: false
  })

  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<number>(-1)

  const deleteSupplier = async () => {
    if (currentId > -1) {
      await supabaseClient
        .from('item_categories')
        .update({
          deleted: true
        })
        .eq('id', currentId)
    }
    setShowModal(false)
    setCurrentId(-1)
    tableQueryResult.refetch()
  }

  const t = useTranslate()

  return (
    <List title={t('tariff.title')} headerButtons={
      <>
        <CreateButton onClick={() => createShow()}>
          {t('tariff.actions.addTariff')}
        </CreateButton>
      </>
    }>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
        >
          <Table.Column
            dataIndex="code"
            title={t('tariff.code')}
            align='center'
            sorter
          />
          <Table.Column
            dataIndex="description"
            title={t('tariff.description')}
          />
          <Table.Column
            dataIndex="iva_percent"
            title={t('tariff.iva_percent')}
          />
          <Table.Column
            dataIndex="apply_iva"
            title={t('tariff.apply_iva')}
            render={(value) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Checkbox checked={value} disabled={true} />
              </div>
            )}
          />
          <Table.Column
            dataIndex="igic_percent"
            title={t('tariff.igic_percent')}
          />
          <Table.Column
            dataIndex="aiem_percent"
            title={t('tariff.aiem_percent')}
          />
          <Table.Column
            title={t('table.actions')}
            align='center'
            dataIndex="actions"
            render={(_, record: any) => {
              return (
                <>
                  <Space>
                    <EditButton
                      onClick={() => editShow(record.id)}
                      hideText
                      size='small'
                    />
                    <Button
                      onClick={() => {
                        setShowModal(true)
                        setCurrentId(record.id)
                      }}
                      icon={<Icons.DeleteOutlined style={{ color: 'red' }} />}
                      size='small'
                    />
                  </Space>
                </>
              )
            }}
          />
        </Table>
      </Form>
      <CreateItemCategories
        drawerProps={createDrawerProps}
        saveButtonProps={createButtonProps}
        formProps={createFormProps}
        close={createClose}
        isEdit={false}
      />
      <CreateItemCategories
        drawerProps={editDrawerProps}
        saveButtonProps={editButtonProps}
        formProps={editFormProps}
        close={editClose}
        isEdit={true}
      />
      <Modal visible={showModal} onCancel={() => setShowModal(false)} onOk={() => deleteSupplier()}>
        <Space>
          <Typography>{t('suppliers.modalTitle')}</Typography>
        </Space>
      </Modal>
    </List>
  )
}
