import { IResourceComponentsProps, useSelect, useTranslate } from '@pankod/refine-core'

import {
  Alert,
  Edit,
  Form,
  Select,
  useForm
} from '@pankod/refine-antd'

import { definitions } from 'interfaces'
import { ShipmentsForm } from './form'
import { supabaseClient } from 'utility'
import { useState } from 'react'

export const ShipmentEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<definitions['shipments']>({
    metaData: {
      select: '*, orders(status_id)'
    }
  })

  const { options: statusOptions } = useSelect<definitions['status']>({
    resource: 'status',
    fetchSize: 30,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc'
      }
    ]
  })

  const t = useTranslate()

  const [showUpdateError, setShowUpdateError] = useState<boolean>(false)

  const updateOrderStatus = async (newStatus: string) => {
    setShowUpdateError(false)
    const { data: lastStatus } = await supabaseClient.from('orders').select('status_id').eq('id', formProps.initialValues?.order_id).single()

    if (lastStatus) {
      await supabaseClient
        .from('orders')
        .update({
          status_id: newStatus,
          prev_status_id: lastStatus.status_id,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', formProps.initialValues?.order_id)
    } else {
      setShowUpdateError(true)
    }
  }

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={t('shipments.titles.edit')}
    >
      <ShipmentsForm
        formProps={formProps}
      />
      {Number.isInteger(formProps.initialValues?.orders.status_id) && (
        <Form.Item
          label={t('shipments.fields.status')}
        >
          <Select
            options={statusOptions}
            defaultValue={formProps.initialValues?.orders?.status_id}
            onChange={(value) => updateOrderStatus(value)}
          />
        </Form.Item>
      )}
      {showUpdateError && <Alert message={t('shipments.errors.errorUpdating')} type="warning" />}
    </Edit>
  )
}
