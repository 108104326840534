import { Col, DateField, Descriptions, Row, Tag } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT, capitalizeFirstLetter, currency, getColorLabel, getOrderIdAlias } from 'utility'

export const OrderSimpleInfo: React.FC<{ order?: definitions['Order'] }> = ({ order }) => {
  const t = useTranslate()

  function showText (value?: string) {
    return order ? value : ''
  }

  return (
    <Row key={order?.id} gutter={[64, 0]} wrap>
      <Col xs={24}>
        <Descriptions title={t('order.details')} labelStyle={{ fontWeight: 'bold' }} column={1}>
          <Descriptions.Item label={t('order.fields.marketplace')}>{showText(order?.marketplace_id.name)}</Descriptions.Item>
          <Descriptions.Item label={t('order.fields.alias')}>{showText(getOrderIdAlias(order?.id || 0))}</Descriptions.Item>
          <Descriptions.Item label={t('order.fields.status')}>
            {order && <Tag color={getColorLabel(order?.status?.name, order?.prev_status_id)}>{capitalizeFirstLetter(order?.status?.name)}</Tag>}
          </Descriptions.Item>
          <Descriptions.Item label={t('order.fields.type')}>{showText(order?.type)}</Descriptions.Item>
          <Descriptions.Item label={t('order.fields.shipmentMethod')}>{showText(order?.shipping_method?.name)}</Descriptions.Item>
          <Descriptions.Item label={t('order.fields.createdAt')}>
            {order && <DateField value={order?.created_at} format={DEFAULT_DATE_TIME_FORMAT} />}
          </Descriptions.Item>
          <Descriptions.Item label={t('order.fields.updatedAt')}>
            {order && <DateField value={order?.updated_at} format={DEFAULT_DATE_TIME_FORMAT} />}
          </Descriptions.Item>
          <Descriptions.Item style={{ padding: 0 }} label={t('order.fields.total')}>{showText(currency(order?.total))}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}
