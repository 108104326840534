import { useTranslate } from '@pankod/refine-core'

import {
  Input,
  InputNumber,
  Select,
  Typography
} from '@pankod/refine-antd'
import { useEffect, useState } from 'react'

type NotVolSupplementProps = {
  edit: boolean
  supplementData: any
  supplementOptions: any
  shippingMethod: any
  handleNotVolSuplements: Function
}

// eslint-disable-next-line no-empty-pattern
export const NotVolSuplementsForm: React.FC<NotVolSupplementProps> = ({
  edit,
  supplementData,
  supplementOptions,
  handleNotVolSuplements
}) => {
  const t = useTranslate()

  const [description, setDescription] = useState('')
  const [supplement, setSupplement] = useState(0)
  const [reason, setReason] = useState('')

  const [supData, setSupData] = useState<any>()

  if (edit) {
    useEffect(() => {
      setDescription(supplementData?.description)
      setReason(supplementData?.reason)
      setSupplement(supplementData?.supplement)
      setSupData({
        description: supplementData?.description,
        supplement: supplementData?.supplement,
        reason: supplementData?.reason,
        volumetric: false,
        id: Number.isInteger(supplementData?.id) && supplementData?.id
      })
    }, [supplementData])
  }

  useEffect(() => {
    setSupData({
      description: description,
      supplement: supplement,
      reason: reason,
      volumetric: false,
      id: Number.isInteger(supplementData?.id) && supplementData?.id
    })
  }, [description, supplement, reason])

  useEffect(() => {
    handleNotVolSuplements(supData)
  }, [supData])

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.description')}</Typography>
        <Select
          style={{ width: '100%' }}
          options={supplementOptions.filter((x: any) => x.description !== 'unified')}
          defaultValue={supplementData?.description || undefined}
          getPopupContainer={trigger => trigger.parentElement}
          onChange={(value) => setDescription(value)}
        />
      </div>
      {(description === 'other' || description === 'refund') && (
        <div style={{ marginBottom: '10px' }}>
          <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.reason')}</Typography>
          <Input defaultValue={supplementData?.reason || ''} style={{ width: '100%' }} onChange={(event) => setReason(event.target?.value)} />
        </div>
      )}
      <div style={{ marginBottom: '10px' }}>
        <Typography style={{ fontSize: 14, marginBottom: '10px', fontWeight: 700 }}>{t('supplement.fields.supplement')}</Typography>
        <InputNumber max={description && description.toLowerCase().startsWith('refund') ? 0 : undefined} defaultValue={supplementData?.supplement || 0} onChange={(value) => setSupplement(Number(value ?? 0))} decimalSeparator=',' />
      </div>
    </>
  )
}
