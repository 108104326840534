import { AutoComplete, Icons, Input } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'

import { supabaseClient } from 'utility'

const { SearchOutlined } = Icons

type UserFinderProps = {
  updateUser: Function
}

export const UserFinder: React.FC<UserFinderProps> = ({ updateUser }) => {
  const [value, setValue] = useState<string>('')
  const [options, setOptions] = useState<any[]>([])

  const updateSelected = (item: any) => {
    console.log(item)
    updateUser(item)
  }

  const renderItem = (label: string, item: any) => {
    return (
      <div onClick={(e) => {
        e.preventDefault()
        updateSelected(item)
      }}>
        <p style={{ height: '15px', textAlign: 'start' }}>{label}</p>
      </div>
    )
  }

  const refetchPosts = async () => {
    const { data: users, error: errorUsers } = await supabaseClient.from('users')
      .select('id, email, name')
      .or(`email.ilike.%${value}%`)
      .eq('role_id', 5)
      .eq('deleted', false)

    if (errorUsers) {
      return
    }

    if (users) {
      const data = [...users]
      data.forEach(user => {
        user.label = renderItem(user.name, user)
      })

      if (data.length > 0) {
        setOptions([
          {
            label: (<p>{'Users'}</p>),
            options: data
          }
        ])
      }
    }
  }

  useEffect(() => {
    setOptions([])
    refetchPosts()
  }, [value])

  return (
    <>
      <AutoComplete
        style={{ width: '100%', marginBottom: '1rem' }}
        filterOption={false}
        options={options}
        onSearch={(value: string) => setValue(value)}
      >
        <Input
          size="large"
          placeholder="Search users"
          suffix={<SearchOutlined />}
        />
      </AutoComplete>
    </>
  )
}
