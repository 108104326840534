import { Col, Row } from '@pankod/refine-antd'
import {
  useShow,
  IResourceComponentsProps
} from '@pankod/refine-core'
import { AuditCompanyOrder } from 'components/company_order/auditCompanyOrder'
import { CompanyOrder } from 'components/company_order/companyOrder'

import { definitions } from 'interfaces'

export const CompanyOrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<definitions['company_order']>({
    metaData: {
      select:
        '*, user_id(id, *), shipping_address_id(id, *)'
    }
  })

  const { data } = queryResult
  const companyOrder = data?.data

  if (!companyOrder) return <></>

  return (
    <>
      <Row style={{ marginLeft: '-7.5px', marginRight: '-7.5px' }} gutter={[15, 15]}>
        <Col style={{ display: 'flex' }} xs={24}>
          <CompanyOrder companyOrder={companyOrder} refetch={queryResult.refetch} />
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem', marginLeft: '-7.5px', marginRight: '-7.5px' }}>
        <Col style={{ display: 'flex', backgroundColor: 'white' }} xs={24}>
          <AuditCompanyOrder orderId={companyOrder.id} />
        </Col>
      </Row>
    </>
  )
}
