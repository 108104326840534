export const getOrderIdAlias = (orderId: number): string => {
  const letters = 'ABCDEFGHIJ'
  let alias = ''
  Array.from(orderId.toString()).forEach((number: any) => { alias += letters[number] })
  return alias
}

export const getOrderIdByAlias = (alias: string): number => {
  const numbers = 'A B C D E F G H I J'.split(' ')
  let orderId = ''
  Array.from(alias).forEach((letter: any) => {
    const val = numbers.findIndex((l) => l === letter)
    orderId += val
  })
  return parseInt(orderId)
}
