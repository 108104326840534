import { useTranslate } from '@pankod/refine-core'

import {
  Checkbox,
  Drawer,
  DrawerProps,
  Form,
  Grid,
  Icons,
  InputNumber
} from '@pankod/refine-antd'
import { supabaseClient } from 'utility/supabaseClient'
import { useEffect, useState } from 'react'
import MDEditor from '@uiw/react-md-editor'
import { Button, List, Typography } from 'antd'

type BannerTextConfiguratorProps = {
  drawerProps: DrawerProps
}

export const BannerTextConfigurator: React.FC<BannerTextConfiguratorProps> = ({
  drawerProps
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const [currentConfig, setCurrentConfig] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const [checkWeb, setCheckWeb] = useState<boolean>(false)
  const [checkMobile, setCheckMobile] = useState<boolean>(false)
  const [mdvalue, setMDValue] = useState('')
  const [msgList, setMsgList] = useState<string[]>([])
  const [timeTransition, setTimeTransition] = useState<number>(3)

  useEffect(() => {
    supabaseClient.from('variables').select('value').eq('name', 'BANNER_INFO_CONFIG').then(({ data }: any) => {
      if (data && data[0] && data[0].value) {
        const cConfig = JSON.parse(data[0].value)
        setCurrentConfig(cConfig)
        setCheckWeb(cConfig.checkWeb)
        setCheckMobile(cConfig.checkMobile)
        setMsgList(cConfig.msgList)
        setTimeTransition(cConfig.timeTransition)
      }
    })
  }, [])

  const addMsg = (msg: any) => {
    const currentMsgs = [...msgList]
    currentMsgs.push(msg)
    setMsgList([...currentMsgs])
    setMDValue('')
  }

  const removeMsg = (i: number) => {
    const elements = [...msgList]
    elements.splice(i, 1)
    setMsgList([...elements])
  }

  const saveConfig = async () => {
    setShowError(false)
    setShowSuccess(false)
    setLoading(true)
    const config = {
      checkWeb: checkWeb,
      checkMobile: checkMobile,
      msgList: msgList,
      timeTransition: (msgList.length > 1) ? timeTransition : 0
    }

    const { error } = await supabaseClient.from('variables').update({
      value: JSON.stringify(config)
    }).eq('name', 'BANNER_INFO_CONFIG')

    if (error) {
      setShowError(true)
    }

    setShowSuccess(true)
    setLoading(false)
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: '2rem' }}
      zIndex={1001}
    >
      <Form
        layout="vertical"
        initialValues={currentConfig || {
          enabledWeb: false,
          enabledMobile: false,
          scrollable: false,
          info: [],
          velocity: 0
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Form.Item
            name="Enabled in web"
          >
            <Checkbox onChange={(e: any) => setCheckWeb(e.target.checked)} checked={checkWeb} >
              <b>{t('banners.fields.enabledWeb')}</b>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="Enabled in mobile"
          >
            <Checkbox onChange={(e: any) => setCheckMobile(e.target.checked)} checked={checkMobile} >
              <b>{t('banners.fields.enabledMobile')}</b>
            </Checkbox>
          </Form.Item>
        </div>

        <div>
          <Typography style={{ marginBottom: 10 }}><b>{t('banners.fields.typeMsg')}</b></Typography>
          <MDEditor
            value={mdvalue}
            onChange={(e: any) => setMDValue(e)}
          />
          <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => addMsg(mdvalue)} >{t('banners.fields.add')}</Button>
            {(msgList.length > 1) && <div style={{ gap: 10, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
              <Typography><b>{t('banners.fields.showMessageTime')}</b></Typography>
              <InputNumber value={timeTransition} onChange={(value: number) => setTimeTransition(value)} />
            </div>}
          </div>
        </div>

        <List style={{ margin: 10 }}>
          {msgList.map((m: string, i: number) => {
            return <div key={i} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 20 }}>
              <MDEditor.Markdown source={m} style={{ backgroundColor: 'transparent', color: 'black', whiteSpace: 'pre-wrap' }} />
              <Button type='text' onClick={() => removeMsg(i)} >
                <Icons.DeleteOutlined />
              </Button>
            </div>
          })}
        </List>

        {(showError) && <Typography style={{ color: 'red' }}><b>{t('banners.fields.error')}</b></Typography>}
        {(showSuccess) && <Typography style={{ color: 'green' }}><b>{t('banners.fields.success')}</b></Typography>}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
          <Button disabled={msgList.length === 0} loading={loading} type='primary' onClick={() => saveConfig()}>
            {t('banners.fields.save')}
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}
