import { DatePicker, Drawer, ExportButton, Grid, Space, Typography, useDrawerForm } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { ComponentProps, useState } from 'react'

export const ExportDrawerButton = (props: ComponentProps<typeof ExportToExcelButton>) => {
  const { RangePicker } = DatePicker

  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()
  const [dates, setDates] = useState<[string, string]>()

  const {
    drawerProps,
    close,
    show
  } = useDrawerForm({
    action: 'create',
    redirect: false
  })

  const changeDates: ComponentProps<typeof RangePicker>['onChange'] = (values) => {
    if (!values || !values[0] || !values[1]) return setDates(undefined)
    const startDate = values[0]
    const endDate = values[1]
    const dateStart = `${startDate.get('year')}-${(startDate.get('month') + 1 > 9) ? startDate.get('month') + 1 : '0' + (startDate.get('month') + 1)}-${(startDate.get('date') > 9) ? startDate.get('date') : '0' + startDate.get('date')}`
    const dateEnd = `${endDate.get('year')}-${(endDate.get('month') + 1 > 9) ? endDate.get('month') + 1 : '0' + (endDate.get('month') + 1)}-${(endDate.get('date') > 9) ? endDate.get('date') : '0' + endDate.get('date')}`
    setDates([dateStart, dateEnd])
  }

  return (
    <>
      <ExportButton onClick={() => show()} />
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? '800px' : '100%'}
        bodyStyle={{ padding: 0 }}
        zIndex={1001}
      >
        <Space style={{ width: '100%', display: 'grid', marginTop: '5%', marginLeft: '10%' }} direction="vertical" size={12}>
          <Typography.Text>
            {t('common.labelDate')}
          </Typography.Text>

          <RangePicker showTime={false} onChange={changeDates} />

          <ExportToExcelButton
            {...props}
            close={close}
            style={{ marginTop: '1%' }}
            type='primary'
            disabled={!dates || dates.length < 2}
            filters={{ dates }}
          />
        </Space>
      </Drawer>
    </>
  )
}
