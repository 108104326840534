import { useTranslate } from '@pankod/refine-core'
import {
  List,
  Table,
  Space,
  EditButton,
  CreateButton,
  useTable,
  useModal,
  useDrawerForm,
  BooleanField,
  FilterDropdownProps,
  FilterDropdown,
  Radio
} from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'
import { useState } from 'react'
import { CreateChildCategory } from 'components/childMarketplaceCategory/create'
import { EditChildCategory } from 'components/childMarketplaceCategory/edit'
import { QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'

export const ChildrenCategories: React.FC<{ record: definitions['marketplace_categories'] }> = ({ record }) => {
  const t = useTranslate()

  const { tableProps: postTableProps, filters, setFilters, tableQueryResult } = useTable<definitions['marketplace_categories']>({
    resource: 'marketplace_categories',
    permanentFilter: [
      {
        field: 'parent_id',
        operator: 'eq',
        value: record.id
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: false
      }
    ],
    initialSorter: [
      {
        field: 'weight',
        order: 'asc'
      }
    ]
  })

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<definitions['marketplace_categories']>({
    action: 'edit',
    resource: 'marketplace_categories',
    redirect: false
  })

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<definitions['marketplace_categories']>({
    action: 'create',
    resource: 'marketplace_categories',
    redirect: false
  })

  createFormProps.initialValues = { parent_id: record.id }

  const { modalProps, show, close } = useModal()
  const [id, setId] = useState(0)

  return (
    <List
      title={`${record.id}. ${record.name}`}
      createButtonProps={undefined}
      pageHeaderProps={{
        extra:
          <CreateButton onClick={() => createShow()}>
            {t('actions.create')}
          </CreateButton>
      }}
    >
      <Table {...postTableProps}>
        <Table.Column
          key="id"
          dataIndex="id"
          title={t('marketplace_categories.fields.id')}
          sorter
        />
        <Table.Column
          key="name"
          dataIndex="name"
          title={t('marketplace_categories.fields.name')}
          align='center'
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
              props={props}
              entity='marketplace_categories'
              optionLabel='name'
              optionValue='id'
              filters={[
                {
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                },
                {
                  field: 'parent_id',
                  operator: 'eq',
                  value: record.id
                }
              ]}
            />
          )}
        />
        <Table.Column
          key="description"
          dataIndex="description"
          title={t('marketplace_categories.fields.description')}
          align='center'
          sorter
        />
        <Table.Column
          key="weight"
          dataIndex="weight"
          title={t('marketplace_categories.fields.weight')}
          align='center'
          sorter
          filterDropdown={() => (
            <QuantityFilterDropdown
              field='weight'
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
        <Table.Column
          key="is_in_home"
          dataIndex="is_in_home"
          title={t('marketplace.fields.isInHome')}
          align='center'
          render={(value) => (
            <BooleanField
              value={value}
              valueLabelTrue={t('status.yes')}
              valueLabelFalse={t('status.no')}
            />
          )}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Radio.Group>
                <Radio value='true'>{t('status.yes')}</Radio>
                <Radio value='false'>{t('status.no')}</Radio>
              </Radio.Group>
            </FilterDropdown>
          )}
        />
        <Table.Column<definitions['marketplace_categories']>
          title={t('table.actions')}
          dataIndex="actions"
          align='center'
          render={(_, record) => (
            <Space>
              <EditButton
                onClick={() => editShow(record.id)}
                hideText
              />
              <ButtonLogicDelete click={() => {
                show()
                setId(record.id)
              }} />
                <DeleteModal
                  modalProps={modalProps}
                  close={close}
                  id={id}
                  entity='marketplace_categories'
                  tableQueryResult={tableQueryResult}
                />
            </Space>
          )}
        />
      </Table>
      <EditChildCategory
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateChildCategory
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </List>
  )
}
